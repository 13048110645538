import React ,{useEffect, useState}from 'react'
import AwaitingLogHeader from './AwaitingLogHeader';
import AwaitingLogTab from './AwaitingLogTab';
import { Button, Col, DatePicker, Divider, Dropdown, Input, Select, Space, Tooltip } from "antd";
import dayjs from 'dayjs';
import "./awaitingLog.css";
import { getCurrentFiscalYear } from '../../../utils/dateFormatter';
import { decryptData } from '../../../utils/localStorageEncodeDecode';
import { GetDropdownAreaManagerAsync, GetDropdownStateHeadAsync, GetDropdownZonalHeadAsync } from '../../../redux/stateData/stateDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import Status from '../../../components/allSchemeStatus/allSchemeStatus';
import FullScreenLoader from '../../../components/loader/FullScreenLoader';
import FinancialYearButton from '../../../components/financialYearButton/FinancialYearButton';

const currentFiscalYear = getCurrentFiscalYear();

const initPayloadData = {
    fyear: currentFiscalYear,
    schemeType: 1,
    // state: 1,
    // userid: 0,
    month: dayjs().month() + 1,
    monthEnd: dayjs().month() + 1,
    fyear: "",
    pageSize: 150,
    pageNumber: 1,
    totalPage: 0,
    enforcedMonth: 0,
    sortingOrder: "ascend",
    sortingType: "DealerCode",
    selectId: 0,
  };
const CollectionApprovalLogSchemes = () => {
  const dispatch = useDispatch()
  const { Option } = Select;
    const [payload, setPayload] = useState(initPayloadData);
    const [collectionData, setCollectionData] = useState(initPayloadData);
    const [stateHeadData, setStateHeadData] = useState([])
    const [collectionToggle, setCollectionToggle] = useState(false);
    const [selectUserName, setSelectUserName] = useState(0)
    const [selectedStateId, setSelectedStateId] = useState(0);
    
    const { userRole, userId, userName } = decryptData(sessionStorage.getItem("userInfo"));
    const [selectedStatus, setSelectedStatus] = useState("");
    const [zonalHeadData, setZonalHeadData] = useState([]);
    const [backendHtmlString, setBackendHtmlString] = useState('');
    const [areaMngrData, setAreaMngrData] = useState([]);
    const [selectedSH, setSelectedSH] = useState("");
    const [startMonth, setStartMonth] = useState(dayjs());
    const [enforcedMonth, setEnforcedMonth] = useState();
    const [selectedSHID, setSelectedSHID] = useState(0);
    const [selectedAMID, setSelectedAMID] = useState(0);
    const [selectedZHID, setSelectedZHID] = useState(0);
    const [selectedZH, setSelectedZH] = useState("");
    const [selectedAM, setSelectedAM] = useState("");
    const [areaDropdown, setAreaDropdown] = useState([]);
    const [fullPageLoader, setFullPageLoader] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [sendPayload, setSendPayload] = useState({});
    const [stateListArr, setStateListArr] = useState([]);
    const { loadingScheme } = useSelector((state) => state.trackCollection);
    const {
      getAreaManager,
      getStateHead,
      getZonalHead,
      getZonalHeadSH,
      loadingTrack,
    } = useSelector((state) => state.schemeTrack);
    // const {loadingState1} = useSelector((state) => state?.stateDataReducer)
    const { stateList, loadingState } = useSelector((state) => state.stateData);
    const financialYearStartMonth = 3;
    const currentDate = dayjs();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month();
    const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
    const financialYear = financialYearStartYear + 1;
    const [dateValue, setDateValue] = useState(financialYear);
    const { RangePicker } = DatePicker;
    let getApiData = () => {
      if (userRole == 'SADM') {
        dispatch(GetDropdownAreaManagerAsync({ userId: userId })).then((x) => {
          let resultData = x?.payload?.data || []
          if (resultData?.length != 0) {
            setStateHeadData([
              {
                key: '0',
                label: (
                  <span onClick={() => {
                    setCollectionToggle(!collectionToggle)
                    setCollectionData({ ...collectionData, selectId: 0 })
                    setSelectUserName("All Area Managers")
                  }}>All Area Managers</span>)
              },
              ...resultData?.map((item) => ({
                key: item?.id,
                label: (
                  <span
                    onClick={() => {
                      setCollectionToggle(!collectionToggle)
                      setSelectUserName(item?.aM)
                      setCollectionData({ ...collectionData, selectId: item?.id })
  
                    }}
                  >
                    {item?.aM}
                  </span>
                )
              }))
            ])
          }
        })
      } else if (userRole == 'ZADM') {
        dispatch(GetDropdownStateHeadAsync({ userId: userId })).then((x) => {
          let resultData = x?.payload?.data || []
          if (resultData?.length != 0) {
            setStateHeadData([
              {
                key: '0',
                label: (
                  <span onClick={() => {
                    setCollectionData({ ...collectionData, selectId: 0 })
                    setSelectUserName("All State Heads")
                    setCollectionToggle(!collectionToggle)
                  }}>All State Heads</span>)
              },
              ...resultData?.map((item) => ({
                key: item?.id,
                label: (
                  <span
                    onClick={() => {
                      setSelectUserName(item?.stateHeadName)
                      setCollectionToggle(!collectionToggle)
                      setCollectionData({ ...collectionData, selectId: item?.id })
                    }}
                  >
                    {item?.stateHeadName}
                  </span>
                )
              }))
            ])
          }
        })
      }
      else if (userRole == 'NSH' || userRole == 'PMIS') {
        dispatch(GetDropdownZonalHeadAsync({ userId: userId })).then((x) => {
          let resultData = x?.payload?.data || []
          if (resultData?.length != 0) {
            setStateHeadData([
              {
                key: '0',
                label: (
                  <span onClick={() => {
                    setCollectionToggle(!collectionToggle)
                    setCollectionData({ ...collectionData, selectId: 0 })
                    setSelectUserName("All Zonal Head")
                  }}>All Zonal Head</span>)
              },
              ...resultData?.map((item) => ({
                key: item?.id,
                label: (
                  <span
                    onClick={() => {
                      setSelectUserName(item?.zonalHeadName)
                      setCollectionToggle(!collectionToggle)
                      setCollectionData({ ...collectionData, selectId: item?.id })
                    }}
                  >
                    {item?.zonalHeadName}
                  </span>
                )
              }))
            ])
          }
        })
      }
    }
    useEffect(() => {
      if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
        setStateListArr(stateList?.data);
      } else {
        setStateListArr([]);
      }
    }, [stateList]);
    const getSH = () => {
      // if(userRole == 'ZADM') {
      //   return userName;
      // };
      if (userRole == "SADM" && userName) {
        return userName;
      }
      if (userRole == "ZADM") {
        return selectedSH == "" ? "All State Heads" : selectedSH;
      }
      if (userRole == "AMGR" && getStateHead?.data?.userName) {
        return getStateHead?.data?.userName;
      }
      if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
        return selectedSH == "" ? "All State Heads" : selectedSH;
      }
      return selectedSH == "All State Heads" ? "All State Heads" : selectedSH;
    };
    const handleStateSelect = (value, option) => {
      const id = option.key;
      setSelectedStateId(parseInt(id));
  
    };
    const getSchemeType = (schemeType) => {
      if (schemeType == 1) {
        return "With Delivery Plan";
      }
      if (schemeType == 2) {
        return "Without Delivery Plan";
      }
      if (schemeType == 3) {
        return "Slab Based";
      }
      if (schemeType == 8) {
        return "Single Target";
      }
      if (schemeType == 4) {
        return "Single Target With Upper Cap";
      }
      if (schemeType == 5) {
        return "Unconditional Installation";
      }
    };
    const subColType = [
      {
        key: "0",
        label: (
          <a
            onClick={() =>
              setCollectionData({
                ...collectionData,
                schemeType: 0,
                pageNumber: 1,
              })
            }
          >
            All Scheme Sub Type
          </a>
        ),
      },
      {
        key: "1",
        label: (
          <a
            onClick={() =>
              setCollectionData({
                ...collectionData,
                schemeType: 1,
                pageNumber: 1,
              })
            }
          >
            With Delivery Plan
          </a>
        ),
      },
      {
        key: "2",
        label: (
          <a
            onClick={() =>
              setCollectionData({
                ...collectionData,
                schemeType: 2,
                pageNumber: 1,
              })
            }
          >
            Without Delivery Plan
          </a>
        ),
        disabled: false,
      },
    ];
    const getZhId = () => {
      if (userRole == "ZADM") {
        return userId;
      }
      if (userRole == "SADM") {
        return getZonalHeadSH?.data?.userId;
      }
  
      if (userRole == "AMGR" && getZonalHead?.data?.userId) {
        return getZonalHead?.data?.userId;
      }
      if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
        return selectedZHID;
      }
    };
  
    const getShId = () => {
      if (userRole == "ZADM") {
        return selectedSHID;
      }
      if (userRole == "SADM") {
        return userId;
      }
      if (userRole == "AMGR" && getStateHead?.data?.userId) {
        return getStateHead?.data?.userId;
      }
      if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
        return selectedSHID;
      }
    };
  
    const getAmId = () => {
      if (userRole == "ZADM") {
        return selectedAMID;
      }
      if (userRole == "SADM") {
        return selectedAMID;
      }
      if (userRole == "AMGR") {
        return userId;
      }
      if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
        return selectedAMID;
      }
    };
    useEffect(() => {
      let lastTwoDigits = dateValue % 100;
      console.log(startMonth,"datedatedate")
      const payload = {
        startMonth: startMonth[0]
          ? dayjs(startMonth[0]).format("MM")
          : 0,
        endMonth: startMonth[1]
        ? dayjs(startMonth[1]).format("MM")
        : 0,
        userid: userId, // 18
        status: selectedStatus || 0,
        stateId: selectedStateId,
        stateHeadId: getShId(),
        areaManagerId: getAmId(),
        zonalHeadId: getZhId(),
        runningMonth: collectionData?.currentMonth,
        userRole: userRole,
        fyear: "FY" + lastTwoDigits,
        schemeType: collectionData?.schemeType,
        state: selectedStateId,
        dealerLocation: collectionData?.dealerLocation,
        schemeNumber: collectionData?.schemeNumber,
        dealerCode: collectionData?.dealerCode,
        pageSize: collectionData?.pageSize,
        pageNumber: collectionData?.pageNumber,
        sortingOrder: collectionData?.sortingOrder == "descend" ? "DESC" : "ASC",
        sortingType: collectionData?.sortingType || "DealerCode",
      };
    //   const timeout = setTimeout(() => {
    //   dispatch(getTrackCollectionDealersListApi(payload));
    // },300)
    // return () => {
    //   clearTimeout(timeout);
    // };
    }, [
      selectedStateId,
      selectedStatus,
      collectionData,
      selectedSHID,
      selectedAMID,
      getZonalHeadSH,
      getStateHead,
      getZonalHead,
      selectedZHID,
      dateValue
    ]);

    const handleAMClick = (event) => {
      const selectedAm = areaDropdown.find(
        (value) => value.id === parseInt(event.key)
      );
      if (selectedAm?.am) {
        setSelectedAM(selectedAm.am);
      }
      // console.log(selectedAm,"selectedAmselectedAm")
      // getDealerDropdownData(parseInt(event.key), "amID");
      // if (type === "amID") {
      setCollectionData(({
        ...collectionData,
        amID: userId,
        pageNumber: 1,
      }));
      // }
    };

    const disabledDate = (current) => {
      const today = dayjs().year(dateValue - 1);
      const currentYear = today.year();
      const startFinancialYear = new Date(currentYear, 3, 1);
      const endFinancialYear = new Date(currentYear + 1, 2, 31);
      const currentDate = current instanceof Date ? current : new Date(current);
      return currentDate < startFinancialYear || currentDate > endFinancialYear;
    };
  
    const getZH = () => {
      if (userRole == "ZADM") {
        return userName;
      }
      if (userRole == "SADM" && getZonalHeadSH?.data?.userName) {
        return getZonalHeadSH?.data?.userName;
      }
      if (userRole == "AMGR" && getZonalHead?.data?.userName) {
        return getZonalHead?.data?.userName;
      }
      if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
        return selectedZH == "" ? "All Zonal Head" : selectedZH;
      }
      return selectedZH == "All Zonal Head" ? "All Zonal Head" : selectedZH;
    };

    const getAM = () => {
      if (userRole == "ZADM") {
        return selectedAM == "" ? "All Area Managers" : selectedAM;
      }
      if (userRole == "SADM") {
        return selectedAM == "" ? "All Area Managers" : selectedAM;
      }
      if (userRole == "AMGR" && userName) {
        return userName;
      }
      if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
        return selectedAM == "" ? "All Area Managers" : selectedAM;
      }
      return selectedAM == "All Area Managers" ? "All Area Managers" : selectedAM;
    };

    return (
      <>
        <div className="create_scheme_wrap collection-schme-box">
          <Divider />

          {/* <div className="approval_heading">Approved Schemes Log</div> */}
          <AwaitingLogHeader
            payload={payload}
            onChangeFilter={(key, val) => setPayload({ ...payload, [key]: val })}
            showEnforcedMonth={true}
            startMonth={startMonth} setStartMonth={setStartMonth}
          />
              {/* {fullPageLoader ? <FullScreenLoader /> : ""} */}
      {/* <div>
        <div
          style={{ marginTop: "18px" }}
          id="space"
          className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
        >
          <div
            className="allSchmeStatus select_state heightauto"
            style={{ color: "red" }}
          >
             <Tooltip title={"All Status"}> 
            <Status
              isDisable={
                ["PMIS", "Finance", "COSM", "NSH"].includes(userRole)
                  ? true
                  : false
              }
              className=""
              selectedStatus={
                ["PMIS", "Finance", "COSM", "NSH"].includes(userRole)
                  ? 2
                  : selectedStatus
              }
              handleStatusDropdown={(statusId) => {
                setSelectedStatus(statusId);
                setCollectionData({ ...collectionData, pageNumber: 1 });
              }}
            />
            </Tooltip>
          </div>
          <Col>
          <Tooltip title={"All Scheme Sub Type"}>
            <Dropdown menu={{ items: subColType }}>
              <Button>
                <Space>
                  {collectionData?.schemeType == ""
                    ? "All Scheme Sub Type"
                    : getSchemeType(collectionData?.schemeType)}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            </Tooltip>
          </Col>
          <div className="marginauto">
            <FinancialYearButton handleDate={(x) => {
              setDateValue(x);
            }} />
          </div>
          <div className="marginauto">
          <Tooltip title={"Running Month"}>
            <DatePicker
              // onChange={(date) => handleInputChange('enforcedMonth', date)}
              onChange={(date, formatDate) => {
                if (formatDate == "") {
                  setCollectionData({
                    ...collectionData,
                    currentMonth: 0,
                    pageNumber: 1,
                  });
                  return;
                }
                setCollectionData({
                  ...collectionData,
                  currentMonth: new Date(formatDate).getMonth() + 1,
                  pageNumber: 1,
                });
              }}
              className="start"
              placeholder="Running Month"
              picker="month"
              value={enforcedMonth}
              allowClear
              disabled={
                collectionData.startMonth != "" || collectionData.endMonth != ""
              }
              disabledDate={disabledDate}
            />
            </Tooltip>
          </div>

          <div className="month ">
          <Tooltip title={"Start Month    End Month"}>
            <RangePicker
              mode={["month", "month"]}
              dateValue={dateValue}
              picker="month"
              onChange={(date) => {
         
                setCollectionData((collectionData) => ({
                  ...collectionData,
                  startMonth: date && date[0] ? date[0].format() : 0,
                  endMonth: date && date[1] ? date[1].format() : 0,
                  pageNumber: 1,
                }));
                setStartMonth(date);
              }}

              value={startMonth}
              disabled={
                collectionData.currentMonth != ""
              }
              disabledDate={disabledDate}
            />
              </Tooltip>
          </div>
          <Col>
          <Tooltip title={"All Zonal Heads"}>
            <Dropdown
              menu={{ items: zonalHeadData }}
              disabled={["ZADM", "SADM", "AMGR"].includes(userRole)}
            >
              <Button>
                <Space>
                  {getZH()}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            </Tooltip>
          </Col>

        </div>
        <div
          style={{ marginTop: "0" }}
          id="space"
          className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
        >
          {true && (
            <Col>
              <Tooltip title={"All State Heads"}> 
              <Dropdown
                menu={{ items: stateHeadData }}
                disabled={["SADM", "AMGR"].includes(userRole)}
              >
                <Button>
                  <Space>
                    {getSH()}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              </Tooltip>
            </Col>
          )}

          {true && (
            <Col>
              <Tooltip title={"All Area Managers"}> 
              <Dropdown
                menu={{ items: areaMngrData, onClick: handleAMClick }}
                disabled={["AMGR"].includes(userRole)}
              >
                <Button>
                  <Space>
                    {getAM()}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              </Tooltip>
            </Col>
          )}
                     <Tooltip title={"Select State"}>
          <Select
            showSearch
            className="select_state heightauto"
            placeholder="Select State"
            optionFilterProp="children"
            onSelect={handleStateSelect}
            value={selectedStateId === 0 ? 0 : stateListArr.find(option => option.stateID === selectedStateId)?.stateName}
          >
            <Option key={0} value={0}>
              All States
            </Option>
            {stateListArr?.map((option) => (
              <Option key={option.stateID} value={option.stateName}>
                {option.stateName}
              </Option>
            ))}
          </Select></Tooltip>
          <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
            <Input
              placeholder="Scheme No."
              value={collectionData?.schemeNumber}
              onChange={(e) =>
                setCollectionData({
                  ...collectionData,
                  schemeNumber: e.target.value,
                  pageNumber: 1,
                })
              }
            />
          </div>
          <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
            <Input
              placeholder="Dealer Code"
              value={collectionData?.dealerCode}
              onChange={(e) =>
                setCollectionData({
                  ...collectionData,
                  dealerCode: e.target.value,
                  pageNumber: 1,
                })
              }
            />
          </div>
          <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
            <Input
              placeholder="Dealer Location"
              value={collectionData?.dealerLocation}
              onChange={(e) =>
                setCollectionData({
                  ...collectionData,
                  dealerLocation: e.target.value,
                  pageNumber: 1,
                })
              }
            />
          </div>
        </div>
      </div> */}
          <AwaitingLogTab payload={payload}  startMonth={startMonth}/>
        </div>
      </>
    );
}

export default CollectionApprovalLogSchemes;
