import axios from "axios";
import ToastifyShow from "../../components/ToastifyShow";
import axiosAPI from "../../services/axiosApi"
import { decryptData } from "../../utils/localStorageEncodeDecode";
export const getStateList = async (data) => {
    try {

      const response=axiosAPI.post(`SchemeGenerate/GetState`)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const updateSchemeListApi = async (data) => {
    try {

      const response=axiosAPI.post(`Dashboard/SchemeStateRequestList`,data)
        .then((data) => {
          ToastifyShow("Schemes updated successfully","success")
          return data?.data;
        })
        .catch((error) => {
          ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
  export const getStateWiseData = async (payload) => {
    try {
      const { userRole, userId } = sessionStorage.getItem("userInfo")
      ? decryptData(sessionStorage.getItem("userInfo"))
      : {};
      const response=axiosAPI.post(userRole=="SADM"?`Summary/StateWiseReportsforStatehead`:userRole=="AMGR"?`Summary/StateWiseReportsforAreaManager`:userRole=="ZADM"?`Summary/StateWiseReportsforZonalHead`:`Dashboard/StateWiseReports`,payload)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };
  export const dealerTrackCounter = async (data) => {
    try {

      const response=axios.get(`https://swarajcdms.com/MobileAPI/API/CDMS/BillingData/DealerTractorCountMonthly`,data, {
        headers: { 'Content-Type': 'application/xml' }
      })
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const GetStatesbyZonalHeadId = async (data) => {
    try {
      const response=axiosAPI.post(`SchemeGenerate/GetStatesbyZonalHeadId`, data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const GetFinancialInputApi = async (data) => {
    try {
      const response=axiosAPI.post(`FinanceDashboard/GetStatesByMonthForFinanceInput`, data)
        .then((data) => {
          
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const GetYTDValueForFinanceInputApi = async (data) => {
    try {

      const response=axiosAPI.post(`FinanceDashboard/GetYTDValueForFinanceInput`, data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const getLockedSchemeTypeApi = async (amId) => {
    try {
      const getUrl = () => amId != '' ? `dashboard/DecodeTokenNEW?Nameid=`+amId : `dashboard/DecodeTokenNEW`  
      const {Token} = sessionStorage.getItem('userInfo') ? decryptData(sessionStorage.getItem('userInfo')) : {};
      const response = await axiosAPI.get(getUrl())
        .then((data) => {
         
          return data?.data;
          // if(data.data.scheme1 === true && value === 1 ){
          //         setLock(true)
          //         message.warning("Scheme selected is locked!!")
          // }
        })
        .catch((error) => {
          ToastifyShow("Something went wrong", "error")
          return error;
        });
      return response;
  
    } catch (err) {
      throw err;
    }
  };


  export const getLockedZonalHeadApi= async (data) => {
    try {

      const response = await axiosAPI.post("SchemeGenerate/GetStatesTopSlabbyZonalHeadId",data)
        .then((data) => {
         
          return data?.data;
          // if(data.data.scheme1 === true && value === 1 ){
          //         setLock(true)
          //         message.warning("Scheme selected is locked!!")
          // }
        })
        .catch((error) => {
          ToastifyShow("Something went wrong", "error")
          return error;
        });
      return response;
  
    } catch (err) {
      throw err;
    }
  };


  export const getLockedSchemeStateApi = async (data) => {
 
    try {
      const response = await axiosAPI.post(`Dashboard/GetTopTargetByStateId?EncryptedText=${data?.data?.replaceAll('_','/')}`,{
      })
        .then((data) => {
        
          return data?.data;
        })
        .catch((error) => {
          ToastifyShow("Something went wrong", "error")
          return error;
        });
      return response;
  
    } catch (err) {
      throw err;
    }
  };


  export const getAwaitingLockScheme = async (amId) => {
    try {
      const getUrl = () => `dashboard/DecodeTokenNEW`  
      const response = await axiosAPI.get(getUrl())
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
          ToastifyShow("Something went wrong", "error")
          return error;
        });
      return response;
  
    } catch (err) {
      throw err;
    }
  };

  export const GetDropdownStateHead = async (data) => {
    try {
      const response=axiosAPI.post(`SchemeGenerate/GetDropdownStateHead`, data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const GetDropdownAreaManager = async (data) => {
    try {
      const response=axiosAPI.post(`SchemeGenerate/GetDropdownAM`, data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const GetDropdownZonalHead = async (data) => {
    try {
      const response=axiosAPI.post(`SchemeGenerate/GetzonalHead`, data)
        .then((data) => {
          return data?.data;
        })
        .catch((error) => {
            ToastifyShow("Something went wrong","error")
          return error;
        });
      return response;
    } catch (err) {
      throw err;
    }
  };