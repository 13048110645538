import { Table, Tooltip } from "antd";
import InputTable from "../form/InputTable";

export const renderInputTable = (props) => {
  let { name, placeholder, setValue, value, error, index, onBlurAction } =
    props;

  return (
    <InputTable
      name={`[${index}].${name}`}
      placeholder={placeholder}
      setValue={(val) => setValue(`records.[${index}].${name}`, val)}
      value={value}
      error={error || ""}
      styleclass={error ? "form-input" : ""}
    />
  );
};

export const renderDisableInputTable = (props) => {
  let {
    name,
    placeholder,
    setValue,
    value,
    error,
    index,
    onBlurAction,
    disabled,
  } = props;

  return (
    <InputTable
      name={`[${index}].${name}`}
      placeholder={placeholder}
      setValue={(val) =>
        setValue(`records.[${index}].${name}`, parseInt(val || 0))
      }
      value={value}
      error={error}
      onBlurAction={onBlurAction}
      disabled={disabled}
      styleclass={error ? "form-input" : ""}
    />
  );
};
const getWidth = (width) => ({ width });
export const columnWidth = getWidth("120px");
export const columnWidth220 = getWidth("150px");
export const columnWidth180 = getWidth("180px");
export const columnWidth150 = getWidth("150px");
export const columnWidth120 = getWidth("100px");
export const smallColumnWidth = getWidth("90px");
export const columnWidth80 = getWidth("80px");
export const inputColumnWidth = getWidth("50px");
export const TableSummaryInput = ({ index, data }) => {
  return (
    <Table.Summary.Cell index={index} className="text-center font-bold">
      {data?.toLocaleString("en-IN") || ""}
    </Table.Summary.Cell>
  );
};

export const DummyTableInstallationSummary = () => {
  return (
    <>
      <Table.Summary.Cell index={0} className="text-center font-bold">
        Total
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1}></Table.Summary.Cell>
      <Table.Summary.Cell index={2}></Table.Summary.Cell>
      <Table.Summary.Cell index={3}></Table.Summary.Cell>
      <Table.Summary.Cell index={4}></Table.Summary.Cell>
      <Table.Summary.Cell index={5}></Table.Summary.Cell>
      <Table.Summary.Cell index={6}></Table.Summary.Cell>
      <Table.Summary.Cell index={7}></Table.Summary.Cell>
    </>
  );
};
export const DummyTableCollectionSummary = () => {
  return (
    <>
      <Table.Summary.Cell index={0} className="text-center font-bold">
        Total
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1}></Table.Summary.Cell>
      <Table.Summary.Cell index={2}></Table.Summary.Cell>
      <Table.Summary.Cell index={3}></Table.Summary.Cell>
      <Table.Summary.Cell index={4}></Table.Summary.Cell>
      <Table.Summary.Cell index={5}></Table.Summary.Cell>
      <Table.Summary.Cell index={6}></Table.Summary.Cell>
      <Table.Summary.Cell index={7}></Table.Summary.Cell>
      <Table.Summary.Cell index={8}></Table.Summary.Cell>
      <Table.Summary.Cell index={9}></Table.Summary.Cell>
      <Table.Summary.Cell index={10}></Table.Summary.Cell>
      <Table.Summary.Cell index={11}></Table.Summary.Cell>
      <Table.Summary.Cell index={12}></Table.Summary.Cell>
      <Table.Summary.Cell index={13}></Table.Summary.Cell>
      <Table.Summary.Cell index={14}></Table.Summary.Cell>
      <Table.Summary.Cell index={15}></Table.Summary.Cell>
      <Table.Summary.Cell index={16}></Table.Summary.Cell>
      <Table.Summary.Cell index={17}></Table.Summary.Cell>
      <Table.Summary.Cell index={18}></Table.Summary.Cell>
    </>
  );
};

export const DisableShow = () => {
  return <div className="input-disabled"></div>;
};
export const checkDisableText = (value, data) => {
  return !data ? (
    <DisableShow />
  ) : (
    <Tooltip title={value} className="cursor-default">
      {value?.toLocaleString("en-IN") || 0}
    </Tooltip>
  );
};

export const amountCheckIN = (data) => {
  return (
    <Tooltip title={data} className="cursor-default">
      {data?.toLocaleString("en-IN") || 0}
    </Tooltip>
  );
};
