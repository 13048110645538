import React, { useState } from "react";
import AreaManager from "../dashboard/areaManager";
import StateHead from "../dashboard/stateHead";
import ZonalHead from "../dashboard/zonalHead";
import PmisDashboard from "../dashboard/pmisDashboard";
import { decryptData } from "../../utils/localStorageEncodeDecode";

const Dashboard = () => {
  const [totalCountShow, SetTotalCountShow] = useState(false);
  const { userRole } = decryptData(sessionStorage.getItem("userInfo"));

  return (
    <>
      {userRole === "AMGR" ? (
        <AreaManager
          SetTotalCountShow={SetTotalCountShow}
          totalCountShow={totalCountShow}
        />
      ) : userRole === "SADM" ? (
        <StateHead
          SetTotalCountShow={SetTotalCountShow}
          totalCountShow={totalCountShow}
        />
      ) : ["PMIS", "NSH", "COSM", "Finance"].includes(userRole) ? (
        <PmisDashboard />
      ) : (
        userRole === "ZADM" && (
          <ZonalHead
            SetTotalCountShow={SetTotalCountShow}
            totalCountShow={totalCountShow}
            type="hideFYSelect1"
          />
        )
      )}
    </>
  );
};
export default Dashboard;
