import {
  Button,
  Table,
  Row,
  Col,
  Tag,
  DatePicker,
  Dropdown,
  Space,
  Select,
  Input,
  Pagination,
  message,
  Tooltip,
} from "antd";

import moment from "moment";
import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined, MoreOutlined, FilterOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import XLSX from "xlsx-js-style";
import axiosAPI from "../../../services/axiosApi";
import {
  getLockedZonalHeadApiAsync,
  getStateListApi,
} from "../../../redux/stateData/stateDataSlice";
import {
  getModificationCollectionAsync,
  getModificationHoldAsync,
} from "../../../redux/modification/modificationSlice";
import ModificationCollectionTable from "../modificationApproval/ModificationCollectionTable";
import Status from "../../../components/allSchemeStatus/allSchemeStatus";
import "../modificationApproval/ModificationInstallation";
import {
  getCurrentFiscalYear,
  getCurrentFiscalYear1,
} from "../../../utils/dateFormatter";
import { ReactComponent as Download } from "../../../assets/vector/download.svg";
import FinancialYearButton from "../../../components/financialYearButton/FinancialYearButton";
import { ReactComponent as Edit } from "../../../assets/vector/edit.svg";

import {
  trackGetAreaManagerApi,
  trackGetStateHeadbyAMIdApi,
  trackGetZonalHeadbyAMIdApi,
  trackZonalHeadbySHIdApi,
} from "../../../redux/schemetrack/schemaTrackSlice";

import ModificationEditSchemes from "../modificationApproval/EditSchemes/ModificationEditSchemes";
import ModificationEditAllSchemes from "./EditSchemes/ModificationEditAllSchemes";

import ToastifyShow from "../../../components/ToastifyShow";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import "./modificationCollection.css";
import FullScreenLoader from "../../../components/loader/FullScreenLoader";
import TableColorDetails from "../../../components/table/TableColorDetails";

const { RangePicker } = DatePicker;
const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  schemeType: 1,
  dealerCode: [],
  // month:new Date().getMonth() + 1,
  // monthEnd: new Date().getMonth() + 1,
  month: 0,
  monthEnd: 0,
  currentMonth: 0,
  selectedState: "",
  dealerLocation: "",
  schemeNumber: "",
  dealerCode: "",
  pageSize: "150",
  pageNumber: 1,
  sortingOrder: "ascend",
  sortingType: "DealerCode",
};
const ModificationCollection = ({
  props,
  onChangeFilter,
  showEnforcedMonth = false,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("Schemes");
  const [enforcedMonth, setEnforcedMonth] = useState();
  const [stateListArr, setStateListArr] = useState([]);
  const [schemeIdNo, setSchemeIdNo] = useState([]);
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const financialYearStartMonth = 3;
  const financialYearStartYear =
    currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
  const financialYear = financialYearStartYear + 1;
  const [dateValue, setDateValue] = useState(financialYear);
  useEffect(() => {
    setDateValue(financialYear);
  }, [financialYear]);
  const {loadingSaveHoldState}=useSelector((state)=>state.holdData)
  const { stateList, loadingState } = useSelector((state) => state.stateData);
  const {
    getAreaManager,
    getStateHead,
    getZonalHead,
    getZonalHeadSH,
    loadingTrack,
  } = useSelector((state) => state.schemeTrack);
  const [toggleEdit, setToggleEdit] = useState("1");

  const [selectedStateId, setSelectedStateId] = useState(0);
  const [selectedSchemeType, setSelectedSchemeType] = useState(1);
  const { userRole, userId, userName } = decryptData(
    sessionStorage.getItem("userInfo")
  );
  const [selectedStatus, setSelectedStatus] = useState("");
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const currentFiscalYear = getCurrentFiscalYear1(
    collectionData?.month || new Date()
  );
  const [stateHeadData, setStateHeadData] = useState([]);
  const [zonalHeadData, setZonalHeadData] = useState([]);
  const [areaMngrData, setAreaMngrData] = useState([]);
  const [selectedSH, setSelectedSH] = useState("");
  const [selectedSHID, setSelectedSHID] = useState(0);
  const [selectedAMID, setSelectedAMID] = useState(0);
  const [selectedZHID, setSelectedZHID] = useState(0);

  const [selectedZH, setSelectedZH] = useState("");
  const [selectedAM, setSelectedAM] = useState("");
  const [areaDropdown, setAreaDropdown] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [monthlyFilters, setMonthlyFilters] = useState({
    monthStart: new Date().getMonth() + 1,
    monthEnd: new Date().getMonth() + 1,
    enforcedMonth: 0,
  });
  const [dataSource, setDataSource] = useState([]);

  const handleEditClick = (e) => {
    if (selectedRowKeys.length > 0) {
      setToggleEdit("3");
      setSelectedRowKeys([])
    } else {
      message.warning({
        content: "Please select the checkbox",
        className: "custom-message",
      });
    }
  };
  useEffect(() => {
    let lastTwoDigits = dateValue % 100;
    dispatch(
      getLockedZonalHeadApiAsync({
        fyear: "FY" + lastTwoDigits,
        userid: userId,
      })
    );
  }, []);
  const handleClearFilter = () => {
    setCollectionData({ ...InitCollectionData });
    setEnforcedMonth(null);
    setSelectedMonth(null);
    setSelectedStatus(0);
    setSelectedLabel("Regular Scheme");
    setSelectedStateId(0);
    setSelectedAM(0);
    setSelectedZH(0);
    setSelectedSH(0);
    document.querySelector(".select_state .ant-select-selector").innerText =
      "All States";
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      getAM(true);
      getSH(true);
      return;
    }
    if (userRole == "ZADM") {
      getAM(true);
      getSH(true);
      return;
    }
    if (userRole == "SADM") {
      getAM(true);
      return;
    }
  };

  const captalizeObjKeys = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
    );

  const getCol = (item) => {
    let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
    let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
    return {
      Scheme_No: item.schemeNo,
      scheme: item,
      Dealer_Code: item.dealerCode,
      Dealer_Location: item.dealerLocation,
      Dealer_Name: item.dealerName,
      State: item.stateName,
      State_Head: item.stateHeadName,
      Territory_Manager: item.territoryManager,
      topTarget: item.topTarget,
      Start_Month:
        formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
      End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
      Industry: item.industry,
      B: item.oldBillingQty ? item.oldBillingQty : 0,
      I: Math.round(item.oldCollection),
      C: item.oldInstallation ? item.oldInstallation : 0,
      Opening_Stock_Nos: item.oldOpeningStock,
      Opening_Stock_Days: item.openingStockDays,
      Closing_OS_IN_LACS_Last_Third_Month: item.closingOutStandingMonth_1,
      Closing_OS_IN_LACS_Last_Two_Month: item.closingOutStandingMonth_2,
      Closing_OS_IN_LACS_Last_One_Month: item.closingOutStandingMonth_3,
      Days: item.outStandingDays,
      BG_Lacs: item.bgLacsCurrentYear,
      Installation_Plan_Nos: item.delayPlan,
      Billing_Details_B: item.billing,
      Billing_Details_i: item.installation,
      Billing_Details_c: Math.round(item.collection),
      Books_Collection_Target_T1: item.t1,
      Books_Collection_Target_T2: item.t2,
      Books_Collection_Target_T2T1: item.t2_T1,
      Books_Collection_Target_T3: item.t3,
      Books_Collection_Target_T3T2: item.t3_T2,
      Books_Collection_Target_T4: item.t4,
      Books_Collection_Target_T4T3: item.t4_T3,
      Books_Collection_Target_T5: item.t5,
      Books_Collection_Target_T5T4: item.t5_T4,
      Books_Collection_Target_T6: item.t6,
      Books_Collection_Target_T6T5: item.t6_T5,
      Payout_Rate_R1: item.r1,
      Payout_Rate_R2: item.r2,
      Payout_Rate_R3: item.r3,
      Payout_Rate_R4: item.r4,
      Payout_Rate_R5: item.r5,
      Payout_Rate_R6: item.r6,
      Payout_P1: item.p1,
      Payout_P2: item.p2,
      Payout_P3: item.p3,
      Payout_P4: item.p4,
      Payout_P5: item.p5,
      Payout_P6: item.p6,
      TEP_Vol: item.volume,
      TEP_Per_Tr: item.perTr,
      TEP_Amount: item.amount,
      Remarks: item.reason,
      closingStock: item.closingStock,
      closingOutStanding: item.closingOutStanding,
    };
  };

  const handleDownload = () => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };
    let selectedSchemeType = collectionData?.schemeType;
    if ([1, 2].includes(selectedSchemeType)) {
      if (dataSource?.length == 0) {
        ToastifyShow("Something went wrong", "error");
        return false;
      }

      let tab_text = `<table>
      <thead>
      <tr>
          <th rowspan="2">SCHEME NO</th>
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">TOP SLAB</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">Industry</th>
          <th rowspan="2">B</th>
          <th rowspan="2">I</th>
          <th rowspan="2">C</th>
     <th colspan="2">OPENING STOCK</th>
        <th colspan="4">CLOSING OS.(IN LACS)</th>
          <th>BG</th>
          <th>INSTALLATION PLAN</th>
          <th colspan="3">BILLING DETAILS</th>
          <th rowspan="2">CLOSING STOCK</th>
          <th rowspan="2">CLOSING OUTSTANDING (IN LACS)</th>
          <th colspan="11">BOOKING COLLECTION TARGETS(NO. OF TRS)</th>
          <th colspan="6">PAYOUT RATE</th>
          <th colspan="6">PAYOUT</th>
          <th rowspan="2">REMARKS</th>
          <th colspan="3">TOTAL EXPECTED PAYOUT</th>
          </tr>
      <tr>
         <th>NOS</th>
         <th>Days</th>
         <th>LAST 3rd MONTH</th>
         <th>LAST 2nd MONTH</th>
         <th>LAST 1st MONTH</th>
       <th>LAST 1st MONTH DAYS</th>
         <th>LACS</th>
         <th>NOS</th>
         <th>B</th>
         <th>I</th>
         <th>C</th>
         <th>T1</th>
         <th>T2</th>
         <th>T2-T1</th>
         <th>T3</th>
         <th>T3-T2</th>

         <th>T4</th>
         <th>T4-T3</th>
         <th>T5</th>
         <th>T5-T4</th>

         <th>T6</th>
         <th>T6-T5</th>


         <th>R1</th>
         <th>R2</th>
         <th>R3</th>

         <th>R4</th>
         <th>R5</th>
         <th>R6</th>


         <th>P1</th>
         <th>P2</th>
         <th>P3</th>  
         <th>P4</th>
         <th>P5</th>
         <th>P6</th>  
         <th>Expected Volume</th>
         <th>PER TR.</th>
         <th>AMOUNT</th>  
        </tr>
      </thead>`;

      dataSource?.forEach((x) => {
        tab_text += `<tr ><td>${x?.schemeNo}</td>
    <td>${x?.dealerCode}</td>
    <td>${x?.dealerLocation}</td>
    <td>${x?.dealerName}</td>
    <td>${x?.stateName}</td>
    <td>${x?.territoryManager}</td>
    <td>${x?.topTarget}</td>
    <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
    <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
    <td>${x?.industry}</td>
    <td>${x?.oldBillingQty}</td>
    <td>${x?.oldInstallation}</td>
    <td>${x?.oldCollection}</td>
    <td>${x?.oldOpeningStock}</td>
    <td>${x?.openingStockDays}</td>
    <td>${x?.closingOutStandingMonth_3}</td>
    <td>${x?.closingOutStandingMonth_2}</td>
    <td>${x?.closingOutStandingMonth_1}</td>
    <td>${x?.outStandingDays}</td>
    <td>${x?.bGLacsCurrentYear}</td>
    <td>${x?.delayPlan}</td>
    <td>${x?.billing}</td>
    <td>${x?.installation}</td>
    <td>${x?.collection}</td>
    <td>${x?.closingStock}</td>
    <td>${x?.closingOutStanding}</td>
    <td>${x?.t1}</td>
    <td>${x?.t2}</td>
    <td>${x?.t2_T1 && x?.topTarget?.includes("t2") ? x?.t2_T1 : 0}</td>
    <td>${x?.t3}</td>
    <td>${x?.t3_T2 && x?.topTarget?.includes("t3") ? x?.t3_T2 : 0}</td>
    <td>${x?.t4}</td>
    <td>${x?.t4_T3 && x?.topTarget?.includes("t4") ? x?.t4_T3 : 0}</td>
    <td>${x?.t5}</td>
    <td>${x?.t5_T4 && x?.topTarget?.includes("t5") ? x?.t5_T4 : 0}</td>
    <td>${x?.t6}</td>
    <td>${x?.t6_T5 && x?.topTarget?.includes("t6") ? x?.t6_T5 : 0}</td>
    <td>${x?.r1}</td>
    <td>${x?.r2}</td>
    <td>${x?.r3}</td>
    <td>${x?.r4}</td>
    <td>${x?.r5}</td>
    <td>${x?.r6}</td>

    <td>${x?.p1}</td>
    <td>${x?.p2}</td>
    <td>${x?.p3}</td>

    <td>${x?.p4}</td>
    <td>${x?.p5}</td>
    <td>${x?.p6}</td>

    <td>${x?.reason||""}</td>
    <td>${x?.volume}</td>
    <td>${x?.perTr}</td>
    <td>${x?.amount}</td>
    </tr>`;
      });

      let totals = {
        industry: 0,
        oldBillingQty: 0,
        oldInstallation: 0,
        oldCollection: 0,
        oldOpeningStock: 0,
        openingStockDays: 0,
        closingOutStandingMonth_1: 0,
        closingOutStandingMonth_2: 0,
        closingOutStandingMonth_3: 0,
        outStandingDays: 0,
        bgLacsCurrentYear: 0,
        delayPlan: 0,
        billing: 0,
        installation: 0,
        collection: 0,
        closingStock: 0,
        closingOutStanding: 0,
        t1: 0,
        t2: 0,
        t2_T1: 0,
        t3: 0,
        t3_T2: 0,
        t4: 0,
        t4_T3: 0,
        t5: 0,
        t5_T4: 0,
        t6: 0,
        t6_T5: 0,
        r1: 0,
        r2: 0,
        r3: 0,
        r4: 0,
        r5: 0,
        r6: 0,
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        reason: "",
        volume: 0,
        perTr: 0,
        amount: 0,
      };

      dataSource.forEach((item) => {
        for (let key in totals) {
          if (key === "t2_T1" && item.topTarget.includes("t2")) {
            totals[key] += parseInt(item[key]) || 0;
          } else if (key === "t3_T2" && item.topTarget.includes("t3")) {
            totals[key] += parseInt(item[key]) || 0;
          } else if (key === "t4_T3" && item.topTarget.includes("t4")) {
            totals[key] += parseInt(item[key]) || 0;
          } else if (key === "t5_T4" && item.topTarget.includes("t5")) {
            totals[key] += parseInt(item[key]) || 0;
          } else if (key === "t6_T5" && item.topTarget.includes("t6")) {
            totals[key] += parseInt(item[key]) || 0;
          } else if (
            !["t2_T1", "t3_T2", "t4_T3", "t5_T4", "t6_T5"].includes(key)
          ) {
            totals[key] += parseInt(item[key]) || 0;
          }
        }
      });

      totals.r1 = totals.t1 !== 0 ? parseInt(totals.p1 / totals.t1) : 0;
      totals.r2 = totals.t2 !== 0 ? parseInt(totals.p2 / totals.t2) : 0;
      totals.r3 = totals.t3 !== 0 ? parseInt(totals.p3 / totals.t3) : 0;
      totals.r4 = totals.t4 !== 0 ? parseInt(totals.p4 / totals.t4) : 0;
      totals.r5 = totals.t5 !== 0 ? parseInt(totals.p5 / totals.t5) : 0;
      totals.r6 = totals.t6 !== 0 ? parseInt(totals.p6 / totals.t6) : 0;
      totals.perTr =
        totals.volume !== 0 ? parseInt(totals.amount / totals.volume) : 0;

      let totalsRow = "<tr class='total-row'><td colspan='9' >Total</td>";
      for (let key in totals) {
        if (
          key != "reason" &&
          key != "closingStock" &&
          key != "closingOutStanding"
        ) {
          if (key.startsWith("r") || key === "perTr") {
            totalsRow += `<td>${totals[key]}</td>`;
          } else {
            totalsRow += `<td>${totals[key]}</td>`;
          }
        } else {
          totalsRow += `<td></td>`;
        }
      }
      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      // Add style to total row color
      const allColumns = dataSource.reduce((columns, item) => {
        Object.keys(item).forEach((key) => {
          if (!columns.includes(key)) {
            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1) {
          return "Invalid input";
        } else if (number <= 26) {
          return String.fromCharCode(number + 64);
        } else {
          let firstChar = String.fromCharCode((number - 1) / 26 + 64);
          let secondChar = String.fromCharCode(((number - 1) % 26) + 65);
          return firstChar + secondChar;
        }
      }

      let excelArr = [];
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(
          dataSource.length + 3
        ).toString()}`;
        excelArr.push(ele);
      }
      Object.keys(wb.Sheets[wb.SheetNames[0]])
        .filter((cell) => excelArr.includes(cell))
        .forEach((cell) => {
          wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
        });
      let nameFile =
        selectedSchemeType == 1
          ? "With_DeliveryPlan.xlsx"
          : "Without_Delivery_Plan.xlsx";
      // generate file
      XLSX.writeFile(wb, nameFile);
    }
  };

  useEffect(() => {


    if (toggleEdit == 1) {
      const timeout = setTimeout(() => {
      if (selectedLabel === "Hold Schemes") {
        getHoldData();
      } else {
        getApiData();
      }
    },300)
    return () => {
      clearTimeout(timeout);
    };
    }
  }, [toggleEdit]);

  const items = [
    {
      label: (
        <Button
          className="link"
          type="link"
          onClick={(e) => handleEditClick(e)}
        >
          Edit
        </Button>
      ),
      key: "1",
      icon: <Edit />,
    },
    {
      label: (
        <Link
          className="link"
          disabled={dataSource?.length == 0}
          onClick={(e) => handleDownload(e)}
        >
          Download
        </Link>
      ),
      icon: <Download />,
      key: "3",
    },
    {
      label: (
        <Link className="link" onClick={(e) => handleClearFilter(e)}>
          Clear Filters
        </Link>
      ),
      icon: <FilterOutlined />,
      key: "4",
    },
  ];
  const collapseProps = {
    items,
    trigger: ["click"],
  };

  // useEffect(() => {
  //   if (selectedSHID == 0) {
  //     dispatch(getStateListApi());
  //     return;
  //   }
  //   if (userRole !== "AMGR") {
  //     dispatch(getStateListApi());
  //   }
  // }, [selectedSHID]);

  const { collection, loadingState1,loadingModificationSaveState, loadingScheme } = useSelector(
    (state) => state.modifyCollection
  );
  useEffect(() => {
    setDataSource(collection?.data?.result?.approvedSchemeList);
  }, [collection]);

  const getZhId = () => {
    if (userRole == "ZADM") {
      return userId;
    }
    if (userRole == "SADM") {
      return getZonalHeadSH?.data?.userId;
    }

    if (userRole == "AMGR" && getZonalHead?.data?.userId) {
      return getZonalHead?.data?.userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedZHID;
    }
  };

  const getShId = () => {
    if (userRole == "ZADM") {
      return selectedSHID;
    }
    if (userRole == "SADM") {
      return userId;
    }
    if (userRole == "AMGR" && getStateHead?.data?.userId) {
      return getStateHead?.data?.userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedSHID;
    }
  };

  const getAmId = () => {
    if (userRole == "ZADM") {
      return selectedAMID;
    }
    if (userRole == "SADM") {
      return selectedAMID;
    }
    if (userRole == "AMGR") {
      return userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedAMID;
    }
  };

  let lastTwoDigits = dateValue % 100;
  const payload = {
    month: collectionData?.month
      ? new Date(collectionData?.month)?.getMonth() + 1
      : collectionData?.month,
    monthEnd: collectionData?.monthEnd
      ? new Date(collectionData?.monthEnd)?.getMonth() + 1
      : collectionData?.monthEnd,
    // month: new Date(collectionData?.month)?.getMonth() + 1,
    // monthEnd: new Date(collectionData?.monthEnd)?.getMonth() + 1,
    userid: userId,
    stateId: selectedStateId,
    stateHeadId: getShId(),
    areaManagerId: getAmId(),
    zonalHeadId: getZhId(),
    enforcedMonth: collectionData?.currentMonth,
    userRole: userRole,
    fyear: "FY" + lastTwoDigits,
    schemeType: collectionData?.schemeType,
    state: selectedStateId,
    dealerLocation: collectionData?.dealerLocation,
    schemeNumber: collectionData?.schemeNumber,
    dealerCode: collectionData?.dealerCode,
    pageSize: collectionData?.pageSize,
    pageNumber: collectionData?.pageNumber,
    sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
    sortingType: collectionData.sortingType || "DealerCode",
  };
  let getApiData = () => {
    dispatch(getModificationCollectionAsync(payload));
  };
  let getHoldData = () => {
    dispatch(getModificationHoldAsync(payload));
  };

  useEffect(() => {
    if (selectedLabel === "Hold Schemes") {
      const timeout = setTimeout(() => {
        getHoldData();
      }, 300);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      const timeout = setTimeout(() => {
      getApiData();
    }, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [
    selectedStateId,
    selectedStatus,
    monthlyFilters,
    collectionData,
    selectedSHID,
    selectedAMID,
    getZonalHeadSH,
    getStateHead,
    getZonalHead,
    selectedZHID,
    dateValue,
  ]);

  useEffect(() => {
    if (userRole != "AMGR") {
      dispatch(trackGetAreaManagerApi({ userid: userId }));
    }
    if (userRole !== "SADM") {
      // dispatch(trackGetStateHeadbyAMIdApi({"userid": userId}))
    }
    if (userRole == "SADM") {
      dispatch(trackZonalHeadbySHIdApi({ UserId: userId }));
    }
    if (userRole == "ZADM") {
      dispatch(trackGetZonalHeadbyAMIdApi({ userid: userId }));
      dispatch(trackZonalHeadbySHIdApi({ userid: userId }));
    }
    if (userRole == "AMGR") {
      dispatch(trackGetZonalHeadbyAMIdApi({ userid: userId }));
      dispatch(trackGetStateHeadbyAMIdApi({ userid: userId }));
      // dispatch(trackZonalHeadbySHIdApi({"userid": userId}))
    }
  }, [selectedStateId, selectedStatus, enforcedMonth]);

  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
      setStateListArr(stateList?.data);
    } else {
      setStateListArr([]);
    }
  }, [stateList]);

  const handleStateSelect = (value, option) => {
    const id = option.key;
    setSelectedStateId(parseInt(id));
  };

  // const disabledDate = (current, selectedMonth) => {
  //   // lock
  //   if (false) return true;
  //   const today = new Date();
  //   const currentYear = today.getFullYear() - 1;
  //   const currentMonth = today.getMonth();
  //   const futureYear = currentYear + 1;

  //   //disableDateRestriction
  //   if (false) {
  //     return null;
  //   }

  //   return (
  //     current &&
  //     (current.year() < currentYear ||
  //       (current.year() === currentYear && current.month() < 3) ||
  //       current.year() > futureYear ||
  //       (current.year() === futureYear && current.month() > 2))
  //   );
  // };
  const disabledDate = (current) => {
    const today = dayjs().year(dateValue - 1);
    const currentYear = today.year();

    // Calculate the start date of the current financial year (April of the current year)
    const startFinancialYear = new Date(currentYear, 3, 1); // Months are zero-based, so April is 3

    // Calculate the end date of the current financial year (March of the next year)
    const endFinancialYear = new Date(currentYear + 1, 2, 31); // March is 2

    // Convert current date to Date object if it's not already
    const currentDate = current instanceof Date ? current : new Date(current);

    // Disable dates that are outside the current financial year
    return currentDate < startFinancialYear || currentDate > endFinancialYear;
  };

  const handlePanelChange = (value) => {
    setSelectedMonth(value);
  };

  const getShDropdownData = (userId) => {
    axiosAPI
      .post(`SchemeGenerate/GetDropdownStateHead`, { userId })
      .then((result) => {
        setStateHeadData([
          {
            key: "0",
            label: (
              <span
                onClick={() => {
                  setSelectedSH("All State Heads");
                  setSelectedSHID(0);
                  setSelectedAMID(0);
                  setSelectedAM("All Area Managers");
                  setAreaMngrData([]);
                }}
              >
                All State Heads
              </span>
            ),
          },
          ...result?.data?.data?.map((item) => {
            return {
              key: item.id,
              label: (
                <span
                  onClick={() => {
                    setSelectedSH(item.stateHeadName);
                    setSelectedSHID(item.id);
                    setSelectedAMID(0);
                    setSelectedAM("All Area Managers");
                    getAmDropdownData(item.id);
                  }}
                >
                  {item.stateHeadName}
                </span>
              ),
            };
          }),
        ]);
      });
  };

  const getSH = () => {
    // if(userRole == 'ZADM') {
    //   return userName;
    // };
    if (userRole == "SADM" && userName) {
      return userName;
    }
    if (userRole == "ZADM") {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    if (userRole == "AMGR" && getStateHead?.data?.userName) {
      return getStateHead?.data?.userName;
    }
    return selectedSH == "All State Heads" ? "All State Heads" : selectedSH;
  };

  useEffect(() => {
    if (userRole == "ZADM") {
      getShDropdownData(userId);
    }
    if (userRole == "SADM") {
      getAmDropdownData(userId);
    }
  }, []);

  useEffect(() => {
    axiosAPI.post(`SchemeGenerate/GetzonalHead`).then((result) => {
      setZonalHeadData([
        {
          key: "0",
          label: (
            <span
              onClick={() => {
                setSelectedZH("All Zonal Head");
                setStateHeadData([]);
                setSelectedZHID(0);
                setSelectedSHID(0);
                setSelectedSH("All State Heads");
                setAreaMngrData([]);
                setSelectedAMID(0);
                setSelectedAM("All Area Managers");
              }}
            >
              All Zonal Head
            </span>
          ),
        },
        ...result.data.data?.map((item) => {
          return {
            key: item.id,
            label: (
              <span
                onClick={() => {
                  setSelectedZH(item.zonalHeadName);
                  setSelectedZHID(item.id);
                  setSelectedSHID(0);
                  setSelectedSH("All State Heads");
                  getShDropdownData(item.id);
                  setAreaMngrData([]);
                  setSelectedAMID(0);
                  setSelectedAM("All Area Managers");
                }}
              >
                {item.zonalHeadName}
              </span>
            ),
          };
        }),
      ]);
    });

    if (["SADM"].includes(userRole)) {
      getStateByStateHeadId(userId);
    }
  }, []);

  const getDealerDropdownData = (userId, type) => {
    const monthStart = collectionData?.startDate
      ? dayjs(collectionData?.startDate).month() + 1
      : dayjs().month() + 1;
    const monthEnd = collectionData?.endDate
      ? dayjs(collectionData?.endDate).month() + 1
      : dayjs().month() + 1;

    if (type === "amID") {
      setCollectionData((collectionData) => ({
        ...collectionData,
        amID: userId,
        pageNumber: 1,
      }));
    }
  };

  const handleAMClick = (event) => {
    const selectedAm = areaDropdown.find(
      (value) => value.id === parseInt(event.key)
    );
    if (selectedAm?.aM) {
      setSelectedAM(selectedAm.aM);
    }
    getDealerDropdownData(parseInt(event.key), "amID");
  };

  const getAmDropdownData = (userId) => {
    axiosAPI.post(`SchemeGenerate/GetDropdownAM`, { userId }).then((result) => {
      setAreaDropdown(result?.data?.data);
      setAreaMngrData([
        {
          key: "0",
          label: (
            <span
              onClick={() => {
                setSelectedAM("All Area Managers");
                setSelectedAMID(0);
              }}
            >
              All Area Managers
            </span>
          ),
        },
        ...result?.data?.data?.map((item) => {
          return {
            key: item.id,
            label: (
              <span onClick={() => setSelectedAMID(item.id)}>{item.aM}</span>
            ),
          };
        }),
      ]);
    });
  };

  const getAM = () => {
    if (userRole == "ZADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if (userRole == "SADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }

    return selectedAM == "All Area Managers" ? "All Area Managers" : selectedAM;
  };
  const getStateByStateHeadId = (SHID) => {
    let lastTwoDigits = dateValue % 100;
    const payload = {
      fyear: "FY" + lastTwoDigits,
      userid: SHID ? SHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatebyStateHeadId`, payload)
      .then((result) => {
        const data = result?.data?.data;
        setStateListArr([
          {
            stateID: data ? data?.stateID : 0,
            stateName: data ? data?.stateName : "stateName",
          },
        ]);
      });
  };

  const getStateByZonalHeadId = (ZHID) => {
    let lastTwoDigits = dateValue % 100;
    const payload = {
      fyear: "FY" + lastTwoDigits,
      userid: ZHID ? ZHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatesbyZonalHeadId`, payload)
      .then((result) => {
        const data = result.data.data;

        setStateListArr(data);
      });
  };

  useEffect(() => {
    if (userRole == "SADM") {
      getStateByStateHeadId(userId);
      return;
    }
    if (selectedSHID) {
      getStateByStateHeadId(selectedSHID);
    }
    if (userRole == "ZADM") {
      getStateByZonalHeadId(userId);
      return;
    }
  }, [selectedSHID]);

  useEffect(() => {
    if (["AMGR"].includes(userRole)) {
      getStateByStateHeadId(getStateHead?.data?.userId);
    }
  }, [getStateHead]);
  const subColType = [
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            setSelectedRowKeys([]);
            setCollectionData({
              ...collectionData,
              schemeType: 1,
              pageNumber: 1,
            });
          }}
        >
          With Delivery Plan
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            setSelectedRowKeys([]);
            setCollectionData({
              ...collectionData,
              schemeType: 2,
              pageNumber: 1,
            });
          }}
        >
          Without Delivery Plan
        </a>
      ),
      disabled: false,
    },
  ];

  const getSchemeType = (schemeType) => {
    if (schemeType == 1) {
      return "With Delivery Plan";
    }
    if (schemeType == 2) {
      return "Without Delivery Plan";
    }
  };
  const itemsforstatus = [
    {
      label: (
        <a
          onClick={() => {
            getApiData();
            setSelectedLabel("Regular Schemes");
          }}
        >
          Regular Schemes
        </a>
      ),

      key: "1",
    },
    {
      label: (
        <a
          onClick={() => {
            getHoldData();
            setSelectedLabel("Hold Schemes");
          }}
        >
          Hold Schemes
        </a>
      ),
      key: "2",
    },
  ];

  return (
    <>
      {toggleEdit == "1" ? (
        <div
          id="table"
          className="trackcollection create_scheme_wrap onemoth-date-selector view-schela news"
        >
          <div
            id="space"
            className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
          >
            <Col>
              <Tooltip title={"Scheme Type"}>
                <Dropdown menu={{ items: itemsforstatus }}>
                  <Button>
                    <Space>
                      {selectedLabel == "Schemes"
                        ? "Regular Scheme"
                        : selectedLabel}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Tooltip>
            </Col>

            <Col>
              <Tooltip title={"Select Scheme"}>
                <Dropdown menu={{ items: subColType }}>
                  <Button>
                    <Space>
                      {collectionData?.schemeType == 6
                        ? "All Scheme Sub Type"
                        : getSchemeType(collectionData?.schemeType)}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Tooltip>
            </Col>

            <div className="marginauto">
              <Tooltip title={"Running Month"}>
                <DatePicker
                  onChange={(date, formatDate) => {
                    if (formatDate == "") {
                      setCollectionData({
                        ...collectionData,
                        currentMonth: 0,
                        pageNumber: 1,
                      });
                      return;
                    }
                    setCollectionData({
                      ...collectionData,
                      currentMonth: new Date(formatDate).getMonth() + 1,
                      pageNumber: 1,
                    });
                  }}
                  className="start"
                  placeholder="Running Month"
                  picker="month"
                  value={enforcedMonth}
                  allowClear
                  disabled={
                    collectionData.month != "" || collectionData.monthEnd != ""
                  }
                />
              </Tooltip>
            </div>
            <div className="marginauto">
              <Tooltip title={"Financial Year"}>
                <FinancialYearButton
                  handleDate={(x) => {
                    setDateValue(x);
                  }}
                />
              </Tooltip>
            </div>
            <div className="month ">
            <Tooltip title={"Start Month       End Month"}>
              <RangePicker
                mode={["month", "month"]}
                picker="month"
                disabledDate={disabledDate}
                onChange={(date, formatDate) => {
                  setCollectionData((collectionData) => {
                    return {
                      ...collectionData,
                      month: date && date[0] ? date[0].format() : 0,
                      monthEnd: date && date[1] ? date[1].format() : 0,
                      pageNumber: 1,
                    }
                  });
                }}
                //   disabledDate={(current) => disabledDate(current, selectedMonth)}
                // disabled={[!showStartMonth, !showEndMonth]}
                onPanelChange={handlePanelChange}
                disabled={collectionData.currentMonth > 0}
                // value={selectedMonth}
              />
              </Tooltip>
            </div>

            {true && (
              <Col>
                <Tooltip title={"State Head"}>
                  <Dropdown
                    menu={{
                      items: stateHeadData,
                      onClick: () => {},
                    }}
                    disabled={["SADM"].includes(userRole)}
                  >
                    <Button>
                      <Space>
                        {getSH()}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Tooltip>
              </Col>
            )}
            {true && (
              <Col>
                <Tooltip title={"Area Manager"}>
                  <Dropdown
                    menu={{ items: areaMngrData, onClick: handleAMClick }}
                  >
                    <Button>
                      <Space>
                        {getAM()}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Tooltip>
              </Col>
            )}
          </div>

          <div
            style={{ marginTop: "0" }}
            id="space"
            className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
          >
            <Tooltip title={"Select State"}>
              <Select
                showSearch
                className="select_state heightauto"
                placeholder="Select State"
                optionFilterProp="children"
                onSelect={handleStateSelect}
                defaultValue={0}
              >
                <Option key={0} value={0}>
                  All States
                </Option>
                {stateListArr?.map((option) => (
                  <Option key={option.stateID} value={option.stateName}>
                    {option.stateName}
                  </Option>
                ))}
              </Select>
            </Tooltip>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
              <Input
                placeholder="Scheme No."
                value={collectionData.schemeNumber}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    schemeNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
              <Input
                style={{ marginLeft: "10px" }}
                placeholder="Dealer Code"
                value={collectionData.dealerCode}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    dealerCode: e.target.value,
                  })
                }
              />
            </div>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
              <Input
                style={{ marginLeft: "20px" }}
                placeholder="Dealer Location"
                value={collectionData.dealerLocation}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    dealerLocation: e.target.value,
                  })
                }
              />
            </div>
            <Col className="actionbuttons rightmargin">
              <Dropdown menu={{ items: items }}>
                <Button>
                  <Space>
                    Actions
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </div>

          <div className="fullwidths">
            <div
              className="recent-boxes fullwidths"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <h2 style={{ fontSize: "18px", padding: "20px" }}>
            Recent {getSchemeType(selectedSchemeType)}
          </h2> */}
            </div>
          </div>

          <ModificationCollectionTable
            setSchemeIdNo={setSchemeIdNo}
            selectedLabel={selectedLabel}
            schemeIdNo={schemeIdNo}
            setToggleEdit={() => setToggleEdit("2")}
            dataSource={dataSource}
            selectedStatus={selectedStatus}
            selectedSchemeType={selectedSchemeType}
            monthlyFilters={monthlyFilters}
            selectedStateId={selectedStateId}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowKeys={selectedRowKeys}
            loading={
              loadingState1 == "pending" ||
              loadingState == "pending" ||
              loadingTrack == "pending" ||
              loadingScheme == "pending"
                ? true
                : false
            }
            handleChange={(pagination, filters, sorter) => {
              setCollectionData({
                ...collectionData,
                sortingOrder: sorter?.order,
                sortingType: sorter?.columnKey,
              });
            }}
          />

          <Pagination
            defaultCurrent={1}
            className="paginationDesign"
            // style={{ position: "absolute", right: "12px", marginTop: "-47px" }}
            current={collectionData.pageNumber}
            onChange={(pageNumber, pageSize) =>
              setCollectionData({ ...collectionData, pageNumber, pageSize })
            }
            total={collection?.data?.totalCount}
            showSizeChanger
            pageSize={collectionData.pageSize}
            pageSizeOptions={["150", "250", "350"]}
          />

          <div className="All_scheme_status1">
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "green" }}
              ></div>
              Modified Data
            </div>
            <div className="Schemes_status">
              <div
                className="Status_color"
                style={{ backgroundColor: "#9999ff" }}
              ></div>
              Hold Data
            </div>
          </div>

          <div>
            <Row
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-end",
              }}
            ></Row>
          </div>
        </div>
      ) : toggleEdit == "2" ? (
        <ModificationEditSchemes
          backBtn={(x) => {
            setToggleEdit("1");
            setSelectedRowKeys([])
          }}
          selectedLabel={selectedLabel}
        />
      ) : (
        <ModificationEditAllSchemes
          backBtn={(x) => {
            setToggleEdit("1");
            setSelectedRowKeys([])
          }}
          selectedLabel={selectedLabel}
          selectedSchemeType={collectionData?.schemeType}
          schemeDetails={schemeIdNo}
        />
      )}
      {(loadingState1 == "pending" ||
      loadingModificationSaveState=="pending"||
      loadingSaveHoldState=="pending"||
        loadingState == "pending" ||
        loadingTrack == "pending" ||
        loadingScheme == "pending") && <FullScreenLoader />}
      {contextHolder}
    </>
  );
};
export default ModificationCollection;
