export const formatUnderscore = (text) => {
	if (!text || text === '') { return text };
	let splitText = text.split('_');
	return splitText.map((word) => word.charAt(0).toUpperCase() +  word.slice(1)).join(' ');
};


export const formatCurrency = (value, deciamalPlaces) => {
	if (!value || value === '') { return value };
	deciamalPlaces = deciamalPlaces || 2;
	return value?.toFixed(deciamalPlaces);
};

export const formatNumberDecimal=(value, decimalPlaces=2)=> {
	if (value % 1 !== 0) {
        // Check if the number has decimal places
        return value?.toFixed(decimalPlaces);
    } else {
        // If the number is a whole number, return it without applying toFixed()
        return value?.toString();
    }
}

export const uniqueByKey = (array, key) => {
    const seen = new Set();
    return array.reduce((acc, current) => {
      const value = current[key];
      if (!seen.has(value)) {
        seen.add(value);
        acc.push(value);
      }
      return acc;
    }, []);
  };