import React, { useEffect, useState } from "react";
import { Button, Space, DatePicker, Input, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import "./InstallationAllScheme.css";
import dayjs from "dayjs";
import { CREATE_SCHEME_ROLES } from "../../../config";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import FinancialYearButton from "../../../components/financialYearButton/FinancialYearButton";

const InstallationAllSchemeHeader = ({
  onChangeFilter,
  showEnforcedMonth,
  startMonth,
  setStartMonth,
  setDealerDetails,
  dealerDetails,
  showFilter,
  setDateValue,
  dateValue,
}) => {
  const [endMonth, setEndMonth] = useState(dayjs());
  const [enforcedMonth, setEnforcedMonth] = useState();
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    const userData = sessionStorage.getItem("userInfo")
      ? decryptData(sessionStorage.getItem("userInfo"))
      : {};
    setUserInfo(userData);
  }, []);

  useEffect(() => {
    if (dateValue) {
      setStartMonth(dayjs().year(dateValue) || dayjs());
      setEndMonth(dayjs().year(dateValue) || dayjs());
    }
  }, [dateValue]);

  const handleInputChange = (key, date) => {
    switch (key) {
      case "month":
        setStartMonth(date);
        setEnforcedMonth(null);
        break;
      case "monthEnd":
        setEndMonth(date);
        setEnforcedMonth(null);
        break;
      case "enforcedMonth":
        setEnforcedMonth(date);
        setStartMonth(null);
        setEndMonth(null);
        break;
      default:
        break;
    }
    const month = date ? new Date(date).getMonth() + 1 : 0;
    onChangeFilter(key, month);
  };

  const disabledDate = (current) => {
    if (!startMonth || startMonth === 0) {
      const currentYear = dayjs()?.year(dateValue);
      const fiscalYearStartMonth = 3; // April
      const fiscalYearEndMonth = 2; // March
      const isBeforeFiscalYearStart = current.month() < fiscalYearStartMonth;
      const isAfterFiscalYearEnd = current.month() > fiscalYearEndMonth;

      const isFiscalYear =
        (current.year() === currentYear.year() && !isBeforeFiscalYearStart) ||
        (current.year() === currentYear.year() - 1 && !isAfterFiscalYearEnd);
      return isFiscalYear;
    }

    const today = new Date(startMonth) || new Date(dateValue);
    const currentYear =
      today.getFullYear() && today.getMonth() < 2
        ? today.getFullYear() - 1
        : today.getFullYear();
    const fiscalYearStartMonth = 3;

    return (
      current &&
      (current.year() < currentYear ||
        (current.year() === currentYear &&
          current.month() < fiscalYearStartMonth) ||
        current.year() > currentYear + 1 ||
        (current.year() === currentYear + 1 &&
          current.month() >= fiscalYearStartMonth))
    );
  };

  return (
    <>
      <div className="All_scheme_inner">
        <Space className="all_Scheme_header">
          <div className="marginauto">
            <FinancialYearButton
              handleDate={(x) => {
                setDateValue(x);
              }}
            />
          </div>
          {showEnforcedMonth ? (
            <Tooltip title={"Running Month"}>
              <DatePicker
                onChange={(date) => handleInputChange("enforcedMonth", date)}
                className="start"
                placeholder="Running Month"
                picker="month"
                value={enforcedMonth}
                allowClear
                disabled={startMonth != null || endMonth != null}
                disabledDate={disabledDate}
              />
            </Tooltip>
          ) : null}

          <div className="month">
            <div>
              <Tooltip title={"Start Month"}>
                <DatePicker
                  onChange={(date) => handleInputChange("month", date)}
                  className="start"
                  placeholder="Start Month"
                  picker="month"
                  value={startMonth}
                  disabledDate={disabledDate}
                  disabled={enforcedMonth > 0}
                  //     disabledDate={(current) => {
                  //   const endDate = dayjs(endMonth).format("YYYY-MM-DD");
                  //   return current && current > dayjs(endDate, "YYYY-MM-DD");
                  // }}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip title={"End Month"}>
                <DatePicker
                  onChange={(date) => handleInputChange("monthEnd", date)}
                  className="end"
                  placeholder="End Month"
                  picker="month"
                  value={endMonth}
                  disabledDate={disabledDate}
                  disabled={enforcedMonth > 0}
                />
              </Tooltip>
            </div>
          </div>
          {showFilter && (
            <div className="d-flex my-2">
              <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search mx-2">
                <Tooltip title={"Dealer Code"}>
                  <Input
                    placeholder="Dealer Code"
                    value={dealerDetails?.code}
                    className="h-42 "
                    onChange={(e) => {
                      setDealerDetails({
                        ...dealerDetails,
                        code: e.target.value,
                      });
                    }}
                  />
                </Tooltip>
              </div>
              <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search mx-2">
                <Tooltip title={"Dealer Location"}>
                  <Input
                    placeholder="Dealer Location"
                    value={dealerDetails?.location}
                    className="h-42 "
                    onChange={(e) => {
                      setDealerDetails({
                        ...dealerDetails,
                        location: e.target.value,
                      });
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          )}
          {CREATE_SCHEME_ROLES.includes(userInfo?.userRole) ? (
            <div className="allscheme_btn_newscheme">
              <Link to="/installation/createNewScheme">
                <Button
                  className="create btn-big"
                  icon={<PlusOutlined style={{ color: "white" }} />}
                >
                  <span style={{ color: "#fff" }}>Create New Scheme</span>
                </Button>
              </Link>
            </div>
          ) : null}
        </Space>
      </div>
    </>
  );
};

export default InstallationAllSchemeHeader;
