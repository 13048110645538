import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getStateList,
  getStateWiseData,
  GetStatesbyZonalHeadId,
  GetFinancialInputApi,
  GetYTDValueForFinanceInputApi,
  getLockedSchemeTypeApi,
  GetDropdownStateHead,
  GetDropdownZonalHead,
  GetDropdownAreaManager,
  getAwaitingLockScheme,
  getLockedSchemeStateApi,
  getLockedZonalHeadApi,
  dealerTrackCounter,
  updateSchemeListApi
} from "./stateDataService";

export const getStateListApi = createAsyncThunk(
  "getStateListApi",
  async (data, thunkAPI) => {
    try {
      const response = await getStateList(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postSchemeListApi = createAsyncThunk(
  "postSchemeListApi",
  async (data, thunkAPI) => {
    try {
      const response = await updateSchemeListApi(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//
export const getStateWiseDataApi = createAsyncThunk(
  "getStateWiseDataApi",
  async (data, thunkAPI) => {
    try {
      const response = await getStateWiseData(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Summary/StateWiseReportsforZonalHead
//dealerTrackCounter

export const dealerTrackCounterApi = createAsyncThunk(
  "dealerTrackCounterApi",
  async (data, thunkAPI) => {
    try {
      const response = await dealerTrackCounter(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getCollectionDealersList = createAsyncThunk(
  "getCollectionDealersList",
  async (data, thunkAPI) => {
    try {
      const response = await getStateList(data);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetFinancialInputAsync = createAsyncThunk(
  "getfinancialInput",
  async (data, thunkAPI) => {
    try {
      const response = await GetFinancialInputApi(data);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const GetStatesbyZonalHeadIdApi = createAsyncThunk(
  "GetStatesbyZonalHeadIdApi",
  async (data, thunkAPI) => {
    try {
      const response = await GetStatesbyZonalHeadId(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);



export const GetYTDValueForFinanceInputAsync = createAsyncThunk(
  "GetYTDValueForFinanceInput",
  async (data, thunkAPI) => {
    try {
      const response = await GetYTDValueForFinanceInputApi(data);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const getLockedSchemeTypeAsync = createAsyncThunk("getLockedSchemeType",async (amId,thunkAPI) => {
    try {    
      const response = await getLockedSchemeTypeApi(amId); 
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//getLockedZonalHeadApi

export const getLockedZonalHeadApiAsync = createAsyncThunk(
  "getLockedZonalHeadApiAsync",
  async (data, thunkAPI) => {
    try {    
      const response = await getLockedZonalHeadApi(data); 
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getLockedSchemeStateApiAsync = createAsyncThunk(
  "getLockedSchemeStateApiAsync",
  async (data,thunkAPI) => {
    try {    
      const response = await getLockedSchemeStateApi(data); 
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAwaitingLockSchemeAsync = createAsyncThunk(
  "getAwaitingLockSchemeAsync",
  async ( _, thunkAPI) => {
    try {    
      const response = await getAwaitingLockScheme(); 
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const GetDropdownStateHeadAsync = createAsyncThunk(
  "GetDropdownStateHeadAsync",
  async ( amId, thunkAPI) => {
    try {    
      const response = await GetDropdownStateHead(amId); 
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetDropdownAreaManagerAsync = createAsyncThunk(
  "GetDropdownAreaManagerAsync",
  async ( amId, thunkAPI) => {
    try {    
      const response = await GetDropdownAreaManager(amId); 
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetDropdownZonalHeadAsync = createAsyncThunk(
  "GetDropdownZonalHeadAsync",
  async ( amId, thunkAPI) => {
    try {    
      const response = await GetDropdownZonalHead(amId); 
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const stateDataSlice = createSlice({
  name: "stateDataSlice",
  initialState: {
    stateList: {},
    finance: [],
    stateWiseData: {},
    ZonalHeadStateList: {},
    awaitingLockSchemes:[],
    loadingState: "idle",
    loadingDealer:"idle",
    dealerData:[],
    FinanceYtdValues : {},
    lockedStates :{}
  },
  reducers: {
    resetFinance:(state,action)=>{
      state.finance=[]
    }
    // resetState: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getStateListApi.pending, (state) => {
      state.loadingState = "pending";
    });
    builder.addCase(getStateListApi.fulfilled, (state, action) => {
      state.loadingState = "succeeded";
      state.stateList = action.payload;
      state.error = false;
    });
    builder.addCase(getStateListApi.rejected, (state) => {
      state.stateList = {};
      state.loadingState = "succeeded";
      state.error = true;
    });

    //dealerTrackCounterApi
    builder.addCase(dealerTrackCounterApi.pending, (state) => {
      state.loadingDealer = "pending";
    });
    builder.addCase(dealerTrackCounterApi.fulfilled, (state, action) => {
      state.loadingDealer = "succeeded";
      state.dealerData = action.payload.data;
      state.error = false;
    });
    builder.addCase(dealerTrackCounterApi.rejected, (state) => {
      state.dealerData = [];
      state.loadingDealer = "succeeded";
      state.error = true;
    });


    builder.addCase(getStateWiseDataApi.pending, (state) => {
      state.loadingState = "pending";
      state.loadingStateLoader = "pending";
    });
    builder.addCase(getStateWiseDataApi.fulfilled, (state, action) => {
   
      state.loadingState = "succeeded";
      state.loadingStateLoader = "succeeded";
      state.stateWiseData = action.payload.data;
      // state.stateWiseData = action.payload;
      state.error = false;
    });
    builder.addCase(getStateWiseDataApi.rejected, (state) => {
      state.stateWiseData = {};
      state.loadingState = "succeeded";
      state.loadingStateLoader ="succeeded";
      state.error = true;
    });
    builder.addCase(getCollectionDealersList.pending, (state) => {
      state.loadingState = "pending";
    });
    builder.addCase(
      getCollectionDealersList.fulfilled,
      (state, { payload }) => {
        state.loadingState = "succeeded";
        // state.stateWiseData = payload;
        state.error = false;
      }
    );
    builder.addCase(getCollectionDealersList.rejected, (state) => {
      state.stateWiseData = {};
      state.loadingState = "succeeded";
      state.error = true;
    });
    builder.addCase(
      GetStatesbyZonalHeadIdApi.fulfilled,
      (state, { payload }) => {
        
        state.ZonalHeadStateList = payload;
        state.loadingState = "succeeded";
        state.error = true;
      }
    );
    // Financial Input
    builder.addCase(GetFinancialInputAsync.pending, (state) => {
      state.loadingState = "pending";
    });
    builder.addCase(GetFinancialInputAsync.fulfilled, (state, { payload }) => {
   
      state.finance = payload.data;
      state.stateList = state;
      state.loadingState = "succeeded";
      state.error = true;
    });
    builder.addCase(GetFinancialInputAsync.rejected, (state) => {
      state.stateWiseData = {};
      state.loadingState = "succeeded";
      state.error = true;
    });


        // ytd values for finance Input
        builder.addCase(GetYTDValueForFinanceInputAsync.pending, (state) => {
          state.loadingState = "pending";
        });
        builder.addCase(GetYTDValueForFinanceInputAsync.fulfilled, (state, { payload }) => {
       
          state.FinanceYtdValues = payload.data;
          // state.stateList = state;
          state.loadingState = "succeeded";
          state.error = true;
        });
        builder.addCase(GetYTDValueForFinanceInputAsync.rejected, (state) => {
          // state.stateWiseData = {};
          state.loadingState = "succeeded";
          state.error = true;
        });



        // locked dropdown
        builder.addCase(getLockedSchemeTypeAsync.pending, (state) => {
          state.loadingState = "pending";
        });
        builder.addCase(getLockedSchemeTypeAsync.fulfilled, (state, { payload }) => {     
          state.lockedStates = payload;     
          // state.stateList = state;
          state.loadingState = "succeeded";
          state.error = true;
        });
        builder.addCase(getLockedSchemeTypeAsync.rejected, (state) => {
          // state.stateWiseData = {};
          state.loadingState = "succeeded";
          state.error = true;
        });
        builder.addCase(getLockedSchemeStateApiAsync.pending, (state) => {
          state.loadingState = "pending";
        });
        builder.addCase(getLockedSchemeStateApiAsync.fulfilled, (state, { payload }) => {     
          state.lockedStatesData = payload;     
          // state.stateList = state;
          state.loadingState = "succeeded";
          state.error = true;
        });
        builder.addCase(getLockedSchemeStateApiAsync.rejected, (state) => {
          // state.stateWiseData = {};
          state.loadingState = "succeeded";
          state.error = true;
        });

        //
        builder.addCase(GetDropdownStateHeadAsync.pending, (state) => {
          state.loadingState = "pending";
        });
        builder.addCase(GetDropdownStateHeadAsync.fulfilled, (state,payload) => {     
          state.stateHeadList = payload.data;     
          state.loadingState = "succeeded";
          state.error = true;
        });
        builder.addCase(GetDropdownStateHeadAsync.rejected, (state) => {
          state.stateHeadList = {};
          state.loadingState = "succeeded";
          state.error = true;
        });
        builder.addCase(GetDropdownAreaManagerAsync.pending, (state) => {
          state.loadingState = "pending";
        });
        builder.addCase(GetDropdownAreaManagerAsync.fulfilled, (state,payload) => {     
          state.areaManager = payload.data;     
          state.loadingState = "succeeded";
          state.error = true;
        });
        builder.addCase(GetDropdownAreaManagerAsync.rejected, (state) => {
          state.areaManager = {};
          state.loadingState = "succeeded";
          state.error = true;
        });
        builder.addCase(GetDropdownZonalHeadAsync.pending, (state) => {
          state.loadingState = "pending";
        });
        builder.addCase(GetDropdownZonalHeadAsync.fulfilled, (state,payload) => {     
          state.zonalHead = payload.data;     
          state.loadingState = "succeeded";
          state.error = true;
        });
        builder.addCase(GetDropdownZonalHeadAsync.rejected, (state) => {
          state.zonalHead = {};
          state.loadingState = "succeeded";
          state.error = true;
        });

        builder.addCase(getAwaitingLockSchemeAsync.pending, (state) => {
          state.loadingState = "pending";
        });
        builder.addCase(getAwaitingLockSchemeAsync.fulfilled, (state,payload) => { 
          state.awaitingLockSchemes = payload?.payload;     
          state.loadingState = "succeeded";
          state.error = true;
        });
        builder.addCase(getAwaitingLockSchemeAsync.rejected, (state) => {
          state.awaitingLockSchemes = {};
          state.loadingState = "succeeded";
          state.error = true;
        });


        builder.addCase(getLockedZonalHeadApiAsync.pending, (state) => {
          state.loadingStateLocked = "pending";
        });
        builder.addCase(getLockedZonalHeadApiAsync.fulfilled, (state,payload) => { 
          state.lockedSchemeZonal = payload?.payload;     
          state.loadingStateLocked = "succeeded";
          state.error = true;
        });
        builder.addCase(getLockedZonalHeadApiAsync.rejected, (state) => {
          state.lockedSchemeZonal = {};
          state.loadingStateLocked = "succeeded";
          state.error = true;
        });

        builder.addCase(postSchemeListApi.pending, (state) => {
          state.loadingSchemeList = "pending";
        });
        builder.addCase(postSchemeListApi.fulfilled, (state,payload) => { 
          state.loadingSchemeList = "succeeded";
        });
        builder.addCase(postSchemeListApi.rejected, (state) => {
          state.loadingSchemeList = "succeeded";
        });
        

  },
});



export const { resetFinance } = stateDataSlice.actions;

export default stateDataSlice.reducer;