import {
  Button,
  Table,
  Row,
  Col,
  Tag,
  DatePicker,
  Dropdown,
  Space,
  Select,
  Input,
  Pagination,
  Tooltip,
} from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { getStateListApi } from "../../../../redux/stateData/stateDataSlice";
import TrackCollectionTable from "./trackCollectionTable";
import Status from "../../../../components/allSchemeStatus/allSchemeStatus";
import "./TrackInstallation";
import {
  trackGetAreaManagerApi,
  trackGetStateHeadbyAMIdApi,
  trackGetZonalHeadbyAMIdApi,
  trackZonalHeadbySHIdApi,
} from "../../../../redux/schemetrack/schemaTrackSlice";
import { getTrackInstallationDealersListApi } from "../../../../redux/trackCollection/trackCollectionSlice";
import axiosAPI from "../../../../services/axiosApi";
import { getCurrentFiscalYear, getCurrentFiscalYear1 } from "../../../../utils/dateFormatter";
import XLSX from "xlsx-js-style";
import { DownOutlined, DownloadOutlined } from "@ant-design/icons";
import ToastifyShow from "../../../../components/ToastifyShow";
import FullScreenLoader from "../../../../components/loader/FullScreenLoader";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";
import MailPreviewModal from "../../../../components/modal/alertModal/MailPreviewModal";
import JoditEditor from "jodit-react";
import { parseInt } from "lodash";
import FinancialYearButton from "../../../../components/financialYearButton/FinancialYearButton";
const { RangePicker } = DatePicker;

const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  schemeType: 3,
  dealerCode: [],
  startMonth: 0,
  endMonth: 0,
  currentMonth: 0,
  selectedState: "",
  dealerLocation: "",
  schemeNumber: "",
  dealerCode: "",
  pageSize: "150",
  pageNumber: 1,
};

const TrackInstallation = ({
  props,
  onChangeFilter,
  showEnforcedMonth = false,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [openmailModal, setOpenMailModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [enforcedMonth, setEnforcedMonth] = useState();
  const [stateListArr, setStateListArr] = useState([]);
  const [areaManagerArr, setAreaManagerArr] = useState([]);
  const [backendHtmlString, setBackendHtmlString] = useState("");
  const [sendPayload, setSendPayload] = useState({});
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const financialYearStartMonth = 3;
  const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
  const financialYear = financialYearStartYear + 1;
  const [dateValue, setDateValue] = useState(financialYear);
  useEffect(() => {
    setDateValue(financialYear);
  }, [financialYear]);
  const { loadingScheme } = useSelector((state) => state.trackCollection);
  // const {loadingState1} = useSelector((state) => state?.stateDataReducer)
  const { stateList, loadingState } = useSelector((state) => state.stateData);
  const {
    getAreaManager,
    getStateHead,
    getZonalHead,
    getZonalHeadSH,
    loadingTrack,
  } = useSelector((state) => state.schemeTrack);
  const [selectedStateId, setSelectedStateId] = useState(0);
  const [selectedSchemeType, setSelectedSchemeType] = useState(InitCollectionData);
  const { userRole, userId, userName } = decryptData(
    sessionStorage.getItem("userInfo")
  );
  const [selectedStatus, setSelectedStatus] = useState("");

  const [stateHeadData, setStateHeadData] = useState([]);
  const [zonalHeadData, setZonalHeadData] = useState([]);
  const [areaMngrData, setAreaMngrData] = useState([]);
  const [selectedSH, setSelectedSH] = useState("");
  const [selectedSHID, setSelectedSHID] = useState(0);
  const [selectedAMID, setSelectedAMID] = useState(0);
  const [selectedZHID, setSelectedZHID] = useState(0);

  const [selectedZH, setSelectedZH] = useState("");
  const [selectedAM, setSelectedAM] = useState("");
  const [areaDropdown, setAreaDropdown] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [monthlyFilters, setMonthlyFilters] = useState({
    monthStart: new Date().getMonth() + 1,
    monthEnd: new Date().getMonth() + 1,
    enforcedMonth: 0,
  });

  const subColType = [
    //   {
    //     key: "0",
    //     label: (
    //       <a onClick={() => setCollectionData({...collectionData, schemeType: 0, pageNumber: 1})}>All Scheme Sub Type
    //       </a>
    //     ),
    //   },
    {
      key: "1",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 3,
              pageNumber: 1,
            })
          }
        >
          Slab Based
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 8,
              pageNumber: 1,
            })
          }
        >
          Single Target
        </a>
      ),
      disabled: false,
    },
    {
      key: "1",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 4,
              pageNumber: 1,
            })
          }
        >
          Single Target With Upper Cap
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 5,
              pageNumber: 1,
            })
          }
        >
          Unconditional Installation
        </a>
      ),
      disabled: false,
    },
  ];

  const getSchemeType = (schemeType) => {
    if (schemeType == 1) {
      return "With Delivery Plan";
    }
    if (schemeType == 2) {
      return "Without Delivery Plan";
    }
    if (schemeType == 3) {
      return "Slab Based";
    }
    if (schemeType == 8) {
      return "Single Target";
    }
    if (schemeType == 4) {
      return "Single Target With Upper Cap";
    }
    if (schemeType == 5) {
      return "Unconditional Installation";
    }
  };

  useEffect(() => {
    if (selectedSHID == 0) {
      dispatch(getStateListApi());
      return;
    }
    if (userRole == 'NSH') {
      dispatch(getStateListApi());
    }
  }, [selectedSHID]);

  const { dealersList } = useSelector((state) => state.trackCollection);

  const { trackInstallationList } = useSelector(
    (state) => state.trackCollection
  );
  const getZhId = () => {
    if (userRole == "ZADM") {
      return userId;
    }
    if (userRole == "SADM") {
      return getZonalHeadSH?.data?.userId;
    }

    if (userRole == "AMGR" && getZonalHead?.data?.userId) {
      return getZonalHead?.data?.userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedZHID;
    }
  };

  const getShId = () => {
    if (userRole == "ZADM") {
      return selectedSHID;
    }
    if (userRole == "SADM") {
      return userId;
    }
    if (userRole == "AMGR" && getStateHead?.data?.userId) {
      return getStateHead?.data?.userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedSHID;
    }
  };

  const getAmId = () => {
    if (userRole == "ZADM") {
      return selectedAMID;
    }
    if (userRole == "SADM") {
      return selectedAMID;
    }
    if (userRole == "AMGR") {
      return userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedAMID;
    }
  };

  useEffect(() => {
    let lastTwoDigits = dateValue % 100;
    const payload = {
      startMonth: collectionData?.startMonth
        ? new Date(collectionData?.startMonth)?.getMonth() + 1
        : collectionData?.startMonth,
      endMonth: collectionData?.endMonth
        ? new Date(collectionData?.endMonth)?.getMonth() + 1
        : collectionData?.endMonth,
      userid: userId, // 18
      status: selectedStatus || 0,
      stateId: selectedStateId,
      stateHeadId: getShId(),
      areaManagerId: getAmId(),
      zonalHeadId: getZhId(),
      runningMonth: collectionData?.currentMonth,
      userRole: userRole,
      fyear: "FY" + lastTwoDigits,
      schemeType: collectionData?.schemeType,
      state: selectedStateId,
      dealerLocation: collectionData?.dealerLocation,
      schemeNumber: collectionData?.schemeNumber,
      dealerCode: collectionData?.dealerCode,
      pageSize: collectionData?.pageSize,
      pageNumber: collectionData?.pageNumber,
      sortingOrder: collectionData.sortingOrder == "descend" ? "DESC" : "ASC",
      sortingType: collectionData.sortingType || "DealerCode",
      //  year: new Date(collectionData?.startMonth)?.getFullYear() || "",
    };
    const timeout = setTimeout(() => {
      dispatch(getTrackInstallationDealersListApi(payload));
    }, 300)
    return () => {
      clearTimeout(timeout);
    };
  }, [
    selectedStateId,
    selectedStatus,
    monthlyFilters,
    collectionData,
    selectedSHID,
    selectedAMID,
    getZonalHeadSH,
    getStateHead,
    getZonalHead,
    selectedZHID,
    dateValue
  ]);

  useEffect(() => {
    if (userRole != "AMGR") {
      dispatch(trackGetAreaManagerApi({ userid: userId }));
    }
    if (userRole !== "SADM") {
      // dispatch(trackGetStateHeadbyAMIdApi({"userid": userId}))
    }
    if (userRole == "SADM") {
      dispatch(trackZonalHeadbySHIdApi({ UserId: userId }));
    }
    if (userRole == "ZADM") {
      dispatch(trackGetZonalHeadbyAMIdApi({ userid: userId }));
      dispatch(trackZonalHeadbySHIdApi({ userid: userId }));
    }
    if (userRole == "AMGR") {
      dispatch(trackGetZonalHeadbyAMIdApi({ userid: userId }));
      dispatch(trackGetStateHeadbyAMIdApi({ userid: userId }));
      // dispatch(trackZonalHeadbySHIdApi({"userid": userId}))
    }
  }, [selectedStateId, selectedStatus, enforcedMonth]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };
  const hasSelected = selectedRowKeys.length > 0;
  const handleInputChange = (key, date, dateString) => {
    switch (key) {
      case "monthStart":
        setStartMonth(date);
        break;
      case "monthEnd":
        setEndMonth(date);
        break;
      case "enforcedMonth":
        setEnforcedMonth(date);
        break;
    }
    const month = date ? new Date(date).getMonth() + 1 : 0;
    // onChangeFilter(key, month);
    setCollectionData({
      ...collectionData,
      currentMonth: month,
      pageNumber: 1,
    });
    setMonthlyFilters((monthlyFilters) => ({
      ...monthlyFilters,
      [key]: month,
    }));
  };

  useEffect(() => {
    if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
      setStateListArr(stateList?.data);
    } else {
      setStateListArr([]);
    }
  }, [stateList]);

  const handleStateSelect = (value, option) => {
    const id = option.key;
    setSelectedStateId(parseInt(id));
  };

  // const disabledDate = (current, selectedMonth) => {
  //   // lock
  //   if (false) return true;
  //   const today = new Date();
  //   const currentYear = today.getFullYear() - 1;
  //   const currentMonth = today.getMonth();
  //   const futureYear = currentYear + 1;

  //   //disableDateRestriction
  //   if (false) {
  //     return null;
  //   }

  //   return (
  //     current &&
  //     (current.year() < currentYear ||
  //       (current.year() === currentYear && current.month() < 3) ||
  //       current.year() > futureYear ||
  //       (current.year() === futureYear && current.month() > 2))
  //   );
  // };
  const disabledDate = (current) => {
    const today = dayjs().year(dateValue - 1);
    const currentYear = today.year();

    // Calculate the start date of the current financial year (April of the current year)
    const startFinancialYear = new Date(currentYear, 3, 1); // Months are zero-based, so April is 3

    // Calculate the end date of the current financial year (March of the next year)
    const endFinancialYear = new Date(currentYear + 1, 2, 31); // March is 2

    // Convert current date to Date object if it's not already
    const currentDate = current instanceof Date ? current : new Date(current);

    // Disable dates that are outside the current financial year
    return currentDate < startFinancialYear || currentDate > endFinancialYear;
  };

  const handlePanelChange = (value) => {
    setSelectedMonth(value);
  };

  const currentFiscalYear = getCurrentFiscalYear1(collectionData?.startMonth || new Date());

  const getShDropdownData = (userId) => {
    axiosAPI
      .post(`SchemeGenerate/GetDropdownStateHead`, { userId })
      .then((result) => {
        setStateHeadData([
          {
            key: "0",
            label: (
              <span
                onClick={() => {
                  setSelectedSH("All State Heads");
                  setSelectedSHID(0);
                  setSelectedAMID(0);
                  setSelectedAM("All Area Managers");
                  setAreaMngrData([]);
                }}
              >
                All State Heads
              </span>
            ),
          },
          ...result.data.data?.map((item) => {
            return {
              key: item.id,
              label: (
                <span
                  onClick={() => {
                    setSelectedSH(item.stateHeadName);
                    setSelectedSHID(item.id);
                    setSelectedAMID(0);
                    setSelectedAM("All Area Managers");
                    getAmDropdownData(item.id);
                  }}
                >
                  {item.stateHeadName}
                </span>
              ),
            };
          }),
        ]);
      });
  };

  useEffect(() => {
    if (userRole == "ZADM") {
      getShDropdownData(userId);
    }
    if (userRole == "SADM") {
      getAmDropdownData(userId);
    }
  }, []);

  useEffect(() => {
    axiosAPI.post(`SchemeGenerate/GetzonalHead`).then((result) => {
      setZonalHeadData([
        {
          key: "0",
          label: (
            <span
              onClick={() => {
                setSelectedZH("All Zonal Head");
                setStateHeadData([]);
                setSelectedZHID(0);
                setSelectedSHID(0);
                setSelectedSH("All State Heads");
                setAreaMngrData([]);
                setSelectedAMID(0);
                setSelectedAM("All Area Managers");
              }}
            >
              All Zonal Head
            </span>
          ),
        },
        ...result.data.data?.map((item) => {
          return {
            key: item.id,
            label: (
              <span
                onClick={() => {
                  setSelectedZH(item.zonalHeadName);
                  setSelectedZHID(item.id);
                  setSelectedSHID(0);
                  setSelectedSH("All State Heads");
                  getShDropdownData(item.id);
                  setAreaMngrData([]);
                  setSelectedAMID(0);
                  setSelectedAM("All Area Managers");
                }}
              >
                {item.zonalHeadName}
              </span>
            ),
          };
        }),
      ]);
    });

    if (["SADM"].includes(userRole)) {
      getStateByStateHeadId(userId);
    }
  }, []);

  const getDealerDropdownData = (userId, type) => {
    const monthStart = collectionData?.startDate
      ? dayjs(collectionData?.startDate).month() + 1
      : dayjs().month() + 1;
    const monthEnd = collectionData?.endDate
      ? dayjs(collectionData?.endDate).month() + 1
      : dayjs().month() + 1;

    if (type === "amID") {
      setCollectionData((collectionData) => ({
        ...collectionData,
        amID: userId,
        pageNumber: 1,
      }));
    }
    const postData = {
      fyear: currentFiscalYear,
      month: monthStart,
      monthEnd: monthEnd,
      userId,
      year: dayjs().year(),
    };
    // axiosAPI.post(`SchemeGenerate/GetSchemeList`, postData).then((result) => {
    //   const data = formatData(result.data.data);
    //   // setDealer(result.data.data)

    //   dispatch(schemeList(data));
    // });
  };

  const handleAMClick = (event) => {
    const selectedAm = areaDropdown.find(
      (value) => value.id === parseInt(event.key)
    );
    if (selectedAm?.am) {
      setSelectedAM(selectedAm.am);
    }
    getDealerDropdownData(parseInt(event.key), "amID");
  };

  const getAmDropdownData = (userId) => {
    axiosAPI.post(`SchemeGenerate/GetDropdownAM`, { userId }).then((result) => {
      setAreaDropdown(result?.data?.data);
      setAreaMngrData([
        {
          key: "0",
          label: (
            <span
              onClick={() => {
                setSelectedAM("All Area Managers");
                setSelectedAMID(0);
              }}
            >
              All Area Managers
            </span>
          ),
        },
        ...result.data.data.map((item) => {
          return {
            key: item.id,
            label: (
              <span
                onClick={() => {
                  setSelectedAM(item.aM);
                  setSelectedAMID(item.id);
                }}
              >
                {item.aM}
              </span>
            ),
          };
        }),
      ]);
    });
  };

  const getZH = (isClear = false) => {
    if (isClear) {
      setSelectedZH("");
      setSelectedZHID(0);
      return "All Zonal Head";
    }
    if (userRole == "ZADM") {
      return userName;
    }
    if (userRole == "SADM" && getZonalHeadSH?.data?.userName) {
      return getZonalHeadSH?.data?.userName;
    }
    if (userRole == "AMGR" && getZonalHead?.data?.userName) {
      return getZonalHead?.data?.userName;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedZH == "" ? "All Zonal Head" : selectedZH;
    }
    return selectedZH == "All Zonal Head" ? "All Zonal Head" : selectedZH;
  };

  const getSH = (isClear = false) => {
    if (isClear) {
      setSelectedSH("");
      setSelectedSHID(0);
      return "All State Heads";
    }
    if (userRole == "SADM" && userName) {
      return userName;
    }
    if (userRole == "ZADM") {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    if (userRole == "AMGR" && getStateHead?.data?.userName) {
      return getStateHead?.data?.userName;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    return selectedSH == "All State Heads" ? "All State Heads" : selectedSH;
  };

  const getAM = (isClear = false) => {
    if (isClear) {
      setSelectedAM("");
      setSelectedAMID(0);
      return "All Area Managers";
    }
    if (userRole == "ZADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if (userRole == "SADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if (userRole == "AMGR" && userName) {
      return userName;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    return selectedAM == "All Area Managers" ? "All Area Managers" : selectedAM;
  };

  const getStateByStateHeadId = (SHID) => {
    const payload = {
      fyear: currentFiscalYear,
      userid: SHID ? SHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatebyStateHeadId`, payload)
      .then((result) => {
        const data = result?.data?.data;
        setStateListArr([
          {
            stateID: data ? data?.stateID : 0,
            stateName: data ? data?.stateName : "stateName",
          },
        ]);
      });
  };

  const getStateByZonalHeadId = (ZHID) => {
    const payload = {
      fyear: currentFiscalYear,
      userid: ZHID ? ZHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatesbyZonalHeadId`, payload)
      .then((result) => {
        const data = result.data.data;
        setStateListArr(data);
      });
  };

  useEffect(() => {
    if (userRole == "SADM") {
      getStateByStateHeadId(userId);
      return;
    }
    if (selectedSHID) {
      getStateByStateHeadId(selectedSHID);
    }
    if (userRole == "ZADM") {
      getStateByZonalHeadId(userId);
      return;
    }
  }, [selectedSHID]);

  useEffect(() => {
    if (["AMGR"].includes(userRole)) {
      getStateByStateHeadId(getStateHead?.data?.userId);
    }
  }, [getStateHead]);

  const captalizeObjKeys = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
    );

  const getCol = (item) => {
    let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
    let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
    // Slab based scheme

    if (collectionData?.schemeType == 3) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        Dealer_Location: item.dealerLocation,
        State: item.stateName,
        Territory_Manager: item.territoryManager,
        created_By: item.createdBy,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Target_T1: item.t1,
        Target_T2: item.t2,
        Target_T3: item.t3,
        Inc_Per_Tractor_R1: item.r1,
        Inc_Per_Tractor_R2: item.r2,
        Inc_Per_Tractor_R3: item.r3,
        Payout_P1: item.p1,
        Payout_P2: item.p2,
        Payout_P3: item.p3,
        Remarks: item.reason,
        HoldRemarks: item.holdReason || "",
        TOP_TARGET: item.topTarget
      };
    }
    // Single Target Scheme
    if (collectionData?.schemeType == 8) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Location: item.dealerLocation,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Territory_Manager: item.territoryManager,
        created_By: item.createdBy,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: item.installationTarget,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason,
        HoldRemarks: item.holdReason || "",
      };
    }
    // Single Target With Upper Cap Scheme
    if (collectionData?.schemeType == 4) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Location: item.dealerLocation,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Territory_Manager: item.territoryManager,
        created_By: item.createdBy,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: item.installationTarget,
        Upper_Cap: item.upperCap,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason,
        HoldRemarks: item.holdReason || "",
      };
    }
    // Unconditional Scheme
    if (collectionData?.schemeType == 5) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Location: item.dealerLocation,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Territory_Manager: item.territoryManager,
        created_By: item.createdBy,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Expected: item.installationTarget,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason,
        HoldRemarks: item.holdReason || "",
      };
    }
  };

  const handleDownload = () => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" },
      },
      font: {
        color: { rgb: "212121" },
        bold: true,
        sz: "12",
      },
    };
    const data =
      trackInstallationList?.data?.installationList &&
      trackInstallationList?.data?.installationList?.map((item) => {
        return captalizeObjKeys(getCol(item));
      });

    if (data?.length == 0) {
      ToastifyShow("Something went wrong", "error");
      return false;
    }

    let trackInstallationData = (x) => {


      if (x == 3) {
        let tab_text = `<table>
            <thead>
            <tr>
                <th rowspan="2">SCHEME NO</th>
                <th rowspan="2">DEALER CODE</th>
                <th rowspan="2">DEALER LOCATION</th>
                <th rowspan="2">DEALER NAME</th>
                <th rowspan="2">STATE</th>
                <th rowspan="2">TERRITORY MANAGER</th>
                <th rowspan="2">TOP SLAB</th>
                <th rowspan="2">CREATED BY</th>
                <th rowspan="2">START MONTH</th>
                <th rowspan="2">END MONTH</th>
                <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
                <th colspan="3">TARGETS</th>
                <th colspan="3">INC. PER TRACTOR</th>
                <th colspan="3">PAYOUT</th>
                <th rowspan="2">REMARKS</th>
                <th rowspan="2">HOLD REMARKS</th>
                </tr>
            <tr>
               <th>T1</th>
               <th>T2</th>
               <th>T3</th>
               <th>R1</th>
               <th>R2</th>
               <th>R3</th>
               <th>P1</th>
               <th>P2</th>
               <th>P3</th>
              </tr>
            </thead>`;

        data?.forEach((header) => {
          tab_text += `<tr ><td>${header?.SCHEME_NO}</td>
          <td>${header?.DEALER_CODE}</td>
          <td>${header?.DEALER_LOCATION}</td>
          <td>${header?.DEALER_NAME}</td>
          <td>${header?.STATE}</td>
          <td>${header?.TERRITORY_MANAGER}</td>
          <td>${header?.TOP_TARGET}</td>
          <td>${header?.CREATED_BY}</td>
          <td>${header?.START_MONTH}</td>
          <td>${header?.END_MONTH}</td>
          <td>${header?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
          <td>${header?.TARGET_T1}</td>
          <td>${header?.TARGET_T2}</td>
          <td>${header?.TARGET_T3}</td>
          <td>${header?.INC_PER_TRACTOR_R1}</td>
          <td>${header?.INC_PER_TRACTOR_R2}</td>
          <td>${header?.INC_PER_TRACTOR_R3}</td>
          <td>${header?.PAYOUT_P1}</td>
          <td>${header?.PAYOUT_P2}</td>
          <td>${header?.PAYOUT_P3}</td>
          <td>${header?.REMARKS}</td>
          <td>${header?.HOLDREMARKS}</td>
         
          </tr>`;
        });

        let totals = {

          ACTUAL_INST_LAST_YEAR_MONTH: 0,
          TARGET_T1: 0,
          TARGET_T2: 0,
          TARGET_T3: 0,
          INC_PER_TRACTOR_R1: 0,
          INC_PER_TRACTOR_R2: 0,
          INC_PER_TRACTOR_R3: 0,
          PAYOUT_P1: 0,
          PAYOUT_P2: 0,
          PAYOUT_P3: 0,
          reason: "",
          holdReason: ""
        };



        data.forEach((item) => {
          for (let key in totals) {
            totals[key] += parseInt(item[key]) || 0;
          }
        });

        totals.INC_PER_TRACTOR_R1 = totals.TARGET_T1 !== 0 ? parseInt(totals.PAYOUT_P1 / totals.TARGET_T1) : 0;
        totals.INC_PER_TRACTOR_R2 = totals.TARGET_T2 !== 0 ? parseInt(totals.PAYOUT_P2 / totals.TARGET_T2) : 0;
        totals.INC_PER_TRACTOR_R3 = totals.TARGET_T3 !== 0 ? parseInt(totals.PAYOUT_P3 / totals.TARGET_T3) : 0;
        let totalsRow = "<tr><td colspan='10' >Total</td>";
        for (let key in totals) {
          if (key != 'reason' && key != 'holdReason') {
            if (key.startsWith('INC_PER')) {
              totalsRow += `<td>${totals[key]}</td>`;
            } else {
              totalsRow += `<td>${totals[key]}</td>`;
            }
          } else {
            totalsRow += `<td></td>`;
          }
        }

        totalsRow += "</tr>";
        tab_text += totalsRow;

        tab_text += "</table>";
        return tab_text;

      } else if (x == 8) {
        let tab_text = `<table>
                <thead>
                <tr>
                    <th >SCHEME NO</th>
                    <th >DEALER CODE</th>
                    <th >DEALER NAME</th>
                    <th >STATE</th>
                    <th >DEALER LOCATION</th>
                    <th >TERRITORY MANAGER</th>
                    <th >START MONTH</th>
                    <th >END MONTH</th>
                    <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
                    <th >INSTALLATION TARGET</th>
                    <th >INC. PER TRACTOR</th>
                    <th >PAYOUT</th>
                    <th >REMARKS</th>
                    <th > HOLD REMARKS</th>

                    </tr>
                </thead>`;

        data?.forEach((x) => {
          tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
              <td>${x?.DEALER_CODE}</td>
              <td>${x?.DEALER_NAME}</td>
              <td>${x?.STATE}</td>
              <td>${x?.DEALER_LOCATION}</td>
              <td>${x?.TERRITORY_MANAGER}</td>
              <td>${x?.START_MONTH}</td>
              <td>${x?.END_MONTH}</td>
              <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
              <td>${x?.INSTALLATION_TARGET}</td>
              <td>${x?.INC_PER_TRACTOR}</td>
              <td>${x?.PAYOUT}</td>
              <td>${x?.REMARKS}</td>
              <td>${x?.HOLDREMARKS}</td>

              </tr>`;
        });


        let totals = {
          ACTUAL_INST_LAST_YEAR_MONTH: 0,
          INSTALLATION_TARGET: 0,
          INC_PER_TRACTOR: 0,

          PAYOUT: 0,
          reason: "",
          holdReason: ""

        };


        data.forEach((item) => {
          for (let key in totals) {
            totals[key] += parseInt(item[key]) || 0;
          }
        });
        totals.INC_PER_TRACTOR = totals.INSTALLATION_TARGET !== 0 ? parseInt(totals.PAYOUT / totals.INSTALLATION_TARGET) : 0;

        let totalsRow = "<tr><td colspan='8' >Total</td>";
        for (let key in totals) {
          if (key != 'reason' && key != 'holdReason') {
            if (key.startsWith('INC_PER')) {
              totalsRow += `<td>${totals[key]}</td>`;
            } else {
              totalsRow += `<td>${totals[key]}</td>`;
            }
          } else {
            totalsRow += `<td></td>`;
          }
        }

        totalsRow += "</tr>";
        tab_text += totalsRow;
        tab_text += "</table>";
        return tab_text;
      } else if (x == 4) {
        let tab_text = `<table>
            <thead>
            <tr>
                <th >SCHEME NO</th>
                <th >DEALER CODE</th>
                <th >DEALER NAME</th>
                <th >STATE</th>
                <th >DEALER LOCATION</th>
                <th >TERRITORY MANAGER</th>
                <th >START MONTH</th>
                <th >END MONTH</th>
                <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
                <th >INSTALLATION TARGET</th>
                <th>UPPER CAP</th>
                <th >INC. PER TRACTOR</th>
                <th >PAYOUT</th>
                <th >REMARKS</th>
                <th >HOLD REMARKS</th>

                </tr>
            </thead>`;

        data?.forEach((x) => {
          tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
          <td>${x?.DEALER_CODE}</td>
          <td>${x?.DEALER_NAME}</td>
          <td>${x?.STATE}</td>
          <td>${x?.DEALER_LOCATION}</td>
          <td>${x?.TERRITORY_MANAGER}</td>
          <td>${x?.START_MONTH}</td>
          <td>${x?.END_MONTH}</td>
          <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
          <td>${x?.INSTALLATION_TARGET}</td>
          <td>${x?.UPPER_CAP}</td>
          <td>${x?.INC_PER_TRACTOR}</td>
          <td>${x?.PAYOUT}</td>
          <td>${x?.REMARKS}</td>
          <td>${x?.HOLDREMARKS}</td>

          </tr>`;
        });

        let totals = {
          ACTUAL_INST_LAST_YEAR_MONTH: 0,
          INSTALLATION_TARGET: 0,
          UPPER_CAP: 0,
          INC_PER_TRACTOR: 0,
          PAYOUT: 0,
          reason: "",
          holdReason: ""

        };


        data.forEach((item) => {
          for (let key in totals) {
            totals[key] += parseInt(item[key]) || 0;
          }
        });
        totals.INC_PER_TRACTOR = totals.INSTALLATION_TARGET !== 0 ? parseInt(totals.PAYOUT / totals.INSTALLATION_TARGET) : 0;


        let totalsRow = "<tr><td colspan='8' >Total</td>";
        for (let key in totals) {
          if (key != 'reason' && key != 'holdReason') {
            if (key.startsWith('p')) {
              totalsRow += `<td>${totals[key]}</td>`;
            } else {
              totalsRow += `<td>${totals[key]}</td>`;
            }
          } else {
            totalsRow += `<td></td>`;
          }
        }


        totalsRow += "</tr>";
        tab_text += totalsRow;
        tab_text += "</table>";
        return tab_text;
      } else if (x == 5) {
        let tab_text = `<table>
            <thead>
            <tr>
                <th >SCHEME NO</th>
                <th >DEALER CODE</th>
                <th >DEALER NAME</th>
                <th >STATE</th>
                <th >DEALER LOCATION</th>
                <th >TERRITORY MANAGER</th>
                <th >START MONTH</th>
                <th >END MONTH</th>
                <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
                <th >INSTALLATION EXPECTED</th>
                <th >INC. PER TRACTOR</th>
                <th >PAYOUT</th>
                <th >REMARKS</th>
                <th >HOLD REMARKS</th>

                </tr>
            </thead>`;

        data?.forEach((x) => {
          tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
          <td>${x?.DEALER_CODE}</td>
          <td>${x?.DEALER_NAME}</td>
          <td>${x?.STATE}</td>
          <td>${x?.DEALER_LOCATION}</td>
          <td>${x?.TERRITORY_MANAGER}</td>
          <td>${x?.START_MONTH}</td>
          <td>${x?.END_MONTH}</td>
          <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
          <td>${x?.INSTALLATION_EXPECTED}</td>
          <td>${x?.INC_PER_TRACTOR}</td>
          <td>${x?.PAYOUT}</td>
          <td>${x?.REMARKS}</td>
          <td>${x?.HOLDREMARKS}</td>
          </tr>`;
        });


        let totals = {
          ACTUAL_INST_LAST_YEAR_MONTH: 0,

          INSTALLATION_EXPECTED: 0,
          INC_PER_TRACTOR: 0,
          PAYOUT: 0,
          reason: "",
          holdReason: ""

        };

        data.forEach((item) => {
          for (let key in totals) {
            totals[key] += parseInt(item[key]) || 0;
          }
        });

        totals.INC_PER_TRACTOR = totals.INSTALLATION_EXPECTED !== 0 ? parseInt(totals.PAYOUT / totals.INSTALLATION_EXPECTED) : 0;
        let totalsRow = "<tr><td colspan='8' >Total</td>";
        for (let key in totals) {
          if (key != 'reason' && key != 'holdReason') {
            totalsRow += `<td>${totals[key]}</td>`;
          } else {
            totalsRow += `<td></td>`;
          }
        }

        totalsRow += "</tr>";
        tab_text += totalsRow;
        tab_text += "</table>";
        return tab_text;
      }
    };

    var elt = document.createElement("div");
    elt.innerHTML = [trackInstallationData(collectionData?.schemeType)];
    document.body.appendChild(elt);
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    document.body.removeChild(elt);
    const allColumns = data.reduce((columns, item) => {
      Object.keys(item).forEach(key => {
        if (!columns.includes(key)) {

          columns.push(key);
        }
      });
      return columns;
    }, []);




    function getAlphabetEquivalent(number) {
      if (number < 1 || number > 26) { return "Invalid input"; }
      return String.fromCharCode(number + 64);
    }
    let excelArr = []
    for (let index = 0; index < allColumns.length; index++) {

      if (collectionData?.schemeType === 3) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 3).toString()}`
        excelArr.push(ele);
      } else {

        let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
        excelArr.push(ele);
      }


    }

    Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
      excelArr
        .includes(cell)).forEach(cell => {
          wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
        });


    // generate file
    XLSX.writeFile(
      wb,
      `Download_track_${collectionData?.schemeType == 5 ? "undconditional" : collectionData?.schemeType == 4 ? "Single_Target_with_upper_Cap" : collectionData?.schemeType == 8 ? "Single_Target" : "Slab_Based"} _installation${new Date().toJSON().slice(0, 10)}.xlsx`
    );

    // const url = window.URL.createObjectURL(
    // new Blob([trackInstallationData(collectionData?.schemeType)], { type: "application/vnd.ms-excel" })
    // );
    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", `${new Date().toJSON().slice(0, 10)}.xlsx`);
    // document.body.appendChild(link);
    // link.click(); // const data = formatData(result.data.data); // dispatch(schemeList(data));
  };

  const handleClearFilter = () => {
    setCollectionData({ ...InitCollectionData });
    setEnforcedMonth(null);
    setStartMonth(null);
    setSelectedStatus(0);
    setSelectedStateId(0);
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      getAM(true);
      getSH(true);
      getZH(true);
      return;
    }
    if (userRole == "ZADM") {
      getAM(true);
      getSH(true);
      return;
    }
    if (userRole == "SADM") {
      getAM(true);
      return;
    }
    // if(userRole == 'AMGR'){
    //     getAM(true);
    //     getZH(true);
    //     return;
    // }
  };

  const handleMailModal = (record) => {
    setOpenMailModal(!openmailModal);
  };

  const handlePreviewEmail = (payload) => {
    setFullPageLoader(true);
    handleMailModal();

    const updatePayload = payload.reduce((acc, item) => {
      delete item.key;
      acc[item.id] = item;
      return acc;
    });

    axiosAPI
      .post(`controller/MailToDealers`, updatePayload)
      .then((data) => {
        setBackendHtmlString(data.data.data.result.body);
        setSendPayload(data.data.data.result);
        setFullPageLoader(false);
      })
      .catch((err) => {
        ToastifyShow("Email has not been sent.", "error");
        setFullPageLoader(false);
      });
  };
  const handleSendEmail = () => {


    axiosAPI
      .post(`controller/SendPreviewMailers`, sendPayload)
      .then((data) => {
        ToastifyShow("Email has been sent.", "success");
        setFullPageLoader(false);
      })
      .catch((err) => {
        ToastifyShow("Email has not been sent.", "error");
        setFullPageLoader(false);
      });
    handleMailModal();
  };

  const actionsList = [
    {
      key: "1",
      disabled: trackInstallationList?.data?.installationList?.length == 0,
      label: (<a onClick={handleDownload}>Download</a>),
    },
    {
      key: "2",
      label: (<a onClick={handleClearFilter}>Clear Filters</a>),
      disabled: false,
    },
    {
      key: "3",
      label: (<a onClick={() => handlePreviewEmail(selectedRows)}>Send E-mail</a>),
      disabled: selectedRows?.length == 0,
    }
  ];

  return (
    <div
      id="table"
      className="create_scheme_wrap onemoth-date-selector view-schela news"
    >
      {fullPageLoader ? <FullScreenLoader /> : ""}
      <div>
        <div
          style={{ marginTop: "18px" }}
          id="space"
          className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
        >
          <div
            className="allSchmeStatus select_state heightauto"
            style={{ color: "red" }}
          >
            <Status
              isDisable={['PMIS', 'Finance', 'COSM', 'NSH'].includes(userRole) ? true : false}
              className=""
              selectedStatus={['PMIS', 'Finance', 'COSM', 'NSH'].includes(userRole) ? 2 : selectedStatus}
              handleStatusDropdown={(statusId) => setSelectedStatus(statusId)}
            />
          </div>

          <Col>
            <Tooltip title={"Scheme Type"}>
              <Dropdown menu={{ items: subColType }}>
                <Button>
                  <Space>
                    {collectionData?.schemeType == ""
                      ? "All Scheme Sub Type"
                      : getSchemeType(collectionData?.schemeType)}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Tooltip>
          </Col>
          <div className="marginauto">
            <Tooltip title={"Financial Year"}>
              <FinancialYearButton handleDate={(x) => {
                setDateValue(x);
                setStartMonth(x);
              }} />
            </Tooltip>
          </div>
          <div className="marginauto">
            <Tooltip title={"Running Month"}>
              <DatePicker
                // onChange={(date) => handleInputChange('enforcedMonth', date)}
                onChange={(date, formatDate) => {
                  if (formatDate == "") {
                    setCollectionData({
                      ...collectionData,
                      currentMonth: 0,
                      pageNumber: 1,
                    });
                    // setEnforcedMonth(date);
                    return;
                  }
                  setCollectionData({
                    ...collectionData,
                    currentMonth: new Date(formatDate).getMonth() + 1,
                    pageNumber: 1,
                  });
                }}
                className="start"
                placeholder="Running Month"
                picker="month"
                value={enforcedMonth}
                allowClear
                disabled={
                  collectionData.startMonth != "" || collectionData.endMonth != ""
                }
                disabledDate={disabledDate}
              />
            </Tooltip>
          </div>

          <div className="month ">
            <Tooltip title={"Start Month       End MOnth"}>
              <RangePicker
                mode={["month", "month"]}
                picker="month"
                onChange={(date, formatDate) => {
                  setCollectionData((collectionData) => ({
                    ...collectionData,
                    startMonth: date && date[0] ? date[0].format() : 0,
                    endMonth: date && date[1] ? date[1].format() : 0,
                    pageNumber: 1,
                  }));
                  setStartMonth(date);
                  // setEndMonth(formatDate)
                }}
                // disabled={[!showStartMonth, !showEndMonth]}
                onPanelChange={handlePanelChange}
                disabled={
                  collectionData.currentMonth != ""
                }
                disabledDate={disabledDate}
                value={startMonth}
              />
            </Tooltip>
          </div>
          <Col>
            <Tooltip title={"Zonal Head"}>
              <Dropdown
                menu={{ items: zonalHeadData }}
                disabled={["ZADM", "SADM", "AMGR"].includes(userRole)}
              >
                <Button>
                  <Space>
                    {getZH()}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Tooltip>
          </Col>
          <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
            <Input
              placeholder="Dealer Location"
              value={collectionData.dealerLocation}
              onChange={(e) =>
                setCollectionData({
                  ...collectionData,
                  dealerLocation: e.target.value,
                  pageNumber: 1,
                })
              }
            />
          </div>
        </div>
        <div
          style={{ marginTop: "0" }}
          id="space"
          className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
        >
          {true && (
            <Col>
              <Tooltip title={"State Head"}>
                <Dropdown
                  menu={{ items: stateHeadData }}
                  disabled={["SADM", "AMGR"].includes(userRole)}
                >
                  <Button>
                    <Space>
                      {getSH()}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown></Tooltip>
            </Col>
          )}
          {true && (
            <Col>
              <Tooltip title={"Area Manager"}>
                <Dropdown
                  menu={{ items: areaMngrData, onClick: handleAMClick }}
                  disabled={["AMGR"].includes(userRole)}
                >
                  <Button>
                    <Space>
                      {getAM()}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown></Tooltip>
            </Col>
          )}
          <Tooltip title={"Select State"}>
            <Select
              showSearch
              className="select_state heightauto"
              placeholder="Select State"
              optionFilterProp="children"
              onSelect={handleStateSelect}
              defaultValue={0}
              value={selectedStateId === 0 ? 0 : stateListArr.find(option => option.stateID === selectedStateId)?.stateName}
            >
              <Option key={0} value={0}>
                All States
              </Option>
              {stateListArr?.map((option) => (
                <Option key={option.stateID} value={option.stateName}>
                  {option.stateName}
                </Option>
              ))}
            </Select></Tooltip>
          <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
            <Input
              placeholder="Scheme No."
              value={collectionData.schemeNumber}
              onChange={(e) =>
                setCollectionData({
                  ...collectionData,
                  schemeNumber: e.target.value,
                  pageNumber: 1,
                })
              }
            />
          </div>
          <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
            <Input
              placeholder="Dealer Code"
              value={collectionData.dealerCode}
              onChange={(e) =>
                setCollectionData({
                  ...collectionData,
                  dealerCode: e.target.value,
                  pageNumber: 1,
                })
              }
            />
          </div>

          <Col className="actionbuttons">
            <Dropdown menu={{ items: actionsList }}>
              <Button>
                <Space>
                  Actions
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Col>
        </div>
      </div>
      <div className="fullwidths">
        <div
          class="recent-boxes fullwidths"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <h2 style={{ fontSize: "18px", padding: "20px" }}>
              Recent {getSchemeType(selectedSchemeType)}
            </h2> */}
        </div>
        <span>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <TrackCollectionTable
        dataSource={trackInstallationList?.data?.installationList || []}
        grandTotal={trackInstallationList?.data?.grandTotal}
        selectedStatus={selectedStatus}
        selectedSchemeType={collectionData?.schemeType}
        monthlyFilters={monthlyFilters}
        loading={
          loadingState == "pending" ||
            loadingTrack == "pending" ||
            loadingScheme == "pending"
            ? true
            : false
        }
        onSendEmail={handlePreviewEmail}
        onSelectedRows={(rows) => setSelectedRows(rows)}
        handleChange={(pagination, filters, sorter) => {
          setCollectionData({
            ...collectionData,
            sortingOrder: sorter?.order,
            sortingType: sorter?.columnKey,
          });
        }}
      />

      <Pagination
        defaultCurrent={1}
        className="paginationDesign"
        current={collectionData.pageNumber}
        onChange={(pageNumber, pageSize) =>
          setCollectionData({ ...collectionData, pageNumber, pageSize })
        }
        total={trackInstallationList?.data?.totalCount}
        showSizeChanger
        pageSize={collectionData.pageSize}
        pageSizeOptions={['150', '250', '350']}
      />
      <MailPreviewModal
        open={openmailModal}
        onOk={() => {
          handleSendEmail();
        }}
        onCancel={handleMailModal}
      >
        <JoditEditor
          value={backendHtmlString}
          // onChange={handleSendEmail}

          config={{
            spellcheck: false,
            // minHeight: 100,
            // defaultMode: '1',
            buttons: ["preview"],
            readonly: true,
          }}
          readonly={true}
        />
      </MailPreviewModal>
      {(loadingState == "pending" ||
        loadingTrack == "pending" ||
        loadingScheme == "pending") && <FullScreenLoader />}
      <div className="All_scheme_status">
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "green" }}
          ></div>
          Approved
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "red" }}
          ></div>
          Rejected
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "yellow" }}
          ></div>
          Work in Progress
        </div>

        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "#BCB38D" }}
          ></div>
          Deviation
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "#9999ff" }}
          ></div>
          Hold
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "rgb(255,0,255)" }}
          ></div>
          Auto Rejected
        </div>
      </div>
    </div>
  );
};
export default TrackInstallation;
