import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import "./createScheme.css";
import {
  Button,
  Table,
  Divider,
  Col,
  Row,
  Form,
  Input,
  Alert,
  Dropdown,
  Menu,
  Space,
  Tooltip,
} from "antd";
import ThemeButton from "../../../components/button/buttons";

import { CodeSandboxCircleFilled, LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import XLSX from "xlsx-js-style";
import { ReactComponent as ChangeScheme } from "../../../assets/vector/changeScheme.svg";
import { useDispatch, useSelector } from "react-redux";
import { schemeList } from "../../../redux/collection/SchemeList";
import { add, fetchgenerateScheme } from "../../../redux/collection/PostScheme";
import { draftScheme } from "../../../redux/collection/AllDraftScheme";
import AlertModal from "../../../components/modal/alertModal/AlertModal";
import ChangeSchemeModal from "../../../components/changeSchemeModal";
import dayjs from "dayjs";
import SMDialog from "../../../components/modal/alertModal/CommonModal";
import { useNavigate, useLocation } from "react-router-dom";
import StatusModal from "../../../components/modal/alertModal/StatusModal";

import useEditSchemeColumns from "./editColumnsData";
import { message, Select } from "antd";
import axiosAPI from "../../../services/axiosApi";
import { getCurrentFiscalYear, getCurrentFiscalYear1 } from "../../../utils/dateFormatter";
// import { useHistory } from 'react-router-dom';
import { formSchema } from "../../../utils/schemeValidations";
import ValidationDifferentSchemeError from "../../../components/modal/alertModal/ValidationDifferentSchemeError";
import { Formik, useFormik } from "formik";
import {
  schemeDraftPostApi,
  schemeGeneratePostApi,
} from "../../../redux/schemaGenerate/schemaGenerateSlice";
import FullScreenLoader from "../../../components/loader/FullScreenLoader";
import ToastifyShow, { TextLocalString } from "../../../components/ToastifyShow";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import { render } from "@testing-library/react";
import { collectionSchemeUpload } from "../../../components/download/collectionSchemeUpload";
import { collectionSchemeCollectionHeaderDownload } from "../../../components/download/collectionSchemeDownload";
import {
  getAwaitingLockSchemeAsync,
  getLockedSchemeTypeAsync,
} from "../../../redux/stateData/stateDataSlice";



const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  schemeType: "",
  dealerCode: [],
  startDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
  endDate:  dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
};

const CreateSchemeTable = ({ type = "new", editType = "create" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const formik = useFormik({
    initialValues: [],
  });
  const { lockedStates } = useSelector((state) => state?.stateData);
  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const userRole = userData.userRole;
  const userId = userData.userId;

  const schemeLockedStatus = lockedStates?.[0]
    ? {
      t1: true,
      t2: true,
      t3: true,
      t4: lockedStates?.[0]?.t4 ? true : false,
      t5: lockedStates?.[0]?.t5 ? true : false,
      t6: lockedStates?.[0]?.t6 ? true : false,
    }
    : { t4: false, t5: false, t6: false };
  // const schemeLockedStatus = { t4: true, t5: true, t6: true };
  //23110674
  const listData = useSelector((state) => state.schemeList.data);
  const EditableContext = React.createContext(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [changeSchemeDate, setchangeSchemeDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showHide, setShowUnhide] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [openSchemeModal, setOpenSchemeModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [dataHandle, setDataHandle] = useState([]);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showDraftModal, setShowDraftModal] = useState(false);
  const [submitData1, setSubmitData1] = useState({});
  const [previousValue, setPreviousValue] = useState([]);
  const [checkCalculate, setCheckCalculate] = useState(false);
  const [checkCalculateArr, setCheckCalculateArr] = useState([]);
  const [dataDraftHandle, setDataDraftHandle] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectIndex, setSelectIndex] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [codeError, setCodeError] = useState([]);
  const [openErrorDialog, setOpenErrorDialog] = useState({
    open: false,
    errorsFields: {},
  });
  const [confirmType, setConfirmType] = useState({
    type: "",
    route: "",
    payload: [],
  });

  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });

  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const { schemeType, startDate, endDate, dealerCode } = collectionData;
  const { loadingScheme } = useSelector((state) => state?.schemagenerate);
  const topTargetArr = [
    {
      name: "T1",
      value: "t1",
      disabled: !schemeLockedStatus?.t1 ? true : false,
    },
    {
      name: "T2",
      value: "t2",
      disabled: !schemeLockedStatus?.t2 ? true : false,
    },
    {
      name: "T3",
      value: "t3",
      disabled: !schemeLockedStatus?.t3 ? true : false,
    },
    {
      name: "T4",
      value: "t4",
      disabled: !schemeLockedStatus?.t4 ? true : false,
    },
    {
      name: "T5",
      value: "t5",
      disabled: !schemeLockedStatus?.t5 ? true : false,
    },
    {
      name: "T6",
      value: "t6",
      disabled: !schemeLockedStatus?.t6 ? true : false,
    },
  ];
  const selectedRow = JSON.parse(sessionStorage.getItem("rowData"));

  const schemeIds = selectedRow?.map((schemeKey) => schemeKey.scheme?.schemeId);

  const generatedSchemeData = useSelector(
    (state) => state.generatedScheme.data
  );

  //loadingScheme
  const draftSchemeData = useSelector((state) => state.draftScheme.data);
  const currentFiscalYear = getCurrentFiscalYear1(collectionData?.startDate || new Date());
  const approvalSchemeData = useSelector((state) => state.approvalScheme.data);

  const getGeneratedData = () => {
    if (type !== "edit") return;
    if (editType === "create") return generatedSchemeData;
    else if (editType === "draft") return draftSchemeData;
    else if (editType === "schemeProgress") return approvalSchemeData;
  };

  const generatedData = getGeneratedData();

  useEffect(() => {
    let finalData = [];
    if (editType === "create" && type === "edit") {
      if (schemeIds) {
        finalData = generatedSchemeData.data?.data.filter((data) =>
          schemeIds.includes(data.schemeId)
        );
      }
    } else if (editType === "schemeProgress" && type === "edit") {
      if (schemeIds) {
        finalData = approvalSchemeData?.filter((data) =>
          schemeIds.includes(data?.schemeId || data?.scheme?.schemeId)
        );
      }
    } else if (editType === "draft" && type === "edit") {
      if (schemeIds) {
        finalData = draftSchemeData?.data?.data.filter((data) =>
          schemeIds.includes(data.schemeId)
        );
      }
    } else {
      finalData = generatedData;
    }

    /* DISCLAIMER: REMOVE IF THERE IS ANY ISSUE WHILE CHECKBOX SELECTION IN EDIT */
    if (type === "edit" && schemeIds?.length) {
      setSelectedRowKeys(schemeIds);
    }

    if (finalData?.length > 0) {
      const newData = finalData.map((item, index) => {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");

        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
        return {
          status: item.status,
          key: item.schemeId,
          scheme: item,
          code: item.dealerCode,
          name: item.dealerName,
          Location: item.dealerLocation,
          state: item.stateName,
          stateId: item.stateId,
          Manager: item.territoryManager,
          topTarget: item.topTarget,
          StartMonth: formattedStartDate,
          EndMonth: formattedEndDate,
          delayPlan: item.delayPlan,
          target: "",
          nov21: null,
          dec21: null,
          BooksCollectionTargetT1: item.t1,
          BooksCollectionTargetT2: item.t2,
          BooksCollectionTargetT2T1: item.t2_T1,
          BooksCollectionTargetT3: item.t3,
          BooksCollectionTargetT3T2: item.t3_T2,
          BooksCollectionTargetT4: item.t4 ? item.t4 : 0,
          BooksCollectionTargetT5: item.t5 ? item.t5 : 0,
          BooksCollectionTargetT6: item.t6 ? item.t6 : 0,
          BooksCollectionTargetT4T3: item.t4_T3,
          BooksCollectionTargetT5T4: item.t5_T4,
          BooksCollectionTargetT6T5: item.t6_T5,
          PayoutRatep1: item.r1,
          PayoutRatep2: item.r2,
          PayoutRatep3: item.r3,
          PayoutRatep4: item.r4 ? item.r4 : 0,
          PayoutRatep5: item.r5 ? item.r5 : 0,
          PayoutRatep6: item.r6 ? item.r6 : 0,
          payoutR1: item.p1,
          payoutR2: item.p2,
          payoutR3: item.p3,
          payoutR4: item.p4 ? item.p4 : 0,
          payoutR5: item.p5 ? item.p5 : 0,
          payoutR6: item.p6 ? item.p6 : 0,
          reason: item.reason,
          TEPvol: item.volume,
          TEPpertr: item.perTr,
          TEPamount: item.amount,
          oldBillingQty: item.oldBillingQty,
          oldInstallation: item.oldInstallation,
          oldCollection: Math.round(item.oldCollection),
          oldOpeningStock: item.oldOpeningStock,
          openingStockDays: item.openingStockDays,
          outStandingDays: item.outStandingDays,
          closingOutStandingMonth_1: item.closingOutStandingMonth_1,
          closingOutStandingMonth_2: item.closingOutStandingMonth_2,
          closingOutStandingMonth_3: item.closingOutStandingMonth_3,
          bgLacsCurrentYear:
            item?.bGLacsCurrentYear || item.bgLacsCurrentYear || 0,
          industry: item.industry ? item.industry : 0,
          billing: item.billing ? parseInt(item.billing) : 0,
          installation: item.billing ? parseInt(item.installation) : 0,
          collection: Math.round(item.billing ? parseInt(item.collection) : 0),
          SlabAchieved: item.slab,
          billingFactorCOS: item.billingFactorCOS,
          closingStock: item.closingStock,
          closingOutStanding: item.closingOutStanding
        };
      });

      dispatch(schemeList(newData));
    }
  }, [generatedData]);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleSaveModal = () => {
    setOpenSaveModal(!openSaveModal);
  };

  const handleSuccessModal = () => {
    setOpenSuccessModal((openSuccessModal) => !openSuccessModal);
  };

  const handleConfirmationModal = async (confirm = false) => {
    setOpenConfirmationModal((openConfirmationModal) => !openConfirmationModal);
    if (confirm && confirmType.type === "updateDraft") {
      return handleSaveDraft(confirmType.route, confirmType.payload);
    }

    if (confirm) {
      if (type === "edit" && editType === "draft") {
        let payload = [];
        // saveDraft(false)

        const fetchGeneratedScheme = async (demoParams) => {
          const result = await axiosAPI.post(
            "SchemeGenerate/GetDraftedScheme",
            demoParams
          );
          dispatch(draftScheme(result.data.data));
          navigate("/collection/draftSchemes");
        };
        // /* const payload = listData.filter((data) => selectedRowKeys.includes(data.schemeId))
        //   .map((obj) => {
        //     const { status, ...rest } = obj;
        //     return rest;
        //   }); */
        let status = 0;

        listData.length > 0 &&
          listData.map((currentRecord) => {
            const userData = decryptData(sessionStorage.getItem("userInfo"));
            const userId = userData.userId;
            const userName = userData.userName;
            const formattedPayout = {
              dealerCode: currentRecord.code,
              dealerId: +currentRecord.key,
              // Manager: currentRecord.Manager,
              schemeId: currentRecord.key,
              stateName: currentRecord.state,
              startMonth: changeSchemeDate.startDate
                ? changeSchemeDate.startDate
                : currentRecord.scheme.startMonth,
              endMonth: changeSchemeDate.endDate
                ? changeSchemeDate.endDate
                : currentRecord.scheme.endMonth,
              reason: currentRecord.reason,
              delayPlan: currentRecord.scheme.delayPlan,
              billing: currentRecord.scheme.billing,
              installation: currentRecord.scheme.installation,
              collection: Math.round(currentRecord.scheme.collection),
              openingStockDays: currentRecord.scheme.openingStockDays,
              outStandingDays: currentRecord.scheme.outStandingDays,
              status: status,
              isSAP: true,
              isActive: true,
              t1: parseInt(currentRecord.BooksCollectionTargetT1),
              t2: parseInt(currentRecord.BooksCollectionTargetT2),
              t3: parseInt(currentRecord.BooksCollectionTargetT3),
              t4: parseInt(currentRecord.BooksCollectionTargetT4),
              t5: parseInt(currentRecord.BooksCollectionTargetT5),
              t6: parseInt(currentRecord.BooksCollectionTargetT6),
              t2_T1: parseInt(currentRecord.BooksCollectionTargetT2T1),
              t3_T2: parseInt(currentRecord.BooksCollectionTargetT3T2),
              t4_T3: parseInt(currentRecord.BooksCollectionTargetT4T3),
              t5_T4: parseInt(currentRecord.BooksCollectionTargetT5T4),
              t6_T5: parseInt(currentRecord.BooksCollectionTargetT6T5),
              p1: parseInt(currentRecord.payoutR1),
              p2: parseInt(currentRecord.payoutR2),
              p3: parseInt(currentRecord.payoutR3),
              p4: parseInt(currentRecord.payoutR4),
              p5: parseInt(currentRecord.payoutR5),
              p6: parseInt(currentRecord.payoutR6),
              r1: +currentRecord.PayoutRatep1,
              r2: +currentRecord.PayoutRatep2,
              r3: +currentRecord.PayoutRatep3,
              r4: +currentRecord.PayoutRatep4,
              r5: +currentRecord.PayoutRatep5,
              r6: +currentRecord.PayoutRatep6,
              volume: parseInt(currentRecord.TEPvol),
              perTr: parseInt(currentRecord.TEPpertr),
              amount: parseInt(currentRecord.TEPamount),
              remarks: currentRecord.scheme.remarks,
              lastMonthSlab: currentRecord.scheme.lastMonthSlab,
              lastMonthColQty: currentRecord.scheme.lastMonthColQty,
              lastTwoMonthSlab: currentRecord.scheme.lastTwoMonthSlab,
              lastTwoMonthColQty: currentRecord.scheme.lastTwoMonthColQty,
              fyear: currentFiscalYear,
              userName: userName,
              userId: userId,
              SlabAchieved: currentRecord.slab,
              TopTarget: "t1,t2,t3",
              industry: currentRecord.industry
                ? parseInt(currentRecord.industry)
                : 0,
              billingFactorCOS: currentRecord.billingFactorCOS,
              closingStock: currentRecord.closingStock,
              closingOutStanding: currentRecord.closingOutStanding,
            };
            payload.push(formattedPayout);
          });

        await axiosAPI
          .post(`UpdateScheme/DraftToSchemeGenerate`, payload)
          .then((result) => {
            message.success({
              content: "Scheme successfully generated",
              className: "custom-message",
            });
            const userData = decryptData(sessionStorage.getItem("userInfo"));
            const userId = userData.userId;
            const demoParams = {
              fyear: currentFiscalYear,
              schemeType: 6,
              userid: userId,
            };
            fetchGeneratedScheme(demoParams);
          });
      }
    }
  };
  const handleErrorModal = (firstItem, secondItem, opt = false) => {
    setOpenErrorModal(opt ? opt : !openErrorModal);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };
  const handleSelectModal = () => {
    setOpenSelectModal(!openSelectModal);
  };
  const handleDraftModal = () => {
    setOpenDraftModal(!openDraftModal);
  };
  // Columns for table

  /* DISCLAIMER: REMOVE IF THERE IS ANY ISSUE WHILE CHECKBOX SELECTION */
  useEffect(() => {
    if (listData?.length && dealerCode?.length) {
      const selectedKeys = listData
        .filter((listItem) => dealerCode.includes(listItem.code))
        .map((listItem) => listItem.key);
      setSelectedRowKeys(selectedKeys);
    }
  }, [dealerCode, listData]);

  // Dynamic Headers - Opening OS and Closing OS

  const selectedFirstMonth = new Date(startDate);
  const selectedSecondMonth = new Date(endDate);

  const handleCreateSchema = async (e, y) => {
    if (y != "draft") {
      e.preventDefault();
    }
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const userRole = userData.userRole;

    const handleDropDownSchemeCheckIndex = (x) => {
      let { t1, t2, t3, t4, t5, t6 } = x.reduce(
        (a, v) => ({ ...a, [v]: v }),
        {}
      );

      let data =
        t1 && !t2 && !t3 && !t4 && !t5 && !t6
          ? "t1"
          : t1 && t2 && !t3 && !t4 && !t5 && !t6
            ? "t2"
            : t1 && t2 && t3 && !t4 && !t5 && !t6
              ? "t3"
              : t1 && t2 && t3 && t4 && !t5 && !t6
                ? "t4"
                : t1 && t2 && t3 && t4 && t5 && !t6
                  ? "t5"
                  : t1 && t2 && t3 && t4 && t5 && t6
                    ? "t6"
                    : "";

      return data;
    };

    let status = 1;
    if (editType === "schemeProgress" && type === "edit") {
      status = 1;
    } else if (editType === "draft" && type === "edit") {
      status = 0;
    }
    let data = formik?.values?.map((x) => ({
      dealerCode: x.code,
      dealerName: x.name,
      dealerLocation: x.Location,
      dealerId: +x.key,
      StartMonth: startDate
        ? dayjs(startDate).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        : startDate,
      EndMonth: endDate
        ? dayjs(endDate).endOf("month").format("YYYY-MM-DD HH:mm:ss")
        : endDate,
      reason: x.reason,
      status: y != "draft" ? 1 : 0,
      state: x.stateId,
      billing: x.billing ? +x.billing : 0,
      installation: x.installation ? +x.installation : 0,
      collection: Math.round(x.collection ? +x.collection : 0),
      delayPlan: x.delayPlan,
      isSAP: true,
      isActive: true,
      schemePlanStartMonth: startDate,
      schemePlanEndMonth: endDate,
      schemeTypeId: schemeType,
      openingStockDays: x.openingStockDays,
      outStandingDays: x.outStandingDays ? parseInt(x.outStandingDays) : 0,
      ...(handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1"
        ? {
          t1: +x.BooksCollectionTargetT1 || 0,
          t2: 0,
          t3: 0,
          t4: 0,
          t5: 0,
          t6: 0,
          t2_T1: +x.BooksCollectionTargetT2T1 || 0,
          t3_T2: 0,
          t4_T3: 0,
          t5_T4: 0,
          t6_T5: 0,
          p1: +x.payoutR1 || 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
          r1: +x?.PayoutRatep1 || 0,
          r2: 0,
          r3: 0,
          r4: 0,
          r5: 0,
          r6: 0,
        }
        : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2"
          ? {
            t1: +x.BooksCollectionTargetT1 || 0,
            t2: +x.BooksCollectionTargetT2 || 0,
            t3: 0,
            t4: 0,
            t5: 0,
            t6: 0,
            t2_T1: +x.BooksCollectionTargetT2T1 || 0,
            t3_T2: +x.BooksCollectionTargetT3T2 || 0,
            t4_T3: 0,
            t5_T4: 0,
            t6_T5: 0,
            p1: +x.payoutR1 || 0,
            p2: +x.payoutR2 || 0,
            p3: 0,
            p4: 0,
            p5: 0,
            p6: 0,
            r1: +x?.PayoutRatep1 || 0,
            r2: +x?.PayoutRatep2 || 0,
            r3: 0,
            r4: 0,
            r5: 0,
            r6: 0,
          }
          : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3"
            ? {
              t1: +x.BooksCollectionTargetT1 || 0,
              t2: +x.BooksCollectionTargetT2 || 0,
              t3: +x.BooksCollectionTargetT3 || 0,
              t4: 0,
              t5: 0,
              t6: 0,
              t2_T1: +x.BooksCollectionTargetT2T1 || 0,
              t3_T2: +x.BooksCollectionTargetT3T2 || 0,
              t4_T3: +x.BooksCollectionTargetT4T3 || 0,
              t5_T4: 0,
              t6_T5: 0,
              p1: +x.payoutR1 || 0,
              p2: +x.payoutR2 || 0,
              p3: +x.payoutR3 || 0,
              p4: 0,
              p5: 0,
              p6: 0,
              r1: +x?.PayoutRatep1 || 0,
              r2: +x?.PayoutRatep2 || 0,
              r3: +x?.PayoutRatep3 || 0,
              r4: 0,
              r5: 0,
              r6: 0,
            }
            : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t4"
              ? {
                t1: +x.BooksCollectionTargetT1 || 0,
                t2: +x.BooksCollectionTargetT2 || 0,
                t3: +x.BooksCollectionTargetT3 || 0,
                t4: +x.BooksCollectionTargetT4 || 0,
                t5: 0,
                t6: 0,
                t2_T1: +x.BooksCollectionTargetT2T1 || 0,
                t3_T2: +x.BooksCollectionTargetT3T2 || 0,
                t4_T3: +x.BooksCollectionTargetT4T3 || 0,
                t5_T4: +x.BooksCollectionTargetT5T4 || 0,
                t6_T5: 0,
                p1: +x.payoutR1 || 0,
                p2: +x.payoutR2 || 0,
                p3: +x.payoutR3 || 0,
                p4: +x.payoutR4 || 0,
                p5: 0,
                p6: 0,
                r1: +x?.PayoutRatep1 || 0,
                r2: +x?.PayoutRatep2 || 0,
                r3: +x?.PayoutRatep3 || 0,
                r4: +x?.PayoutRatep4 || 0,
                r5: 0,
                r6: 0,
              }
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t5"
                ? {
                  t1: +x.BooksCollectionTargetT1 || 0,
                  t2: +x.BooksCollectionTargetT2 || 0,
                  t3: +x.BooksCollectionTargetT3 || 0,
                  t4: +x.BooksCollectionTargetT4 || 0,
                  t5: +x.BooksCollectionTargetT5 ? +x.BooksCollectionTargetT5 : 0,
                  t6: 0,
                  t2_T1: +x.BooksCollectionTargetT2T1 || 0,
                  t3_T2: +x.BooksCollectionTargetT3T2 || 0,
                  t4_T3: +x.BooksCollectionTargetT4T3 || 0,
                  t5_T4: +x.BooksCollectionTargetT5T4 || 0,
                  t6_T5: 0,
                  p1: +x.payoutR1 || 0,
                  p2: +x.payoutR2 || 0,
                  p3: +x.payoutR3 || 0,
                  p4: +x.payoutR4 || 0,
                  p5: +x.payoutR5 || 0,
                  p6: 0,
                  r1: +x?.PayoutRatep1 || 0,
                  r2: +x?.PayoutRatep2 || 0,
                  r3: +x?.PayoutRatep3 || 0,
                  r4: +x?.PayoutRatep4 || 0,
                  r5: +x?.PayoutRatep5 || 0,
                  r6: 0,
                }
                : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t6"
                  ? {
                    t1: +x.BooksCollectionTargetT1 || 0,
                    t2: +x.BooksCollectionTargetT2 || 0,
                    t3: +x.BooksCollectionTargetT3 || 0,
                    t4: +x.BooksCollectionTargetT4 || 0,
                    t5: +x.BooksCollectionTargetT5 || 0,
                    t6: +x?.BooksCollectionTargetT6 || 0,
                    t2_T1: +x.BooksCollectionTargetT2T1 || 0,
                    t3_T2: +x.BooksCollectionTargetT3T2 || 0,
                    t4_T3: +x.BooksCollectionTargetT4T3 || 0,
                    t5_T4: +x.BooksCollectionTargetT5T4 || 0,
                    t6_T5: +x.BooksCollectionTargetT6T5 || 0,
                    p1: +x.payoutR1 || 0,
                    p2: +x.payoutR2 || 0,
                    p3: +x.payoutR3 || 0,
                    p4: +x.payoutR4 || 0,
                    p5: +x.payoutR5 || 0,
                    p6: +x.payoutR6 || 0,
                    r1: +x?.PayoutRatep1 || 0,
                    r2: +x?.PayoutRatep2 || 0,
                    r3: +x?.PayoutRatep3 || 0,
                    r4: +x?.PayoutRatep4 || 0,
                    r5: +x?.PayoutRatep5 || 0,
                    r6: +x?.PayoutRatep6 || 0,
                  }
                  : {
                    t1: 0,
                    t2: 0,
                    t3: 0,
                    t4: 0,
                    t5: 0,
                    t6: 0,
                    t2_T1: 0,
                    t3_T2: 0,
                    t4_T3: 0,
                    t5_T4: 0,
                    t6_T5: 0,
                    p1: 0,
                    p2: 0,
                    p3: 0,
                    p4: 0,
                    p5: 0,
                    p6: 0,
                    r1: 0,
                    r2: 0,
                    r3: 0,
                    r4: 0,
                    r5: 0,
                    r6: 0,
                  }),

      volume: x.TEPvol,
      perTr: Math.round(x.TEPpertr),
      amount: x.TEPamount,
      ...(y != "draft" && { isSubmitted: 1 }),
      userrole: userRole,
      ...(y != "draft" && { Manager: y.Manager }),
      fyear: currentFiscalYear,
      userid: +userId,
      username: userName,
      industry: x.industry ? parseInt(x.industry) : 0,
      oldBillingQty: x.oldBillingQty,
      oldInstallation: x.oldInstallation,
      oldCollection: Math.round(x.oldCollection),
      oldOpeningStock: x.oldOpeningStock,
      closingOutStandingMonth_1: x.closingOutStandingMonth_1,
      closingOutStandingMonth_2: x.closingOutStandingMonth_2,
      closingOutStandingMonth_3: x.closingOutStandingMonth_3,
      bgLacsCurrentYear: x.bgLacsCurrentYear,
      SlabAchieved: x.slab,
      TopTarget: x?.dropdownscheme?.toString(),
      closingStock: x?.closingStock,
      closingOutStanding: x?.closingOutStanding,
      billingFactorCOS: x?.billingFactorCOS
    }));

    let extraValidation = {};

if(formik?.values?.filter((selectedSchemesFil)=>selectedRowKeys.includes(selectedSchemesFil?.key))?.length!=0){    
formik?.values?.filter((selectedSchemesFil)=>selectedRowKeys.includes(selectedSchemesFil?.key)).map((x, index) => {
      let topTargetDetails = x?.dropdownscheme;
      let errors = [];
      if (topTargetDetails?.length == 0) {
        errors.push("Please select top target");
      }
      if (!x.industry) {
        errors.push("Please enter industry");
      }
      // if (!x.openingStockDays) {
      //   errors.push("Please enter open stock days");
      // }
      // if (!x.outStandingDays) {
      //   errors.push("Please enter outstanding days");
      // }
      if (!x.billing) {
        errors.push("Please enter billing");
      }
      if (!x.installation) {
        errors.push("Please enter installation");
      }
      if (!x.collection) {
        errors.push("Please enter collection");
      }
      if (!x.delayPlan && schemeType == 1) {
        errors.push("Please enter Installtion Plan Nos");
      }
      if (!x.TEPvol) {
        errors.push("Please enter volume");
      }
      //TEPvol
      if (
        topTargetDetails?.includes("t6") &&
        parseInt(x?.BooksCollectionTargetT6 || 0) <=
        parseInt(x?.BooksCollectionTargetT5 || 0)
      ) {
        errors.push("T6 should be greater than T5");
      }
      if (
        topTargetDetails?.includes("t5") &&
        parseInt(x?.BooksCollectionTargetT5 || 0) <=
        parseInt(x?.BooksCollectionTargetT4 || 0)
      ) {
        errors.push("T5 should be greater than T4");
      }
      if (
        topTargetDetails?.includes("t4") &&
        parseInt(x?.BooksCollectionTargetT4 || 0) <=
        parseInt(x?.BooksCollectionTargetT3 || 0)
      ) {
        errors.push("T4 should be greater than T3");
      }
      if (
        topTargetDetails?.includes("t3") &&
        parseInt(x?.BooksCollectionTargetT3 || 0) <=
        parseInt(x?.BooksCollectionTargetT2 || 0)
      ) {
        errors.push("T3 should be greater than T2");
      }
      if (
        topTargetDetails?.includes("t2") &&
        parseInt(x?.BooksCollectionTargetT2 || 0) <=
        parseInt(x?.BooksCollectionTargetT1 || 0)
      ) {
        errors.push("T2 should be greater than T1");
      }
      if (
        topTargetDetails?.includes("t6") &&
        parseInt(x?.PayoutRatep6 || 0) <= parseInt(x?.PayoutRatep5 || 0)
      ) {
        errors.push("R6 should be greater than R5");
      }
      if (
        topTargetDetails?.includes("t5") &&
        parseInt(x?.PayoutRatep5 || 0) <= parseInt(x?.PayoutRatep4 || 0)
      ) {
        errors.push("R5 should be greater than R4");
      }
      if (
        topTargetDetails?.includes("t4") &&
        parseInt(x?.PayoutRatep4 || 0) <= parseInt(x?.PayoutRatep3 || 0)
      ) {
        errors.push("R4 should be greater than R3");
      }
      if (
        topTargetDetails?.includes("t3") &&
        parseInt(x?.PayoutRatep3 || 0) <= parseInt(x?.PayoutRatep2 || 0)
      ) {
        errors.push("R3 should be greater than R2");
      }
      if (
        topTargetDetails?.includes("t2") &&
        parseInt(x?.PayoutRatep2 || 0) <= parseInt(x?.PayoutRatep1 || 0)
      ) {
        errors.push("R2 should be greater than R1");
      }
      if (!x.PayoutRatep1) {
        errors.push("Please enter R1");
      }
      if (!x.PayoutRatep2 && topTargetDetails?.includes("t2")) {
        errors.push("Please enter R2");
      }
      if (!x.PayoutRatep3 && topTargetDetails?.includes("t3")) {
        errors.push("Please enter R3");
      }
      if (!x.PayoutRatep4 && topTargetDetails?.includes("t4")) {
        errors.push("Please enter R4");
      }
      if (!x.PayoutRatep5 && topTargetDetails?.includes("t5")) {
        errors.push("Please enter R5");
      }
      if (!x.PayoutRatep6 && topTargetDetails?.includes("t6")) {
        errors.push("Please enter R6");
      }
      if (!x.BooksCollectionTargetT1) {
        errors.push("Please enter T1");
      }
      if (!x.BooksCollectionTargetT2 && topTargetDetails?.includes("t2")) {
        errors.push("Please enter T2");
      }
      if (!x.BooksCollectionTargetT3 && topTargetDetails?.includes("t3")) {
        errors.push("Please enter T3");
      }
      if (!x.BooksCollectionTargetT4 && topTargetDetails?.includes("t4")) {
        errors.push("Please enter T4");
      }
      if (!x.BooksCollectionTargetT5 && topTargetDetails?.includes("t5")) {
        errors.push("Please enter T5");
      }
      if (!x.BooksCollectionTargetT6 && topTargetDetails?.includes("t6")) {
        errors.push("Please enter T6");
      }
      if (errors.length > 0) {
        extraValidation[x?.code] = errors;
      }
    });

    setCodeError(Object?.keys(extraValidation || {}));

    if (Object?.keys?.(extraValidation)?.length != 0) {
      showErrorDialog(extraValidation);
    }
const selectedData1=data?.filter((selectedSchemesFil)=>selectedRowKeys.includes(selectedSchemesFil?.dealerId))
    if (Object?.keys?.(extraValidation)?.length == 0) {
      if (y != "draft") {
        setShowDraftModal(true);
        setSubmitData1(selectedData1);
      } else {
        setSubmitData1(selectedData1);
        setShowSubmitModal(true);
        // setSubmitData1(data1)
      }
    }
  }else{
    ToastifyShow("Please select the scheme","error")
  }
  };

  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    if (order == "") {
      setOrder("desc");
      setOrderBy(property);
      return;
    }
    if (order == "desc") {
      setOrder("asc");
      setOrderBy(property);
      return;
    }
    if (order == "asc") {
      setOrder("desc");
      setOrderBy(property);
      return;
    }
  };

  const [visibleRows, setVisibleRows] = useState([]);
  useEffect(() => {
    setVisibleRows(stableSort(formik?.values, getComparator(order, orderBy)));
  }, [formik?.values, order, orderBy]);

  const calculateFormula = (index) => {
    let y = formik?.values[index];
    let {
      BooksCollectionTargetT2,
      BooksCollectionTargetT1,
      BooksCollectionTargetT3,
      BooksCollectionTargetT4,
      BooksCollectionTargetT5,
      BooksCollectionTargetT6,
      PayoutRatep1,
      PayoutRatep2,
      PayoutRatep3,
      PayoutRatep4,
      PayoutRatep5,
      PayoutRatep6,
      TEPamount,
      TEPvol,
      TEPpertr,
      dropdownscheme,
      oldOpeningStock,
      billing,
      installation,
      collection,
      closingOutStandingMonth_1,
      billingFactorCOS
    } = formik?.values[index];

    const TEPvol1 = TEPvol ? parseInt(TEPvol) : 0;


    const handleDropDownSchemeCheckIndex = () => {
      let { t1, t2, t3, t4, t5, t6 } = dropdownscheme.reduce(
        (a, v) => ({ ...a, [v]: v }),
        {}
      );
      return t1 && !t2 && !t3 && !t4 && !t5 && !t6
        ? "t1"
        : t1 && t2 && !t3 && !t4 && !t5 && !t6
          ? "t2"
          : t1 && t2 && t3 && !t4 && !t5 && !t6
            ? "t3"
            : t1 && t2 && t3 && t4 && !t5 && !t6
              ? "t4"
              : t1 && t2 && t3 && t4 && t5 && !t6
                ? "t5"
                : t1 && t2 && t3 && t4 && t5 && t6
                  ? "t6"
                  : "";
    };
    let validationSoftTarget = handleDropDownSchemeCheckIndex();

    // let calculateTepAMount =
    //   (TEPvol1 >= BooksCollectionTargetT1 && TEPvol1 < BooksCollectionTargetT2) ? (parseInt(TEPvol1) * parseInt(PayoutRatep1))
    //     : (TEPvol1 < BooksCollectionTargetT1 || TEPvol == 0) ? 0
    //       : TEPvol1 >= BooksCollectionTargetT2 && TEPvol1 < BooksCollectionTargetT3 ? parseInt(TEPvol1) * parseInt(PayoutRatep2)
    //         : parseInt(TEPvol1) * parseInt(PayoutRatep3)

    // let calculateTepAMount =
    // TEPvol1 >= BooksCollectionTargetT1 && TEPvol1 < BooksCollectionTargetT2
    //   ? parseInt(TEPvol1) * parseInt(PayoutRatep1)
    //   : TEPvol1 < BooksCollectionTargetT1 || TEPvol == 0
    //     ? 0
    //     : TEPvol1 >= BooksCollectionTargetT2 &&
    //       TEPvol1 < BooksCollectionTargetT3
    //       ? parseInt(TEPvol1) * parseInt(PayoutRatep2)
    //       : parseInt(TEPvol1) * parseInt(PayoutRatep3);

    let calculateTepAMount1 = () => {
      // eslint-disable-next-line default-case
      switch (validationSoftTarget) {
        case "t1":
        return parseInt(TEPvol1) < parseInt(BooksCollectionTargetT1)
        ? 0
        : parseInt(TEPvol1) 
          ? parseInt(TEPvol1) * parseInt(PayoutRatep1):0

        case "t2":
                return parseInt(TEPvol1) < parseInt(BooksCollectionTargetT1)
                ? 0
                : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT1))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT2))
                  ? parseInt(TEPvol1) * parseInt(PayoutRatep1)
                  : parseInt(TEPvol1)
                    ? parseInt(TEPvol1) * parseInt(PayoutRatep2):0
              
        case "t3":
          return parseInt(TEPvol1) < parseInt(BooksCollectionTargetT1)
                  ? 0
                  : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT1))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT2))
                    ? parseInt(TEPvol1) * parseInt(PayoutRatep1)
                    : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT2))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT3))
                      ? parseInt(TEPvol1) * parseInt(PayoutRatep2)
                      : parseInt(TEPvol1)
                        ? parseInt(TEPvol1) * parseInt(PayoutRatep3):0


        case "t4":
            return parseInt(TEPvol1) < parseInt(BooksCollectionTargetT1)
                    ? 0
                    : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT1))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT2))
                      ? parseInt(TEPvol1) * parseInt(PayoutRatep1)
                      : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT2))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT3))
                        ? parseInt(TEPvol1) * parseInt(PayoutRatep2)
                        : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT3))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT4))
                          ? parseInt(TEPvol1) * parseInt(PayoutRatep3)
                          : parseInt(TEPvol1)
                            ? parseInt(TEPvol1) * parseInt(PayoutRatep4):0

        case "t5":


                      return parseInt(TEPvol1) < parseInt(BooksCollectionTargetT1)
                      ? 0
                      : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT1))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT2))
                        ? parseInt(TEPvol1) * parseInt(PayoutRatep1)
                        : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT2))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT3))
                          ? parseInt(TEPvol1) * parseInt(PayoutRatep2)
                          : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT3))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT4))
                            ? parseInt(TEPvol1) * parseInt(PayoutRatep3)
                            : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT4))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT5))
                              ? parseInt(TEPvol1) * parseInt(PayoutRatep4)
                              : parseInt(TEPvol1)
                                ? parseInt(TEPvol1) * parseInt(PayoutRatep5):0
        case "t6":
          return parseInt(TEPvol1) < parseInt(BooksCollectionTargetT1)
            ? 0
            : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT1))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT2))
              ? parseInt(TEPvol1) * parseInt(PayoutRatep1)
              : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT2))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT3))
                ? parseInt(TEPvol1) * parseInt(PayoutRatep2)
                : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT3))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT4))
                  ? parseInt(TEPvol1) * parseInt(PayoutRatep3)
                  : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT4))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT5))
                    ? parseInt(TEPvol1) * parseInt(PayoutRatep4)
                    : (parseInt(TEPvol1) >= parseInt(BooksCollectionTargetT5))&&(parseInt(TEPvol1)<parseInt(BooksCollectionTargetT6))
                      ? parseInt(TEPvol1) * parseInt(PayoutRatep5)
                      : parseInt(TEPvol1)
                        ? parseInt(TEPvol1) * parseInt(PayoutRatep6)
                        : 0;
      }
    };
    let calculateTepAMount = calculateTepAMount1();

    let TEPpertr1Calculate = calculateTepAMount / TEPvol1;

    //Calculate ClosingStock
    const calculateClosingStock = () => {
      return (oldOpeningStock + parseInt(billing)) - installation;
    };
    const closingStock = calculateClosingStock();

    const calculateClosingOutStanding = () => {
      const closingOutstanding = ((parseFloat(closingOutStandingMonth_1 * 100000)) 
        + (parseInt(billing) * parseFloat(billingFactorCOS)))
        - (parseInt(collection) * parseFloat(billingFactorCOS));
      
      return closingOutstanding / 100000;
    };
    const closingOutStanding = calculateClosingOutStanding().toFixed(2);

    let slabAchieve;

    if (TEPvol1 < BooksCollectionTargetT1){
        slabAchieve = "";
      }else if (TEPvol1 >= BooksCollectionTargetT1 &&( (TEPvol1 < BooksCollectionTargetT2) || validationSoftTarget == "t1")){
        slabAchieve = "T1";
      }else if (TEPvol1 >= BooksCollectionTargetT2 && ((TEPvol1 < BooksCollectionTargetT3) || validationSoftTarget == "t2")){
        slabAchieve = "T2";
      }else if (TEPvol1 >= BooksCollectionTargetT3 && ((TEPvol1 < BooksCollectionTargetT4) || validationSoftTarget == "t3")){
        slabAchieve = "T3";
      }else if (TEPvol1 >= BooksCollectionTargetT4 && ((TEPvol1 < BooksCollectionTargetT5) || validationSoftTarget == "t4")){
        slabAchieve = "T4";
      }else if (TEPvol1 >= BooksCollectionTargetT5 && ((TEPvol1 < BooksCollectionTargetT6 )|| validationSoftTarget == "t5")){
        slabAchieve = "T5";
      }else if (TEPvol1 >= BooksCollectionTargetT6){
        slabAchieve = "T6";
      }

    formik?.setFieldValue(index, {
      ...y,
      BooksCollectionTargetT2T1:
        +(
          BooksCollectionTargetT2 &&
          BooksCollectionTargetT1 &&
          parseInt(BooksCollectionTargetT2 || 0) -
          parseInt(BooksCollectionTargetT1 || 0)
        ) || 0,
      BooksCollectionTargetT3T2: +(
        (BooksCollectionTargetT3 &&
          BooksCollectionTargetT2 &&
          parseInt(BooksCollectionTargetT3 || 0) -
          parseInt(BooksCollectionTargetT2 || 0)) ||
        0
      ),
      BooksCollectionTargetT4T3:
        +(
          BooksCollectionTargetT4 &&
          BooksCollectionTargetT3 &&
          parseInt(BooksCollectionTargetT4 || 0) -
          parseInt(BooksCollectionTargetT3 || 0)
        ) || 0,
      BooksCollectionTargetT5T4:
        +(
          BooksCollectionTargetT5 &&
          BooksCollectionTargetT4 &&
          parseInt(BooksCollectionTargetT5 || 0) -
          parseInt(BooksCollectionTargetT4 || 0)
        ) || 0,
      BooksCollectionTargetT6T5:
        +(
          BooksCollectionTargetT6 &&
          BooksCollectionTargetT5 &&
          parseInt(BooksCollectionTargetT6 || 0) -
          parseInt(BooksCollectionTargetT5 || 0)
        ) || 0,
      payoutR1:
        BooksCollectionTargetT1 && PayoutRatep1
          ? parseInt(BooksCollectionTargetT1 || 0) * parseInt(PayoutRatep1 || 0)
          : 0,
      payoutR2:
        BooksCollectionTargetT2 && PayoutRatep2
          ? parseInt(BooksCollectionTargetT2 || 0) * parseInt(PayoutRatep2 || 0)
          : 0,
      payoutR3:
        BooksCollectionTargetT3 && PayoutRatep3
          ? parseInt(BooksCollectionTargetT3 || 0) * parseInt(PayoutRatep3 || 0)
          : "",
      payoutR4:
        BooksCollectionTargetT4 && PayoutRatep4
          ? parseInt(BooksCollectionTargetT4 || 0) * parseInt(PayoutRatep4 || 0)
          : "",
      payoutR5:
        BooksCollectionTargetT5 && PayoutRatep5
          ? parseInt(BooksCollectionTargetT5 || 0) * parseInt(PayoutRatep5 || 0)
          : "",
      payoutR6:
        BooksCollectionTargetT6 && PayoutRatep6
          ? parseInt(BooksCollectionTargetT6 || 0) * parseInt(PayoutRatep6 || 0)
          : "",
      TEPpertr: Math.round(TEPpertr1Calculate),
      TEPamount: isNaN(Math.round(calculateTepAMount)) ? 0 : Math.round(calculateTepAMount),
      slab: slabAchieve,
      closingStock: closingStock,
      closingOutStanding: closingOutStanding
    });

    if (
      BooksCollectionTargetT1 != null &&
      BooksCollectionTargetT2 != null &&
      +BooksCollectionTargetT2 <= +BooksCollectionTargetT1 &&
      (validationSoftTarget == "t2" ||
        validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("T2", "T1", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   BooksCollectionTargetT2: "",
      // });
    }

    if (
      BooksCollectionTargetT2 != null &&
      BooksCollectionTargetT3 != null &&
      +BooksCollectionTargetT3 <= +BooksCollectionTargetT2 &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("T3", "T2", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   BooksCollectionTargetT3: "",
      // });
    }

    if (
      BooksCollectionTargetT3 != null &&
      BooksCollectionTargetT4 != null &&
      +BooksCollectionTargetT4 <= +BooksCollectionTargetT3 &&
      (validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("T4", "T3", true);
      return false;
    }

    if (
      BooksCollectionTargetT4 != null &&
      BooksCollectionTargetT5 != null &&
      +BooksCollectionTargetT5 <= +BooksCollectionTargetT4 &&
      (validationSoftTarget == "t5" || validationSoftTarget == "t6")
    ) {
      handleErrorModal("T5", "T4", true);
      return false;
    }
    if (
      BooksCollectionTargetT5 != null &&
      BooksCollectionTargetT6 != null &&
      +BooksCollectionTargetT6 <= +BooksCollectionTargetT5 &&
      validationSoftTarget == "t6"
    ) {
      handleErrorModal("T6", "T5", true);
      return false;
    }
    if (
      PayoutRatep2 != null &&
      PayoutRatep1 != null &&
      +PayoutRatep2 <= +PayoutRatep1 &&
      (validationSoftTarget == "t2" ||
        validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("R2", "R1", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   PayoutRatep2: "",
      // });
    }

    if (
      PayoutRatep3 != null &&
      PayoutRatep2 != null &&
      +PayoutRatep3 <= +PayoutRatep2 &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("R3", "R2", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   PayoutRatep3: "",
      // });
    }

    if (
      PayoutRatep4 != null &&
      PayoutRatep3 != null &&
      +PayoutRatep4 <= +PayoutRatep3 &&
      (validationSoftTarget == "t4" ||
        validationSoftTarget == "t5" ||
        validationSoftTarget == "t6")
    ) {
      handleErrorModal("R4", "R3", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   PayoutRatep3: "",
      // });
    }

    if (
      PayoutRatep5 != null &&
      PayoutRatep4 != null &&
      +PayoutRatep5 <= +PayoutRatep4 &&
      (validationSoftTarget == "t5" || validationSoftTarget == "t6")
    ) {
      handleErrorModal("R5", "R4", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   PayoutRatep3: "",
      // });
    }

    if (
      PayoutRatep6 != null &&
      PayoutRatep5 != null &&
      +PayoutRatep6 <= +PayoutRatep5 &&
      validationSoftTarget == "t6"
    ) {
      handleErrorModal("R6", "R5", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   PayoutRatep3: "",
      // });
    }
    //

  };
  const useCreateSchemeColumns = (
    formik,
    listData,
    selectedFirstMonth,
    selectedSecondMonth
  ) => {
    const firstPastMonth = new Date(
      selectedFirstMonth.getFullYear(),
      selectedFirstMonth.getMonth() - 2,
      1
    );
    const secondPastMonth = new Date(
      selectedFirstMonth.getFullYear(),
      selectedFirstMonth.getMonth() - 1,
      1
    );
    const thirdPastMonth = new Date(
      selectedFirstMonth.getFullYear(),
      selectedFirstMonth.getMonth() - 3,
      1
    );

    const firstPastMonthString = firstPastMonth.toLocaleString("en-IN", {
      year: "2-digit",
      month: "short",
    });
    const secondPastMonthString = secondPastMonth.toLocaleString("en-IN", {
      year: "2-digit",
      month: "short",
    });
    const thirdPastMonthString = thirdPastMonth.toLocaleString("en-IN", {
      year: "2-digit",
      month: "short",
    });

    const previousYearSameMonth = new Date(
      selectedFirstMonth.getFullYear() - 1,
      selectedFirstMonth.getMonth(),
      1
    );

    const previousYearSameMonthString = previousYearSameMonth.toLocaleString(
      "en-IN",
      { year: "2-digit", month: "short" }
    );

    const selectedFirstMonthString = selectedFirstMonth?.toLocaleString(
      "en-IN",
      {
        year: "2-digit",
        month: "short",
      }
    );
    const selectedSecondMonthString = selectedSecondMonth?.toLocaleString(
      "en-IN",
      {
        year: "2-digit",
        month: "short",
      }
    );
    const getDropdown = () =>
      schemeLockedStatus?.t1
        ? [
          {
            title: "Top Slab",
            width: "150px",
            render: (x, y, index) => {
              const handleChange = (value) => {
                let dropdownValues = formik?.values?.[index]?.dropdownscheme;
                let checkValue1 = value?.includes("t1");
                let checkValue2 = value?.includes("t2");
                let checkValue3 = value?.includes("t3");
                let checkValue4 = value?.includes("t4");
                let checkValue5 = value?.includes("t5");
                let checkValue6 = value?.includes("t6");
                const handleDropDownScheme = () => {
                  return checkValue6
                    ? ["t1", "t2", "t3", "t4", "t5", "t6"]
                    : checkValue5
                      ? ["t1", "t2", "t3", "t4", "t5"]
                      : checkValue4
                        ? ["t1", "t2", "t3", "t4"]
                        : checkValue3
                          ? ["t1", "t2", "t3"]
                          : checkValue2
                            ? ["t1", "t2"]
                            : checkValue1
                              ? ["t1"]
                              : [];
                };
                const handleDropdownSchemes = handleDropDownScheme();

                formik.setFieldValue(index, {
                  ...x,
                  dropdownscheme: handleDropDownScheme() || null,
                  ...(handleDropdownSchemes.includes("t6")
                  ? {
                  }:handleDropdownSchemes.includes("t5")
                    ? {
                      BooksCollectionTargetT6T5: null,
                      PayoutRatep6: null,
                      BooksCollectionTargetT6: null,
                    }
                    : handleDropdownSchemes.includes("t4")
                      ? {
                        PayoutRatep5: null,
                        BooksCollectionTargetT5: null,
                        PayoutRatep6: null,
                        BooksCollectionTargetT6: null,
                        BooksCollectionTargetT5T4: 0,
                      }
                      : handleDropdownSchemes.includes("t3")
                        ? {
                          PayoutRatep4: null,
                          BooksCollectionTargetT4: null,
                          PayoutRatep5: null,
                          BooksCollectionTargetT5: null,
                          PayoutRatep6: null,
                          BooksCollectionTargetT6: null,
                          BooksCollectionTargetT4T3: 0,
                        }
                        : handleDropdownSchemes.includes("t2")
                          ? {
                            PayoutRatep3: null,
                            BooksCollectionTargetT3: null,
                            PayoutRatep4: null,
                            BooksCollectionTargetT4: null,
                            PayoutRatep5: null,
                            BooksCollectionTargetT5: null,
                            PayoutRatep6: null,
                            BooksCollectionTargetT6: null,
                            BooksCollectionTargetT3T2: 0,
                          }
                          : handleDropdownSchemes.includes("t1") && {
                            PayoutRatep2: null,
                            BooksCollectionTargetT2: null,
                            PayoutRatep3: null,
                            BooksCollectionTargetT3: null,
                            PayoutRatep4: null,
                            BooksCollectionTargetT4: null,
                            PayoutRatep5: null,
                            BooksCollectionTargetT5: null,
                            PayoutRatep6: null,
                            BooksCollectionTargetT6: null,
                            BooksCollectionTargetT2T1: 0,
                          }),

                  BooksCollectionTargetT6T5: null,
                });
              };
              return (
                <Select
                  onChange={handleChange}
                  value={y?.dropdownscheme}
                  mode="multiple"
                  autoClearSearchValue="multiple"
                  className="w-100"
                  allowClear
                  onDeselect={false}
                  showarrow={false}
                  removeIcon={false}
                >
                  {topTargetArr
                    ?.filter((x) => !x?.disabled)
                    ?.map((x) => (
                      <Select.Option value={x?.value}>
                        {x?.name}
                      </Select.Option>
                    ))}
                </Select>
              );
            },
          },
        ]
        : [];

    const getTargetT1 = () =>
      schemeLockedStatus?.t1
        ? [
          {
            title: "T1",
            dataIndex: "BooksCollectionTargetT1",
            editable: true,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.BooksCollectionTargetT1}
                value={formik?.values?.[index]?.BooksCollectionTargetT1 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    BooksCollectionTargetT1: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
        : [];
    const getTargetT2 = () =>
      schemeLockedStatus?.t2
        ? [
          {
            title: "T2",
            dataIndex: "BooksCollectionTargetT2",
            editable: true,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.BooksCollectionTargetT2}
                value={formik?.values?.[index]?.BooksCollectionTargetT2 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    BooksCollectionTargetT2: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
          {
            title: "T2-T1",
            dataIndex: "BooksCollectionTargetT2T1",
            width: "100px",
            render: (x, y, index) => {
              return formik?.values?.[index]?.dropdownscheme?.includes(
                "t2"
              ) && formik?.values?.[index]?.dropdownscheme?.includes("t1") ? (
                     <div>{TextLocalString(x)}</div>
              ) : (
                <div style={{ height: "26px" }} className={"input-disabled1"}>
                  &nbsp;
                </div>
              );
            },
          },
        ]
        : [];
    const getTargetT3 = () =>
      schemeLockedStatus?.t3
        ? [
          {
            title: "T3",
            dataIndex: "BooksCollectionTargetT3",
            editable: true,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.BooksCollectionTargetT3}
                value={formik?.values?.[index]?.BooksCollectionTargetT3 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? true
                    : false
                }
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    BooksCollectionTargetT3: value,
                  });
                }}
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
          {
            title: "T3-T2",
            dataIndex: "BooksCollectionTargetT3T2",
            width: "100px",
            render: (x, y, index) => {
              return formik?.values?.[index]?.dropdownscheme?.includes(
                "t3"
              ) && formik?.values?.[index]?.dropdownscheme?.includes("t2") ? (
                     <div>{TextLocalString(x)}</div>
              ) : (
                <div style={{ height: "26px" }} className={"input-disabled1"}>
                  &nbsp;
                </div>
              );
            },
          },
        ]
        : [];
    const getTargetT4 = () =>
      schemeLockedStatus?.t4
        ? [
          {
            title: "T4",
            dataIndex: "BooksCollectionTargetT1",
            editable: true,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.BooksCollectionTargetT4}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                    ? true
                    : false
                }
                value={formik?.values?.[index]?.BooksCollectionTargetT4 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    BooksCollectionTargetT4: value,
                  });
                }}
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
          {
            title: "T4-T3",
            dataIndex: "BooksCollectionTargetT4T3",
            width: "100px",
            render: (x, y, index) => {
              return formik?.values?.[index]?.dropdownscheme?.includes(
                "t4"
              ) && formik?.values?.[index]?.dropdownscheme?.includes("t3") ? (
                     <div>{TextLocalString(x)}</div>
              ) : (
                <div style={{ height: "26px" }} className={"input-disabled1"}>
                  &nbsp;
                </div>
              );
            },
          },
        ]
        : [];
    const getTargetT5 = () =>
      schemeLockedStatus?.t5
        ? [
          {
            title: "T5",
            dataIndex: "BooksCollectionTargetT5",
            editable: true,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.BooksCollectionTargetT5}
                value={formik?.values?.[index]?.BooksCollectionTargetT5 || ""}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                    ? true
                    : false
                }
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    BooksCollectionTargetT5: value,
                  });
                }}
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
          {
            title: "T5-T4",
            dataIndex: "BooksCollectionTargetT5T4",
            width: "100px",
            render: (x, y, index) => {
              return formik?.values?.[index]?.dropdownscheme?.includes(
                "t5"
              ) && formik?.values?.[index]?.dropdownscheme?.includes("t4") ? (
                     <div>{TextLocalString(x)}</div>
              ) : (
                <div style={{ height: "26px" }} className={"input-disabled1"}>
                  &nbsp;
                </div>
              );
            },
          },
        ]
        : [];
    const getTargetT6 = () =>
      schemeLockedStatus?.t6
        ? [
          {
            title: "T6",
            dataIndex: "BooksCollectionTargetT6",
            editable: true,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.BooksCollectionTargetT6}
                value={formik?.values?.[index]?.BooksCollectionTargetT6 || ""}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                    ? true
                    : false
                }
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    BooksCollectionTargetT6: value,
                  });
                }}
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
          {
            title: "T6-T5",
            dataIndex: "BooksCollectionTargetT6T5",
            width: "100px",
            render: (x, y, index) => {
              return formik?.values?.[index]?.dropdownscheme?.includes(
                "t6"
              ) && formik?.values?.[index]?.dropdownscheme?.includes("t5") ? (
                <div>{TextLocalString(x)}</div>
              ) : (
                <div style={{ height: "26px" }} className={"input-disabled1"}>
                  &nbsp;
                </div>
              );
            },
          },
        ]
        : [];
    const getPayoutRateR1 = () =>
      schemeLockedStatus?.t1
        ? [
          {
            title: "R1",
            dataIndex: "PayoutRatep1",
            editable: false,
            width: "120px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.PayoutRatep1}
                value={formik?.values?.[index]?.PayoutRatep1 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    PayoutRatep1: value,
                  });
                }}
                maxLength={5}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
        : [];
    const getPayoutRateR2 = () =>
      schemeLockedStatus?.t2
        ? [
          {
            title: "R2",
            dataIndex: "PayoutRatep2",
            editable: false,
            width: "120px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.PayoutRatep2}
                value={formik?.values?.[index]?.PayoutRatep2 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    PayoutRatep2: value,
                  });
                }}
                maxLength={5}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
        : [];
    const getPayoutRateR3 = () =>
      schemeLockedStatus?.t3
        ? [
          {
            title: "R3",
            dataIndex: "PayoutRatep3",
            editable: true,
            width: "120px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.PayoutRatep3}
                value={formik?.values?.[index]?.PayoutRatep3 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    PayoutRatep3: value,
                  });
                }}
                maxLength={5}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
        : [];
    const getPayoutRateR4 = () =>
      schemeLockedStatus?.t4
        ? [
          {
            title: "R4",
            dataIndex: "PayoutRatep4",
            editable: true,
            width: "120px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.PayoutRatep4}
                value={formik?.values?.[index]?.PayoutRatep4 || ""}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                    ? true
                    : false
                }
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    PayoutRatep4: value,
                  });
                }}
                maxLength={5}
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
        : [];
    const getPayoutRateR5 = () =>
      schemeLockedStatus?.t5
        ? [
          {
            title: "R5",
            dataIndex: "PayoutRatep5",
            editable: true,
            width: "120px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.PayoutRatep5}
                value={formik?.values?.[index]?.PayoutRatep5 || ""}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                    ? true
                    : false
                }
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    PayoutRatep5: value,
                  });
                }}
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                    ? ""
                    : "Type here"
                }
                maxLength={5}
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
        : [];
    const getPayoutRateR6 = () =>
      schemeLockedStatus?.t6
        ? [
          {
            title: "R6",
            dataIndex: "PayoutRatep3",
            editable: true,
            width: "120px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.PayoutRatep6}
                value={formik?.values?.[index]?.PayoutRatep6 || ""}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                    ? true
                    : false
                }
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    PayoutRatep6: value,
                  });
                }}
                maxLength={5}
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
        : [];
    const getPayoutP1 = () =>
      schemeLockedStatus?.t1
        ? [
          {
            title: "P1",
            dataIndex: "payoutR1",
            width: "120px",
            render: (x, y, index) =>
              formik?.values?.[index]?.dropdownscheme?.includes("t1") ? (
                     <div>{TextLocalString(x)}</div>
              ) : (
                <div className="h-24 input-disabled"></div>
              ),
          },
        ]
        : [
          {
            title: "P1",
            dataIndex: "payoutR1",
            width: "120px",
            render: (x) => <div className="h-24 input-disabled"></div>,
          },
        ];
    const getPayoutP2 = () =>
      schemeLockedStatus?.t2
        ? [
          {
            title: "P2",
            dataIndex: "payoutR2",
            width: "120px",
            render: (x, y, index) =>
              formik?.values?.[index]?.dropdownscheme?.includes("t2") ? (
                     <div>{TextLocalString(x)}</div>
              ) : (
                <div className="h-24 input-disabled"></div>
              ),
          },
        ]
        : [
          [
            {
              title: "P2",
              dataIndex: "payoutR2",
              width: "120px",
              render: (x) => <div className="h-24 input-disabled"></div>,
            },
          ],
        ];
    const getPayoutP3 = () =>
      schemeLockedStatus?.t3
        ? [
          {
            title: "P3",
            dataIndex: "payoutR3",
            width: "120px",
            render: (x, y, index) =>
              formik?.values?.[index]?.dropdownscheme?.includes("t3") ? (
                     <div>{TextLocalString(x)}</div>
              ) : (
                <div className="h-24 input-disabled"></div>
              ),
          },
        ]
        : [
          {
            title: "P3",
            dataIndex: "payoutR3",
            width: "120px",
            render: (x) => <div className="h-24 input-disabled"></div>,
          },
        ];
    const getPayoutP4 = () =>
      schemeLockedStatus?.t4
        ? [
          {
            title: "P4",
            dataIndex: "payoutR4",
            width: "120px",
            render: (x, y, index) =>
              formik?.values?.[index]?.dropdownscheme?.includes("t4") ? (
                     <div>{TextLocalString(x)}</div>
              ) : (
                <div className="h-24 input-disabled"></div>
              ),
          },
        ]
        : [];
    const getPayoutP5 = () =>
      schemeLockedStatus?.t5
        ? [
          {
            title: "P5",
            dataIndex: "payoutR5",
            width: "120px",
            render: (x, y, index) =>
              formik?.values?.[index]?.dropdownscheme?.includes("t5") ? (
                     <div>{TextLocalString(x)}</div>
              ) : (
                <div className="h-24 input-disabled"></div>
              ),
          },
        ]
        : [];
    const getPayoutP6 = () =>
      schemeLockedStatus?.t6
        ? [
          {
            title: "P6",
            dataIndex: "payoutR6",
            width: "120px",
            render: (x, y, index) =>
              formik?.values?.[index]?.dropdownscheme?.includes("t6") ? (
                     <div>{TextLocalString(x)}</div>
              ) : (
                <div className="h-24 input-disabled"></div>
              ),
          },
        ]
        : [];
    return [
      {
        title: (
          <div onClick={() => handleRequestSort("code")}>
            Dealer <br /> Code
          </div>
        ),
        // (<div className="text-center" >"Dealer" < br />Code </div >),
        dataIndex: "code",
        fixed: "left",
        width: "160px",
        sorter: true,
        defaultSortOrder: "ascend",
        key: "DealerCode",
        // filters:
        //   listData?.length &&
        //   listData?.map((item) => ({
        //     text: item.code,
        //     value: item.code,
        //   })),
        // onFilter: (value, record) => record.code.indexOf(value) === 0,
      },
      {
        title: (
          <div>
            Dealer <br /> Location
          </div>
        ),
        dataIndex: "Location",
        width: "190px",
        fixed: "left",
        sorter: true,
        defaultSortOrder: "ascend",
        key: "DealerLocation",
        // filters: listData.map((item) => ({
        //   text: item.Location,
        //   value: item.Location,
        // })),
        // onFilter: (value, record) => record.Location.indexOf(value) === 0,
      },
      {
        title: (
          <div>
            Dealer <br /> Name
          </div>
        ),
        dataIndex: "name",
        // fixed: "left",
        width: "190px",
        sorter: true,
        defaultSortOrder: "ascend",
        key: "DealerName",
        // defaultSortOrder: "descend",
        // sorter: (a, b) => a.name && b.name && a.name.length - b.name.length,
        // filters: listData.map((item) => ({
        //   text: item.name,
        //   value: item.name,
        // })),
        // onFilter: (value, record) => record.name.indexOf(value) === 0,
      },
      {
        title: "State",
        dataIndex: "state",
        width: "130px",
        sorter: true,
        defaultSortOrder: "ascend",
        key: "StateName",
        // filters: listData.map((item) => ({
        //   text: item.state,
        //   value: item.state,
        // })),

        // onFilter: (value, record) => record.state.indexOf(value) === 0,
      },
      {
        title: (
          <div>
            Territory <br /> Manager
          </div>
        ),
        dataIndex: "Manager",
        width: "210px",
        sorter: true,
        defaultSortOrder: "ascend",
        key: "TerritoryManager",
        // filters: listData.map((item) => ({
        //   text: item.Manager,
        //   value: item.Manager,
        // })),

        // onFilter: (value, record) => record.Manager.indexOf(value) === 0,
      },
      ...getDropdown(),
      {
        title: (
          <div>
            Start <br /> Month
          </div>
        ),
        dataIndex: "StartMonth",
        width: "80px",
      },
      {
        title: (
          <div>
            End <br /> Month
          </div>
        ),
        dataIndex: "EndMonth",
        width: "80px",
      },
      {
        title: previousYearSameMonthString,
        dataIndex: "month",
        editable: false,
        color: "#BCB38D",

        children: [
          {
            title: "Industry",
            dataIndex: "industry",
            width: "90px",
            editable: true,
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.industry}
                placeholder="Type here"
                value={formik?.values?.[index]?.industry}
                // onBlur={()=>{
                //   calculateFormula(index)
                // }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    industry: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
          {
            title: "B",
            dataIndex: "oldBillingQty",
            width: "50px",
          },
          {
            title: "I",
            dataIndex: "oldInstallation",
            width: "50px",

          },
          {
            title: "C",
            dataIndex: "oldCollection",
            width: "50px",
          },
        ],
      },

      {
        title: `${secondPastMonthString} Opening Stock`,
        dataIndex: "stock",
        editable: false,
        children: [
          {
            title: "Nos",
            dataIndex: "oldOpeningStock",
            width: "80px",
          },
          {
            title: "Days",
            dataIndex: "openingStockDays",
            //  editable: false,
            width: "100px",
            // render: (x, y, index) => (
            //   <input
            //     name={formik?.values?.[index]?.openingStockDays}
            //     placeholder="Type here"
            //     value={formik?.values?.[index]?.openingStockDays}
            //     // onBlur={()=>{
            //     //   calculateFormula(index)
            //     // }}
            //     onChange={(e) => {
            //       let value = e.target?.value.replace(/[^\d]/g, "");
            //       formik.setFieldValue(index, {
            //         ...y,
            //         openingStockDays: value,
            //       });
            //     }}
            //     className="input1"
            //     style={{ width: "90%", padding: "5px" }}
            //   />
            // ),
          },
        ],
      },

      {
        title: "Closing Os. (In Lacs)",
        dataIndex: "stock",
        editable: false,
        textAlign: "center",

        children: [
          {
            title: (
              <span>
                Last <span>3<sup>rd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_3",
            width: "150px",
          },
          {
            title: (
              <span>
                Last <span>2<sup>nd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_2",
            width: "150px",
          },
          {
            title: (
              <span>
               Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_1",
            width: "150px",
          },
          // {
          //   title: (
          //     <span>
          //       Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month Days
          //     </span>
          //   ),
          //   dataIndex: "outStandingDays",
          //   width: "200px",
          // },
         
        ],
      },
      {
        title: "Closing OS Days",
        dataIndex: "outStandingDays",
        children: [
          {
            title: "OS Last Month",
            dataIndex: "outStandingDays",
            width: "200px",
          },
        ],
      },
      {
        title: "BG",
        dataIndex: "bg",
        children: [
          {
            title: "Lacs",
            dataIndex: "bgLacsCurrentYear",
            width: "120px",
            render: (x, y) => x || 0,
          },
        ],
      },

      {
        title: `${selectedFirstMonthString}-${selectedSecondMonthString}-No's`,
        dataIndex: "plan",
        editable: false,
        children: [
          {
            title: "B",
            dataIndex: "billing",
            editable: true,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.billing}
                placeholder="Type here"
                value={formik?.values?.[index]?.billing}
                // onBlur={()=>{
                //   calculateFormula(index)
                // }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    billing: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
          {
            title: "I",
            dataIndex: "installation",
            editable: false,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.installation}
                placeholder="Type here"
                value={formik?.values?.[index]?.installation}
                onBlur={() => {
                  calculateFormula(index)
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    installation: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
          {
            title: "C",
            dataIndex: "collection",
            editable: false,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.collection}
                placeholder="Type here"
                value={formik?.values?.[index]?.collection}
                onBlur={() => {
                  calculateFormula(index)
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    collection: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ],
      },
      {
        title: (
          <div>
            Closing <br /> stock
          </div>
        ),
        dataIndex: "closingStock",
        width: "100px",
        render: (x, y, index) =>
               <div>{TextLocalString(x)}</div>
      },
      {
        title: (
          <div>
            Closing <br /> OutStanding (In Lacs)
          </div>
        ),
        dataIndex: "closingOutStanding",
        width: "110px",
        render: (x, y, index) =>
               <div>{TextLocalString(x)}</div>
      },
      {
        title: "Installation plan",
        dataIndex: "instaPlan",
        editable: false,
        children: [
          {
            title: "Nos",
            dataIndex: "delayPlan",
            width: "200px",
            editable: false,
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.delayPlan}
                placeholder="Type here"
                value={formik?.values?.[index]?.delayPlan}
                // onBlur={()=>{
                //   calculateFormula(index)
                // }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  if (schemeType == 1) {
                    value = value.replace(/^0+/, "");
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    delayPlan: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ],
      },
      {
        title: "Books Collection Targets (No. of Trs.)",
        dataIndex: "BooksCollectionTarget",
        editable: false,
        children: [
          ...getTargetT1(),
          ...getTargetT2(),
          ...getTargetT3(),
          ...getTargetT4(),
          ...getTargetT5(),
          ...getTargetT6(),
        ],
      },
      {
        title: "Payout Rate",
        dataIndex: "pr",
        editable: false,

        children: [
          ...getPayoutRateR1(),
          ...getPayoutRateR2(),
          ...getPayoutRateR3(),
          ...getPayoutRateR4(),
          ...getPayoutRateR5(),
          ...getPayoutRateR6(),
        ],
      },
      {
        title: "Payout",
        dataIndex: "payout",
        editable: false,

        children: [
          ...getPayoutP1(),
          ...getPayoutP2(),
          ...getPayoutP3(),
          ...getPayoutP4(),
          ...getPayoutP5(),
          ...getPayoutP6(),
        ],
      },
      {
        title: "Remarks",
        dataIndex: "reason",
        editable: true,
        width: "180px",
        render: (x, y, index) => (
          <input
            name={formik?.values?.[index]?.reason}
            placeholder="Type here"
            value={formik?.values?.[index]?.reason}
            onChange={(e) => {
              let value = e.target?.value;
              const sanitizedValue = value
                ?.replace("<script>", "")
                ?.replace("</script>", "")
             //   .replace(/[^a-zA-Z0-9\s]/g, "");
              formik.setFieldValue(index, {
                ...y,
                reason: sanitizedValue,
              });
            }}
            className="input1"
            style={{ width: "90%", padding: "5px" }}
          />
        ),
      },
      {
        title: "Total Expected Payout",
        dataIndex: "TEP",
        editable: true,
        children: [
          {
            title: "Expected Volume",
            dataIndex: "TEPvol",
            editable: true,
            width: "150px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.TEPvol}
                placeholder="Type here"
                value={formik?.values?.[index]?.TEPvol}
                onBlur={() => {
                  calculateFormula(index);
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    TEPvol: value,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
          {
            title: "Per Tr.",
            dataIndex: "TEPpertr",
            width: "150px",
            render: (x) =>       <div>{TextLocalString(x)}</div> || 0,
          },
          {
            title: "Amount",
            dataIndex: "TEPamount",
            width: "150px",
            render: (x) =>       <div>{TextLocalString(x)}</div> || 0,
          },
        ],
      },
      {
        title: "Slab achieve",
        dataIndex: "slab",
        width: "120px",
        render: (x) => {
          return x != 0 ? x : "NO SLAB ACHIEVED";
        },
      },
    ];
  };

  const createColumns = useCreateSchemeColumns(
    formik,
    formik?.values,
    selectedFirstMonth,
    selectedSecondMonth,
    setSelectIndex
  );

  const editColumns = useEditSchemeColumns(
    listData,
    handleRequestSort,
    selectedFirstMonth,
    selectedSecondMonth
  );

  const defaultColumns = type === "edit" ? editColumns : createColumns;

  const selectedFirstMonthString = selectedFirstMonth.toLocaleString("en-IN", {
    year: "2-digit",
    month: "short",
  });
  const selectedSecondMonthString = selectedSecondMonth.toLocaleString(
    "en-IN",
    {
      year: "2-digit",
      month: "short",
    }
  );
  const handleDropDownSchemeCheck = () => {
    let { t1, t2, t3, t4, t5, t6 } = schemeLockedStatus;
    return t1 && !t2 && !t3 && !t4 && !t5 && !t6
      ? ["t1"]
      : t1 && t2 && !t3 && !t4 && !t5 && !t6
        ? ["t1", "t2"]
        : t1 && t2 && t3 && !t4 && !t5 && !t6
          ? ["t1", "t2", "t3"]
          : t1 && t2 && t3 && t4 && !t5 && !t6
            ? ["t1", "t2", "t3", "t4"]
            : t1 && t2 && t3 && t4 && t5 && !t6
              ? ["t1", "t2", "t3", "t4", "t5"]
              : t1 && t2 && t3 && t4 && t5 && t6
                ? ["t1", "t2", "t3", "t4", "t5", "t6"]
                : "";
  };
  const getFilterData = (listData, dealerData) => {
    let data = listData
      ?.filter((x) => x?.isExist == 0)
      .map((item) => {
        // if (dealerData.map(item => item.dealerCode).includes(item.code)) {
        if (
          dealerData?.[0]?.dealerCode == "allDealer" ||
          (dealerData?.length != 0 &&
            dealerData?.map((item) => item.dealerCode).includes(item.code))
        ) {
          return {
            ...item,
            dropdownscheme: handleDropDownSchemeCheck(),
            StartMonth: selectedFirstMonthString,
            EndMonth: selectedSecondMonthString,
            industry: "",
            billing: "",
            installation: "",
            collection: "",
            outStandingDays:
              dealerData.find((res) => item?.code == res?.dealerCode)?.fSum ||
              0,
            openingStockDays:
              dealerData.find((res) => item?.code == res?.dealerCode)
                ?.fSumOpeningStock || 0,
            BooksCollectionTargetT4: null,
            BooksCollectionTargetT5: null,
            BooksCollectionTargetT6: null,
            PayoutRatep4: null,
            PayoutRatep5: null,
            PayoutRatep6: null,
          };
        }
      })
      .filter((item) => item != undefined);

    return data;
  };

  const [dataList, setDataList] = useState([]);

  const billsValue = async (payload) => {
    setLoading(true);

    const result = await axiosAPI.post("controller/PriceProcessing", payload);
    // const data = useMemo(() => getFilterData(listData, result?.data?.data), [collectionData]);

    const data = getFilterData(listData, result?.data?.data);
    setDataList(data);
    setLoading(false);
  };

  //previousValue
  useEffect(() => {
    if (dataList) {
      if (previousValue?.length != 0) {
        let data1 = dataList?.map((y) => {
          let find = previousValue?.filter((x) => x?.key == y?.key);
          if (find) {
            return {
              BooksCollectionTargetT4: null,
              BooksCollectionTargetT5: null,
              BooksCollectionTargetT6: null,
              PayoutRatep4: null,
              PayoutRatep5: null,
              PayoutRatep6: null,
              ...y,
              ...find?.[0],
            };
          } else {
            return {
              BooksCollectionTargetT4: null,
              BooksCollectionTargetT5: null,
              BooksCollectionTargetT6: null,
              PayoutRatep4: null,
              PayoutRatep5: null,
              PayoutRatep6: null,
              ...y,
            };
          }
        });

        formik?.setValues(data1);
      } else {
        formik?.setValues(dataList);
      }
    }
  }, [dataList]);

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();

    if (
      showHide &&
      selectedRowKeys.length > 0 &&
      selectedRowKeys.includes({ ...props }["data-row-key"])
    )
      return;
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    name1,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave(
          {
            ...record,
            ...values,
          },
          values
        );
      } catch (errInfo) { }
    };
    const handleInputChange = (e, dataIndex) => {
      const isInteger = /^[1-9]+$/;
      const { name, value } = e.target;

      if (name == "Reason") {
        form.setFieldsValue({
          [dataIndex]: e.target.value,
        });

        if (type === "edit") {
          // listData.find((data) => selectedRowKeys.includes(data.key))[
          // editdataSource.find((data) => selectedRowKeys.includes(data.key))[
          listData.find((data) => selectedRowKeys.includes(data.key))[
            "reason"
          ] = value;
        }
        return;
      }

      if (
        name === "T1" ||
        name === "T2" ||
        name === "T3" ||
        name === "R1" ||
        name === "R2" ||
        name === "R3" ||
        name === "B" ||
        name === "I" ||
        name === "C" ||
        name === "Industry" ||
        name === "Nos" ||
        name === "Vol" ||
        name === "Reason"
      ) {
        if (
          (e.target.value !== "" && e.target.value == 0) ||
          e.target.value < 0 ||
          !Number.isInteger(+e.target.value)
        ) {
          handleModal();
          setDirty(true);
          form.resetFields();
          return;
        }
      }

      if (
        e.target.value !== "" &&
        e.target.value !== 0 &&
        (isInteger.test(+e.target.value) || Number.isInteger(+e.target.value))
      ) {
        form.setFieldsValue({
          [dataIndex]: e.target.value,
        });
        if (type === "edit") {
          // listData.find((data) => selectedRowKeys.includes(data.key))[
          // editdataSource.find((data) => selectedRowKeys.includes(data.key))[
          //causes an error on draft below line~ankit
          // listData.find((data) => selectedRowKeys.includes(data.key))[
          //   dataIndex
          // ] = parseInt(e.target.value);
        }
        return;
      }
    };
    let childNode = children;

    // let toptarget=record?.scheme?.topTarget?.split(",")
    if (
      (editable &&
        record.status !== 1 &&
        record.status !== 2 &&
        editType === "create") ||
      (type === "edit" &&
        editable &&
        selectedRowKeys.length > 0 &&
        selectedRowKeys.includes(record.key) &&
        (dataIndex != "payoutR1" ||
          dataIndex != "payoutR2" ||
          dataIndex != "payoutR3" ||
          dataIndex != "payoutR4" ||
          dataIndex != "payoutR5" ||
          dataIndex != "payoutR6" ||
          dataIndex != "PayoutRatep1" ||
          dataIndex != "PayoutRatep2" ||
          dataIndex != "PayoutRatep3" ||
          dataIndex != "PayoutRatep4" ||
          dataIndex != "PayoutRatep5" ||
          dataIndex != "PayoutRatep6"))
    ) {
      // if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            dataIndex !== "reason" && {
              required: true,
              message: "Required",
            },
          ]}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            type={dataIndex !== "reason" && "number"}
            name={title}
            onChange={(value) => {
              handleInputChange(value, dataIndex);
            }}
          />
        </Form.Item>
      ) : (
        <td
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
            height: 20,
            display: "block",
            // background: 'red',
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
          }}
          onClick={toggleEdit}
        >
          {children}
        </td>
      );
    }

    return (!record?.scheme?.topTarget?.split(",")?.includes("t1") &&
      dataIndex == "BooksCollectionTargetT1") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t2") &&
        dataIndex == "BooksCollectionTargetT2") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t3") &&
        dataIndex == "BooksCollectionTargetT3") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t4") &&
        dataIndex == "BooksCollectionTargetT4") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t5") &&
        dataIndex == "BooksCollectionTargetT5") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t6") &&
        dataIndex == "BooksCollectionTargetT6") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t1") &&
        dataIndex == "PayoutRatep1") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t2") &&
        dataIndex == "PayoutRatep2") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t3") &&
        dataIndex == "PayoutRatep3") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t4") &&
        dataIndex == "PayoutRatep4") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t5") &&
        dataIndex == "PayoutRatep5") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t6") &&
        dataIndex == "PayoutRatep6") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t1") &&
        dataIndex == "payoutR1") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t2") &&
        dataIndex == "payoutR2") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t3") &&
        dataIndex == "payoutR3") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t4") &&
        dataIndex == "payoutR4") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t5") &&
        dataIndex == "payoutR5") ||
      (!record?.scheme?.topTarget?.split(",")?.includes("t6") &&
        dataIndex == "payoutR6") ? (
      <td
        className="input-disabled1"
        style={{
          height: "20px",
          cursor: "not-allowed",
          backgroundColor: "lightgrey",
        }}
      ></td>
    ) : (
      <td {...restProps}>{childNode}</td>
    );
  };

  const handleSave = (row, form) => {
    const handleDropDownSchemeCheckIndex = () => {
      let { t1, t2, t3, t4, t5, t6 } = row?.scheme?.topTarget
        ?.split(",")
        .reduce((a, v) => ({ ...a, [v]: v }), {});
      return t1 && !t2 && !t3 && !t4 && !t5 && !t6
        ? "t1"
        : t1 && t2 && !t3 && !t4 && !t5 && !t6
          ? "t2"
          : t1 && t2 && t3 && !t4 && !t5 && !t6
            ? "t3"
            : t1 && t2 && t3 && t4 && !t5 && !t6
              ? "t4"
              : t1 && t2 && t3 && t4 && t5 && !t6
                ? "t5"
                : t1 && t2 && t3 && t4 && t5 && t6
                  ? "t6"
                  : "";
    };

    let validationSoftTarget = handleDropDownSchemeCheckIndex();

    let {
      PayoutRatep1,
      PayoutRatep2,
      PayoutRatep3,
      PayoutRatep4,
      PayoutRatep5,
      PayoutRatep6,
      BooksCollectionTargetT1,
      BooksCollectionTargetT2,
      BooksCollectionTargetT3,
      BooksCollectionTargetT4,
      BooksCollectionTargetT5,
      BooksCollectionTargetT6,
      TEPamount,
      TEPvol,
    } = row;

    let calculateTepAMount;

    if (validationSoftTarget == "t1") {
      calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep1);
    } else if (validationSoftTarget == "t2") {
      TEPvol >= BooksCollectionTargetT1 && TEPvol < BooksCollectionTargetT2
        ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep1))
        : (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep2));
    } else if (validationSoftTarget == "t3") {
      TEPvol >= BooksCollectionTargetT1 && TEPvol < BooksCollectionTargetT2
        ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep1))
        : TEPvol >= BooksCollectionTargetT2 && TEPvol < BooksCollectionTargetT3
          ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep2))
          : (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep3));
    } else if (validationSoftTarget == "t4") {
      TEPvol >= BooksCollectionTargetT1 && TEPvol < BooksCollectionTargetT2
        ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep1))
        : TEPvol >= BooksCollectionTargetT2 && TEPvol < BooksCollectionTargetT3
          ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep2))
          : TEPvol >= BooksCollectionTargetT3 && TEPvol < BooksCollectionTargetT4
            ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep3))
            : (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep4));
    } else if (validationSoftTarget == "t5") {
      TEPvol >= BooksCollectionTargetT1 && TEPvol < BooksCollectionTargetT2
        ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep1))
        : TEPvol >= BooksCollectionTargetT2 && TEPvol < BooksCollectionTargetT3
          ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep2))
          : TEPvol >= BooksCollectionTargetT3 && TEPvol < BooksCollectionTargetT4
            ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep3))
            : TEPvol >= BooksCollectionTargetT4 && TEPvol < BooksCollectionTargetT5
              ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep4))
              : (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep5));
    } else if (validationSoftTarget == "t6") {
      TEPvol >= BooksCollectionTargetT1 && TEPvol < BooksCollectionTargetT2
        ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep1))
        : TEPvol >= BooksCollectionTargetT2 && TEPvol < BooksCollectionTargetT3
          ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep2))
          : TEPvol >= BooksCollectionTargetT3 && TEPvol < BooksCollectionTargetT4
            ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep3))
            : TEPvol >= BooksCollectionTargetT4 && TEPvol < BooksCollectionTargetT5
              ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep4))
              : TEPvol >= BooksCollectionTargetT5 && TEPvol < BooksCollectionTargetT6
                ? (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep5))
                : (calculateTepAMount = parseInt(TEPvol) * parseInt(PayoutRatep6));
    } else {
      calculateTepAMount = 0;
    }
let slabAchieve=""
    if (TEPvol < BooksCollectionTargetT1){
      slabAchieve = "";
    }else if (TEPvol >= BooksCollectionTargetT1 &&( (TEPvol < BooksCollectionTargetT2) || validationSoftTarget == "t1")){
      slabAchieve = "T1";
    }else if (TEPvol >= BooksCollectionTargetT2 && ((TEPvol < BooksCollectionTargetT3) || validationSoftTarget == "t2")){
      slabAchieve = "T2";
    }else if (TEPvol >= BooksCollectionTargetT3 && ((TEPvol < BooksCollectionTargetT4) || validationSoftTarget == "t3")){
      slabAchieve = "T3";
    }else if (TEPvol >= BooksCollectionTargetT4 && ((TEPvol < BooksCollectionTargetT5) || validationSoftTarget == "t4")){
      slabAchieve = "T4";
    }else if (TEPvol >= BooksCollectionTargetT5 && ((TEPvol < BooksCollectionTargetT6 )|| validationSoftTarget == "t5")){
      slabAchieve = "T5";
    }else if (TEPvol >= BooksCollectionTargetT6){
      slabAchieve = "T6";
    }
    TEPamount == "NaN" && (calculateTepAMount = TEPvol);
    let TEPpertr1Calculate = TEPvol
      ? Math.round(parseInt(calculateTepAMount) / parseInt(TEPvol))
      : parseInt(calculateTepAMount) / parseInt(TEPvol) == "NaN"
        ? TEPvol
        : 0;

    if (
      row.BooksCollectionTargetT2 != null &&
      row.BooksCollectionTargetT1 != null &&
      +row.BooksCollectionTargetT2 <= +row.BooksCollectionTargetT1
    ) {
      handleErrorModal("T2", "T1");
      return;
    }
    if (
      row.BooksCollectionTargetT2 != null &&
      row.BooksCollectionTargetT3 != null &&
      +row.BooksCollectionTargetT3 <= +row.BooksCollectionTargetT2
      // && +row.BooksCollectionTargetT3 <= +row.BooksCollectionTargetT1
    ) {
      handleErrorModal("T3", "T2");
      return;
    }

    if (
      row.PayoutRatep2 != null &&
      row.PayoutRatep1 != null &&
      +row.PayoutRatep2 <= +row.PayoutRatep1
    ) {
      handleErrorModal("R2", "R1");

      return;
    }
    if (
      row.PayoutRatep3 != null &&
      row.PayoutRatep2 != null &&
      +row.PayoutRatep3 <= +row.PayoutRatep2
    ) {
      handleErrorModal("R3", "R2");
      return;
    }
    // if (
    //   row.TEPvol != null &&
    //   row.BooksCollectionTargetT1 != null
    //   // row.TEPvol == 0
    //   // +row.TEPvol < +row.BooksCollectionTargetT1
    // ) {
    //   handleErrorModal("Volume", "T1");
    //   return;
    // }
    const newData = [...listData];
    const index = newData.findIndex((item) => row.key === item.key);

    const item = { ...newData[index], ...form };
    if (item.BooksCollectionTargetT3) {
      item.BooksCollectionTargetT3T2 = (
        parseInt(item.BooksCollectionTargetT3) -
        parseInt(item.BooksCollectionTargetT2)
      ).toString();
    } else if (item.BooksCollectionTargetT2) {
      item.BooksCollectionTargetT2T1 = (
        parseInt(item.BooksCollectionTargetT2) -
        parseInt(item.BooksCollectionTargetT1)
      ).toString();
    } else {
      item.BooksCollectionTargetT2T1 = item.BooksCollectionTargetT1;
    }

    if (item.BooksCollectionTargetT6) {
      item.BooksCollectionTargetT6T5 = (
        parseInt(item.BooksCollectionTargetT6) -
        parseInt(item.BooksCollectionTargetT5)
      ).toString();
    } else if (item.BooksCollectionTargetT5) {
      item.BooksCollectionTargetT5T4 = (
        parseInt(item.BooksCollectionTargetT5) -
        parseInt(item.BooksCollectionTargetT4)
      ).toString();
    } else {
      item.BooksCollectionTargetT4T3 = item.BooksCollectionTargetT3;
    }

    if (item.BooksCollectionTargetT2T1 == "NaN") {
      item.BooksCollectionTargetT2T1 =
        item.BooksCollectionTargetT1 || item.BooksCollectionTargetT2;
    }
    if (item.BooksCollectionTargetT3T2 == "NaN") {
      item.BooksCollectionTargetT3T2 =
        item.BooksCollectionTargetT3 || item.BooksCollectionTargetT2;
    }
    if (item.BooksCollectionTargetT4T3 == "NaN") {
      item.BooksCollectionTargetT4T3 =
        item.BooksCollectionTargetT3 || item.BooksCollectionTargetT4;
    }
    if (item.BooksCollectionTargetT5T4 == "NaN") {
      item.BooksCollectionTargetT5T4 =
        item.BooksCollectionTargetT4 || item.BooksCollectionTargetT5;
    }
    if (item.BooksCollectionTargetT6T5 == "NaN") {
      item.BooksCollectionTargetT6T5 =
        item.BooksCollectionTargetT5 || item.BooksCollectionTargetT6;
    }
    if (item.BooksCollectionTargetT1) {
      item.payoutR1 = (
        parseInt(item.BooksCollectionTargetT1) * parseInt(item.PayoutRatep1)
      ).toString();
    }
    if (item.BooksCollectionTargetT2) {
      item.payoutR2 = (
        parseInt(item.BooksCollectionTargetT2) * parseInt(item.PayoutRatep2)
      ).toString();
    }
    if (item.BooksCollectionTargetT3) {
      item.payoutR3 = (
        parseInt(item.BooksCollectionTargetT3) * parseInt(item.PayoutRatep3)
      ).toString();
    }
    if (item.BooksCollectionTargetT4) {
      item.payoutR3 = (
        parseInt(item.BooksCollectionTargetT4) * parseInt(item.PayoutRatep4)
      ).toString();
    }
    if (item.BooksCollectionTargetT5) {
      item.payoutR3 = (
        parseInt(item.BooksCollectionTargetT5) * parseInt(item.PayoutRatep5)
      ).toString();
    }
    if (item.BooksCollectionTargetT6) {
      item.payoutR3 = (
        parseInt(item.BooksCollectionTargetT6) * parseInt(item.PayoutRatep6)
      ).toString();
    }

    // amount calculation
    if (
      TEPvol >= BooksCollectionTargetT1 &&
      TEPvol < item.BooksCollectionTargetT2
    ) {
      item.TEPamount = (
        parseInt(TEPvol) * parseInt(item.PayoutRatep1)
      ).toString();
    } else if (TEPvol < BooksCollectionTargetT1 || TEPvol == 0) {
      item.TEPamount = (0).toString();
    } else if (
      TEPvol >= item.BooksCollectionTargetT2 &&
      TEPvol < item.BooksCollectionTargetT3
    ) {
      item.TEPamount = (
        parseInt(TEPvol) * parseInt(item.PayoutRatep2)
      ).toString();
    } else if (
      TEPvol >= item.BooksCollectionTargetT3 &&
      TEPvol < item.BooksCollectionTargetT4
    ) {
      item.TEPamount = (
        parseInt(TEPvol) * parseInt(item.PayoutRatep3)
      ).toString();
    } else if (
      TEPvol >= item.BooksCollectionTargetT4 &&
      TEPvol < item.BooksCollectionTargetT5
    ) {
      item.TEPamount = (
        parseInt(TEPvol) * parseInt(item.PayoutRatep4)
      ).toString();
    } else if (
      TEPvol >= item.BooksCollectionTargetT5 &&
      TEPvol < item.BooksCollectionTargetT6
    ) {
      item.TEPamount = (
        parseInt(TEPvol) * parseInt(item.PayoutRatep5)
      ).toString();
    } else {
      item.TEPamount = (
        parseInt(TEPvol) * parseInt(item.PayoutRatep6)
      ).toString();
    }

    //  paypot calculation
    if (TEPvol) {
      item.TEPpertr = Math.round(
        parseInt(item.TEPamount) / parseInt(TEPvol)
      ).toString();
    }
    if (item.payoutR1 == "NaN") {
      item.payoutR1 = BooksCollectionTargetT1 || item.PayoutRatep1;
    }
    if (item.payoutR2 == "NaN") {
      item.payoutR2 = item.BooksCollectionTargetT2 || item.PayoutRatep2;
    }
    if (item.payoutR3 == "NaN") {
      item.payoutR3 = item.BooksCollectionTargetT3 || item.PayoutRatep3;
    }
    if (item.payoutR4 == "NaN") {
      item.payoutR4 = item.BooksCollectionTargetT4 || item.PayoutRatep4;
    }
    if (item.payoutR5 == "NaN") {
      item.payoutR5 = item.BooksCollectionTargetT5 || item.PayoutRatep5;
    }
    if (item.payoutR6 == "NaN") {
      item.payoutR6 = item.BooksCollectionTargetT6 || item.PayoutRatep6;
    }
    if (item.TEPamount == "NaN") {
      item.TEPamount = item.TEPvol;
    }
    if (item.TEPpertr == "NaN") {
      item.TEPpertr = item.TEPvol;
    }

    // const item = { ...newData[index], ...form,amount:calculateTepAMount };
    newData.splice(index, 1, {
      ...row,
      ...item,
      TEPamount: calculateTepAMount,
      TEPpertr: TEPpertr1Calculate,
    });

    dispatch(schemeList(newData));
    // setDataSource(newData);
  };
  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }

    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        name1: col.dataIndex,
        handleSave,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };

  const columns = defaultColumns.map((col) => {
    return mapColumns(col);
  });

  const formatData = (result) => {
    const data = [];
    result &&
      result.map((item, index) => {
        for (let i = 0; i < 1; i++) {
          let formattedStartDate = dayjs(startDate).format("MMM'YY");
          let formattedEndDate = dayjs(endDate).format("MMM'YY");

          data.push({
            status: item.status,
            key: item.admDealerId,
            code: item.dealerCode,
            name: item.dealerName,
            Location: item.cityName,
            state: item.stateName,
            Manager: item.territoryName,
            StartMonth: formattedStartDate,
            EndMonth: formattedEndDate,
            stateId: item.stateId,
            oldBillingQty: item.oldBillingQty,
            oldInstallation: item.oldInstallation,
            oldCollection: Math.round(item.oldCollection),
            oldOpeningStock: item.oldOpeningStock || 0,
            openingStockDays: item.openingStockDays,
            outStandingDays: item.outStandingDays,
            closingOutStandingMonth_1:
              item.closingOutStandingMonth_1?.toFixed(2),
            closingOutStandingMonth_2:
              item.closingOutStandingMonth_2?.toFixed(2),
            closingOutStandingMonth_3:
              item.closingOutStandingMonth_3?.toFixed(2),
            bgLacsCurrentYear:
              item?.bGLacsCurrentYear || item.bgLacsCurrentYear || 0,
            BooksCollectionTargetT1: null,
            BooksCollectionTargetT2: null,
            BooksCollectionTargetT2T1: null,
            BooksCollectionTargetT3: null,
            BooksCollectionTargetT3T2: null,
            PayoutRatep1: null,
            PayoutRatep2: null,
            PayoutRatep3: null,
            payoutR1: null,
            payoutR2: null,
            payoutR3: null,
            TEPvol: null,
            TEPpertr: null,
            TEPamount: null,
            LMSA: null,
            LCQ: null,
            LMSA1: null,
            LCQ1: null,
            isExist: item.isExist,
            delayPlan: item.delayPlan,
            billingFactorCOS: item.billingFactorCOS
          });
        }
      });

    return data;
  };

  const getDealerDropdownData = (userId, type) => {
    setLoading(true);
    if (userId) {
      dispatch(getLockedSchemeTypeAsync(userId));
    }
    const monthStart = collectionData?.startDate
      ? dayjs(collectionData?.startDate).month() + 1
      : dayjs().month() + 1;
    const monthEnd = collectionData?.endDate
      ? dayjs(collectionData?.endDate).month() + 1
      : dayjs().month() + 1;

    if (type === "amID") {
      setCollectionData((collectionData) => ({
        ...collectionData,
        amID: userId,
      }));
    }
    const today = new Date();
    const postData = {
      fyear: currentFiscalYear,
      month: monthStart,
      monthEnd: monthEnd,
      userId,
      year: new Date(collectionData?.startDate).getFullYear(),
    };
    let data = async () => {
      await axiosAPI
        .post(`SchemeGenerate/GetSchemeList`, postData)
        .then((result) => {
          const data = formatData(result.data.data);
          // setDealer(result.data.data)

          dispatch(schemeList(data));
        });
      await setLoading(false);
    };
    data();
    formik?.resetForm();
  };

  useEffect(() => {
    const { userId, userRole } = decryptData(sessionStorage.getItem("userInfo"));

    // const staticAmId = 2;
    let staticAmId = userId;
    // if (userRole &&  type === "edit") {
    if (
      userRole == "AMGR" &&
      !(["create", "draft"].includes(editType) && type === "edit")
    ) {
      getDealerDropdownData(userId);
    } else {
      setLoading(true);
      const monthStart = collectionData?.startDate
        ? dayjs(collectionData?.startDate).month() + 1
        : dayjs().month() + 1;
      const monthEnd = collectionData?.endDate
        ? dayjs(collectionData?.endDate).month() + 1
        : dayjs().month() + 1;
      if (collectionData?.amID) {
        staticAmId = collectionData?.amID;
      }

      // const postData = {
      //   fyear: currentFiscalYear,
      //   month: monthStart,
      //   monthEnd: monthEnd,
      //   userId: staticAmId,
      //   year: dayjs().year(),
      //   schemeType,
      // };
      const postData = {
        userId: staticAmId,
      };

      let data = async () => {
        await axiosAPI
          .post(`SchemeGenerate/GetSchemeList`, postData)
          .then((result) => {
            // dispatch(schemeList(result.data.data));
          });
        setLoading(false);
      };
      data();
    }
  }, [changeSchemeDate, startDate, endDate]);

  const handleChangeScheme = (data) => {
    setOpenSchemeModal((openSchemeModal) => !openSchemeModal);

    if (data?.startDate) {
      setchangeSchemeDate(data);

      const currentRecord = listData.find((data) =>
        selectedRowKeys.includes(data.key)
      );

      const payload = {
        startMonth: data.startDate,
        endMonth: data.endDate,
        schemeId: currentRecord.key,
      };

      axiosAPI
        .post(`UpdateScheme/UpdateChangeScheme`, payload)
        .then((result) => {
          const editListData = [...listData];
          let updatedList = [];
          editListData.forEach((row) => {
            if (selectedRowKeys.includes(row.key)) {
              updatedList = [
                ...updatedList,
                {
                  ...row,
                  StartMonth: dayjs(data.startDate).format("MMM'YY"),
                  EndMonth: dayjs(data.endDate).format("MMM'YY"),
                },
              ];
            }
          });
          dispatch(schemeList(updatedList));
          message.success(
            result?.data?.errorMessage || "Scheme updated successfully!!"
          );
          // handleSuccessModal();
        });
      // .then(() => window.location.reload())
    }
  };

  const isAnyEmpty = (collectionData) =>
    Object.values(collectionData).every((value) => {
      if (value == "" || value == []) {
        return false;
      }
      return true;
    });

  // Table header
  const tableHeader = () => {
    const handleHide = () => {
      if (selectedRowKeys.length == 0) {
        handleSelectModal();
        return;
      }
      setShowUnhide(!showHide);
    };

    return (
      <Row className="table_sub_buttons">
        {/* <Col>
          <Button
            icon={showHide === true ? <UnHideIcon /> : <HideIcon />}
            type="text"
            style={{
              margin: "10px",
              color: showHide === true ? "#FF3232" : "",
            }}
            onClick={handleHide}
          >
            {showHide === true ? "Show" : "Hide"}
          </Button>
        </Col> */}
        {/* <Col>
          <Button type="text" style={{ margin: "10px" }}>
            <MergeIcon />
            Merge
          </Button>
        </Col>
        <Col>
          <Button type="text" style={{ margin: "10px" }}>
            <UnmergeIcon />
            Unmerge
          </Button>
        </Col> */}
        {["draft", "create"].includes(editType) && type === "edit" && (
          <Col>
            <Button
              onClick={handleChangeScheme}
              type="text"
              style={{ margin: "10px" }}
            >
              <ChangeScheme />
              Change Scheme
            </Button>
          </Col>
        )}
        {/* {editType ==="create" && collectionData.dealerCode.length >1 &&(
            <Col>
            <Button
              disabled ={ disable }
              icon={<MergeIcon />}
              type="text"
              onClick={(e) => handleMerge()}
            >
              <span>Merge</span>
            </Button>
          </Col>
        )} */}
      </Row>
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const handlePayloadValidation = (payload) => {
    if (!payload.reason || !payload.reason) {
      return;
    }
  };

  const draftData = () => {
    if (schemeType == 1 || schemeType == 2) {
      handleCreateSchema("", "draft");
    } else {
      const userData = decryptData(sessionStorage.getItem("userInfo"));
      const userId = userData.userId;
      const userName = userData.userName;
      const userRole = userData.userRole;
      const finalData = [];
      let isValid = true;
      if (selectedRowKeys.length === 0) {
        handleSelectModal();
        return;
      }
      let payload;
      selectedRowKeys.forEach((item) => {
        for (let dataItem of listData) {
          if (dataItem.key === item) {
            payload = {
              dealerCode: dataItem.code,
              state: dataItem.stateId,
              status: 0,
              dealerId: +dataItem.key,
              StartMonth: startDate
                ? dayjs(startDate)
                  .startOf("month")
                  .format("YYYY-MM-DD HH:mm:ss")
                : startDate,
              EndMonth: endDate
                ? dayjs(endDate).endOf("month").format("YYYY-MM-DD HH:mm:ss")
                : endDate,
              reason: dataItem.reason,
              billing: dataItem.billing ? +dataItem.billing : 0,
              installation: dataItem.installation ? +dataItem.installation : 0,
              collection: Math.round(
                dataItem.collection ? +dataItem.collection : 0
              ),
              isSAP: true,
              isActive: true,
              schemePlanStartMonth: startDate,
              schemePlanEndMonth: endDate,
              schemeTypeId: schemeType,
              state: dataItem?.stateId,
              t1: +dataItem.BooksCollectionTargetT1,
              t2: +dataItem.BooksCollectionTargetT2,
              t3: +dataItem.BooksCollectionTargetT3,
              t4: dataItem.BooksCollectionTargetT4
                ? +dataItem.BooksCollectionTargetT4
                : 0,
              t5: dataItem.BooksCollectionTargetT5
                ? +dataItem.BooksCollectionTargetT5
                : 0,
              t6: dataItem.BooksCollectionTargetT6
                ? +dataItem.BooksCollectionTargetT6
                : 0,
              t2_T1: dataItem.BooksCollectionTargetT2T1,
              t3_T2: dataItem.BooksCollectionTargetT3T2,
              t4_T3: dataItem.BooksCollectionTargetT4T3,
              t5_T4: dataItem.BooksCollectionTargetT5T4,
              t6_T5: dataItem.BooksCollectionTargetT6T5,
              p1: dataItem.payoutR1,
              p2: dataItem.payoutR2,
              p3: dataItem.payoutR3,
              p4: dataItem.payoutR4 ? +dataItem.payoutR4 : 0,
              p5: dataItem.payoutR5 ? +dataItem.payoutR5 : 0,
              p6: dataItem.payoutR6 ? +dataItem.payoutR6 : 0,
              r1: +dataItem.PayoutRatep1,
              r2: +dataItem.PayoutRatep2,
              r3: +dataItem.PayoutRatep3,
              r4: +dataItem.PayoutRatep4 ? +dataItem.PayoutRatep4 : 0,
              r5: +dataItem.PayoutRatep5 ? +dataItem.PayoutRatep5 : 0,
              r6: +dataItem.PayoutRatep6 ? +dataItem.PayoutRatep6 : 0,

              // ...(handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1"
              // ? {
              //     t1: +x.BooksCollectionTargetT1 || 0,
              //     t2: 0,
              //     t3: 0,
              //     t4: 0,
              //     t5: 0,
              //     t6: 0,
              //     t2_T1: +x.BooksCollectionTargetT2T1 || 0,
              //     t3_T2: 0,
              //     t4_T3: 0,
              //     t5_T4: 0,
              //     t6_T5: 0,
              //     p1: +x.payoutR1 || 0,
              //     p2: 0,
              //     p3: 0,
              //     p4: 0,
              //     p5: 0,
              //     p6: 0,
              //     r1: +x?.PayoutRatep1 || 0,
              //     r2: 0,
              //     r3: 0,
              //     r4: 0,
              //     r5: 0,
              //     r6: 0,
              //   }
              // : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2"
              // ? {
              //     t1: +x.BooksCollectionTargetT1 || 0,
              //     t2: +x.BooksCollectionTargetT2 || 0,
              //     t3: 0,
              //     t4: 0,
              //     t5: 0,
              //     t6: 0,
              //     t2_T1: +x.BooksCollectionTargetT2T1 || 0,
              //     t3_T2: +x.BooksCollectionTargetT3T2 || 0,
              //     t4_T3: 0,
              //     t5_T4: 0,
              //     t6_T5: 0,
              //     p1: +x.payoutR1 || 0,
              //     p2: +x.payoutR2 || 0,
              //     p3: 0,
              //     p4: 0,
              //     p5: 0,
              //     p6: 0,
              //     r1: +x?.PayoutRatep1 || 0,
              //     r2: +x?.PayoutRatep2 || 0,
              //     r3: 0,
              //     r4: 0,
              //     r5: 0,
              //     r6: 0,
              //   }
              // : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3"
              // ? {
              //     t1: +x.BooksCollectionTargetT1 || 0,
              //     t2: +x.BooksCollectionTargetT2 || 0,
              //     t3: +x.BooksCollectionTargetT3 || 0,
              //     t4: 0,
              //     t5: 0,
              //     t6: 0,
              //     t2_T1: +x.BooksCollectionTargetT2T1 || 0,
              //     t3_T2: +x.BooksCollectionTargetT3T2 || 0,
              //     t4_T3: +x.BooksCollectionTargetT4T3 || 0,
              //     t5_T4: 0,
              //     t6_T5: 0,
              //     p1: +x.payoutR1 || 0,
              //     p2: +x.payoutR2 || 0,
              //     p3: +x.payoutR3 || 0,
              //     p4: 0,
              //     p5: 0,
              //     p6: 0,
              //     r1: +x?.PayoutRatep1 || 0,
              //     r2: +x?.PayoutRatep2 || 0,
              //     r3: +x?.PayoutRatep3 || 0,
              //     r4: 0,
              //     r5: 0,
              //     r6: 0,
              //   }
              // : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t4"
              // ? {
              //     t1: +x.BooksCollectionTargetT1 || 0,
              //     t2: +x.BooksCollectionTargetT2 || 0,
              //     t3: +x.BooksCollectionTargetT3 || 0,
              //     t4: +x.BooksCollectionTargetT4 || 0,
              //     t5: 0,
              //     t6: 0,
              //     t2_T1: +x.BooksCollectionTargetT2T1 || 0,
              //     t3_T2: +x.BooksCollectionTargetT3T2 || 0,
              //     t4_T3: +x.BooksCollectionTargetT4T3 || 0,
              //     t5_T4: +x.BooksCollectionTargetT5T4 || 0,
              //     t6_T5: 0,
              //     p1: +x.payoutR1 || 0,
              //     p2: +x.payoutR2 || 0,
              //     p3: +x.payoutR3 || 0,
              //     p4: +x.payoutR4 || 0,
              //     p5: 0,
              //     p6: 0,
              //     r1: +x?.PayoutRatep1 || 0,
              //     r2: +x?.PayoutRatep2 || 0,
              //     r3: +x?.PayoutRatep3 || 0,
              //     r4: +x?.PayoutRatep4 || 0,
              //     r5: 0,
              //     r6: 0,
              //   }
              // : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t5"
              // ? {
              //     t1: +x.BooksCollectionTargetT1 || 0,
              //     t2: +x.BooksCollectionTargetT2 || 0,
              //     t3: +x.BooksCollectionTargetT3 || 0,
              //     t4: +x.BooksCollectionTargetT4 || 0,
              //     t5: +x.BooksCollectionTargetT5 ? +x.BooksCollectionTargetT5 : 0,
              //     t6: 0,
              //     t2_T1: +x.BooksCollectionTargetT2T1 || 0,
              //     t3_T2: +x.BooksCollectionTargetT3T2 || 0,
              //     t4_T3: +x.BooksCollectionTargetT4T3 || 0,
              //     t5_T4: +x.BooksCollectionTargetT5T4 || 0,
              //     t6_T5: 0,
              //     p1: +x.payoutR1 || 0,
              //     p2: +x.payoutR2 || 0,
              //     p3: +x.payoutR3 || 0,
              //     p4: +x.payoutR4 || 0,
              //     p5: +x.payoutR5 || 0,
              //     p6: 0,
              //     r1: +x?.PayoutRatep1 || 0,
              //     r2: +x?.PayoutRatep2 || 0,
              //     r3: +x?.PayoutRatep3 || 0,
              //     r4: +x?.PayoutRatep4 || 0,
              //     r5: +x?.PayoutRatep5 || 0,
              //     r6: 0,
              //   }
              // : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t6"
              // ? {
              //     t1: +x.BooksCollectionTargetT1 || 0,
              //     t2: +x.BooksCollectionTargetT2 || 0,
              //     t3: +x.BooksCollectionTargetT3 || 0,
              //     t4: +x.BooksCollectionTargetT4 || 0,
              //     t5: +x.BooksCollectionTargetT5 || 0,
              //     t6: +x?.BooksCollectionTargetT6 || 0,
              //     t2_T1: +x.BooksCollectionTargetT2T1 || 0,
              //     t3_T2: +x.BooksCollectionTargetT3T2 || 0,
              //     t4_T3: +x.BooksCollectionTargetT4T3 || 0,
              //     t5_T4: +x.BooksCollectionTargetT5T4 || 0,
              //     t6_T5: +x.BooksCollectionTargetT6T5 || 0,
              //     p1: +x.payoutR1 || 0,
              //     p2: +x.payoutR2 || 0,
              //     p3: +x.payoutR3 || 0,
              //     p4: +x.payoutR4 || 0,
              //     p5: +x.payoutR5 || 0,
              //     p6: +x.payoutR6 || 0,
              //     r1: +x?.PayoutRatep1 || 0,
              //     r2: +x?.PayoutRatep2 || 0,
              //     r3: +x?.PayoutRatep3 || 0,
              //     r4: +x?.PayoutRatep4 || 0,
              //     r5: +x?.PayoutRatep5 || 0,
              //     r6: +x?.PayoutRatep6 || 0,
              //   }
              // : {
              //     t1: 0,
              //     t2: 0,
              //     t3: 0,
              //     t4: 0,
              //     t5: 0,
              //     t6: 0,
              //     t2_T1: 0,
              //     t3_T2: 0,
              //     t4_T3: 0,
              //     t5_T4: 0,
              //     t6_T5: 0,
              //     p1: 0,
              //     p2: 0,
              //     p3: 0,
              //     p4: 0,
              //     p5: 0,
              //     p6: 0,
              //     r1: 0,
              //     r2: 0,
              //     r3: 0,
              //     r4: 0,
              //     r5: 0,
              //     r6: 0,
              //   }),
              volume: +dataItem.TEPvol,
              perTr: dataItem.TEPpertr,
              amount: dataItem.TEPamount,
              // isSubmitted: 0,
              fyear: currentFiscalYear,
              //schemeType: schemeType,
              userid: +userId,
              username: userName,
              userrole: userRole,
              industry: dataItem.industry ? parseInt(dataItem.industry) : 0,
              oldBillingQty: dataItem.oldBillingQty,
              oldInstallation: dataItem.oldInstallation,
              oldCollection: Math.round(dataItem.oldCollection),
              oldOpeningStock: dataItem.oldOpeningStock,
              closingOutStandingMonth_1: dataItem.closingOutStandingMonth_1,
              closingOutStandingMonth_2: dataItem.closingOutStandingMonth_2,
              closingOutStandingMonth_3: dataItem.closingOutStandingMonth_3,
              bgLacsCurrentYear: dataItem.bgLacsCurrentYear,
              openingStockDays: dataItem.openingStockDays
                ? (dataItem.openingStockDays)
                : 0,
              outStandingDays: dataItem.outStandingDays
                ? parseInt(dataItem.outStandingDays)
                : 0,
              delayPlan: dataItem.delayPlan ? parseInt(dataItem.delayPlan) : 0,
              billingFactorCOS: dataItem.billingFactorCOS,
              closingStock: dataItem.closingStock,
              closingOutStanding: dataItem.closingOutStanding,
            };

            try {
              formSchema.validateSync(payload, { abortEarly: false });
              finalData.push(payload);
              break;
            } catch (error) {
              const errFields = error.inner?.map((err) =>
                err.path === "delayPlan" ? "Installation plan Nos." : err.path
              );
              isValid = false;
              showErrorDialog(errFields);
              break;
            }
          }
        }
      });
      if (!isValid) {
        return;
      }

      /* if (
      !payload.volume ||
      !payload.perTr ||
      !payload.amount ||
      !payload.billing ||
      !payload.collection ||
      !payload.r1 ||
      !payload.r2 ||
      !payload.r3 ||
      !payload.t1 ||
      !payload.t2 ||
      !payload.t3 ||
      !payload.industry ||
      !payload.delayPlan
    ) {
      return handleSaveModal();
    } */

      // dispatch(fetchgenerateScheme(finalData));
      handleDraftModal();
      setDataDraftHandle(finalData);
      dispatch(add(finalData));
    }
  };

  const saveDraft = (route) => {
    if (selectedRowKeys.length === 0) {
      handleSelectModal();
      return;
    }

    let payload = [],
      isValid = true;
    isValid = true;
    let status = 1;
    if (editType === "schemeProgress" && type === "edit") {
      status = 1;
    } else if (editType === "draft" && type === "edit") {
      status = 0;
    }
    if (listData.length != 0) {
      listData.map((currentRecord) => {
        const userData = decryptData(sessionStorage.getItem("userInfo"));
        const userId = userData.userId;
        const userName = userData.userName;
        const formattedPayout = {
          dealerCode: currentRecord.code,
          dealerId: +currentRecord.key,
          // Manager: currentRecord.Manager,
          schemeId: currentRecord.key,
          stateName: currentRecord.state,
          startMonth: changeSchemeDate.startDate
            ? changeSchemeDate.startDate
            : currentRecord.scheme.startMonth,
          endMonth: changeSchemeDate.endDate
            ? changeSchemeDate.endDate
            : currentRecord.scheme.endMonth,
          reason: currentRecord.reason,
          delayPlan: currentRecord.delayPlan,
          billing: currentRecord.billing,
          installation: currentRecord.installation,
          collection: Math.round(currentRecord.collection),
          openingStockDays: currentRecord.scheme.openingStockDays,
          outStandingDays: currentRecord.scheme.outStandingDays,
          status: status,
          isSAP: true,
          isActive: true,
          t1: parseInt(currentRecord.BooksCollectionTargetT1),
          t2: parseInt(currentRecord.BooksCollectionTargetT2),
          t3: parseInt(currentRecord.BooksCollectionTargetT3),
          t4: parseInt(currentRecord.BooksCollectionTargetT4)
            ? parseInt(currentRecord.BooksCollectionTargetT4)
            : 0,
          t5: parseInt(currentRecord.BooksCollectionTargetT5)
            ? parseInt(currentRecord.BooksCollectionTargetT5)
            : 0,
          t6: parseInt(currentRecord.BooksCollectionTargetT6)
            ? parseInt(currentRecord.BooksCollectionTargetT6)
            : 0,
          t2_T1: parseInt(currentRecord.BooksCollectionTargetT2T1),
          t3_T2: parseInt(currentRecord.BooksCollectionTargetT3T2),
          t4_T3: parseInt(currentRecord.BooksCollectionTargetT4T3),
          t5_T4: parseInt(currentRecord.BooksCollectionTargetT5T4),
          t6_T5: parseInt(currentRecord.BooksCollectionTargetT6T5),
          p1: parseInt(currentRecord.payoutR1),
          p2: parseInt(currentRecord.payoutR2),
          p3: parseInt(currentRecord.payoutR3),
          p4: parseInt(currentRecord.payoutR4)
            ? parseInt(currentRecord.payoutR4)
            : 0,
          p5: parseInt(currentRecord.payoutR5)
            ? parseInt(currentRecord.payoutR5)
            : 0,
          p6: parseInt(currentRecord.payoutR6)
            ? parseInt(currentRecord.payoutR6)
            : 0,
          r1: +currentRecord.PayoutRatep1,
          r2: +currentRecord.PayoutRatep2,
          r3: +currentRecord.PayoutRatep3,
          r4: currentRecord.PayoutRatep4 ? +currentRecord.PayoutRatep4 : 0,
          r5: currentRecord.PayoutRatep5 ? currentRecord.PayoutRatep5 : 0,
          r6: currentRecord.PayoutRatep5 ? currentRecord.PayoutRatep5 : 0,
          volume: parseInt(currentRecord.TEPvol),
          perTr: parseInt(currentRecord.TEPpertr),
          amount: parseInt(currentRecord.TEPamount),
          remarks: currentRecord.scheme.remarks,
          lastMonthSlab: currentRecord.scheme.lastMonthSlab,
          lastMonthColQty: currentRecord.scheme.lastMonthColQty,
          lastTwoMonthSlab: currentRecord.scheme.lastTwoMonthSlab,
          lastTwoMonthColQty: currentRecord.scheme.lastTwoMonthColQty,
          fyear: currentFiscalYear,
          userName: userName,
          userId: userId,
          industry: currentRecord.industry
            ? parseInt(currentRecord.industry)
            : 0,
          billingFactorCOS: currentRecord.billingFactorCOS,
          closingStock: currentRecord.closingStock,
          closingOutStanding: currentRecord.closingOutStanding
        };
        payload.push(formattedPayout);
        try {
          formSchema.validateSync(formattedPayout, { abortEarly: false });
        } catch (error) {
          const errFields = error.inner?.map((err) =>
            err.path === "delayPlan" ? "Installation plan Nos." : err.path
          );
          isValid = false;
          return showErrorDialog(errFields);
        }
      });
    }

    if (!isValid) {
      return;
    }
    setConfirmType({ type: "updateDraft", route: route, payload });
    return handleConfirmationModal();
  };

  const handleSaveDraft = (route, payload) => {
    setConfirmType({ type: "", route: "", payload: [] });
    let payload1 = payload?.map((x) => ({ ...x, TopTarget: "t1,t2,t3" }));
    axiosAPI
      .post(`UpdateScheme/UpdateSchemeGenerate`, payload1)
      .then((result) => {
        // if(type === "edit" ) {
        //   handleSuccessModal();
        // }
        message.success({
          content: "Scheme successfully generated",
          className: "custom-message",
        });
        if (route) {
          if (type === "edit" && editType === "schemeProgress") {
            navigate("/schemeProgress");
          } else if (type === "edit" && editType === "draft") {
            navigate("/collection/draftSchemes");
          } else {
            navigate("/collection/allSchemes");
          }
        }
      });
  };

  const submitData = async (e) => {
    e.preventDefault();
    let payload,
      isValid = true;
    if (
      (type === "new" && editType === "create") ||
      (type === "edit" && editType === "draft")
    ) {
      const userData = decryptData(sessionStorage.getItem("userInfo"));
      const userId = userData.userId;
      const userName = userData.userName;
      const finalData = [];
      if (selectedRowKeys.length == 0) {
        handleSelectModal();
        return;
      }
      selectedRowKeys.forEach(async (item) => {
        for (let dataItem of listData) {
          if (dataItem.key === item) {
            payload = {
              dealerCode: dataItem.code,
              dealerId: +dataItem.key,
              Manager: dataItem.Manager,
              StartMonth: startDate
                ? dayjs(startDate)
                  .startOf("month")
                  .format("YYYY-MM-DD HH:mm:ss")
                : startDate,
              EndMonth: endDate
                ? dayjs(endDate).endOf("month").format("YYYY-MM-DD HH:mm:ss")
                : endDate,
              reason: dataItem.reason,
              status: 1,
              state: dataItem.stateId,
              billing: dataItem.billing ? +dataItem.billing : 0,
              installation: dataItem.installation ? +dataItem.installation : 0,
              collection: Math.round(
                dataItem.collection ? +dataItem.collection : 0
              ),
              delayPlan: dataItem.delayPlan,
              isSAP: true,
              isActive: true,
              schemePlanStartMonth: startDate,
              schemePlanEndMonth: endDate,
              schemeTypeId: schemeType,
              openingStockDays: dataItem.openingStockDays,
              outStandingDays: dataItem.outStandingDays
                ? parseInt(dataItem.outStandingDays)
                : 0,
              t1: +dataItem.BooksCollectionTargetT1,
              t2: +dataItem.BooksCollectionTargetT2,
              t3: +dataItem.BooksCollectionTargetT3,
              t4: dataItem.BooksCollectionTargetT4
                ? +dataItem.BooksCollectionTargetT4
                : 0,
              t5: dataItem.BooksCollectionTargetT5
                ? +dataItem.BooksCollectionTargetT5
                : 0,
              t6: dataItem.BooksCollectionTargetT6
                ? +dataItem.BooksCollectionTargetT6
                : 0,
              t2_T1: dataItem.BooksCollectionTargetT2T1,
              t3_T2: dataItem.BooksCollectionTargetT3T2,
              t4_T3: dataItem.BooksCollectionTargetT4T3,
              t5_T4: dataItem.BooksCollectionTargetT5T4,
              t6_T5: dataItem.BooksCollectionTargetT6T5,
              p1: dataItem.payoutR1,
              p2: dataItem.payoutR2,
              p3: dataItem.payoutR3,
              p4: dataItem.payoutR4 ? +dataItem.payoutR4 : 0,
              p5: dataItem.payoutR5 ? +dataItem.payoutR5 : 0,
              p6: dataItem.payoutR6 ? +dataItem.payoutR6 : 0,
              r1: +dataItem.PayoutRatep1,
              r2: +dataItem.PayoutRatep2,
              r3: +dataItem.PayoutRatep3,
              r4: dataItem.PayoutRatep4 ? +dataItem.PayoutRatep4 : 0,
              r5: dataItem.PayoutRatep5 ? +dataItem.PayoutRatep5 : 0,
              r6: dataItem.PayoutRatep6 ? +dataItem.PayoutRatep6 : 0,
              volume: dataItem.TEPvol,
              perTr: Math.round(dataItem.TEPpertr),
              amount: dataItem.TEPamount,
              isSubmitted: 1,
              fyear: currentFiscalYear,
              userid: +userId,
              username: userName,
              industry: dataItem.industry ? parseInt(dataItem.industry) : 0,
              oldBillingQty: dataItem.oldBillingQty,
              oldInstallation: dataItem.oldInstallation,
              oldCollection: Math.round(dataItem.oldCollection),
              oldOpeningStock: dataItem.oldOpeningStock,
              closingOutStandingMonth_1: dataItem.closingOutStandingMonth_1,
              closingOutStandingMonth_2: dataItem.closingOutStandingMonth_2,
              closingOutStandingMonth_3: dataItem.closingOutStandingMonth_3,
              bgLacsCurrentYear: dataItem.bgLacsCurrentYear,
              billingFactorCOS: dataItem.billingFactorCOS,
              closingStock: dataItem.closingStock,
              closingOutStanding: dataItem.closingOutStanding
            };

            try {
              formSchema.validateSync(payload, { abortEarly: false });
              finalData.push(payload);
              break;
            } catch (error) {
              const errFields = error.inner?.map((err) =>
                err.path === "delayPlan" ? "Installation plan Nos." : err.path
              );
              showErrorDialog(errFields);
              isValid = false;
              break;
            }

            /* if (
              !payload.volume ||
              !payload.perTr ||
              !payload.amount ||
              !payload.billing ||
              !payload.collection ||
              !payload.r1 ||
              !payload.r2 ||
              !payload.r3 ||
              !payload.t1 ||
              !payload.t2 ||
              !payload.t3 ||
              !payload.industry ||
              !payload.delayPlan
            ) {
              // handleSaveModal();
              break;
            } else {
  
              // handleRequired(payload);
              finalData.push(payload);
              break;
            } */
          }
        }
      });
      if (!isValid) {
        return;
      }
      setDataHandle(finalData);
      if (type === "edit" && editType === "draft") {
        handleConfirmationModal();
      } else {
        dispatch(add(finalData));
        if (
          payload.volume &&
          // payload.perTr &&
          // payload.amount &&
          payload.billing &&
          payload.collection &&
          // payload.r1 &&
          // payload.r2 &&
          // payload.r3 &&
          // payload.t1 &&
          // payload.t2 &&
          // payload.t3 &&
          payload.industry &&
          payload.delayPlan
        ) {
          handleSuccessModal();
        }
        // if(openSaveModal=== false){
        // handleSuccessModal();
        // }
      }
    } else if (type === "edit") saveDraft(true);
  };

  const hasSelected = selectedRowKeys?.length > 0;

  const handleDealerCode = async (dlrCode, currentSelected) => {
    if (dealerCode.includes("allDealer") && !dlrCode.includes("allDealer")) {
      setCollectionData((collectionData) => ({
        ...collectionData,
        dealerCode: [],
      }));
      return;
    }
    if (dealerCode.includes("allDealer") && dlrCode.includes("allDealer")) {
      setCollectionData((collectionData) => ({
        ...collectionData,
        dealerCode: dlrCode.filter((item) => item != "allDealer"),
      }));
      return;
    }
    if (dlrCode.includes("allDealer")) {
      let dealerList = [...listData];
      dealerList = dealerList.filter((dealer) => dealer.isExist === 0);
      setCollectionData((collectionData) => ({
        ...collectionData,
        dealerCode: [...dealerList.map((item) => item.code), "allDealer"],
      }));
      return;
    }
    setCollectionData((collectionData) => ({
      ...collectionData,
      dealerCode: dlrCode,
    }));

    // if(collectionData.dealerCode.length === 0){
    //   const disableDealer = listData?.map(item =>
    //     item.isExist
    //   )
    //   if (disableDealer.includes(1)) {
    //     message.warning("Scheme for  disabled dealer already exist")
    //   }
    //   return;
    // }
  };

  const getTitle = () => {
    switch (editType) {
      case "draft":
        return "Edit Draft";
      case "schemeProgress":
        return "Edit Scheme Progress";
      case "create":
        return "Edit";
      default:
        return "";
    }
  };

  const getBackPath = () => {
    if (editType === "draft") return "/collection/draftSchemes";
    else if (editType === "schemeProgress") return "/schemeProgress";
    else return "/collection/allSchemes";
  };

  const showErrorDialog = (errFields) => {
    setOpenErrorDialog((openErrorDialog) => ({
      open: true,
      errorsFields: errFields ? errFields : {},
    }));
  };

  const closeErrorDialog = () => {
    setOpenErrorDialog({ open: false, errorsFields: {} });
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };
  const handleFileUpload = (e) => {
    const maxFileSize = 5 * 1024 * 1024; // Set maximum file size to 5MB
    const files = e.target.files;

    // Check if any file exceeds the maximum file size limit
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxFileSize) {
        ToastifyShow(`File ${files[i].name} is too large. Maximum file size is 5MB.`, "error");
        return;
      }
  
      // Check file headers for XLSX files
      if (!isValidFileHeader(files[i])) {
        ToastifyShow(`Invalid file header for ${files[i].name}.`, "error");
        return;
      }
  
      // Check content type for XLSX files
      if (!isValidContentType(files[i])) {
        ToastifyShow(`Invalid content type for ${files[i].name}.`, "error");
        return;
      }
  
      // Check magic numbers for XLSX files
      if (!isValidMagicNumber(files[i])) {
        ToastifyShow(`Invalid magic number for ${files[i].name}.`, "error");
        return;
      }
    }

    if (files[0].name.split('.')?.[1] !== 'xlsx') {
      ToastifyShow("Please upload an XLSX file only.", "error");
      return;
    }
  
    const reader = new FileReader();
    reader.readAsBinaryString(files[0]);
  
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      let calArr = [];
      const previousYearSameMonth = new Date(
        selectedFirstMonth.getFullYear() - 1,
        selectedFirstMonth.getMonth(),
        1
      );
      const selectedFirstMonthString = selectedFirstMonth?.toLocaleString(
        "en-IN",
        {
          year: "2-digit",
          month: "short",
        }
      );
      const selectedSecondMonthString = selectedSecondMonth?.toLocaleString(
        "en-IN",
        {
          year: "2-digit",
          month: "short",
        }
      );
      const previousYearSameMonthString = previousYearSameMonth.toLocaleString(
        "en-IN",
        { year: "2-digit", month: "short" }
      );
      let { t1, t2, t3, t4, t5, t6 } = schemeLockedStatus;
      let getData = async () => {
        let dataArr = await collectionSchemeUpload({
          parseData: parsedData,
          formikData: formik?.values,
          calArr: calArr,
          previousYearSameMonthString: previousYearSameMonthString,
          selectedSecondMonthString: selectedSecondMonthString,
          selectedFirstMonthString: selectedFirstMonthString,
          collectionLock:
            t1 && !t2 && !t3 && !t4 && !t5 && !t6
              ? "t1"
              : t1 && t2 && !t3 && !t4 && !t5 && !t6
                ? "t2"
                : t1 && t2 && t3 && !t4 && !t5 && !t6
                  ? "t3"
                  : t1 && t2 && t3 && t4 && !t5 && !t6
                    ? "t4"
                    : t1 && t2 && t3 && t4 && t5 && !t6
                      ? "t5"
                      : t1 && t2 && t3 && t4 && t5 && t6
                        ? "t6"
                        : "",
        });
  
        await setCheckCalculate(true);
        await setCheckCalculateArr(calArr);
        await formik?.setValues(dataArr);
      };
      getData();
      ToastifyShow("File uploaded successfully", "success");
    };
  };
  
  // Check if file header is valid for XLSX files
  const isValidFileHeader = (file) => {
    const validHeaders = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    return validHeaders.includes(file.type);
  };
  
  // Check if content type is valid for XLSX files
  const isValidContentType = (file) => {
    const validContentTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    return validContentTypes.includes(file.type);
  };
  
  // Check if magic number is valid for XLSX files
  const isValidMagicNumber = (file) => {
    // For XLSX files, we do not typically check for magic numbers.
    // Instead, we rely on file extension and content type validation.
    return true;
  };

  useEffect(() => {
    if (checkCalculate) {
      checkCalculateArr?.forEach((x) => calculateFormula(x));
      setCheckCalculate(false);
    }
  }, [checkCalculate]);

  const handleSampleFile = () => {
    const firstPastMonth = new Date(
      selectedFirstMonth.getFullYear(),
      selectedFirstMonth.getMonth() - 2,
      1
    );
    const secondPastMonth = new Date(
      selectedFirstMonth.getFullYear(),
      selectedFirstMonth.getMonth() - 1,
      1
    );
    const thirdPastMonth = new Date(
      selectedFirstMonth.getFullYear(),
      selectedFirstMonth.getMonth() - 3,
      1
    );

    const firstPastMonthString = firstPastMonth.toLocaleString("en-IN", {
      year: "2-digit",
      month: "short",
    });
    const secondPastMonthString = secondPastMonth.toLocaleString("en-IN", {
      year: "2-digit",
      month: "short",
    });
    const thirdPastMonthString = thirdPastMonth.toLocaleString("en-IN", {
      year: "2-digit",
      month: "short",
    });

    const previousYearSameMonth = new Date(
      selectedFirstMonth.getFullYear() - 1,
      selectedFirstMonth.getMonth(),
      1
    );

    const selectedFirstMonthString = selectedFirstMonth?.toLocaleString(
      "en-IN",
      {
        year: "2-digit",
        month: "short",
      }
    );
    const selectedSecondMonthString = selectedSecondMonth?.toLocaleString(
      "en-IN",
      {
        year: "2-digit",
        month: "short",
      }
    );
    const previousYearSameMonthString = previousYearSameMonth.toLocaleString(
      "en-IN",
      { year: "2-digit", month: "short" }
    );

    const headerStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
        diagonal: { diagonalUp: true, diagonalDown: true },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
    let { t1, t2, t3, t4, t5, t6 } = schemeLockedStatus;
    let tab_text = collectionSchemeCollectionHeaderDownload({
      previousYearSameMonthString: previousYearSameMonthString,
      selectedFirstMonthString: selectedSecondMonthString,
      selectedSecondMonthString: selectedFirstMonthString,
      thirdPastMonthString: thirdPastMonthString,
      firstPastMonthString: firstPastMonthString,
      secondPastMonthString: secondPastMonthString,
      collectionLock:
        t1 && !t2 && !t3 && !t4 && !t5 && !t6
          ? "t1"
          : t1 && t2 && !t3 && !t4 && !t5 && !t6
            ? "t2"
            : t1 && t2 && t3 && !t4 && !t5 && !t6
              ? "t3"
              : t1 && t2 && t3 && t4 && !t5 && !t6
                ? "t4"
                : t1 && t2 && t3 && t4 && t5 && !t6
                  ? "t5"
                  : t1 && t2 && t3 && t4 && t5 && t6
                    ? "t6"
                    : "",
    });

    {
      formik?.values?.length != 0 &&
        formik?.values.forEach((header) => {
          tab_text += `<tr ><td>${header?.code}</td>
    <td>${header?.Location}</td>
    <td>${header?.name || ""}</td>
    <td>${header?.state}</td>
    <td>${header?.Manager}</td>
    <td>${header?.StartMonth || ""}</td>
    <td>${header?.EndMonth || ""}</td>
    <td>${header?.dropdownscheme?.includes("t6")
              ? "T6"
              : header?.dropdownscheme?.includes("t5")
                ? "T5"
                : header?.dropdownscheme?.includes("t4")
                  ? "T4"
                  : header?.dropdownscheme?.includes("t3")
                    ? "T3"
                    : header?.dropdownscheme?.includes("t2")
                      ? "T2"
                      : header?.dropdownscheme?.includes("t1")
                        ? "T1"
                        : ""
            }
    </td>
    <td></td>
    <td>${header?.oldBillingQty}</td>
    <td>${header?.oldInstallation}</td>
    <td>${header?.oldCollection}</td>
    <td>${header?.oldOpeningStock || 0}</td>
    <td>${header?.openingStockDays || 0}</td>
      <td>${header?.closingOutStandingMonth_3}</td>
      <td>${header?.closingOutStandingMonth_2}</td>
      <td>${header?.closingOutStandingMonth_1}</td>
      <td>${header?.outStandingDays || 0}</td>
      <td>${header?.bgLacsCurrentYear}</td>
      
    </tr>`;
        });
    }
    tab_text += "</table>";

    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);

    // Apply styles to the table using a class
    elt.getElementsByClassName("custom-table")[0].style.width = "100%";

    // generate workbook with XLSXStyle
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });

    Object?.keys(wb?.Sheets?.Sheet1 || {})
      ?.filter(
        (x) =>
          x != "!rows" &&
          x != "!cols" &&
          x != "!merges" &&
          x != "!ref" &&
          x != "!fullref"
      )
      ?.forEach((x) => {
        wb.Sheets[wb.SheetNames[0]][x]["s"] = headerStyle;
      });
    // Dynamically set column widths
    wb.Sheets[wb.SheetNames[0]]["!cols"] = [
      { wch: 15 },
      { wch: 20 },
      { wch: 30 },
      { wch: 10 },
      { wch: 30 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    // Dynamically set row heights
    wb.Sheets[wb.SheetNames[0]]["!rows"] = [
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },

      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      // Add more row heights as needed
    ];

    // remove element
    document.body.removeChild(elt);

    // generate file
    XLSX.writeFile(
      wb,
      schemeType == "1" || schemeType == ""
        ? "With_Delivery_Plan.xlsx"
        : "Without_Delivery_Plan.xlsx"
    );
  };
  const menu = (
    <Menu>
      <Menu.Item>
        {selectedFiles?.length == 0 && (
          <div className="custom-file-input">
            <input type="file" accept=".xlsx" onChange={handleFileUpload} />
            <label htmlFor="fileInput">Choose File</label>
          </div>
        )}
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>
              {file.name}
              <Button onClick={() => handleRemoveFile(index)} className="mx-1">
                Remove
              </Button>
            </li>
          ))}
        </ul>
      </Menu.Item>
      <Menu.Item onClick={handleSampleFile}>Download Sample File</Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (visibleRows?.length > 0 || listData.length > 0) {
      window.addEventListener("beforeunload", alertUser);
    }
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [visibleRows, listData]);

  const alertUser = (event) => {
    event.preventDefault();
    return (event.returnValue = "");
  };

  // useEffect(() => {
  //   // This function will be called whenever the route changes
  //   const handleRouteChange = () => {
  //     // Display an alert when the route changes
  //     window.confirm('Changes that you made may not be saved.');
  //   };

  //   // Listen for changes in the route
  //   const unlisten = () => {
  //     handleRouteChange();
  //   };

  //   // Clean up the listener when the component unmounts
  //   return () => {
  //     unlisten();
  //   };
  // }, [location]);

  // useEffect(() => {
  //   const handleRouteChange = () => {
  //     window.alert("Changes that you made may not be saved.");
  //   };

  //   // Listen for changes in the route
  //   const unlisten = () => {
  //     if(listData?.length > 0){
  //       handleRouteChange();
  //     }
  //   };

  //   // Clean up the listener when the component unmounts
  //   return () => {
  //     unlisten();
  //   };
  // }, [location]);

  return (
    <div className="table_scheme_wrap">
      <Divider style={{ marginBottom: "10px" }} />
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "25px",
        }}
      >
        <Col style={{ display: "flex", alignItems: "center" }}>
          {type === "edit" && (
            <Link to={getBackPath()} style={{ color: "#000" }}>
              <LeftOutlined style={{ fontSize: "14px" }} />
            </Link>
          )}

          <h2 style={{ fontSize: "18px" }}>
            {`${type === "new" ? "Create" : getTitle()} Scheme`}
          </h2>
        </Col>
        {type === "new" && (

          <Col
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px 0px",
              width:"100%",
            }}
          >

            <div class="Button createscheme">
              <ThemeButton
                lastMonth={true}
                collectionData={collectionData}
                listData={listData}
                resetDealers={()=>{
                  setCollectionData({...collectionData,dealerCode:[]})
                }}
                onSelectSchemeType={(schemeType) => {
                  setCollectionData((collectionData) => ({
                    ...collectionData,
                    schemeType,
                  }));

                  formik?.resetForm();
                }}
                disableStartMonthShow={true}
                handleStartChange={(date) => {
                  setCollectionData((collectionData) => ({
                    ...collectionData,
                    startDate: date == null ? "" : date.format(),
                  }));
                }}
                handleEndChange={(date) =>
                  setCollectionData((collectionData) => ({
                    ...collectionData,
                    endDate: date == null ? "" : date.format(),
                  }))
                }
                onSelectDealer={(dealerCode, currentSelected, allDealer) => {
                  let allDealerCheck = currentSelected?.filter(
                    (x) => x?.value == "allDealer"
                  );

                  if (allDealerCheck?.length == 1) {
                    billsValue(
                      allDealer
                        .filter((item) => item.disabled === false)
                        .map((item) => ({
                          year: new Date(collectionData?.startDate).getFullYear(),
                          dealerCode: item.value,
                          startMonth:
                            new Date(collectionData?.startDate).getMonth() + 1,
                          // "endMonth": 0,
                          openingStock: item.openingStock || 0,
                          closingOs: item.closingOs,
                        }))
                    );
                  } else {
                    billsValue(
                      currentSelected.map((item) => ({
                        dealerCode: item.value,
                        year: new Date(collectionData?.startDate).getFullYear(),
                        startMonth:
                          new Date(collectionData?.startDate).getMonth() + 1,
                        // "endMonth": 0,
                        openingStock: item.openingStock,
                        closingOs: item.closingOs,
                      }))
                    );
                  }
                  if (formik?.values?.length != 0) {
                    setPreviousValue(formik?.values);
                  }
                  handleDealerCode(dealerCode, currentSelected);

                  // formik?.resetForm()
                }}
                onGetDealerDropdownData={getDealerDropdownData}
                handleDateRange={(date, formatDate) => {
                  setCollectionData((collectionData) => ({
                    ...collectionData,
                    startDate: date && date[0] ? date[0].format() : "",
                    endDate: date && date[1] ? date[1].format() : "",
                  }));
                }}
              />
            </div>


            <Space
              style={{
                padding: "0",
                paddingTop: "14px",
              }}
              direction="vertical"
            >
              <Tooltip title={"Import/Export to Excel"}>
              <Dropdown overlay={menu} placement="bottomLeft">
                <Button className="h-2">Import/Export to Excel</Button>
              </Dropdown>
              </Tooltip> 
            </Space>
            {/* } */}
          </Col>
        )}
      </Row>
      <span>
        {hasSelected ? `Selected ${selectedRowKeys?.length} items` : ""}
      </span>

      {isAnyEmpty(collectionData) && schemeType == 1 ? (
        <Form>
          <Table
            id="myTable"
            showSorterTooltip={false}
            className="header"
            //title={tableHeader}
            summary={(pageData) => {
              const fieldSum = {
                b: 0,
                i: 0,
                c: 0,
                totalR1: 0,
                totalR2: 0,
                totalR3: 0,
                totalR4: 0,
                totalR5: 0,
                totalR6: 0,
                t1: 0,
                t2: 0,
                t3: 0,
                t4: 0,
                t5: 0,
                t6: 0,
                T2_T1: 0,
                T3_T2: 0,
                T4_T3: 0,
                T5_T4: 0,
                T6_T5: 0,
                vol: 0,
                perTr: 0,
                totalAmount: 0,
                industry:0,
                billing:0,
                installation:0,
                collection:0,
                delayPlan:0
              };

              pageData.forEach(
                ({
                  payoutR1,
                  payoutR2,
                  payoutR3,
                  payoutR4,
                  payoutR5,
                  payoutR6,
                  TEPamount,
                  BooksCollectionTargetT1,
                  BooksCollectionTargetT2,
                  BooksCollectionTargetT3,
                  BooksCollectionTargetT4,
                  BooksCollectionTargetT5,
                  BooksCollectionTargetT6,
                  BooksCollectionTargetT2T1,
                  BooksCollectionTargetT3T2,
                  BooksCollectionTargetT4T3,
                  BooksCollectionTargetT5T4,
                  BooksCollectionTargetT6T5,
                  TEPvol,
                  TEPpertr,
                  dropdownscheme,
                  industry,
                  billing,
                  installation,
                  collection,
                  delayPlan
                }) => {
                  fieldSum.totalR1 +=
                    payoutR1 && dropdownscheme?.includes("t1")
                      ? parseInt(payoutR1 || 0)
                      : 0;
                      fieldSum.industry+= parseInt(industry || 0);
                      fieldSum.billing+= parseInt(billing || 0);
                      fieldSum.installation+= parseInt(installation || 0);
                      fieldSum.collection+= parseInt(collection || 0);
                      fieldSum.delayPlan+= parseInt(delayPlan || 0);
                      
                  fieldSum.totalR2 +=
                    payoutR2 && dropdownscheme?.includes("t2")
                      ? parseInt(payoutR2 || 0)
                      : 0;
                  fieldSum.totalR3 +=
                    payoutR3 && dropdownscheme?.includes("t3")
                      ? parseInt(payoutR3 || 0)
                      : 0;
                  fieldSum.totalR4 +=
                    payoutR4 && dropdownscheme?.includes("t4")
                      ? parseInt(payoutR4 || 0)
                      : 0;
                  fieldSum.totalR5 +=
                    payoutR5 && dropdownscheme?.includes("t5")
                      ? parseInt(payoutR5 || 0)
                      : 0;
                  fieldSum.totalR6 +=
                    payoutR6 && dropdownscheme?.includes("t6")
                      ? parseInt(payoutR6 || 0)
                      : 0;
                  fieldSum.totalAmount += TEPamount ? parseInt(TEPamount) : 0;
                  fieldSum.t1 +=
                    BooksCollectionTargetT1 && dropdownscheme?.includes("t1")
                      ? parseInt(BooksCollectionTargetT1)
                      : 0;
                  fieldSum.t2 +=
                    BooksCollectionTargetT2 && dropdownscheme?.includes("t2")
                      ? parseInt(BooksCollectionTargetT2)
                      : 0;
                  fieldSum.t3 +=
                    BooksCollectionTargetT3 && dropdownscheme?.includes("t3")
                      ? parseInt(BooksCollectionTargetT3)
                      : 0;
                  fieldSum.t4 +=
                    BooksCollectionTargetT4 && dropdownscheme?.includes("t4")
                      ? parseInt(BooksCollectionTargetT4)
                      : 0;
                  fieldSum.t5 +=
                    BooksCollectionTargetT5 && dropdownscheme?.includes("t5")
                      ? parseInt(BooksCollectionTargetT5)
                      : 0;
                  fieldSum.t6 +=
                    BooksCollectionTargetT6 && dropdownscheme?.includes("t6")
                      ? parseInt(BooksCollectionTargetT6)
                      : 0;
                  fieldSum.T2_T1 +=
                    BooksCollectionTargetT2T1 && dropdownscheme?.includes("t2")
                      ? parseInt(BooksCollectionTargetT2T1)
                      : 0;
                  fieldSum.T3_T2 +=
                    BooksCollectionTargetT3T2 && dropdownscheme?.includes("t3")
                      ? parseInt(BooksCollectionTargetT3T2)
                      : 0;
                  fieldSum.T4_T3 +=
                    BooksCollectionTargetT4T3 && dropdownscheme?.includes("t4")
                      ? parseInt(BooksCollectionTargetT4T3)
                      : 0;
                  fieldSum.T5_T4 +=
                    BooksCollectionTargetT5T4 && dropdownscheme?.includes("t5")
                      ? parseInt(BooksCollectionTargetT5T4)
                      : 0;
                  fieldSum.T6_T5 +=
                    BooksCollectionTargetT6T5 && dropdownscheme?.includes("t6")
                      ? parseInt(BooksCollectionTargetT6T5)
                      : 0;
                  fieldSum.vol += TEPvol ? parseInt(TEPvol) : 0;
                  fieldSum.perTr = parseInt(
                    fieldSum.totalAmount / fieldSum.vol
                  );
                }
              );
              return (
                <Table.Summary.Row
                  style={{ fontWeight: "500", fontSize: "18px" }}
                >
                  <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>{fieldSum.industry?TextLocalString(fieldSum.industry,true):""}</Table.Summary.Cell>
                  <Table.Summary.Cell index={10}></Table.Summary.Cell>
                  <Table.Summary.Cell index={11}></Table.Summary.Cell>
                  <Table.Summary.Cell index={12}></Table.Summary.Cell>
                  <Table.Summary.Cell index={13}></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}></Table.Summary.Cell>
                  <Table.Summary.Cell index={15}></Table.Summary.Cell>
                  <Table.Summary.Cell index={16}></Table.Summary.Cell>
                  <Table.Summary.Cell index={17}></Table.Summary.Cell>
                  <Table.Summary.Cell index={18}></Table.Summary.Cell>

                  <Table.Summary.Cell index={20}></Table.Summary.Cell>
                  <Table.Summary.Cell index={21}>{fieldSum.billing?TextLocalString(fieldSum.billing,true):""}</Table.Summary.Cell>
                  <Table.Summary.Cell index={22}>{fieldSum.installation?TextLocalString(fieldSum.installation,true):""}</Table.Summary.Cell>
                  <Table.Summary.Cell index={23}>{fieldSum.collection?TextLocalString(fieldSum.collection,true):""}</Table.Summary.Cell>
                  <Table.Summary.Cell index={24}></Table.Summary.Cell>
                  <Table.Summary.Cell index={25}></Table.Summary.Cell>
                  <Table.Summary.Cell index={26}>                    {TextLocalString(fieldSum.delayPlan,true)||""}</Table.Summary.Cell>
                  <Table.Summary.Cell index={27}>
                    {fieldSum.t1 ? TextLocalString(fieldSum.t1,true) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={28}>
                    {fieldSum.t2 ? TextLocalString(fieldSum.t2,true) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={29}>
                    {fieldSum.T2_T1 ? TextLocalString(fieldSum.T2_T1,true) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={30}>
                    {fieldSum.t3 ? TextLocalString(fieldSum.t3,true) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={31}>
                    {fieldSum.T3_T2 ? TextLocalString(fieldSum.T3_T2,true) : ""}
                  </Table.Summary.Cell>
                  {schemeLockedStatus?.t4 && (
                    <Table.Summary.Cell index={32}>
                      {fieldSum.t4 ? TextLocalString(fieldSum.t4,true) : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t4 && (
                    <Table.Summary.Cell index={33}>
                      {fieldSum.T4_T3 ? TextLocalString(fieldSum.T4_T3,true) : ""}
                    </Table.Summary.Cell>
                  )}

                  {schemeLockedStatus?.t5 && (
                    <Table.Summary.Cell index={34}>
                      {fieldSum.t5 ? TextLocalString(fieldSum.t5,true) : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t5 && (
                    <Table.Summary.Cell index={35}>
                      {fieldSum.T5_T4 ? TextLocalString(fieldSum.T5_T4,true) : ""}
                    </Table.Summary.Cell>
                  )}

                  {schemeLockedStatus?.t6 && (
                    <Table.Summary.Cell index={36}>
                      {fieldSum.t6 ? TextLocalString(fieldSum.t6,true) : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t6 && (
                    <Table.Summary.Cell index={37}>
                      {fieldSum.T6_T5 ? TextLocalString(fieldSum.T6_T5,true) : ""}
                    </Table.Summary.Cell>
                  )}

                  <Table.Summary.Cell index={38}>
                    {fieldSum.t1 && fieldSum.totalR1
                      ? TextLocalString(Math.round(fieldSum.totalR1 / fieldSum.t1),true)
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={39}>
                    {fieldSum.t2 && fieldSum.totalR2
                      ? TextLocalString(Math.round(fieldSum.totalR2 / fieldSum.t2),true)
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={40}>
                    {fieldSum.t3 && fieldSum.totalR3
                      ? TextLocalString(Math.round(fieldSum.totalR3 / fieldSum.t3),true)
                      : ""}
                  </Table.Summary.Cell>

                  {schemeLockedStatus?.t4 && (
                    <Table.Summary.Cell index={41}>
                      {fieldSum.t4 && fieldSum.totalR4
                        ? TextLocalString(Math.round(fieldSum.totalR4 / fieldSum.t4),true)
                        : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t5 && (
                    <Table.Summary.Cell index={42}>
                      {fieldSum.t5 && fieldSum.totalR5
                        ? TextLocalString(Math.round(fieldSum.totalR5 / fieldSum.t5),true)
                        : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t6 && (
                    <Table.Summary.Cell index={43}>
                      {fieldSum.t6 && fieldSum.totalR6
                        ? TextLocalString(Math.round(fieldSum.totalR6 / fieldSum.t6),true)
                        : ""}
                    </Table.Summary.Cell>
                  )}
                  <Table.Summary.Cell index={44}>
                    {" "}
                    {fieldSum.totalR1 ? TextLocalString(fieldSum.totalR1,true) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={45}>
                    {fieldSum.totalR2 ? TextLocalString(fieldSum.totalR2,true) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={46}>
                    {fieldSum.totalR3 ? TextLocalString(fieldSum.totalR3,true) : ""}
                  </Table.Summary.Cell>
                  {schemeLockedStatus?.t4 && (
                    <Table.Summary.Cell index={47}>
                      {fieldSum.totalR4 ? TextLocalString(fieldSum.totalR4,true) : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t5 && (
                    <Table.Summary.Cell index={48}>
                      {fieldSum.totalR5 ? TextLocalString(fieldSum.totalR5,true) : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t6 && (
                    <Table.Summary.Cell index={49}>
                      {fieldSum.totalR6 ? TextLocalString(fieldSum.totalR6,true) : ""}
                    </Table.Summary.Cell>
                  )}
                  <Table.Summary.Cell index={50}></Table.Summary.Cell>
                  <Table.Summary.Cell index={51}>
                    {fieldSum.vol ? TextLocalString(fieldSum.vol,true) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={52}>
                    {fieldSum.perTr ? TextLocalString(fieldSum.perTr,true) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={53}>
                    {fieldSum.totalAmount ? TextLocalString(fieldSum.totalAmount,true) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={54}></Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
            // components={components}
            rowSelection={{
              selectedRowKeys,
              onChange: (newSelectedRowKeys, selectedRow) => {
                setSelectedRowKeys(newSelectedRowKeys);
              },
              getCheckboxProps: (record) => {
                return {
                  // disabled: dealer.includes(record.code)? true : false,
                };
              },
            }}
            rowClassName={(record, index) =>
              codeError?.includes(record?.code) ? "data-row active-row" : ""
            }
            columns={defaultColumns}
            loading={loadingScheme == "pending" ? true : false}
            dataSource={visibleRows}
            bordered
            pagination={false}
            scroll={{
              x: 3900,
              y: 800,
            }}
            size="middle"
          />
        </Form>
      ) : null}

      {isAnyEmpty(collectionData) && schemeType == 2 ? (
        <Form>
          <Table
            id="myTable1"
            //title={tableHeader}
            showSorterTooltip={false}
            pagination={false}
            summary={(pageData) => {
              const fieldSum = {
                b: 0,
                i: 0,
                c: 0,
                totalR1: 0,
                totalR2: 0,
                totalR3: 0,
                totalR4: 0,
                totalR5: 0,
                totalR6: 0,
                t1: 0,
                t2: 0,
                t3: 0,
                t4: 0,
                t5: 0,
                t6: 0,
                T2_T1: 0,
                T3_T2: 0,
                T4_T3: 0,
                T5_T4: 0,
                T6_T5: 0,
                vol: 0,
                perTr: 0,
                totalAmount: 0,
                delayPlan:0,
                collection:0,
                installation:0,
                billing:0,
                industry:0
              };

              pageData.forEach(
                ({
                  payoutR1,
                  payoutR2,
                  payoutR3,
                  payoutR4,
                  payoutR5,
                  payoutR6,
                  TEPamount,
                  BooksCollectionTargetT1,
                  BooksCollectionTargetT2,
                  BooksCollectionTargetT3,
                  BooksCollectionTargetT4,
                  BooksCollectionTargetT5,
                  BooksCollectionTargetT6,
                  BooksCollectionTargetT2T1,
                  BooksCollectionTargetT3T2,
                  BooksCollectionTargetT4T3,
                  BooksCollectionTargetT5T4,
                  BooksCollectionTargetT6T5,
                  TEPvol,
                  TEPpertr,
                  dropdownscheme,
                  delayPlan,
                  collection,
                  installation,
                  billing,
                  industry
                }) => {
                  fieldSum.industry+= parseInt(industry || 0);
                  fieldSum.billing+= parseInt(billing || 0);
                  fieldSum.installation+= parseInt(installation || 0);
                  fieldSum.collection+= parseInt(collection || 0);
                  fieldSum.delayPlan+= parseInt(delayPlan || 0);
                  
                  fieldSum.totalR1 +=
                    payoutR1 && dropdownscheme?.includes("t1")
                      ? parseInt(payoutR1 || 0)
                      : 0;
                  fieldSum.totalR2 +=
                    payoutR2 && dropdownscheme?.includes("t2")
                      ? parseInt(payoutR2 || 0)
                      : 0;
                  fieldSum.totalR3 +=
                    payoutR3 && dropdownscheme?.includes("t3")
                      ? parseInt(payoutR3 || 0)
                      : 0;
                  fieldSum.totalR4 +=
                    payoutR4 && dropdownscheme?.includes("t4")
                      ? parseInt(payoutR4 || 0)
                      : 0;
                  fieldSum.totalR5 +=
                    payoutR5 && dropdownscheme?.includes("t5")
                      ? parseInt(payoutR5 || 0)
                      : 0;
                  fieldSum.totalR6 +=
                    payoutR6 && dropdownscheme?.includes("t6")
                      ? parseInt(payoutR6 || 0)
                      : 0;
                  fieldSum.totalAmount += TEPamount ? parseInt(TEPamount) : 0;
                  fieldSum.t1 +=
                    BooksCollectionTargetT1 && dropdownscheme?.includes("t1")
                      ? parseInt(BooksCollectionTargetT1)
                      : 0;
                  fieldSum.t2 +=
                    BooksCollectionTargetT2 && dropdownscheme?.includes("t2")
                      ? parseInt(BooksCollectionTargetT2)
                      : 0;
                  fieldSum.t3 +=
                    BooksCollectionTargetT3 && dropdownscheme?.includes("t3")
                      ? parseInt(BooksCollectionTargetT3)
                      : 0;
                  fieldSum.t4 +=
                    BooksCollectionTargetT4 && dropdownscheme?.includes("t4")
                      ? parseInt(BooksCollectionTargetT4)
                      : 0;
                  fieldSum.t5 +=
                    BooksCollectionTargetT5 && dropdownscheme?.includes("t5")
                      ? parseInt(BooksCollectionTargetT5)
                      : 0;
                  fieldSum.t6 +=
                    BooksCollectionTargetT6 && dropdownscheme?.includes("t6")
                      ? parseInt(BooksCollectionTargetT6)
                      : 0;
                  fieldSum.T2_T1 +=
                    BooksCollectionTargetT2T1 && dropdownscheme?.includes("t2")
                      ? parseInt(BooksCollectionTargetT2T1)
                      : 0;
                  fieldSum.T3_T2 +=
                    BooksCollectionTargetT3T2 && dropdownscheme?.includes("t3")
                      ? parseInt(BooksCollectionTargetT3T2)
                      : 0;
                  fieldSum.T4_T3 +=
                    BooksCollectionTargetT4T3 && dropdownscheme?.includes("t4")
                      ? parseInt(BooksCollectionTargetT4T3)
                      : 0;
                  fieldSum.T5_T4 +=
                    BooksCollectionTargetT5T4 && dropdownscheme?.includes("t5")
                      ? parseInt(BooksCollectionTargetT5T4)
                      : 0;
                  fieldSum.T6_T5 +=
                    BooksCollectionTargetT6T5 && dropdownscheme?.includes("t6")
                      ? parseInt(BooksCollectionTargetT6T5)
                      : 0;
                  fieldSum.vol += TEPvol ? parseInt(TEPvol) : 0;
                  fieldSum.perTr = parseInt(
                    fieldSum.totalAmount / fieldSum.vol
                  );
                }
              );
              return (
                <Table.Summary.Row
                  style={{ fontWeight: "500", fontSize: "18px" }}
                >
                  <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                  <Table.Summary.Cell index={9}></Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>{TextLocalString(fieldSum.industry)||""}</Table.Summary.Cell>
                  <Table.Summary.Cell index={11}></Table.Summary.Cell>
                  <Table.Summary.Cell index={12}></Table.Summary.Cell>
                  <Table.Summary.Cell index={13}></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}></Table.Summary.Cell>
                  <Table.Summary.Cell index={15}></Table.Summary.Cell>
                  <Table.Summary.Cell index={16}></Table.Summary.Cell>
                  <Table.Summary.Cell index={17}></Table.Summary.Cell>
                  <Table.Summary.Cell index={18}></Table.Summary.Cell>
                  <Table.Summary.Cell index={19}></Table.Summary.Cell>
                  <Table.Summary.Cell index={20}>{TextLocalString(fieldSum.billing)||""}</Table.Summary.Cell>
                  <Table.Summary.Cell index={21}>{TextLocalString(fieldSum.installation)||""}</Table.Summary.Cell>
                  <Table.Summary.Cell index={22}>{TextLocalString(fieldSum.collection)||""}</Table.Summary.Cell>
                  <Table.Summary.Cell index={23}></Table.Summary.Cell>
                  <Table.Summary.Cell index={24}></Table.Summary.Cell>
                  <Table.Summary.Cell index={25}>{TextLocalString(fieldSum.delayPlan)||""}</Table.Summary.Cell>
                  <Table.Summary.Cell index={26}>
                    {fieldSum.t1 ? TextLocalString(fieldSum.t1) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={27}>
                    {fieldSum.t2 ? TextLocalString(fieldSum.t2) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={28}>
                    {fieldSum.T2_T1 ? TextLocalString(fieldSum.T2_T1) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={29}>
                    {fieldSum.t3 ? TextLocalString(fieldSum.t3) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={30}>
                    {fieldSum.T3_T2 ? TextLocalString(fieldSum.T3_T2) : ""}
                  </Table.Summary.Cell>
                  {schemeLockedStatus?.t4 && (
                    <Table.Summary.Cell index={31}>
                      {fieldSum.t4 ? TextLocalString(fieldSum.t4) : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t4 && (
                    <Table.Summary.Cell index={32}>
                      {fieldSum.T4_T3 ? TextLocalString(fieldSum.T4_T3) : ""}
                    </Table.Summary.Cell>
                  )}

                  {schemeLockedStatus?.t5 && (
                    <Table.Summary.Cell index={33}>
                      {fieldSum.t5 ? TextLocalString(fieldSum.t5) : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t5 && (
                    <Table.Summary.Cell index={34}>
                      {fieldSum.T5_T4 ? TextLocalString(fieldSum.T5_T4) : ""}
                    </Table.Summary.Cell>
                  )}

                  {schemeLockedStatus?.t6 && (
                    <Table.Summary.Cell index={35}>
                      {fieldSum.t6 ? TextLocalString(fieldSum.t6) : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t6 && (
                    <Table.Summary.Cell index={36}>
                      {fieldSum.T6_T5 ? TextLocalString(fieldSum.T6_T5) : ""}
                    </Table.Summary.Cell>
                  )}

                  <Table.Summary.Cell index={37}>
                    {fieldSum.t1 && fieldSum.totalR1
                      ? TextLocalString(Math.round(fieldSum.totalR1 / fieldSum.t1))
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={38}>
                    {fieldSum.t2 && fieldSum.totalR2
                      ? TextLocalString(Math.round(fieldSum.totalR2 / fieldSum.t2))
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={39}>
                    {fieldSum.t3 && fieldSum.totalR3
                      ? TextLocalString(Math.round(fieldSum.totalR3 / fieldSum.t3))
                      : ""}
                  </Table.Summary.Cell>

                  {schemeLockedStatus?.t4 && (
                    <Table.Summary.Cell index={40}>
                      {fieldSum.t4 && fieldSum.totalR4
                        ? TextLocalString(Math.round(fieldSum.totalR4 / fieldSum.t4))
                        : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t5 && (
                    <Table.Summary.Cell index={41}>
                      {fieldSum.t5 && fieldSum.totalR5
                        ? TextLocalString(Math.round(fieldSum.totalR5 / fieldSum.t5))
                        : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t6 && (
                    <Table.Summary.Cell index={42}>
                      {fieldSum.t6 && fieldSum.totalR6
                        ? TextLocalString(Math.round(fieldSum.totalR6 / fieldSum.t6))
                        : ""}
                    </Table.Summary.Cell>
                  )}
                  <Table.Summary.Cell index={43}>
                    {" "}
                    {fieldSum.totalR1 ? TextLocalString(fieldSum.totalR1) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={44}>
                    {fieldSum.totalR2 ? TextLocalString(fieldSum.totalR2) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={45}>
                    {fieldSum.totalR3 ? TextLocalString(fieldSum.totalR3) : ""}
                  </Table.Summary.Cell>
                  {schemeLockedStatus?.t4 && (
                    <Table.Summary.Cell index={46}>
                      {fieldSum.totalR4 ? TextLocalString(fieldSum.totalR4) : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t5 && (
                    <Table.Summary.Cell index={47}>
                      {fieldSum.totalR5 ? TextLocalString(fieldSum.totalR5) : ""}
                    </Table.Summary.Cell>
                  )}
                  {schemeLockedStatus?.t6 && (
                    <Table.Summary.Cell index={48}>
                      {fieldSum.totalR6 ? TextLocalString(fieldSum.totalR6) : ""}
                    </Table.Summary.Cell>
                  )}
                  <Table.Summary.Cell index={49}></Table.Summary.Cell>
                  <Table.Summary.Cell index={50}>
                    {fieldSum.vol ? TextLocalString(fieldSum.vol) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={51}>
                    {fieldSum.perTr ? TextLocalString(fieldSum.perTr) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={52}>
                    {fieldSum.totalAmount ? TextLocalString(fieldSum.totalAmount) : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={53}></Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
            // components={components}
            rowSelection={{
              selectedRowKeys,
              onChange: (newSelectedRowKeys, selectedRow) => {
                setSelectedRowKeys(newSelectedRowKeys);
              },
              getCheckboxProps: (record) => {
                return {
                  // disabled: dealer.includes(record.code)? true : false,
                };
              },
            }}
            loading={loadingScheme == "pending" ? true : false}
            rowClassName={(record, index) => { }}
            columns={defaultColumns}
            dataSource={visibleRows}
            bordered
            scroll={{
              x: 3900,
              y: 800,
            }}
          />
        </Form>
      ) : null}

      {loading ? <FullScreenLoader /> : ""}
      {type === "edit" && listData?.length > 0 && (
        <Form>
          <Table
            id="myTable2"
            //title={tableHeader}
            components={components}
            rowSelection={{
              selectedRowKeys,
              onChange: (newSelectedRowKeys, selectedRow) => {
                setSelectedRowKeys(newSelectedRowKeys);
                sessionStorage.setItem("rowData", JSON.stringify(selectedRow));
              },
              getCheckboxProps: (record) => {
                // if(selectedRowKeys.length === 1 && selectedRowKeys[0] === record.id){
                //   return{ disabled: true }
                // }
                // else {return {disabled: false}}
              },
            }}
            rowClassName={(record, index) => { }}
            // rowClassName={() => "editable-row"}
            columns={columns}
            dataSource={listData}
            bordered
            scroll={{
              x: 3800,
              y: 800,
            }}
          />
        </Form>
      )}

      {(isAnyEmpty(collectionData) && [2, 1].includes(schemeType)) ||
        (type === "edit" && listData?.length > 0) ? (
        <Row
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {type === "new" && (
            <Col>
              <Button
                type="default"
                shape="round"
                style={{
                  margin: "10px",
                  background: "#FCE838",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  border: "none",
                  padding: "20px",
                }}
                size="large"
                onClick={draftData}
              >
                save as draft
              </Button>
            </Col>
          )}

          {type === "edit" && editType === "draft" && (
            <Col>
              <Button
                type="default"
                shape="round"
                style={{
                  margin: "10px",
                  background: "#FCE838",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  border: "none",
                  padding: "20px",
                }}
                size="large"
                onClick={saveDraft}
              >
                Save as draft
              </Button>
            </Col>
          )}

          <Col>
            <Button
              type="default"
              shape="round"
              className="schemi-btn-all"
              style={{
                margin: "10px",
                background: "#FCE838",
                fontWeight: "700",
                textTransform: "uppercase",
                border: "none",
              }}
              size="large"
              onClick={(e) => {
                if (schemeType == 1 || schemeType == 2) {
                  handleCreateSchema(e, "edit");
                } else {
                  submitData(e);
                }
              }}
            >
              Save & Submit
            </Button>
          </Col>
        </Row>
      ) : null}
      <ChangeSchemeModal
        openModal={openSchemeModal}
        onModalClose={handleChangeScheme}
      />
      {dirty && <AlertModal openModal={openModal} handleModal={handleModal} />}

      <SMDialog open={openSaveModal} onCancel={handleSaveModal}>
        <p className="custom-warning">Warning !</p>
        <p>Please fill the required fields.</p>
      </SMDialog>

      <StatusModal
        open={openSuccessModal}
        onOk={() => {
          dispatch(fetchgenerateScheme(dataHandle));
          handleSuccessModal();
        }}
        onCancel={handleSuccessModal}
      >
        <p>Are you sure you want to Save</p>
      </StatusModal>

      <StatusModal
        open={openConfirmationModal}
        onOk={() => handleConfirmationModal(true)}
        onCancel={() => handleConfirmationModal(false)}
      >
        <p>Are you sure you want to Submit this scheme?</p>
      </StatusModal>

      <StatusModal
        open={showSubmitModal}
        onOk={() => {
          dispatch(schemeGeneratePostApi(submitData1)).then((x) => {
            if (x?.payload?.errorMessage == "Draft Scheme submitted !"||x?.payload?.data =='Scheme generated successfully!!') {
              message.success({
                content: x?.payload?.errorMessage?"Scheme Successfully saved as draft":"Scheme generated successfully!!",
                className: "custom-message",
              });
              navigate("/collection/draftSchemes");
            } else {
              message.error({
                content: "Something Went Wrong",
                className: "custom-message",
              });
            }
          });
          // dispatch(schemeGeneratePostApi(submitData1)).then((x) => {
          //   if (x?.payload?.errorMessage == "Scheme generated successfully!!"){
          //     message.success({
          //       content: 'Scheme Generated Successfully',
          //       className: 'custom-message',
          //     })
          //  navigate("/collection/allSchemes")
          //   }else{
          //  message.error({
          //         content: 'Something Went Wrong',
          //         className: 'custom-message',
          //       })

          //   }
          // })

          setShowSubmitModal(false);
        }}
        onCancel={() => {
          setShowSubmitModal(false);
        }}
      >
        <p>Are you sure you want to save this scheme as Draft?</p>
      </StatusModal>

      <StatusModal
        open={showDraftModal}
        onOk={() => {
          dispatch(schemeGeneratePostApi(submitData1)).then((x) => {
            if (x?.payload?.errorMessage == "Scheme generated successfully!!") {
              message.success({
                content: "Scheme Generated Successfully",
                className: "custom-message",
              });
              navigate("/collection/allSchemes");
            } else {
              message.error({
                content: "Something Went Wrong",
                className: "custom-mesSMDialogsage",
              });
            }
          });

          setShowDraftModal(false);
        }}
        onCancel={() => {
          setShowDraftModal(false);
        }}
      >
        <p>Are you sure you want to save this scheme?</p>
      </StatusModal>
      <StatusModal
        open={openDraftModal}
        onOk={() => {
          dispatch(fetchgenerateScheme(dataDraftHandle));
          handleDraftModal();
        }}
        onCancel={handleDraftModal}
      >
        <p>Are you sure to save as Draft</p>
      </StatusModal>

      <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
        <p className="custom-warning">Warning !</p>

        <p>
          {" "}
          {errorItem.firstItem} should be greater then {errorItem.secondItem}
        </p>
      </SMDialog>
      <SMDialog open={openSelectModal} onCancel={handleSelectModal}>
        <p className="custom-warning">Warning !</p>
        <p>Please Select The Dealer</p>
      </SMDialog>

      <ValidationDifferentSchemeError

        open={openErrorDialog.open}
        onClose={closeErrorDialog}
        errorsFields={openErrorDialog?.errorsFields}
      // SchemeID={codeError}
      />
    </div>

  );
};
export default CreateSchemeTable;
