import React from 'react'
import {
  MoreOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Collapse, Space, Dropdown } from "antd";
import AwaitingLogTable from "./AwaitingLogTable";

import { useEffect, useState } from "react";
import axiosAPI from '../../../services/axiosApi';
import { decryptData } from '../../../utils/localStorageEncodeDecode';

const { Panel } = Collapse;


const AwaitingLogTab = ({ payload,startMonth }) => {
  const [stateAmgr, setStateAmgr] = useState()
  const [stateHeadlist, setStateHeadlist] = useState()
  const [zonalHeadList, setZonalHeadList] = useState();
  const [nSHList, setNSHList] = useState();
  const {userRole,userId} = decryptData(sessionStorage.getItem("userInfo"));

  const getAmDropdownData = () => {
    if(userRole == 'SADM'){
      axiosAPI
      .post(`SchemeGenerate/GetDropdownAM`, { userId })
      .then((result) => {
        console.log(result,"resultresult")
        setStateAmgr(result.data.data)
      });
    }
    if(userRole == 'ZADM'){
      axiosAPI
      .post(`SchemeGenerate/GetDropdownStateHead`, {userId})
      .then((result) => {
        setStateHeadlist(result.data.data)
      });
    }
    if(userRole == 'NSH' || userRole == 'PMIS'){
      axiosAPI
      .post(`SchemeGenerate/GetzonalHead`)
      .then((result) => {
        setZonalHeadList(result.data.data)
      });
    }
  }

  useEffect(() => {
    getAmDropdownData()
  }, [])

  return (
    <>
      <Space
      className="collapse-items approval-scheme"
        direction="vertical"
        size="large"
      >
        {userRole == 'SADM' && stateAmgr?.map((item, key) => (
          <Collapse>

            <Panel size="large" header={item.aM} key={key}>
              <AwaitingLogTable payload={payload} amgrId={item.id} startMonth={startMonth}/>
            </Panel>
          </Collapse>
        ))}
        {userRole == 'ZADM' && stateHeadlist?.map((item, key) => (
          <Collapse >
            <Panel size="large" header={item.stateHeadName} key={key}>
              <AwaitingLogTable payload={payload} stateHeadId={item.id} startMonth={startMonth}/>
            </Panel>
          </Collapse>
        ))}
        {(userRole == 'NSH' || userRole == 'PMIS') && zonalHeadList?.map((item, key) => (
          <Collapse >
            <Panel size="large" header={item.zonalHeadName} key={key}>
              <AwaitingLogTable payload={payload} zonalHeadId={item.id} startMonth={startMonth}/>
            </Panel>
          </Collapse>
        ))}

      </Space>
    </>
  )
}

export default AwaitingLogTab
