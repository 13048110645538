import React, { useEffect, useMemo, useState } from "react";
import "../../collection/createSchemes/createScheme.css";
import {
  Button,
  Table,
  Divider,
  Col,
  Row,
  Form,
  Modal,
  message,
  Select,
  Dropdown,
  Menu,
  Space
} from "antd";
import ThemeButton from "../../../components/button/buttons";

import { LeftOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { schemeList } from "../../../redux/collection/SchemeList";
import dayjs from "dayjs";
import XLSX from 'xlsx-js-style';
import SMDialog from "../../../components/modal/alertModal/CommonModal";
import StatusModal from "../../../components/modal/alertModal/StatusModal";
import axiosAPI from "../../../services/axiosApi";
import { getCurrentFiscalYear, getCurrentFiscalYear1 } from "../../../utils/dateFormatter";
import moment from "moment";
import ToastifyShow from "../../../components/ToastifyShow";
import {
  GetFinancialInputAsync,
  resetFinance,
  GetYTDValueForFinanceInputAsync,
} from "../../../redux/stateData/stateDataSlice";
import { useFormik } from "formik";
import { decryptData } from "../../../utils/localStorageEncodeDecode";

const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  schemeType: "",
  dealerCode: [],
  startDate: "",
  endDate: "",
};

const initVal = {
  stateId: "",
  priceSupport: "",
  btl: "",
  totalAmt: "",
  month: "",
  totalQty: "",
  instutionalQty: "",
  netInstitutionalQty: "",
  collIncentive: "",
  installation: "",
  collIncentivePerTractor: "",
  installationPerTractor: "",
  priceSupportPerTractor: "",
  btlPerTractor: "",
  totalPerTractor: "",
  ytD_PriceSupport: "",
  ytD_BTL: "",
  ytD_TotalAmt: "",
  ytD_Month: "",
  ytD_TotalQty: "",
  ytD_InstutionalQty: "",
  ytD_NetInstitutionalQty: "",
  ytD_CollIncentive: "",
  ytD_Installation: "",
  ytD_CollIncentivePerTractor: "",
  ytD_InstallationPerTractor: "",
  ytD_PriceSupportPerTractor: "",
  ytD_BTLPerTractor: "",
  ytD_TotalPerTractor: "",
};

const EditableContext = React.createContext(null);

const GenerateFinanceReport = ({ type = "new", editType = "create" }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [value, setValue] = useState([initVal]);
  const [addValues, setAddValues] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [financeOpt, setFinanceOpt] = useState([])
  const [optionArr, setOptionArr] = useState([])
  const [checkCalculateArr, setCheckCalculateArr] = useState([])
  const [checkCalculate, setCheckCalculate] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: [],
  });
  const { Option } = Select;


  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const currentFiscalYear = getCurrentFiscalYear1(collectionData?.startDate||new Date());
  const { schemeType, startDate, endDate, dealerCode } = collectionData;

  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const userId = userData.userId;
  const userName = userData.userName;
  const [stateList, setStateList] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const { finance, loadingState, FinanceYtdValues } = useSelector(
    (state) => state.stateData
  );

  useEffect(() => {
    axiosAPI.post(`SchemeGenerate/GetState`).then((result) => {
      const data = result.data.data;

      setStateList(data);
    });
  }, []);
  useEffect(() => {
    if (
      loadingState == "succeeded" &&
      finance?.errorMessage === "Exception while generating finance record!!"
    ) {
      setIsModalOpen(true);
      setValue([initVal]);

      dispatch(resetFinance());
    }
  }, [finance]);

  const generatedSchemeData = useSelector(
    (state) => state.generatedScheme.data
  );

  const draftSchemeData = useSelector((state) => state.draftScheme.data);

  const approvalSchemeData = useSelector((state) => state.approvalScheme.data);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getGeneratedData = () => {
    if (type !== "edit") return;

    if (editType === "create") return generatedSchemeData;
    else if (editType === "draft") return draftSchemeData;
    else return approvalSchemeData;
  };

  const generatedData = getGeneratedData();

  useEffect(() => {
    let finalData = [];

    if (editType === "create" && type === "edit") {
      const schemeId = location?.state?.schemeId;

      if (schemeId) {
        finalData = generatedSchemeData.filter((data) =>
          schemeId.includes(data.schemeId)
        );
      }
    } else {
      finalData = generatedData;
    }

    if (finalData?.length > 0) {
      const newData = finalData.map((item, index) => {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");

        return {
          status: item.status,
          key: item.schemeId,
          scheme: item,
          code: item.dealerCode,
          name: item.dealerName,
          Location: item.dealerLocation,
          state: item.stateName,
          Manager: item.territoryManager,
          StartMonth: formattedStartDate,
          EndMonth: formattedEndDate,
          target: "",
          nov21: null,
          dec21: null,
          BooksCollectionTargetT1: item.t1,
          BooksCollectionTargetT2: item.t2,
          BooksCollectionTargetT2T1: item.t2_T1,
          BooksCollectionTargetT3: item.t3,
          BooksCollectionTargetT3T2: item.t3_T2,
          PayoutRatep1: item.r1,
          PayoutRatep2: item.r2,
          PayoutRatep3: item.r3,
          payoutR1: item.p1,
          payoutR2: item.p2,
          payoutR3: item.p3,
          reason: item.reason,
          TEPvol: item.volume,
          TEPpertr: item.perTr,
          TEPamount: item.amount,
        };
      });

      dispatch(schemeList(newData));
    }
  }, [generatedData]);


  const handleSaveModal = () => {
    setOpenSaveModal(!openSaveModal);
  };
  const handleSuccessModal = () => {
    setOpenSuccessModal(!openSuccessModal);
  };
  const handleErrorModal = (firstItem, secondItem, x) => {
    setOpenErrorModal(x);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };
  const handleSelectModal = () => {
    setOpenSelectModal(!openSelectModal);
  };
  const handleDraftModal = () => {
    setOpenDraftModal(!openDraftModal);
  };
  // Columns for table

  const listData = useSelector((state) => state.schemeList.data);

  const numberFiniteCheck = (x) => isFinite(x) || x != NaN ? x : 0

  const calculateFormula = (index) => {
    let {
      totalQty,
      instutionalQty,
      collIncentive,
      installation,
      netInstitutionalQty,
      totalAmt,
      btl,
      ytD_InstallationPerTractor1,
      ytD_CollIncentivePerTractor1,
      ytD_PriceSupportPerTractor1,
      ytD_BTLPerTractor1,
      ytD_TotalPerTractor1,
      priceSupport,
      ytD_PriceSupport1,
      ytD_InstutionalQty1,
      ytD_NetInstitutionalQty,
      ytD_NetInstitutionalQty1,
      ytD_TotalQty1,
      ytD_CollIncentive,
      ytD_CollIncentive1,
      ytD_Installation1,
      ytD_BTL1,
      ytD_TotalAmt1
    } = formik?.values[index];

    let y = formik?.values[index];

    if (
      totalQty != null &&
      (instutionalQty !== null || instutionalQty !== undefined)
    ) {
      netInstitutionalQty = totalQty ? totalQty - instutionalQty : 0;
    } else {
      handleErrorModal("Total Qty", "Institutional Qty", true);
    }
    let ytdcalculation = (x, y) => (
      numberFiniteCheck(Math.round(parseInt(x || 0) /
        parseInt(y || 0)) || 0)
    )

    let totalAmtDetails =
      +collIncentive + +installation + +priceSupport + +btl || 0;
    let total_Per_TractorDetails =
      Math.round(parseInt(totalAmtDetails) / parseInt(netInstitutionalQty)) || 0;

    let Installation_per_tractor = Math.round(parseInt(installation) / parseInt(netInstitutionalQty)) || 0
    let price_support_per_tractor = Math.round(parseInt(priceSupport) / parseInt(netInstitutionalQty)) || 0
    let collincentive1 = (parseInt(collIncentive || 0) + parseInt(ytD_CollIncentive1 || 0))
    let netInstitueQty1 = (parseInt(netInstitutionalQty || 0) + parseInt(ytD_NetInstitutionalQty1 || 0))
    let installation1 = (parseInt(installation || 0) + parseInt(ytD_Installation1 || 0)) || 0
    let pricesupport1 = (parseInt(priceSupport || 0) + parseInt(ytD_PriceSupport1 || 0)) || 0
    let btl1 = (parseInt(btl || 0) + parseInt(ytD_BTL1 || 0)) || 0

    let total1 = (parseInt(parseInt(collIncentive || 0) + parseInt(installation || 0) + parseInt(priceSupport || 0) + parseInt(btl)) || 0) + (parseInt(ytD_TotalAmt1 || 0) || 0)
    formik?.setFieldValue(index, {
      ...y,
      netInstitutionalQty: +parseInt(totalQty) - +parseInt(instutionalQty) || 0,
      totalAmt: totalAmtDetails || 0,
      collIncentivePerTractor:
        Math.round(parseInt(collIncentive) / parseInt(netInstitutionalQty)) || 0,
      installationPerTractor: Installation_per_tractor,
      priceSupportPerTractor: price_support_per_tractor,
      btlPerTractor: Math.round(parseInt(btl) / parseInt(netInstitutionalQty)) || 0,
      totalPerTractor: total_Per_TractorDetails,
      ytD_TotalQty: parseInt(totalQty) + parseInt(ytD_TotalQty1) || 0,
      ytD_InstutionalQty: parseInt(instutionalQty) + parseInt(ytD_InstutionalQty1) || 0,
      ytD_NetInstitutionalQty: netInstitueQty1 || 0,
      ytD_CollIncentive: collincentive1 || 0,
      ytD_Installation: (parseInt(installation) + parseInt(ytD_Installation1)) || ytD_Installation1 || 0,
      ytD_PriceSupport: (parseInt(priceSupport) + parseInt(ytD_PriceSupport1)) || ytD_PriceSupport1 || 0,
      ytD_BTL: (parseInt(btl) + parseInt(ytD_BTL1)) || ytD_BTL1 || 0,
      ytD_TotalAmt:
        ((parseInt(parseInt(collIncentive || 0) + parseInt(installation || 0) + parseInt(priceSupport || 0) + parseInt(btl || 0)) || 0) + (parseInt(ytD_TotalAmt1)) || ytD_TotalAmt1 || 0),
      ytD_CollIncentivePerTractor: ytdcalculation(collincentive1, netInstitueQty1) || 0,
      ytD_InstallationPerTractor: ytdcalculation(installation1, netInstitueQty1) || 0,
      ytD_PriceSupportPerTractor: ytdcalculation(pricesupport1, netInstitueQty1) || 0,
      ytD_BTLPerTractor: ytdcalculation(btl1, netInstitueQty1) || 0,
      ytD_TotalPerTractor: ytdcalculation(total1, netInstitueQty1) || 0,
    });

  };

  const defaultColumns = useMemo(() => [
    {
      title: "State",
      dataIndex: "stateName",
      fixed: "left",
      width: "100px",
    },
    {
      title: "",
      dataIndex: "",
      editable: true,
      children: [
        {
          title: "Qty",
          dataIndex: "",
          editable: true,
          children: [
            {
              title: "Total Billing",
              dataIndex: "totalQty",
              width: "150px",
              editable: true,
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.totalQty}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.totalQty}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  onChange={(e) => {
                    // // denugger;
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      totalQty: value,
                    });
                  }}
                  className="input1"
                  style={{ width: "120px", padding: "5px" }}
                />
              ),
            },
            {
              title: "Institutional Qty",
              dataIndex: "instutionalQty",
              width: "150px",
              editable: true,
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.instutionalQty}
                  placeholder="Type here"
                  value={formik?.values?.[index]?.instutionalQty}
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  className="input1"
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      instutionalQty: value,
                    });
                  }}
                  style={{ width: "120px", padding: "5px" }}
                />
              ),
            },
            {
              title: "Net of Institutional Qty",
              dataIndex: "netInstitutionalQty",
              width: "250px",
              render: (x, y, index) => x || 0,
            },
          ],
        },
        {
          title: "Amt",
          dataIndex: "",
          editable: true,
          children: [
            {
              title: "Coll Incentive",
              dataIndex: "collIncentive",
              width: "150px",
              editable: true,
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.collIncentive}
                  placeholder="Type here"
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  className="input1"
                  value={formik?.values?.[index]?.collIncentive}
                  onChange={(e) => {
                    formik.setFieldValue(index, {
                      ...y,
                      collIncentive: e.target?.value.replace(/[^\d]/g, ""),
                    });
                  }}
                  style={{ width: "120px", padding: "5px" }}
                />
              ),
            },
            {
              title: "Installation",
              dataIndex: "installation",
              width: "150px",
              editable: true,
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.installation}
                  placeholder="Type here"
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  className="input1"
                  value={formik?.values?.[index]?.installation}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      installation: value,
                    });
                  }}
                  style={{ width: "140px", padding: "5px" }}
                />
              ),
            },
            {
              title: "Price support",
              dataIndex: "priceSupport",
              width: "150px",
              editable: true,
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.priceSupport}
                  placeholder="Type here"
                  className="input1"
                  onBlur={() => {
                    calculateFormula(index);
                  }}
                  value={formik?.values?.[index]?.priceSupport}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      priceSupport: value,
                    });
                  }}
                  style={{ width: "140px", padding: "5px" }}
                />
              ),
            },
            {
              title: "BTL",
              dataIndex: "btl",
              width: "100px",
              editable: true,
              render: (x, y, index) => (
                <input
                  name={formik?.values?.[index]?.btl}
                  placeholder="Type here"
                  onBlur={() => calculateFormula(index)}
                  className="input1"
                  value={formik.values?.[index]?.btl}
                  onChange={(e) => {
                    let value = e.target?.value.replace(/[^\d]/g, "");
                    formik.setFieldValue(index, {
                      ...y,
                      btl: value,
                    });
                  }}
                  style={{ width: "90px", padding: "5px" }}
                />
              ),
            },
            {
              title: "Total",
              dataIndex: "totalAmt",
              width: "100px",
              render: (x) => x || 0,
            },
          ],
        },
        {
          title: "Amt Per Tractor",
          dataIndex: "",
          children: [
            {
              title: "Coll Incentive",
              dataIndex: "collIncentivePerTractor",
              width: "150px",
              render: (x) => x || 0,
            },
            {
              title: "Installation",
              dataIndex: "installationPerTractor",
              width: "150px",
              render: (x) => x || 0,
            },
            {
              title: "Price support",
              dataIndex: "priceSupportPerTractor",
              width: "150px",
              render: (x) => x || 0,
            },
            {
              title: "BTL",
              dataIndex: "btlPerTractor",
              width: "100px",
              render: (x) => x || 0,
            },
            {
              title: "Total",
              dataIndex: "totalPerTractor",
              width: "100px",
              render: (x) => x || 0,
            },
          ],
        },
      ],
    },
    {
      title: "YTD ",
      dataIndex: "",
      children: [
        {
          title: "Qty",
          dataIndex: "",
          children: [
            {
              title: "Total",
              dataIndex: "ytD_TotalQty",
              width: "100px",
              render: (x) => x || 0,
            },
            {
              title: "Institutional Qty",
              dataIndex: "ytD_InstutionalQty",
              width: "150px",
              render: (x) => x || 0,
            },
            {
              title: "Net of Institutional Qty",
              dataIndex: "ytD_NetInstitutionalQty",
              width: "250px",
              render: (x) => x || 0,
            },
          ],
        },
        {
          title: "Amt",
          dataIndex: "",
          editable: true,
          children: [
            {
              title: "Coll Incentive",
              dataIndex: "ytD_CollIncentive",
              width: "150px",
              render: (x) => x || 0,
            },
            {
              title: "Installation",
              dataIndex: "ytD_Installation",
              width: "150px",
              render: (x) => x || 0,
            },
            {
              title: "Price support",
              dataIndex: "ytD_PriceSupport",
              width: "150px",
              render: (x) => x || 0,
            },
            {
              title: "BTL",
              dataIndex: "ytD_BTL",
              width: "100px",
              render: (x) => x || 0,
            },
            {
              title: "Total",
              dataIndex: "ytD_TotalAmt",
              width: "100px",
              render: (x) => x || 0,
            },
          ],
        },
        {
          title: "Amt Per Tractor",
          dataIndex: "",

          children: [
            {
              title: "Coll Incentive",
              dataIndex: "ytD_CollIncentivePerTractor",
              width: "150px",
              render: (x) => x || 0,
            },
            {
              title: "Installation",
              dataIndex: "ytD_InstallationPerTractor",
              width: "150px",
              render: (x) => x || 0,
            },
            {
              title: "Price support",
              dataIndex: "ytD_PriceSupportPerTractor",
              width: "150px",
              render: (x) => x || 0,
            },
            {
              title: "BTL",
              dataIndex: "ytD_BTLPerTractor",
              width: "100px",
              render: (x) => x || 0,
            },
            {
              title: "Total",
              dataIndex: "ytD_TotalPerTractor",
              width: "100px",
              render: (x) => x || 0,
            },
          ],
        },
      ],
    },
  ], [formik?.values])

  const handleSave = (event, dataIndex, row, form) => {
    let valueList = [...value];

    const valueIndex = valueList.findIndex(
      (item) => item?.stateID === row.stateID
    );
    valueList[valueIndex] = {
      ...valueList[valueIndex],
      [dataIndex]: event.target.value,
    };

    setValue(valueList);

    valueList
      .filter((item) => selectedRowKeys.includes(item.stateID))
      .map((list) => {
        // calculate(list);
      });
  };
  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }
    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };

  const columns = defaultColumns.map((col) => {
    return mapColumns(col);
  });

  const isAnyEmpty = (collectionData) =>
    Object.values(collectionData).every((value) => {
      if (value == "" || value == []) {
        return false;
      }
      return true;
    });


  const handleConfirmationModal = async (confirm = false) => {
    setOpenConfirmationModal((openConfirmationModal) => !openConfirmationModal);
    if (confirm === true) {
      submitData();
    }
  };

  const stateMonthData = async () => {


    const postData = {
      fyear: currentFiscalYear,
      // startMonth: startDate,
      month: parseInt(moment(collectionData?.startDate)?.format("MM")),
      stateName: "",
      username: userName,
      userid: userId,
      // isExist: true
    };

    await setFinanceOpt([])
    await dispatch(GetFinancialInputAsync(postData)).then((data)=>{
      console.log(data?.payload?.data,"datadata1223")
      if(Array?.isArray(data?.payload?.data)&&data?.payload?.data?.length){
      setFinanceOpt([{ value1: "all", label: "All States" }].concat(data?.payload?.data))
      }
    })

  };

  const submitData = (e) => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const finalData = [];
    let checkValidation = formik?.values?.filter(
      (x) => !x?.Target || !x?.IPT || !x?.reason
    );

    //// denugger;

    let errorDetails = Object.entries(
      checkValidation?.[0] ? checkValidation?.[0] : {}
    )
      ?.filter(
        (x, index) =>
          !x[1] &&
          (x[0] === "totalQty" ||
            x[0] === "instutionalQty" ||
            x[0] === "installation")
      )
      ?.map((x) =>
        x[0] === "totalQty"
          ? "totalQty"
          : x[0] === "instutionalQty"
            ? "instutionalQty"
            : x[0] === "installation"
              ? "installation"
              : ""
      );

    let postData = [];

    formik?.values.forEach((row) => {

      postData.push({
        fyear: currentFiscalYear,
        month: parseInt(moment(collectionData?.startDate)?.format("MM")),
        ytD_Month: 0,
        startMonth: collectionData?.startDate,
        endMonth: collectionData?.startDate,
        username: userName,
        userid: userId,
        collIncentive: row?.collIncentive ? parseInt(row?.collIncentive) : 0,
        btl: row.btl,
        installation: row.installation ? parseInt(row.installation) : 0,
        instutionalQty: row.instutionalQty ? parseInt(row.instutionalQty) : 0,
        priceSupport: row.priceSupport ? parseInt(row.priceSupport) : 0,
        stateId: row.stateID ? parseInt(row.stateID) : 0,
        totalQty: row.totalQty ? parseInt(row.totalQty) : 0,
        stateName: row?.stateName || 0,
        totalAmt: row?.totalAmt || 0,
        netInstitutionalQty: row?.netInstitutionalQty || 0,
        collIncentivePerTractor: row?.collIncentivePerTractor || 0,
        installationPerTractor: row?.installationPerTractor || 0,
        priceSupportPerTractor: row?.priceSupportPerTractor || 0,
        btlPerTractor: row?.btlPerTractor || 0,
        totalPerTractor: row?.totalPerTractor || 0,
        ytD_PriceSupport: row?.ytD_PriceSupport || 0,
        ytD_BTL: row?.ytD_BTL || 0,
        ytD_TotalAmt: row?.ytD_TotalAmt || 0,
        ytD_Month: row?.ytD_Month || 0,
        ytD_TotalPerTractor: row?.ytD_TotalPerTractor || 0,
        ytD_BTLPerTractor: row?.ytD_BTLPerTractor || 0,
        ytD_PriceSupportPerTractor: row?.ytD_PriceSupportPerTractor || 0,
        ytD_InstallationPerTractor: row?.ytD_InstallationPerTractor || 0,
        ytD_CollIncentivePerTractor: row?.ytD_CollIncentivePerTractor || 0,
        ytD_Installation: row?.ytD_Installation || 0,
        ytD_CollIncentive: row?.ytD_CollIncentive || 0,
        ytD_NetInstitutionalQty: row?.ytD_NetInstitutionalQty || 0,
        ytD_InstutionalQty: row?.ytD_InstutionalQty || 0,
        ytD_TotalQty: row?.ytD_TotalQty || 0,
      });
    });

    axiosAPI
      .post(
        `FinanceDashboard/FinanceGenerate`,
        // [payload]
        postData
      )
      .then(async (result) => {
        if (result.status == 200) {
          await message.success({
            content: "Finance record successfully generated",
            className: "custom-message",
          });
          navigate(`/dashboard`);
        }
        // setDataSource(result.data.data)
      })
      .catch((error) => {

      });
  };


  const hasSelected = selectedRowKeys?.length > 0;

  const getTitle = () => {
    switch (editType) {
      case "draft":
        return "Edit Draft";
      case "schemeProgress":
        return "Edit Scheme Progress";
      case "create":
        return "Edit";
      default:
        return "";
    }
  };

  const getBackPath = () => {
    if (editType === "draft") return "/collection/draftSchemes";
    else if (editType === "schemeProgress")
      return "/schemeProgress";
    else return "/collection/allSchemes";
  };

  const handleSelect = async (item, option) => {

    const stateValues = option.find((item) => item.value === "all");

    if (stateValues && stateValues.value === "all") {

      let filterStateId = stateList.map((state) => state.stateID)
      const month = parseInt(moment(collectionData?.startDate)?.format("MM"));


      // Fetch data from the API for all selected states
      const ytdpayload = {
        fyear: currentFiscalYear,
        userid: userId,
        month: month,
        monthEnd: parseInt(moment(collectionData?.startDate)?.format("MM")),
        stateId: filterStateId, // stateID is an array
      };

      const response = await dispatch(
        GetYTDValueForFinanceInputAsync(ytdpayload)
      );

      const groupBy = (arr, key) => {
        const initialValue = {};
        return arr?.reduce((acc, cval) => {
          const myAttribute = cval[key];
          acc[myAttribute] = [...(acc[myAttribute] || []), cval];
          return acc;
        }, initialValue);
      };

      let stateWiseData = groupBy(response?.payload?.data, "stateId");

      let valueArr = [];

      stateList
        ?.filter((x) => filterStateId?.includes(x?.stateID))
        ?.map((x) => {
          if (stateWiseData[x?.stateID]?.length != 0) {
            const fieldSum = {
              ytD_TotalQty: 0,
              ytD_InstutionalQty: 0,
              ytD_NetInstitutionalQty: 0,
              ytD_CollIncentive: 0,
              ytD_Installation: 0,
              ytD_PriceSupport: 0,
              ytD_BTL: 0,
              ytD_TotalAmt: 0,
              ytD_CollIncentivePerTractor1: 0,
              ytD_InstallationPerTractor1: 0,
              ytD_PriceSupportPerTractor1: 0,
              ytD_BTLPerTractor1: 0,
              ytD_TotalPerTractor1: 0,

              ytD_TotalAmt1: 0,
              ytD_BTL1: 0,
              ytD_PriceSupport1: 0,
              ytD_Installation1: 0,
              ytD_CollIncentive1: 0,
              ytD_NetInstitutionalQty1: 0,
              ytD_InstutionalQty1: 0,
              ytD_TotalQty1: 0,


            };

            stateWiseData?.[x?.stateID]?.forEach(
              ({
                yTD_InstutionalQty,
                yTD_TotalQty,
                yTD_PriceSupport,
                yTD_TotalAmt,
                yTD_CollIncentivePerTractor,
                yTD_InstallationPerTractor,
                yTD_PriceSupportPerTractor,
                yTD_BTLPerTractor,
                yTD_TotalPerTractor,
                yTD_BTL,
                yTD_Installation,
                yTD_CollIncentive,
                yTD_NetInstitutionalQty,


              }) => {
                fieldSum.ytD_TotalQty1 = parseInt(yTD_TotalQty) || 0;
                fieldSum.ytD_InstutionalQty1 =
                  parseInt(yTD_InstutionalQty) || 0;
                fieldSum.ytD_NetInstitutionalQty1 =
                  parseInt(yTD_NetInstitutionalQty) || 0;
                fieldSum.ytD_CollIncentive1 = parseInt(yTD_CollIncentive) || 0;

                fieldSum.ytD_Installation1 = parseInt(yTD_Installation) || 0;
                fieldSum.ytD_PriceSupport1 = parseInt(yTD_PriceSupport) || 0;
                fieldSum.ytD_BTL1 = parseInt(yTD_BTL) || 0;
                fieldSum.ytD_TotalAmt1 = parseInt(yTD_TotalAmt) || 0;

                fieldSum.ytD_TotalQty = parseInt(yTD_TotalQty) || 0;
                fieldSum.ytD_InstutionalQty =
                  parseInt(yTD_InstutionalQty) || 0;
                fieldSum.ytD_NetInstitutionalQty =
                  parseInt(yTD_NetInstitutionalQty) || 0;
                fieldSum.ytD_CollIncentive = parseInt(yTD_CollIncentive) || 0;
                fieldSum.ytD_Installation = parseInt(yTD_Installation) || 0;
                fieldSum.ytD_PriceSupport = parseInt(yTD_PriceSupport) || 0;
                fieldSum.ytD_BTL = parseInt(yTD_BTL) || 0;
                fieldSum.ytD_TotalAmt = parseInt(yTD_TotalAmt) || 0;

                fieldSum.ytD_CollIncentivePerTractor1 =
                  parseInt(yTD_CollIncentivePerTractor) || 0;
                fieldSum.ytD_InstallationPerTractor1 =
                  parseInt(yTD_InstallationPerTractor) || 0;
                fieldSum.ytD_PriceSupportPerTractor1 =
                  parseInt(yTD_PriceSupportPerTractor) || 0;
                fieldSum.ytD_BTLPerTractor1 = parseInt(yTD_BTLPerTractor) || 0;
                fieldSum.ytD_TotalPerTractor1 =
                  parseInt(yTD_TotalPerTractor) || 0;

                fieldSum.ytD_CollIncentivePerTractor =
                  parseInt(yTD_CollIncentivePerTractor) || 0;
                fieldSum.ytD_InstallationPerTractor =
                  parseInt(yTD_InstallationPerTractor) || 0;
                fieldSum.ytD_PriceSupportPerTractor =
                  parseInt(yTD_PriceSupportPerTractor) || 0;
                fieldSum.ytD_BTLPerTractor = parseInt(yTD_BTLPerTractor) || 0;
                fieldSum.ytD_TotalPerTractor =
                  parseInt(yTD_TotalPerTractor) || 0;


              }
            );
            valueArr.push({ ...fieldSum, ...x });
          } else {
            valueArr.push({ ...x, ...initVal });
          }
        });

      if (valueArr?.length != 0) {

        formik?.setValues(valueArr);
      } else {
        formik?.setValues([]);
      }
    } else {
      const filterStateList = stateList?.filter((states) =>
        item.includes(states.stateName)
      );
      let filterStateId = filterStateList?.map((state) => state.stateID)
      const month = parseInt(moment(collectionData?.startDate)?.format("MM"));

      // Fetch data from the API for all selected states
      const ytdpayload = {
        fyear: currentFiscalYear,
        userid: userId,
        month: month,
        monthEnd: parseInt(moment(collectionData?.startDate)?.format("MM")),
        stateId: filterStateId, // stateID is an array
      };

      const response = await dispatch(
        GetYTDValueForFinanceInputAsync(ytdpayload)
      );

      const groupBy = (arr, key) => {
        const initialValue = {};
        return arr?.reduce((acc, cval) => {
          const myAttribute = cval[key];
          acc[myAttribute] = [...(acc[myAttribute] || []), cval];
          return acc;
        }, initialValue);
      };

      let stateWiseData = groupBy(response?.payload?.data, "stateId");

      let valueArr = [];

      stateList
        ?.filter((x) => filterStateId?.includes(x?.stateID))
        ?.map((x) => {
          if (stateWiseData[x?.stateID]?.length != 0) {
            const fieldSum = {
              ytD_TotalQty: 0,
              ytD_InstutionalQty: 0,
              ytD_NetInstitutionalQty: 0,
              ytD_CollIncentive: 0,
              ytD_Installation: 0,
              ytD_PriceSupport: 0,
              ytD_BTL: 0,
              ytD_TotalAmt: 0,
              ytD_CollIncentivePerTractor1: 0,
              ytD_InstallationPerTractor1: 0,
              ytD_PriceSupportPerTractor1: 0,
              ytD_BTLPerTractor1: 0,
              ytD_TotalPerTractor1: 0,

              ytD_TotalAmt1: 0,
              ytD_BTL1: 0,
              ytD_PriceSupport1: 0,
              ytD_Installation1: 0,
              ytD_CollIncentive1: 0,
              ytD_NetInstitutionalQty1: 0,
              ytD_InstutionalQty1: 0,
              ytD_TotalQty1: 0,


            };
           
            stateWiseData?.[x?.stateID]?.forEach(
              ({
                yTD_InstutionalQty,
                yTD_TotalQty,
                yTD_PriceSupport,
                yTD_TotalAmt,
                yTD_CollIncentivePerTractor,
                yTD_InstallationPerTractor,
                yTD_PriceSupportPerTractor,
                yTD_BTLPerTractor,
                yTD_TotalPerTractor,
                yTD_BTL,
                yTD_Installation,
                yTD_CollIncentive,
                yTD_NetInstitutionalQty,


              }) => {
                fieldSum.ytD_TotalQty1 = parseInt(yTD_TotalQty) || 0;
                fieldSum.ytD_InstutionalQty1 =
                  parseInt(yTD_InstutionalQty) || 0;
                fieldSum.ytD_NetInstitutionalQty1 =
                  parseInt(yTD_NetInstitutionalQty) || 0;
                fieldSum.ytD_CollIncentive1 = parseInt(yTD_CollIncentive) || 0;

                fieldSum.ytD_Installation1 = parseInt(yTD_Installation) || 0;
                fieldSum.ytD_PriceSupport1 = parseInt(yTD_PriceSupport) || 0;
                fieldSum.ytD_BTL1 = parseInt(yTD_BTL) || 0;
                fieldSum.ytD_TotalAmt1 = parseInt(yTD_TotalAmt) || 0;

                fieldSum.ytD_TotalQty = parseInt(yTD_TotalQty) || 0;
                fieldSum.ytD_InstutionalQty =
                  parseInt(yTD_InstutionalQty) || 0;
                fieldSum.ytD_NetInstitutionalQty =
                  parseInt(yTD_NetInstitutionalQty) || 0;
                fieldSum.ytD_CollIncentive = parseInt(yTD_CollIncentive) || 0;
                fieldSum.ytD_Installation = parseInt(yTD_Installation) || 0;
                fieldSum.ytD_PriceSupport = parseInt(yTD_PriceSupport) || 0;
                fieldSum.ytD_BTL = parseInt(yTD_BTL) || 0;
                fieldSum.ytD_TotalAmt = parseInt(yTD_TotalAmt) || 0;

                fieldSum.ytD_CollIncentivePerTractor1 =
                  parseInt(yTD_CollIncentivePerTractor) || 0;
                fieldSum.ytD_InstallationPerTractor1 =
                  parseInt(yTD_InstallationPerTractor) || 0;
                fieldSum.ytD_PriceSupportPerTractor1 =
                  parseInt(yTD_PriceSupportPerTractor) || 0;
                fieldSum.ytD_BTLPerTractor1 = parseInt(yTD_BTLPerTractor) || 0;
                fieldSum.ytD_TotalPerTractor1 =
                  parseInt(yTD_TotalPerTractor) || 0;

                fieldSum.ytD_CollIncentivePerTractor =
                  parseInt(yTD_CollIncentivePerTractor) || 0;
                fieldSum.ytD_InstallationPerTractor =
                  parseInt(yTD_InstallationPerTractor) || 0;
                fieldSum.ytD_PriceSupportPerTractor =
                  parseInt(yTD_PriceSupportPerTractor) || 0;
                fieldSum.ytD_BTLPerTractor = parseInt(yTD_BTLPerTractor) || 0;
                fieldSum.ytD_TotalPerTractor =
                  parseInt(yTD_TotalPerTractor) || 0;

              }
            );
            valueArr.push({ ...fieldSum, ...x });
          } else {
            valueArr.push({ ...x, ...initVal });
          }
        });

      if (valueArr?.length != 0) {

        formik?.setValues(valueArr);
      } else {
        formik?.setValues([]);
      }
    }
  };

  useEffect(() => {
    if (collectionData?.startDate&&currentFiscalYear) {
      stateMonthData();
      setAddValues([])
      formik?.setValues([])
    }
  }, [collectionData?.startDate]);
  useEffect(() => {
    if (addValues?.length != 0) {
      handleSelect(addValues, optionArr)
    }
  }, [addValues])
  useEffect(() => {
    if (finance?.length != 0) {
      setFinanceOpt([{ value1: "all", label: "All States" }].concat(finance))
    }
  }, [finance])

  const handleSampleFile = () => {

    const headerStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" } // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12"
      }, border: {
        top: { style: 'thin', color: { rgb: "000000" } },
        bottom: { style: 'thin', color: { rgb: "000000" } },
        left: { style: 'thin', color: { rgb: "000000" } },
        right: { style: 'thin', color: { rgb: "000000" } },
        diagonal: { diagonalUp: true, diagonalDown: true }
      },
      alignment: {
        vertical: "center",
        horizontal: "center"
      }
    };

    let tab_text = `<table class="custom-table">
    <thead >
    <tr>
    <th rowspan="3">STATE</th>
       <th colspan="6">YTD</th>
      </tr>
    <tr >
        <th colspan="2">QTY</th>
        <th colspan="4">AMT</th>
    </tr>
    <tr>
       <th>TOTAL BILLING</th>
       <th>INSTITUTIONAL QTY</th>
       <th>COLL INCENTIVE</th>
       <th>INSTALLATION</th>
       <th>PRICE SUPPORT</th>
       <th>BTL</th>
      </tr>
    </thead>`;

    {
      (formik?.values?.length != 0) &&
        formik?.values.forEach((header) => {
          tab_text += `<tr ><td>${header?.stateName}</td>
    <td>${header?.totalQty || ""}</td>
    <td>${header?.instutionalQty || ""}</td>
    <td>${header?.collIncentive || ""}</td>
    <td>${header?.installation || ""}</td>
    <td>${header?.priceSupport || ""}</td>
    <td>${header?.btl || ""}</td>
    </tr>`;
        });
    }
    tab_text += "</table>";

    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);

    // Apply styles to the table using a class
    elt.getElementsByClassName("custom-table")[0].style.width = "100%";

    // generate workbook with XLSXStyle
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });

    let arr1 = ["A1", "B1", "B2", "B3", "C3", "D2", "D3", "E3", "F3", "G3"]
    arr1?.forEach((x) => {
      wb.Sheets[wb.SheetNames[0]][x]["s"] = headerStyle;
    })

    // Dynamically set column widths
    wb.Sheets[wb.SheetNames[0]]['!cols'] = [
      { wch: 10 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];

    // Dynamically set row heights
    wb.Sheets[wb.SheetNames[0]]['!rows'] = [
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },

      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      { hpx: 20 },
      // Add more row heights as needed
    ];

    // remove element
    document.body.removeChild(elt);
    let getTodayDate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    let filename = ("generateFinance" + getTodayDate + `.xlsx`)
    XLSX.writeFile(wb, filename);
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };
  const handleFileUpload = (e) => {
    const maxFileSize = 1 * 1024 * 1024; // Set maximum file size to 1MB
    const files = e.target.files;
  
    // Check if any file exceeds the maximum file size limit
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxFileSize) {
        ToastifyShow(`File ${files[i].name} is too large. Maximum file size is 1MB.`, "error");
        return;
      }
  
      // Validate file extension
      if (files[0].name.split('.')?.[1] !== 'xlsx') {
        ToastifyShow("Please upload an XLSX file only.", "error");
        return;
      }
  
      // Check file headers for XLSX files
      if (!isValidFileHeader(files[i])) {
        ToastifyShow(`Invalid file header for ${files[i].name}.`, "error");
        return;
      }
  
      // Check content type for XLSX files
      if (!isValidContentType(files[i])) {
        ToastifyShow(`Invalid content type for ${files[i].name}.`, "error");
        return;
      }
  
      // Check magic numbers for XLSX files
      if (!isValidMagicNumber(files[i])) {
        ToastifyShow(`Invalid magic number for ${files[i].name}.`, "error");
        return;
      }
    }
  
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      let calArr = [];
  
      let dataArr = formik?.values?.map((y, index) => {
        if (parsedData.find((x) => x["STATE"] === y?.stateName)) {
          let z = parsedData.find((x) => x["STATE"] === y?.stateName);
          calArr.push(index);
          return {
            ...y,
            totalQty: parseInt(z?.YTD) || 0,
            instutionalQty: parseInt(z?.__EMPTY) || 0,
            collIncentive: parseInt(z?.__EMPTY_1) || 0,
            installation: parseInt(z?.__EMPTY_2) || 0,
            priceSupport: parseInt(z?.__EMPTY_3) || 0,
            btl: parseInt(z?.__EMPTY_4) || 0,
          };
        } else {
          return y;
        }
      });
      setCheckCalculate(true);
      setCheckCalculateArr(calArr);
      formik?.setValues(dataArr);
      ToastifyShow("File uploaded successfully", "success")
    };
  };
  
  // Check if file header is valid for XLSX files
  const isValidFileHeader = (file) => {
    const validHeaders = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    return validHeaders.includes(file.type);
  };
  
  // Check if content type is valid for XLSX files
  const isValidContentType = (file) => {
    const validContentTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    return validContentTypes.includes(file.type);
  };
  
  // Get file signature (magic number) for XLSX files

  
  // Check if magic number is valid for XLSX files
  const isValidMagicNumber = (file) => {
    // For XLSX files, we do not typically check for magic numbers.
    // Instead, we rely on file extension and content type validation.
    return true;
  };
  

  useEffect(() => {
    if (checkCalculate) {
      checkCalculateArr?.forEach((x) => calculateFormula(x));
      setCheckCalculate(false);
    }
  }, [checkCalculate]);
  const menu = (
    <Menu>
      <Menu.Item>
        {selectedFiles?.length == 0 &&
          <div className="custom-file-input"><input type="file" accept=".xlsx" onChange={handleFileUpload} />
            <label htmlFor="fileInput">Choose File</label>
          </div>
        }
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>
              {file.name}
              <Button onClick={() => handleRemoveFile(index)} className="mx-1">Remove</Button>
            </li>
          ))}
        </ul>
      </Menu.Item>
      <Menu.Item onClick={handleSampleFile}>Download Sample File</Menu.Item>
    </Menu>
  );

  return (
    <div className="create_scheme_wrap general-bscheme">
      <div className="table_scheme_wrap generate-finace-report">
        <Divider style={{ marginBottom: "10px" }} />
        <Row
          className="finance-edit-col"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Col style={{ display: "flex", alignItems: "center" }}>
            {type === "edit" && (
              <Link to={getBackPath()} style={{ color: "#000" }}>
                <LeftOutlined style={{ fontSize: "14px" }} />
              </Link>
            )}

            <h2 style={{ fontSize: "18px" }}>
              {`${type === "new" ? "Finance" : getTitle()} Input`}
            </h2>
          </Col>

          {type === "new" && (
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                padding: "20px 0px",
                flexDirection: "row",
              }}
            >
              <ThemeButton
                showDealer={false}
                showSchemeType={false}
                showStartMonth={true}
                showEndMonth={false}
                collectionData={collectionData}
                listData={listData}
                pageType="Finance"
                // currentMonthRestriction={true}
                disableDateRestriction={true}
                handleStartChange={(date) => {
                  setCollectionData({
                    ...collectionData,
                    startDate: date == null ? "" : date.format(),
                  });
                }}
              />
              <Select
                showSearch
                mode="multiple"
                // allowClear
                className="select_state"
                placeholder="Select State"
                maxTagCount="responsive"
                optionFilterProp="children"
                allowClear
                value={addValues}
                onChange={(val, item) => {
                  setAddValues(val)
                  setOptionArr(item)
                }}
              >

                {Array.isArray(financeOpt)
                  ? financeOpt?.map((option, index) => (
                    <Option
                      key={option?.stateID}
                      value={option?.value1 || option?.stateName}
                      disabled={option?.isExist}
                    >
                      {option?.value1 ? option?.label : option?.stateName}
                    </Option>
                  ))
                  : null}
              </Select>


                <Dropdown overlay={menu} >
                  <Button className="h-2" style={{
                    borderRadius: "0"
                  }}>Import/Export to Excel</Button>
                </Dropdown>
            
            </Col>
          )}
        </Row>
        <span>
          {hasSelected ? `Selected ${selectedRowKeys?.length} items` : ""}
        </span>
        <Form>
          <Table
            // title={tableHeader}
            className="finance"
            // components={components}
            rowSelection={{
              selectedRowKeys,
              onChange: (newSelectedRowKeys) => {
                setSelectedRowKeys(newSelectedRowKeys);
              },
            }}
            summary={(pageData) => {
              const fieldSum = {
                btl: 0,
                btlPerTractor: 0,
                collIncentive: 0,
                collIncentivePerTractor: 0,
                installation: 0,
                installationPerTractor: 0,
                instutionalQty: 0,
                month: 0,
                netInstitutionalQty: 0,
                priceSupport: 0,
                priceSupportPerTractor: 0,
                totalAmt: 0,
                totalPerTractor: 0,
                totalQty: 0,
                ytD_BTL: 0,
                ytD_BTLPerTractor: 0,
                ytD_CollIncentive: 0,
                ytD_CollIncentivePerTractor: 0,
                ytD_Installation: 0,
                ytD_InstallationPerTractor: 0,
                ytD_InstutionalQty: 0,
                ytD_Month: 0,
                ytD_NetInstitutionalQty: 0,
                ytD_PriceSupport: 0,
                ytD_PriceSupportPerTractor: 0,
                ytD_TotalAmt: 0,
                ytD_TotalPerTractor: 0,
                ytD_TotalQty: 0,
              };

              pageData.forEach(
                ({
                  netInstitutionalQty,
                  btlPerTractor,
                  totalQty,
                  collIncentivePerTractor,
                  installationPerTractor,
                  priceSupportPerTractor,
                  totalAmt,
                  ytD_InstutionalQty,
                  instutionalQty,
                  totalPerTractor,
                  ytD_BTL,
                  ytD_NetInstitutionalQty,
                  ytD_BTLPerTractor,
                  ytD_CollIncentive,
                  ytD_CollIncentivePerTractor,
                  ytD_Installation,
                  ytD_InstallationPerTractor,
                  ytD_PriceSupport,
                  ytD_PriceSupportPerTractor,
                  ytD_TotalAmt,
                  ytD_TotalPerTractor,
                  ytD_TotalQty,
                  installation,
                  priceSupport,
                  collIncentive,
                  btl,
                }) => {

                  fieldSum.totalAmt += totalAmt ? parseInt(totalAmt) : 0;
                  fieldSum.totalQty += totalQty ? parseInt(totalQty) : 0;
                  fieldSum.instutionalQty += instutionalQty
                    ? parseInt(instutionalQty)
                    : 0;
                  fieldSum.btl += btl ? parseInt(btl) : 0;
                  fieldSum.collIncentive += collIncentive
                    ? parseInt(collIncentive)
                    : 0;
                  fieldSum.installation += installation
                    ? parseInt(installation)
                    : 0;
                  fieldSum.priceSupport += priceSupport
                    ? parseInt(priceSupport)
                    : 0;
                  fieldSum.netInstitutionalQty += netInstitutionalQty
                    ? parseInt(netInstitutionalQty)
                    : 0;
                  fieldSum.collIncentivePerTractor += collIncentivePerTractor
                    ? parseInt(collIncentivePerTractor)
                    : 0;
                  fieldSum.installationPerTractor += installationPerTractor
                    ? parseInt(installationPerTractor)
                    : 0;
                  fieldSum.priceSupportPerTractor += priceSupportPerTractor
                    ? parseInt(priceSupportPerTractor)
                    : 0;
                  fieldSum.ytD_InstutionalQty += ytD_InstutionalQty
                    ? parseInt(ytD_InstutionalQty)
                    : 0;
                  fieldSum.ytD_NetInstitutionalQty += ytD_NetInstitutionalQty
                    ? parseInt(ytD_NetInstitutionalQty)
                    : 0;
                  fieldSum.btlPerTractor += btlPerTractor
                    ? parseInt(btlPerTractor)
                    : 0;
                  fieldSum.totalPerTractor += totalPerTractor
                    ? parseInt(totalPerTractor)
                    : 0;
                  fieldSum.ytD_TotalQty += parseInt(ytD_TotalQty);
                  fieldSum.ytD_CollIncentive += ytD_CollIncentive
                    ? parseInt(ytD_CollIncentive)
                    : 0;
                  fieldSum.ytD_Installation += ytD_Installation
                    ? parseInt(ytD_Installation)
                    : 0;
                  fieldSum.ytD_PriceSupport += ytD_PriceSupport
                    ? parseInt(ytD_PriceSupport)
                    : 0;
                  fieldSum.ytD_BTL += ytD_BTL ? parseInt(ytD_BTL) : 0;
                  fieldSum.ytD_TotalAmt += ytD_TotalAmt
                    ? parseInt(ytD_TotalAmt)
                    : 0;
                  fieldSum.ytD_CollIncentivePerTractor +=
                    ytD_CollIncentivePerTractor
                      ? parseInt(ytD_CollIncentivePerTractor)
                      : 0;
                  fieldSum.ytD_InstallationPerTractor +=
                    ytD_InstallationPerTractor
                      ? parseInt(ytD_InstallationPerTractor)
                      : 0;
                  fieldSum.ytD_PriceSupportPerTractor +=
                    ytD_PriceSupportPerTractor
                      ? parseInt(ytD_PriceSupportPerTractor)
                      : 0;
                  fieldSum.ytD_BTLPerTractor += ytD_BTLPerTractor
                    ? parseInt(ytD_BTLPerTractor)
                    : 0;
                  fieldSum.ytD_TotalPerTractor += ytD_TotalPerTractor
                    ? parseInt(ytD_TotalPerTractor)
                    : 0;
                }
              );

              return (
                <Table.Summary.Row
                  style={{ fontWeight: "500", fontSize: "18px" }}
                >
                  <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>Grand Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    {fieldSum?.totalQty ? fieldSum?.totalQty : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    {fieldSum?.instutionalQty ? fieldSum.instutionalQty : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    {fieldSum?.netInstitutionalQty
                      ? fieldSum?.netInstitutionalQty
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    {fieldSum?.collIncentive ? fieldSum?.collIncentive : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    {fieldSum?.installation ? fieldSum?.installation : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    {fieldSum?.priceSupport ? fieldSum?.priceSupport : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    {fieldSum.btl ? fieldSum.btl : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>
                    {fieldSum?.totalAmt ? fieldSum?.totalAmt : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>
                    {fieldSum?.collIncentivePerTractor
                      ? Math.round(
                        fieldSum?.collIncentive /
                        fieldSum?.netInstitutionalQty
                      )
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11}>
                    {fieldSum?.installationPerTractor
                      ? Math.round(
                        fieldSum?.installation / fieldSum?.netInstitutionalQty
                      )
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12}>
                    {fieldSum?.priceSupportPerTractor
                      ? Math.round(
                        fieldSum?.priceSupport / fieldSum?.netInstitutionalQty
                      )
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={13}>
                    {fieldSum?.btlPerTractor
                      ? Math.round(
                        fieldSum?.btl / fieldSum?.netInstitutionalQty
                      )
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={14}>
                    {fieldSum?.totalPerTractor
                      ? Math.round(
                        fieldSum?.totalAmt / fieldSum?.netInstitutionalQty
                      )
                      : ""}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={15}>
                    {fieldSum?.ytD_TotalQty || ""}

                    {/* { "bkjbkb"} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={16}>
                    {fieldSum?.ytD_InstutionalQty || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={17}>
                    {fieldSum?.ytD_NetInstitutionalQty || ""}
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={18}>{fieldSum?.ytD_TotalQty}</Table.Summary.Cell> */}
                  <Table.Summary.Cell index={19}>
                    {fieldSum?.ytD_CollIncentive || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={20}>
                    {fieldSum?.ytD_Installation || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={21}>
                    {fieldSum?.ytD_PriceSupport || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={22}>
                    {fieldSum?.ytD_BTL || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={23}>
                    {fieldSum?.ytD_TotalAmt || ""}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={24}>
                    {Math.round(fieldSum.ytD_CollIncentive / fieldSum.ytD_NetInstitutionalQty)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={25}>
                    {Math.round(fieldSum.ytD_Installation / fieldSum.ytD_NetInstitutionalQty
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={26}>
                    {Math.round(fieldSum?.ytD_PriceSupport / fieldSum.ytD_NetInstitutionalQty)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={27}>
                    {Math.round(fieldSum?.ytD_BTL / fieldSum.ytD_NetInstitutionalQty)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={28}>
                    {Math.round(fieldSum?.ytD_TotalAmt / fieldSum.ytD_NetInstitutionalQty)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
            loading={loadingState == "pending" ? true : false}
            rowClassName={() => "editable-row"}
            columns={columns}
            pagination={false}
            rowKey={"stateID"}
            dataSource={formik?.values}
            bordered
            scroll={{
              x: 1000,
              y: 300,
            }}
            size="middle"
          />
        </Form>
        <Row
          className="draft-button-wrap"
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Col>
            <Button
              type="default"
              shape="round"
              style={{
                margin: "10px",
                background: "#FCE838",
                fontWeight: "700",
                textTransform: "uppercase",
                border: "none",
                padding: "20px",
              }}
              size="large"
              onClick={handleConfirmationModal}
            >
              Save & Submit
            </Button>
          </Col>
        </Row>
        {(isAnyEmpty(collectionData) && [2, 1].includes(schemeType)) ||
          (type === "edit" && listData?.length > 0) ? (
          <Row
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Col>
              <Button
                type="default"
                shape="round"
                style={{
                  margin: "10px",
                  background: "#FCE838",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  border: "none",
                  padding: "20px",
                }}
                size="large"
                onClick={handleConfirmationModal}
              // disabled={listData.every((item) => item.nos == "0")}
              >
                Save & Submit
              </Button>
            </Col>
          </Row>
        ) : null}


        <SMDialog open={openSaveModal} onCancel={handleSaveModal}>
          <p className="custom-warning">Warning !</p>
          <p>Please fill the required values.</p>
        </SMDialog>

        <SMDialog open={openSuccessModal} onCancel={handleSuccessModal}>
          <p className="custom-warning">Success</p>
          <p>
            Scheme {type === "edit" ? "Updated" : "Generated"} Successfully{" "}
          </p>
        </SMDialog>

        <SMDialog open={openSuccessModal} onCancel={handleSuccessModal}>
          <p className="custom-warning">Success</p>
          <p>
            Scheme {type === "edit" ? "Updated" : "Generated"} Successfully{" "}
          </p>
        </SMDialog>

        <SMDialog open={openDraftModal} onCancel={handleDraftModal}>
          <p className="custom-warning">Success</p>
          <p> Scheme Draft Successfully</p>
        </SMDialog>

        <SMDialog open={openDraftModal} onCancel={handleDraftModal}>
          <p className="custom-warning">Success</p>
          <p> Scheme Saved Successfully</p>
        </SMDialog>
        <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
          <p className="custom-warning">Warning !</p>

          <p>
            {" "}
            {errorItem.firstItem} should be greater then {errorItem.secondItem}
          </p>
        </SMDialog>

        <SMDialog open={openSelectModal} onCancel={handleSelectModal}>
          <p className="custom-warning">Warning !</p>
          <p>Please Select The Dealer</p>
        </SMDialog>

        <StatusModal
          open={openConfirmationModal}
          onOk={() => handleConfirmationModal(true)}
          onCancel={() => handleConfirmationModal(false)}
        >
          <p>Are you sure you want to save</p>
        </StatusModal>

        <Modal open={isModalOpen}        maskClosable={false}
        destroyOnClose={true} onOk={handleOk} onCancel={handleCancel}>
          <p>Data Already Exits</p>
        </Modal>
      </div>
    </div>
  );
};
export default GenerateFinanceReport;
