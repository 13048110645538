import React, { useState ,useEffect } from "react";
import { Divider } from "antd";

import InstallationCollapseTab from "./InstallationColapseTab";
import InsatallationSchemeHeader from "./InstallationHeader";
import "./InstallationAllScheme.css";
import dayjs from "dayjs";
import { getFinancialYearStartYear } from "../../../utils/dateFormatter";


const initPayloadData = {
    schemeType: 7,
    // state: 1,
    // userid: 0,
    month: new Date().getMonth() + 1,
    monthEnd: new Date().getMonth() + 1,
};

const InstallationAllSchemes = () => {
    const [startMonth, setStartMonth] = useState(dayjs());
    const [payload, setPayload] = useState(initPayloadData);
    const [dealerDetails, setDealerDetails] = useState({
        code: "",
        location: "",
      });
      const fyYear=getFinancialYearStartYear()
      const [dateValue, setDateValue] = useState(fyYear);
      useEffect(() => {
        setDateValue(fyYear);
      }, [fyYear]);

    return (
        <>
            <div className='create_scheme_wrap collection-schme-box'>
                <Divider />
                <div>
                    <div className=" collection_header" >
                        <div className="schemes">
                            All Schemes
                        </div>
                        <InsatallationSchemeHeader
                            onChangeFilter={(key, val) =>
                                setPayload({ ...payload, [key]: val })
                            }
                            dateValue={dateValue}
                            setDateValue={setDateValue}
                            startMonth={startMonth} setStartMonth={setStartMonth}
                            showEnforcedMonth={true}
                            setDealerDetails={setDealerDetails}
                            dealerDetails={dealerDetails}
                            showFilter={true}
                        />
                    </div>
                </div>
                <InstallationCollapseTab payload={payload}   dateValue={dateValue} startMonth={startMonth} dealerDetails={dealerDetails}/>
            </div>
            <div className="All_scheme_status" style={{ marginRight: '220px', marginTop: '20px' }} >
                <div className="Schemes_status">
                    <div className="Status_color" style={{ backgroundColor: "green" }}></div>
                    Approved
                </div>
                <div className="Schemes_status">
                    <div className="Status_color" style={{ backgroundColor: "red" }}></div>
                    Rejected
                </div>
                <div className="Schemes_status">
                    <div className="Status_color" style={{ backgroundColor: "yellow" }}></div>
                    Work in Progress
                </div>
                <div className="Schemes_status">
                    <div className="Status_color" style={{ backgroundColor: "rgb(255, 0, 255)" }}></div>
                    Auto Rejected
                </div>
                <div className="Schemes_status">
                    <div className="Status_color" style={{ backgroundColor: "#BCB38D" }}></div>
                    Deviation
                </div>
                <div className="Schemes_status">
                    <div className="Status_color" style={{ backgroundColor: "#9999ff" }}></div>
                    Hold
                </div>
            </div>
        </>
    )
}
export default InstallationAllSchemes;
