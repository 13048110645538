import { useState, useRef } from "react";
import { Collapse, Space, Button } from "antd";
import { useNavigate } from "react-router-dom";
import AllSchemeTable from "./AllSchemeTable";
import Status from "../../../components/allSchemeStatus/allSchemeStatus";
import CollectionFilter from "../../../components/installation/collectionFilter";

const { Panel } = Collapse;

const CollapseTab = ({ payload, startMonth, dealerDetails, dateValue }) => {
  const navigate = useNavigate();
  const [enable, setEnable] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedSchemeType, setSelectedSchemeType] = useState(1);
  const childref = useRef();

  const handleEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/collection/editScheme");
  };
  const actionValues = [
    {
      key: "1",
      label: (
        <Button
          className="link"
          type="link"
          onClick={(e) => handleEditClick(e)}
        >
          Edit
        </Button>
      ),
    },
  ];

  const [items, setItems] = useState(
    actionValues.filter((item) => item.key !== "1")
  );

  const getSelectionCheck = (selectedRowKeys, dataSource) => {
    if (selectedRowKeys.length <= 1) {
      setItems(items.filter((item) => item.key !== "1"));
    } else {
      setItems(actionValues);
    }
  };

  const defaultActiveKey = ["1", "2"];

  const fetchGeneratedScheme = (demoParams) => {
    setSelectedSchemeType(demoParams.schemeType);
  };
  return (
    <>
      <Space
        className="collapse-items collapse-box-cell-table"
        direction="vertical"
        size="large"
      >
        <Collapse
          className="all_scheme_collapse"
          defaultActiveKey={defaultActiveKey}
        >
          <Panel
            className="panel_heading"
            size="large"
            header="Incentive Collection "
            key="1"
          >
            <div className="allSchmeStatus d-flex justify-content-between">
              <div>
                <Status
                  selectedStatus={selectedStatus}
                  handleStatusDropdown={(statusId) =>
                    setSelectedStatus(statusId)
                  }
                />
              </div>
              <div>
                <CollectionFilter onGetData={fetchGeneratedScheme} />

                <Button
                  className="mx-1"
                  onClick={() => childref.current.click()}
                >
                  Download
                </Button>
              </div>
            </div>
            <AllSchemeTable
              selectedStatus={selectedStatus}
              selectedSchemeType={selectedSchemeType}
              payload={payload}
              dateValue={dateValue}
              startMonth={startMonth}
              getSelectionCheck={getSelectionCheck}
              reference={childref}
              setEnable={setEnable}
              enable={enable}
              dealerDetails={dealerDetails}
            />
          </Panel>
        </Collapse>
      </Space>
    </>
  );
};
export default CollapseTab;
