import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Modal, Input ,Form} from 'antd';

const { TextArea } = Input;

const HoldModal = React.memo(({
  confirmationModal,
  setConfirmationModal,
  handleConformationModal,
  editIndex,
  selectedHold,
  modalType,
  selectedRowKeys,
  selectedId,
  formik,
  setSelectedId,
  handleCancel,
}) => {
    const [form] = Form.useForm();
// const [message,setMessage]=useState("");


  const titleText = useMemo(
    () =>
      selectedHold
        ? "Do you want to hold the selected scheme? Please enter the Remarks for the selected Scheme"
        : "Do you want to hold this scheme? Please enter the Remarks",
    [selectedHold, modalType]
  );

  const titleTextUnhold = useMemo(
    () =>
      selectedHold
        ? "Do you want to Unhold the selected scheme?"
        : "Do you want to Unhold this scheme?",
    [selectedHold, modalType]
  );
  
  useEffect(()=>{
    if(formik?.values.records[editIndex||0]){
      const comments = formik?.values.records[editIndex || 0]?.comments || "";
      form.setFieldsValue({ message: comments }); 
    }
  },[formik?.values.records[editIndex||0]])


  const handleFormSubmit = async () => {

    try {
      const values = await form.validateFields(); 
      // console.log(values.message,"message")
      handleConformationModal(values.message); 
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo); 
    }
  };
  return (
    <Modal
      title="Confirmation"
      open={confirmationModal}
      onCancel={handleFormSubmit}
      onOk={handleCancel}
       cancelText="Yes"
        okText="No"      
          maskClosable={false}
        destroyOnClose={true}

     
    >
      <p>{modalType === "hold" ? titleText : titleTextUnhold}</p>
      {modalType === "hold" && (
         <Form form={form} layout="vertical" preserve={false}>
         <Form.Item
           name="message"
           rules={[
             { required: true, message: "Remarks are required!" },
             { max: 100, message: "Remarks cannot exceed 100 characters!" },
           ]}
         >
        <TextArea
          rows={4}
          placeholder="Type here"
          maxLength={100}
          // onChange={handleChange}
          // value={message||""}
         
        />
         </Form.Item>
        </Form>
      )}
    </Modal>
  );
})

export default HoldModal;
