import { Button, Table, Tag, Tooltip } from "antd";
import {
  amountCheckIN,
  checkDisableText,
  columnWidth,
  columnWidth120,
  columnWidth150,
  DummyTableInstallationSummary,
  columnWidth80,
  TableSummaryInput,
  columnWidth180,
} from "../widthTable";

import dayjs from "dayjs";

export const summaryInstallationViewOnly = (tableData, pageType) => {
  if (tableData?.[0]?.schemeTypeId != 3) {
    const {
      actual_Installation_Last_Year_Month,
      installationTarget,
      incPerTarget,
      p1,
      upperCap,
      deviationAmount,
      deviationQuantity,
    } = tableData.reduce(
      (
        sum,
        {
          installationTarget,
          actual_Installation_Last_Year_Month,
          incPerTarget,
          p1,
          upperCap,
          deviationAmount,
          deviationQuantity,
        }
      ) => ({
        installationTarget:
          sum.installationTarget +
          (installationTarget ? installationTarget : 0),
        incPerTarget:
          sum.incPerTarget + (incPerTarget ? parseInt(incPerTarget) : 0),
        actual_Installation_Last_Year_Month:
          sum.actual_Installation_Last_Year_Month +
          (actual_Installation_Last_Year_Month
            ? parseInt(actual_Installation_Last_Year_Month)
            : 0),
        p1: sum.p1 + (p1 ? parseInt(p1) : 0),
        upperCap: sum.upperCap + (upperCap ? parseInt(upperCap) : 0),
        deviationAmount:
          sum.deviationAmount +
          (deviationAmount ? parseInt(deviationAmount) : 0),
        deviationQuantity:
          sum.deviationQuantity +
          (deviationQuantity ? parseInt(deviationQuantity) : 0),
      }),
      {
        installationTarget: 0,
        actual_Installation_Last_Year_Month: 0,
        incPerTarget: 0,
        p1: 0,
        upperCap: 0,
        deviationAmount: 0,
        deviationQuantity: 0,
      }
    );

    if (tableData?.[0]?.schemeTypeId == 8) {
      const deviationSummary = () => {
        return pageType == "deviation log" ? (
          <>
            <TableSummaryInput index={12} data={""} />
            <TableSummaryInput index={13} data={deviationAmount} />
            <TableSummaryInput index={14} data={deviationQuantity} />
            <TableSummaryInput index={15} data={""} />
          </>
        ) : pageType == "deviation request" ? (
          <>
            <Table.Summary.Cell index={12}></Table.Summary.Cell>
            <Table.Summary.Cell index={13}></Table.Summary.Cell>
            <Table.Summary.Cell index={14}></Table.Summary.Cell>
          </>
        ) : (
          <>
            <Table.Summary.Cell index={12}></Table.Summary.Cell>
          </>
        );
      };
      return (
        <Table.Summary.Row>
          <DummyTableInstallationSummary />
          <TableSummaryInput
            index={8}
            data={actual_Installation_Last_Year_Month}
          />
          <TableSummaryInput index={9} data={installationTarget} />
          <TableSummaryInput index={10} data={incPerTarget} />
          <TableSummaryInput index={11} data={p1} />
          {deviationSummary()}
        </Table.Summary.Row>
      );
    } else if (tableData?.[0]?.schemeTypeId == 4) {
      const deviationSummary = () => {
        return pageType == "deviation log" ? (
          <>
            <TableSummaryInput index={13} data={""} />
            <TableSummaryInput index={14} data={deviationAmount} />
            <TableSummaryInput index={15} data={deviationQuantity} />
            <TableSummaryInput index={16} data={""} />
          </>
        ) : pageType == "deviation request" ? (
          <>
            <Table.Summary.Cell index={13}></Table.Summary.Cell>
            <Table.Summary.Cell index={14}></Table.Summary.Cell>
            <Table.Summary.Cell index={15}></Table.Summary.Cell>
          </>
        ) : (
          <>
            <Table.Summary.Cell index={13}></Table.Summary.Cell>
          </>
        );
      };
      return (
        <Table.Summary.Row>
          <DummyTableInstallationSummary />
          <TableSummaryInput
            index={8}
            data={actual_Installation_Last_Year_Month}
          />
          <TableSummaryInput index={9} data={installationTarget} />
          <TableSummaryInput index={10} data={upperCap} />
          <TableSummaryInput index={11} data={incPerTarget} />
          <TableSummaryInput index={12} data={p1} />
          {deviationSummary()}
        </Table.Summary.Row>
      );
    } else if (tableData?.[0]?.schemeTypeId == 5) {
      const deviationSummary = () => {
        return pageType == "deviation log" ? (
          <>
            <TableSummaryInput index={12} data={""} />
            <TableSummaryInput index={13} data={deviationAmount} />
            <TableSummaryInput index={14} data={deviationQuantity} />
            <TableSummaryInput index={15} data={""} />
          </>
        ) : pageType == "deviation request" ? (
          <>
            <Table.Summary.Cell index={12}></Table.Summary.Cell>
            <Table.Summary.Cell index={13}></Table.Summary.Cell>
            <Table.Summary.Cell index={14}></Table.Summary.Cell>
          </>
        ) : (
          <>
            <Table.Summary.Cell index={12}></Table.Summary.Cell>
          </>
        );
      };
      return (
        <Table.Summary.Row>
          <DummyTableInstallationSummary />
          <TableSummaryInput
            index={8}
            data={actual_Installation_Last_Year_Month}
          />
          <TableSummaryInput index={9} data={installationTarget} />
          <TableSummaryInput index={10} data={incPerTarget} />
          <TableSummaryInput index={11} data={p1} />
          {deviationSummary()}
        </Table.Summary.Row>
      );
    }
  } else {
    const {
      actual_Installation_Last_Year_Month,
      t1,
      t2,
      t3,
      r1,
      r2,
      r3,
      p1,
      p2,
      p3,
      deviationAmount,
      deviationQuantity,
    } = tableData.reduce(
      (
        sum,
        {
          actual_Installation_Last_Year_Month,
          topTarget,
          t1,
          t2,
          t3,
          r1,
          r2,
          r3,
          p1,
          p2,
          p3,
          deviationAmount,
          deviationQuantity,
        }
      ) => ({
        actual_Installation_Last_Year_Month:
          sum.actual_Installation_Last_Year_Month +
          (actual_Installation_Last_Year_Month
            ? parseInt(actual_Installation_Last_Year_Month)
            : 0),
        t1: sum.t1 + (t1 && topTarget?.includes("t1") ? parseInt(t1) : 0),
        t2: sum.t2 + (t2 && topTarget?.includes("t2") ? parseInt(t2) : 0),
        t3: sum.t3 + (t3 && topTarget?.includes("t3") ? parseInt(t3) : 0),
        p1: sum.p1 + (p1 && topTarget?.includes("t1") ? parseInt(p1) : 0),
        p2: sum.p2 + (p2 && topTarget?.includes("t2") ? parseInt(p2) : 0),
        p3: sum.p3 + (p3 && topTarget?.includes("t3") ? parseInt(p3) : 0),
        r1: sum.r1 + (r1 && topTarget?.includes("t1") ? parseInt(r1) : 0),
        r2: sum.r2 + (r2 && topTarget?.includes("t2") ? parseInt(r2) : 0),
        r3: sum.r3 + (r3 && topTarget?.includes("t3") ? parseInt(r3) : 0),
        deviationAmount:
          sum.deviationAmount +
          (deviationAmount ? parseInt(deviationAmount) : 0),
        deviationQuantity:
          sum.deviationQuantity +
          (deviationQuantity ? parseInt(deviationQuantity) : 0),
      }),
      {
        actual_Installation_Last_Year_Month: 0,
        t1: 0,
        t2: 0,
        t3: 0,
        r1: 0,
        r2: 0,
        r3: 0,
        p1: 0,
        p2: 0,
        p3: 0,
        deviationAmount: 0,
        deviationQuantity: 0,
      }
    );

    const deviationSummary = () => {
      return pageType == "deviation log" ? (
        <>
          <TableSummaryInput index={18} data={""} />
          <TableSummaryInput index={19} data={deviationAmount} />
          <TableSummaryInput index={20} data={deviationQuantity} />
          <TableSummaryInput index={21} data={""} />
        </>
      ) : pageType == "deviation request" ? (
        <>
          <Table.Summary.Cell index={18}></Table.Summary.Cell>
          <Table.Summary.Cell index={19}></Table.Summary.Cell>
          <Table.Summary.Cell index={20}></Table.Summary.Cell>
        </>
      ) : (
        <>
          <Table.Summary.Cell index={18}></Table.Summary.Cell>
        </>
      );
    };
    return (
      <Table.Summary.Row>
        <DummyTableInstallationSummary />
        <TableSummaryInput
          index={8}
          data={actual_Installation_Last_Year_Month}
        />
        <TableSummaryInput index={9} data={t1 || ""} />
        <TableSummaryInput index={10} data={t2 || ""} />
        <TableSummaryInput index={11} data={t3 || ""} />
        <TableSummaryInput index={12} data={r1 || ""} />
        <TableSummaryInput index={13} data={r2 || ""} />
        <TableSummaryInput index={14} data={r3 || ""} />
        <TableSummaryInput index={15} data={p1 || ""} />
        <TableSummaryInput index={16} data={p2 || ""} />
        <TableSummaryInput index={17} data={p3 || ""} />
        {deviationSummary()}
      </Table.Summary.Row>
    );
  }
};
export const tableInstallationViewOnly = (props) => {
  const {
    showCreated,
    showCreatedBy,
    showCreatedDate,
    showRemark,
    selectedSchemeType,
    showTopSlab,
    type,
    handleBtn,
    disableSort,
    showHoldComment,
  } = props;
  const getCreatedName = () =>
    showCreatedBy
      ? [
          {
            title: (
              <div>
                CREATED <br /> BY
              </div>
            ),
            dataIndex: "createdBy",
            ...columnWidth150,
            defaultSortOrder: "ascend",
            key: "createdBy",
            ellipsis: false,
          },
        ]
      : [];
  const deviationAction = () =>
    type == "deviation request"
      ? [
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            ...columnWidth80,
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            render: (currentRecord, showRecord) => (
              <>
                <Button type="primary" onClick={() => handleBtn()}>
                  Hold Scheme
                </Button>
              </>
            ),
          },
        ]
      : [];
  const getCols = (selectedSchemeType) =>
    selectedSchemeType == 3
      ? [
          {
            title: "Targets",
            dataIndex: "TEP",
            textAlign: "center",
            children: [
              {
                title: "T1",
                dataIndex: "t1",

                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "t1",
                ...columnWidth80,
                render: (value, data) =>
                  checkDisableText(
                    value,
                    data?.topTarget?.split(",")?.includes("t1")
                  ),
              },
              {
                title: "T2",
                dataIndex: "t2",
                ...columnWidth80,

                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "t2",
                render: (value, data) =>
                  checkDisableText(
                    value,
                    data?.topTarget?.split(",")?.includes("t2")
                  ),
              },
              {
                title: "T3",
                dataIndex: "t3",
                ...columnWidth80,

                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "t3",
                render: (value, data) =>
                  checkDisableText(
                    value,
                    data?.topTarget?.split(",")?.includes("t3")
                  ),
              },
            ],
          },
          {
            title: "INC. PER TRACTOR",
            children: [
              {
                title: "R1",
                dataIndex: "r1",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "r1",
                ...columnWidth80,
                render: (value, data) =>
                  checkDisableText(
                    value,
                    data?.topTarget?.split(",")?.includes("t1")
                  ),
              },
              {
                title: "R2",
                dataIndex: "r2",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "r2",
                ...columnWidth80,
                render: (value, data) =>
                  checkDisableText(
                    value,
                    data?.topTarget?.split(",")?.includes("t2")
                  ),
              },
              {
                title: "R3",
                dataIndex: "r3",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "r3",
                ...columnWidth80,
                render: (value, data) =>
                  checkDisableText(
                    value,
                    data?.topTarget?.split(",")?.includes("t3")
                  ),
              },
            ],
          },
          {
            title: "PAYOUT RATE",
            children: [
              {
                title: "P1",
                dataIndex: "p1",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                defaultSortOrder: "ascend",
                key: "p1",
                ...columnWidth80,
                render: (value, data) =>
                  checkDisableText(
                    value,
                    data?.topTarget?.split(",")?.includes("t1")
                  ),
              },
              {
                title: "P2",
                dataIndex: "p2",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },

                key: "p2",
                ...columnWidth80,
                render: (value, data) =>
                  checkDisableText(
                    value,
                    data?.topTarget?.split(",")?.includes("t2")
                  ),
              },
              {
                title: "P3",
                dataIndex: "p3",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },

                key: "p3",
                ...columnWidth80,
                render: (value, data) =>
                  checkDisableText(
                    value,
                    data?.topTarget?.split(",")?.includes("t3")
                  ),
              },
            ],
          },
        ]
      : selectedSchemeType == 8
      ? [
          {
            title: "INSTALLATION TARGET",
            dataIndex: "installationTarget",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "installationTarget",
            ...columnWidth80,
            render: (data) => amountCheckIN(data),
          },
          {
            title: "INC. PER TRACTOR",
            dataIndex: "incPerTarget",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "incPerTarget",
            ...columnWidth80,
            render: (data) => amountCheckIN(data),
          },
          {
            title: "PAYOUT",
            dataIndex: "p1",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "p1",
            ...columnWidth80,
            render: (data) => amountCheckIN(data),
          },
        ]
      : selectedSchemeType == 4
      ? [
          {
            title: "INSTALLATION TARGET",
            dataIndex: "installationTarget",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "installationTarget",
            ...columnWidth80,
            render: (data) => amountCheckIN(data),
          },
          {
            title: "UPPER CAP",
            dataIndex: "upperCap",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "upperCap",
            ...columnWidth80,
            render: (data) => amountCheckIN(data),
          },
          {
            title: "INC. PER TRACTOR",
            dataIndex: "incPerTarget",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "incPerTarget",
            ...columnWidth80,
            render: (data) => amountCheckIN(data),
          },
          {
            title: "PAYOUT",
            dataIndex: "p1",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "p1",
            ...columnWidth80,
            render: (data) => amountCheckIN(data),
          },
        ]
      : selectedSchemeType == 5
      ? [
          {
            title: "INSTALLATION EXPECTED",
            dataIndex: "installationTarget",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "installationTarget",
            ...columnWidth80,
            render: (data) => amountCheckIN(data),
          },
          {
            title: "INC. PER TRACTOR",
            dataIndex: "incPerTarget",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "incPerTarget",
            ...columnWidth80,
            render: (data) => amountCheckIN(data),
          },
          {
            title: "PAYOUT",
            dataIndex: "p1",
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "p1",
            ...columnWidth80,
            render: (data) => amountCheckIN(data),
          },
        ]
      : [];
  const getCreatedDate = () =>
    showCreatedDate
      ? [
          {
            title: (
              <div>
                Created <br />
                Date
              </div>
            ),
            dataIndex: "createdDate",
            ...columnWidth80,
            sorter: disableSort ? false : true,
            align: "center",
            ellipsis: {
              showTitle: false,
            },
            defaultSortOrder: "ascend",
            key: "createdDate",
            render: (data) => <Tooltip title={data}>{data}</Tooltip>,
          },
        ]
      : [];
  const getTopSlab = () =>
    showTopSlab || selectedSchemeType == 3
      ? [
          {
            title: (
              <div>
                TOP <br /> SLAB
              </div>
            ),
            dataIndex: "topTarget",
            ...columnWidth80,
            defaultSortOrder: "ascend",
            key: "topTarget",
            render: (data) => {
              const selectedTopSlab =
                data?.split(",")[data?.split(",")?.length - 1]?.toUpperCase() ||
                "t1"?.toUpperCase();
              return (
                <Tooltip
                  title={<div className="uppercase"> {selectedTopSlab}</div>}
                  className="uppercase"
                >
                  {selectedTopSlab}
                </Tooltip>
              );
            },
          },
        ]
      : [];
  const getRemarks = () =>
    showHoldComment
      ? [
          {
            title: "COMMENTS",
            children: [
              {
                title: "REMARKS",
                align: "center",
                dataIndex: "reason",
                ellipsis: {
                  showTitle: false,
                },
                render: (data) => <Tooltip title={data}>{data}</Tooltip>,
                ...columnWidth,
              },
              {
                title: "HOLD REASON",
                dataIndex: "modifiedReason",
                key: "modifiedReason",
                align: "center",
                ellipsis: {
                  showTitle: false,
                },
                shouldCellUpdate: (record, prevRecord) =>
                  record.modifiedReason !== prevRecord.modifiedReason,
                render: (data) => <Tooltip title={data}>{data || ""}</Tooltip>,
                ...columnWidth,
              },
            ],
          },
        ]
      : showRemark
      ? [
          {
            title: "REMARKS",
            align: "center",
            dataIndex: "reason",
            ellipsis: {
              showTitle: false,
            },
            render: (data) => <Tooltip title={data}>{data}</Tooltip>,
            ...columnWidth,
          },
        ]
      : [];
  return [
    {
      title: (
        <div>
          SCHEME <br /> NO
        </div>
      ),
      dataIndex: "schemeNo",
      fixed: "left",
      sorter: disableSort ? false : true,
      ...columnWidth180,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "descend",
      key: "SchemeNo",
    },
    {
      title: (
        <div>
          DEALER <br /> CODE
        </div>
      ),
      dataIndex: "dealerCode",
      fixed: "left",
      sorter: disableSort ? false : true,
      ...columnWidth80,
      align: "center",
      defaultSortOrder: "descend",
      key: "DealerCode",
      ellipsis: {
        showTitle: false,
      },
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: (
        <div>
          DEALER <br /> LOCATION
        </div>
      ),
      dataIndex: "dealerLocation",
      ...columnWidth80,
      fixed: "left",
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "descend",
      key: "DealerLocation",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: (
        <div>
          DEALER <br /> NAME
        </div>
      ),
      dataIndex: "dealerName",
      ...columnWidth80,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "DealerName",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      title: "STATE",
      dataIndex: "stateName",
      ...columnWidth80,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "StateName",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    ...getCreatedDate(),
    {
      title: (
        <div>
          TERRITORY <br /> MANAGER
        </div>
      ),
      dataIndex: "territoryManager",
      ...columnWidth80,
      sorter: disableSort ? false : true,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "TerritoryManager",
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    ...getTopSlab(),
    ...getCreatedName(),
    {
      title: (
        <div>
          START <br /> MONTH
        </div>
      ),
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "schemePlanStartMonth",
      dataIndex: "schemePlanStartMonth",
      ...columnWidth80,
      render: (data) => dayjs(data).format("MMM'YY"),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "schemePlanEndMonth",
      dataIndex: "schemePlanEndMonth",
      ...columnWidth80,
      render: (data) => dayjs(data).format("MMM'YY"),
    },
    {
      title: (
        <div>
          Actual Installation
          <br />
          Last Year Month
        </div>
      ),
      dataIndex: "actual_Installation_Last_Year_Month",
      ...columnWidth80,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: "ascend",
      key: "actual_Installation_Last_Year_Month",

      render: (data) => amountCheckIN(data),
    },

    ...getCols(selectedSchemeType),
    ...getRemarks(),
    ...deviationAction(),
  ];
};
