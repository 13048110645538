import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import "../../createSchemes/createScheme.css";
import { Button, Table, Col, Row, Form, message, Select, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import axiosAPI from "../../../../services/axiosApi";
import { useFormik } from "formik";
import { schemeList } from "../../../../redux/collection/SchemeList";
import { getCurrentFiscalYear } from "../../../../utils/dateFormatter";
import moment from "moment";
import { ArrowBack, ArrowBackRounded, ConstructionOutlined } from "@mui/icons-material";
import { getSaveModifiedDashboardApi, resetSaveModification } from "../../../../redux/modification/modificationSlice";
import SMDialog from "../../../../components/modal/alertModal/CommonModal";
import ValidationErrorsModal from "../../../../components/modal/alertModal/ValidationErrorsModal";
import { updateSchemeGenerateApi } from "../../../../redux/schemaGenerate/schemaGenerateService";
import { updateSchemeGenerateApiAsync } from "../../../../redux/schemaGenerate/schemaGenerateSlice";
import { postUpdateHoldSchemeApi } from "../../../../redux/hold/holdDataService";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";
import { getAwaitingLockSchemeAsync, getLockedSchemeStateApiAsync, getLockedSchemeTypeAsync, getLockedZonalHeadApiAsync } from "../../../../redux/stateData/stateDataSlice";
import { encryptPayload } from "../../../../services/encryption";
import ValidationDifferentSchemeError from "../../../../components/modal/alertModal/ValidationDifferentSchemeError";
import { postUpdateHoldScheme } from "../../../../redux/hold/holdDataSlice";
import ToastifyShow from "../../../../components/ToastifyShow";
import FullScreenLoader from "../../../../components/loader/FullScreenLoader";
const currentFiscalYear = getCurrentFiscalYear();

const InitCollectionData = {
  currentYear: "2024",
  // collectionType: 'Collection',
  schemeType: "",
  dealerCode: [],
  startDate: "",
  endDate: "",
};

const AwaitingCollectionEdit = ({ backBtn, schemeDetails, selectedSchemeType, viewType }) => {

  const dispatch = useDispatch();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });
  const [openErrorDialog, setOpenErrorDialog] = useState({
    open: false,
    errorsFields: {},
  });  
  const { loadingScheme, schemeApprove } = useSelector(
    (state) => state.schemagenerate
  );
  const { userRole, userId, userName } = decryptData(sessionStorage.getItem("userInfo"));
  const { editDashboard, loadingState } = useSelector((state) => state.modifyCollection);
  const [codeError, setCodeError] = useState([]);
  const {loadingSaveHoldState}=useSelector((state)=>state.holdData)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataHandle, setDataHandle] = useState([]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const formik = useFormik({
    initialValues: [],
  });
  const showErrorDialog = (errFields) => {
    setOpenErrorDialog((openErrorDialog) => ({
      open: true,
      errorsFields: errFields ? errFields : {},
    }));
  };
  const handleChange = (pagination, filters, sorter) => {
    setCollectionData({ ...collectionData, "sortingOrder": sorter?.order, "sortingType": sorter?.columnKey })
  }
  // const { lockedStates } = useSelector((state) => state?.stateData);
  const [lockedStatus, setlockedStatus] = useState([])
  const uniqueByKey = (array, key) => {
    const seen = new Set();
    return array.reduce((acc, current) => {
      const value = current[key];
      if (!seen.has(value)) {
        seen.add(value);
        acc.push(value);
      }
      return acc;
    }, []);
  };


  // console.log(uniqueData,"uniqueData")
  //   useEffect(() => {
  //  if(uniqueData){
  //     dispatch(getLockedSchemeStateApiAsync({data:encryptPayload(uniqueData?.toString())})).then((x) => {
  //       if (x?.payload?.data?.[0]) {

  //         // topTargetArrState: (Array?.isArray(x?.payload?.data)) ? x?.payload?.data?.filter((y) => y?.stateId == x?.stateId)?.[0] || [{
  //         //   t1: false,
  //         //   t2: false,
  //         //   t3: false,
  //         //   t4: false,
  //         //   t5: false,
  //         //   t6: false
  //         // }] : [{
  //         //   t1: false,
  //         //   t2: false,
  //         //   t3: false,
  //         //   t4: false,
  //         //   t5: false,
  //         //   t6: false
  //         // }]
  //       }
  //     })
  //   }   
  //   }, [uniqueData])
  // console.log(lockedStatus,"lockedStatus")
  //   console.log(userId,"userIduserId")
  //   useEffect(() => {

  //     if (userRole == "ZADM"||userRole=="NSH") {
  //       // dispatch(getLockedSchemeTypeAsync(userId))
  //       dispatch(getLockedZonalHeadApiAsync({"fyear":"FY24","userid":userId})).then((x) => {
  // console.log(x?.payload?.data,"x?.payload?.data")
  //         if (Array?.isArray(x?.payload?.data)) {
  //           // setSchemeLockedStatus(x?.payload?.data)
  //         }
  //       })   
  //     }else{
  //       // dispatch(getLockedSchemeTypeAsync(userId)).then((x)=>{

  //       //   if (x?.payload?.[0]) {
  //       //     setSchemeLockedStatus(x?.payload?.[0]?{
  //       //       t1: true,
  //       //       t2: true,
  //       //       t3: true,
  //       //       t4: x?.payload?.[0]?.t4 ? true : false,
  //       //       t5: x?.payload?.[0]?.t5 ? true : false,
  //       //       t6: x?.payload?.[0]?.t6 ? true : false,
  //       //     }:{
  //       //       t1: false,
  //       //       t2: false,
  //       //       t3: false, t4: false, t5: false, t6: false 
  //       //     })
  //       //   }
  //       // })
  //   }   
  //   }, [])
  const schemeLockedStatus = lockedStatus?.[0]
    ? {
      t1: true,
      t2: true,
      t3: true,
      t4: lockedStatus?.[0]?.t4 ? true : false,
      t5: lockedStatus?.[0]?.t5 ? true : false,
      t6: lockedStatus?.[0]?.t6 ? true : false,
    }
    : {
      t1: false,
      t2: false,
      t3: false, t4: false, t5: false, t6: false
    };
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const topTargetArr = [
    {
      name: "T1",
      value: "t1",
      disabled: !schemeLockedStatus?.t1 ? true : false,
    },
    {
      name: "T2",
      value: "t2",
      disabled: !schemeLockedStatus?.t2 ? true : false,
    },
    {
      name: "T3",
      value: "t3",
      disabled: !schemeLockedStatus?.t3 ? true : false,
    },
    {
      name: "T4",
      value: "t4",
      disabled: !schemeLockedStatus?.t4 ? true : false,
    },
    {
      name: "T5",
      value: "t5",
      disabled: !schemeLockedStatus?.t5 ? true : false,
    },
    {
      name: "T6",
      value: "t6",
      disabled: !schemeLockedStatus?.t6 ? true : false,
    },
  ];

  const uniqueData = useMemo(() => uniqueByKey(schemeDetails, 'stateId'), [schemeDetails])
console.log(schemeDetails,"schemeDetailsschemeDetails")
  useEffect(() => {

    if (schemeDetails) {

      dispatch(getLockedSchemeStateApiAsync({ data: encryptPayload(uniqueData?.toString()) })).then((data) => {

        if (data?.payload?.data?.[0]) {
          const data1 = schemeDetails.map((x) => ({
            ...x,
            key:x?.schemeId,
            dealerCode: x.dealerCode,
            dealerId: x.dealerId,
            dealerName: x.dealerName,
            dealerLocation: x.dealerLocation,
            stateName: x.stateName,
            stateHeadName: x.stateHeadName,
            territoryManager: x.territoryManager || "",
            startMonth: x.startMonth,
            endMonth: x.endMonth,
            reason: x.reason,
            billing: x.billing ? +x.billing : 0,
            installation: x.installation ? +x.installation : 0,
            collection: Math.round(x.collection ? +x.collection : 0),
            delayPlan: x.delayPlan,
            schemeTypeId: x.schemeTypeId,
            bookCollectionId: x.bookCollectionId,
            payoutId: x.payoutId,
            schemeId: x.schemeId,
            schemeNo: x.schemeNo,
            schemePlanId: x.schemePlanId,
            openingStockDays: x.openingStockDays,
            outStandingDays: x.outStandingDays ? parseInt(x.outStandingDays) : 0,
            t1: +x.t1,
            t2: +x.t2,
            t3: +x.t3,
            t2_T1: x.t2_T1,
            t3_T2: x.t3_T2,
            t4: +x.t4,
            t4_T3: x.t4_T3,
            t5: +x.t5,
            t5_T4: x.t5_T4,
            t6: +x.t6,
            t6_T5: x.t6_T5,
            p1: x.p1,
            p2: x.p2,
            p3: x.p3,
            p4: x.p4,
            p5: x.p5,
            p6: x.p6,
            r1: +x.r1,
            r2: +x.r2,
            r3: +x.r3,
            r4: +x.r4,
            r5: +x.r5,
            r6: +x.r6,
            volume: x.volume,
            perTr: Math.round(x.perTr),
            amount: x.amount,
            // userrole: userRole,
            // ...(y != "draft" && { Manager: y.Manager }),
            // fyear: currentFiscalYear,
            // userid: +userId,
            // username: userName,
            industry: x.industry ? parseInt(x.industry) : 0,
            oldBillingQty: x.oldBillingQty,
            oldInstallation: x.oldInstallation,
            oldCollection: Math.round(x.oldCollection),
            oldOpeningStock: x.oldOpeningStock,
            closingOutStandingMonth_1: x.closingOutStandingMonth_1,
            closingOutStandingMonth_2: x.closingOutStandingMonth_2,
            closingOutStandingMonth_3: x.closingOutStandingMonth_3,
            bgLacsCurrentYear: x.bgLacsCurrentYear,
            dropdownscheme: x?.topTarget?.split(","),
            holdDrodownScheme: x?.holdTopTarget?.split(","),
            stateId: x?.stateId,
            TopTarget: x?.dropdownscheme?.toString(),
            closingStock: x?.closingStock,
            closingOutStanding: x?.closingOutStanding,
            billingFactorCOS: x?.billingFactorCOS,
            topTargetArrState: (Array?.isArray(data?.payload?.data)) ? data?.payload?.data?.filter((y) => y?.stateId == x?.stateId)?.[0] || [{
              t1: false,
              t2: false,
              t3: false,
              t4: false,
              t5: false,
              t6: false
            }] : [{
              t1: false,
              t2: false,
              t3: false,
              t4: false,
              t5: false,
              t6: false
            }]

          }));

          formik?.setValues(data1);
        }
      })


    }
  }, [schemeDetails]);


  // dropdownscheme
  const handleErrorModal = (firstItem, secondItem, opt = false) => {

    setOpenErrorModal(opt ? opt : !openErrorModal);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };

  const calculateFormula = (index) => {

    let y = formik?.values[index];
    let {
      t1,
      t2,
      t3,
      t4,
      t5,
      t6,
      r1,
      r2,
      r3,
      r4,
      r5,
      r6,
      amount,
      volume,
      TEPpertr,
      dropdownscheme,
      topTarget,
      oldOpeningStock,
      billing,
      installation,
      collection,
      closingOutStandingMonth_1,
      billingFactorCOS
    } = formik?.values[index];

    const handleDropDownSchemeCheckIndex = () => {
      let { lockedT1, lockedT2, lockedT3, lockedT4, lockedT5, lockedT6 } = dropdownscheme?.reduce((a, v) => ({ ...a, [v]: v }), {});
      return t1 && !t2 && !t3 && !t4 && !t5 && !t6
        ? "lockedT1"
        : t1 && t2 && !t3 && !t4 && !t5 && !t6
          ? "lockedT2"
          : t1 && t2 && t3 && !t4 && !t5 && !t6
            ? "lockedT3"
            : t1 && t2 && t3 && t4 && !t5 && !t6
              ? "lockedT4"
              : t1 && t2 && t3 && t4 && t5 && !t6
                ? "lockedT5"
                : t1 && t2 && t3 && t4 && t5 && t6
                  ? "lockedT6"
                  : "lockedT3";
    };
    let validationSoftTarget = handleDropDownSchemeCheckIndex();

    let calculateTepAMount;

    if (validationSoftTarget == "lockedT1") {
      calculateTepAMount = parseInt(volume) * parseInt(r1);
    } else if (validationSoftTarget == "lockedT2") {
      volume >= t1 && volume < t2
        ? (calculateTepAMount = parseInt(volume) * parseInt(r1))
        : (calculateTepAMount = parseInt(volume) * parseInt(r2));
    } else if (validationSoftTarget == "lockedT3") {
      volume >= t1 && volume < t2
        ? (calculateTepAMount = parseInt(volume) * parseInt(r1))
        : volume >= t2 &&
          volume < t3
          ? (calculateTepAMount = parseInt(volume) * parseInt(r2))
          : (calculateTepAMount = parseInt(volume) * parseInt(r3));
    } else if (validationSoftTarget == "lockedT4") {
      volume >= t1 && volume < t2
        ? (calculateTepAMount = parseInt(volume) * parseInt(r1))
        : volume >= t2 &&
          volume < t3
          ? (calculateTepAMount = parseInt(volume) * parseInt(r2))
          : volume >= t3 &&
            volume < t4
            ? (calculateTepAMount = parseInt(volume) * parseInt(r3))
            : (calculateTepAMount = parseInt(volume) * parseInt(r4));
    } else if (validationSoftTarget == "lockedT5") {
      volume >= t1 && volume < t2
        ? (calculateTepAMount = parseInt(volume) * parseInt(r1))
        : volume >= t2 &&
          volume < t3
          ? (calculateTepAMount = parseInt(volume) * parseInt(r2))
          : volume >= t3 &&
            volume < t4
            ? (calculateTepAMount = parseInt(volume) * parseInt(r3))
            : volume >= t4 &&
              volume < t5
              ? (calculateTepAMount = parseInt(volume) * parseInt(r4))
              : (calculateTepAMount = parseInt(volume) * parseInt(r5));
    } else if (validationSoftTarget == "lockedT6") {
      volume >= t1 && volume < t2
        ? (calculateTepAMount = parseInt(volume) * parseInt(r1))
        : volume >= t2 &&
          volume < t3
          ? (calculateTepAMount = parseInt(volume) * parseInt(r2))
          : volume >= t3 &&
            volume < t4
            ? (calculateTepAMount = parseInt(volume) * parseInt(r3))
            : volume >= t4 &&
              volume < t5
              ? (calculateTepAMount = parseInt(volume) * parseInt(r4))
              : volume >= t5 &&
                volume < t6
                ? (calculateTepAMount = parseInt(volume) * parseInt(r5))
                : (calculateTepAMount = parseInt(volume) * parseInt(r6));
    } else {
      calculateTepAMount = 0;
    }


    //Calculate ClosingStock
    const calculateClosingStock = () => {
      return (oldOpeningStock + parseInt(billing)) - installation;
    };
    const closingStock = calculateClosingStock();

    //Calculate Closing OutStanding for Regular Schemes
    const calculateClosingOutStanding = () => {
      const closingOutstanding = ((parseFloat(closingOutStandingMonth_1 * 100000)) 
        + (parseInt(billing) * parseFloat(billingFactorCOS)))
        - (parseInt(collection) * parseFloat(billingFactorCOS));

      return closingOutstanding / 100000;
    };

    const closingOutStanding = calculateClosingOutStanding().toFixed(2);

    let slabAchieve;

    if (validationSoftTarget == "lockedT4") {
      if (
        volume >= t1 &&
        volume < t2
      ) {
        slabAchieve = "lockedT1";
      } else if (
        volume >= t2 &&
        volume < t3
      ) {
        slabAchieve = "lockedT2";
      } else if (
        volume >= t3 &&
        volume < t4
      ) {
        slabAchieve = "lockedT3";
      } else {
        slabAchieve = "lockedT4";
      }
    } else if (validationSoftTarget == "lockedT5") {
      if (
        volume >= t1 &&
        volume < t2
      ) {
        slabAchieve = "lockedT1";
      } else if (
        volume >= t2 &&
        volume < t3
      ) {
        slabAchieve = "lockedT2";
      } else if (
        volume >= t3 &&
        volume < t4
      ) {
        slabAchieve = "lockedT3";
      } else if (
        volume >= t4 &&
        volume < t5
      ) {
        slabAchieve = "lockedT4";
      } else {
        slabAchieve = "lockedT5";
      }
    } else if (validationSoftTarget == "lockedT6") {
      if (
        volume >= t1 &&
        volume < t2
      ) {
        slabAchieve = "lockedT1";
      } else if (
        volume >= t2 &&
        volume < t3
      ) {
        slabAchieve = "lockedT2";
      } else if (
        volume >= t3 &&
        volume < t4
      ) {
        slabAchieve = "lockedT3";
      } else if (
        volume >= t4 &&
        volume < t5
      ) {
        slabAchieve = "lockedT4";
      } else if (
        volume >= t5 &&
        volume < t6
      ) {
        slabAchieve = "lockedT5";
      } else {
        slabAchieve = "lockedT6";
      }
    } else {
      if (
        volume >= t1 &&
        volume < t2
      ) {
        slabAchieve = "lockedT1";
      } else if (
        volume >= t2 &&
        volume < t3
      ) {
        slabAchieve = "lockedT2";
      } else {
        slabAchieve = "lockedT3";
      }
    }

    let calculateTepAMount1 = () => {
      let dropdownData = dropdownscheme?.includes("t6") ? "t6" :
        dropdownscheme?.includes("t5") ? "t5" :
          dropdownscheme?.includes("t4") ? "t4" :
            dropdownscheme?.includes("t3") ? "t3" :
              dropdownscheme?.includes("t2") ? "t2" :
                "t1"
      // eslint-disable-next-line default-case
      switch (dropdownData) {
        case "t1":
        return parseInt(volume) < parseInt(t1)
        ? 0
        : parseInt(volume)
          ? parseInt(volume) * parseInt(r1):0;
        
          case "t2":
          return  parseInt(volume) < parseInt(t1)
          ? 0
          : (parseInt(volume) >= parseInt(t1))&&(parseInt(volume)<parseInt(t2))
            ? parseInt(volume) * parseInt(r1)
            : parseInt(volume)
              ? parseInt(volume) * parseInt(r2):0;

        case "t3":   
          return  parseInt(volume) < parseInt(t1)
          ? 0
          : (parseInt(volume) >= parseInt(t1))&&(parseInt(volume)<parseInt(t2))
            ? parseInt(volume) * parseInt(r1)
            : (parseInt(volume) >= parseInt(t2))&&(parseInt(volume)<parseInt(t3))
              ? parseInt(volume) * parseInt(r2)
              : parseInt(volume)
                ? parseInt(volume) * parseInt(r3):0;
        case "t4":
          return  parseInt(volume) < parseInt(t1)
          ? 0
          : (parseInt(volume) >= parseInt(t1))&&(parseInt(volume)<parseInt(t2))
            ? parseInt(volume) * parseInt(r1)
            : (parseInt(volume) >= parseInt(t2))&&(parseInt(volume)<parseInt(t3))
              ? parseInt(volume) * parseInt(r2)
              : (parseInt(volume) >= parseInt(t3))&&(parseInt(volume)<parseInt(t4))
                ? parseInt(volume) * parseInt(r3)
                : parseInt(volume)
                  ? parseInt(volume) * parseInt(r4):0;

        case "t5":
        
        return parseInt(volume) < parseInt(t1)
        ? 0
        : (parseInt(volume) >= parseInt(t1))&&(parseInt(volume)<parseInt(t2))
          ? parseInt(volume) * parseInt(r1)
          : (parseInt(volume) >= parseInt(t2))&&(parseInt(volume)<parseInt(t3))
            ? parseInt(volume) * parseInt(r2)
            : (parseInt(volume) >= parseInt(t3))&&(parseInt(volume)<parseInt(t4))
              ? parseInt(volume) * parseInt(r3)
              : (parseInt(volume) >= parseInt(t4))&&(parseInt(volume)<parseInt(t5))
                ? parseInt(volume) * parseInt(r4)
                : parseInt(volume)
                  ? parseInt(volume) * parseInt(r5):0;
        case "t6":

        return parseInt(volume) < parseInt(t1)
        ? 0
        : (parseInt(volume) >= parseInt(t1))&&(parseInt(volume)<parseInt(t2))
          ? parseInt(volume) * parseInt(r1)
          : (parseInt(volume) >= parseInt(t2))&&(parseInt(volume)<parseInt(t3))
            ? parseInt(volume) * parseInt(r2)
            : (parseInt(volume) >= parseInt(t3))&&(parseInt(volume)<parseInt(t4))
              ? parseInt(volume) * parseInt(r3)
              : (parseInt(volume) >= parseInt(t4))&&(parseInt(volume)<parseInt(t5))
                ? parseInt(volume) * parseInt(r4)
                : (parseInt(volume) >= parseInt(t5))&&(parseInt(volume)<parseInt(t6))
                  ? parseInt(volume) * parseInt(r5)
                  : parseInt(volume)
                    ? parseInt(volume) * parseInt(r6)
                    : 0;
      }
    }
    let calculateTepAMount2 = calculateTepAMount1()
    let TEPpertr1Calculate = (parseInt(calculateTepAMount2) / parseInt(volume))
    formik?.setFieldValue(index, {
      ...y,
      "t2_T1": +(
        parseInt(t2 || 0) -
        parseInt(t1 || 0)
      ),
      "t3_T2": +(
        parseInt(t3 || 0) -
        parseInt(t2 || 0)
      ),
      "t4_T3": +(
        parseInt(t4 || 0) -
        parseInt(t3 || 0)
      ),
      "t5_T4": +(
        parseInt(t5 || 0) -
        parseInt(t4 || 0)
      ),
      "t6_T5": +(
        parseInt(t6 || 0) -
        parseInt(t5 || 0)
      ),
      p1: t1 && r1 ? (parseInt(t1 || 0) * parseInt(r1 || 0)) : "",
      p2: t2 && r2 ? (parseInt(t2 || 0) * parseInt(r2 || 0)) : "",
      p3: t3 && r3 ? (parseInt(t3 || 0) * parseInt(r3 || 0)) : "",
      p4: t4 && r4 ? (parseInt(t4 || 0) * parseInt(r4 || 0)) : "",
      p5: t5 && r5 ? (parseInt(t5 || 0) * parseInt(r5 || 0)) : "",
      p6: t6 && r6 ? (parseInt(t6 || 0) * parseInt(r6 || 0)) : "",

      r1: r1,
      r2: r2,
      r3: r3,
      r4: r4,
      r5: r5,
      r6: r6,
      perTr: Math.round(TEPpertr1Calculate) || 0,

      amount: Math.round(calculateTepAMount2),
      closingStock: closingStock,
      closingOutStanding: closingOutStanding
    });
    if (

      r2 != null &&
      r1 != null &&
      +r2 <= +r1 &&
      (validationSoftTarget == "t2" || validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("R2", "R1", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   r2: "",
      // });
    }

    if (
      r3 != null &&
      r2 != null &&
      +r3 <= +r2
      &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("R3", "R2", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   r3: "",
      // });
    }

    if (
      t1 != null &&
      t2 != null &&
      +t2 <= +t1 &&
      (validationSoftTarget == "t2" || validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )

    ) {
      handleErrorModal("T2", "T1", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   t2: "",
      // });
    }

    if (
      t2 != null &&
      t3 != null &&
      +t3 <= +t2 &&
      (validationSoftTarget == "t3" ||
        validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("T3", "T2", true);
      return false
      // formik?.setFieldValue(index, {
      //   ...y,
      //   t3: "",
      // });
    }

    if (
      t3 != null &&
      t4 != null &&
      +t4 <= +t3 &&
      (validationSoftTarget == "t4" || validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("T4", "T3", true);
      return false
    }

    if (
      t4 != null &&
      t5 != null &&
      +t5 <= +t4 &&
      (validationSoftTarget == "t5" || validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("T5", "T4", true);
      return false
    }
    if (
      t5 != null &&
      t6 != null &&
      +t6 <= +t5 &&
      (validationSoftTarget == "t6"
      )
    ) {
      handleErrorModal("T6", "T5", true);
      return false
    }
  };

  const calculateFormulaHold = (index) => {
    let y = formik?.values[index];
    let {
      holdT2,
      holdT1,
      holdT3,
      holdT4,
      holdT5,
      holdT6,
      holdR1,
      holdR2,
      holdR3,
      holdR4,
      holdR5,
      holdR6,
      holdAmount,
      holdVolume,
      TEPpertr,
      dropdownscheme,
      holdDrodownScheme,
      oldOpeningStock,
      holdBilling,
      holdInstallation,
      holdCollection,
      closingOutStandingMonth_1,
      billingFactorCOS
    } = formik?.values[index];

    const holdVolume1 = holdVolume ? parseInt(holdVolume) : 0;


    const handleDropDownSchemeCheckIndex = () => {
      let { t1, t2, t3, t4, t5, t6 } = holdDrodownScheme.reduce(
        (a, v) => ({ ...a, [v]: v }),
        {}
      );
      return t1 && !t2 && !t3 && !t4 && !t5 && !t6
        ? "t1"
        : t1 && t2 && !t3 && !t4 && !t5 && !t6
          ? "t2"
          : t1 && t2 && t3 && !t4 && !t5 && !t6
            ? "t3"
            : t1 && t2 && t3 && t4 && !t5 && !t6
              ? "t4"
              : t1 && t2 && t3 && t4 && t5 && !t6
                ? "t5"
                : t1 && t2 && t3 && t4 && t5 && t6
                  ? "t6"
                  : "";
    };
    let validationSoftTarget = handleDropDownSchemeCheckIndex();

    // let calculateholdAmount =
    //   (holdVolume1 >= holdT1 && holdVolume1 < holdT2) ? (parseInt(holdVolume1) * parseInt(holdR1))
    //     : (holdVolume1 < holdT1 || holdVolume == 0) ? 0
    //       : holdVolume1 >= holdT2 && holdVolume1 < holdT3 ? parseInt(holdVolume1) * parseInt(holdR2)
    //         : parseInt(holdVolume1) * parseInt(holdR3)

    // let calculateholdAmount =
    // holdVolume1 >= holdT1 && holdVolume1 < holdT2
    //   ? parseInt(holdVolume1) * parseInt(holdR1)
    //   : holdVolume1 < holdT1 || holdVolume == 0
    //     ? 0
    //     : holdVolume1 >= holdT2 &&
    //       holdVolume1 < holdT3
    //       ? parseInt(holdVolume1) * parseInt(holdR2)
    //       : parseInt(holdVolume1) * parseInt(holdR3);

    let calculateholdAmount1 = () => {
      // eslint-disable-next-line default-case
      switch (validationSoftTarget) {
        case "t1":
          return parseInt(holdVolume1) < parseInt(holdT1)
          ? 0
          : parseInt(holdVolume1)
            ? parseInt(holdVolume1) * parseInt(holdR1):0
        case "t2":
          return parseInt(holdVolume1) < parseInt(holdT1)
          ? 0
          : (parseInt(holdVolume1) >= parseInt(holdT1))&&(parseInt(holdVolume1)<parseInt(holdT2))
            ? parseInt(holdVolume1) * parseInt(holdR1)
            : parseInt(holdVolume1)
              ? parseInt(holdVolume1) * parseInt(holdR2):0
        case "t3":
       
          return parseInt(holdVolume1) < parseInt(holdT1)
          ? 0
          : (parseInt(holdVolume1) >= parseInt(holdT1))&&(parseInt(holdVolume1)<parseInt(holdT2))
            ? parseInt(holdVolume1) * parseInt(holdR1)
            : (parseInt(holdVolume1) >= parseInt(holdT2))&&(parseInt(holdVolume1)<parseInt(holdT3))
              ? parseInt(holdVolume1) * parseInt(holdR2)
              : parseInt(holdVolume1)
                ? parseInt(holdVolume1) * parseInt(holdR3):0
        case "t4":
          return parseInt(holdVolume1) < parseInt(holdT1)
          ? 0
          : (parseInt(holdVolume1) >= parseInt(holdT1))&&(parseInt(holdVolume1)<parseInt(holdT2))
            ? parseInt(holdVolume1) * parseInt(holdR1)
            : (parseInt(holdVolume1) >= parseInt(holdT2))&&(parseInt(holdVolume1)<parseInt(holdT3))
              ? parseInt(holdVolume1) * parseInt(holdR2)
              : (parseInt(holdVolume1) >= parseInt(holdT3))&&(parseInt(holdVolume1)<parseInt(holdT4))
                ? parseInt(holdVolume1) * parseInt(holdR3)
                : parseInt(holdVolume1)
                  ? parseInt(holdVolume1) * parseInt(holdR4):0

        case "t5":
          return parseInt(holdVolume1) < parseInt(holdT1)
          ? 0
          : (parseInt(holdVolume1) >= parseInt(holdT1))&&(parseInt(holdVolume1)<parseInt(holdT2))
            ? parseInt(holdVolume1) * parseInt(holdR1)
            : (parseInt(holdVolume1) >= parseInt(holdT2))&&(parseInt(holdVolume1)<parseInt(holdT3))
              ? parseInt(holdVolume1) * parseInt(holdR2)
              : (parseInt(holdVolume1) >= parseInt(holdT3))&&(parseInt(holdVolume1)<parseInt(holdT4))
                ? parseInt(holdVolume1) * parseInt(holdR3)
                : (parseInt(holdVolume1) >= parseInt(holdT4))&&(parseInt(holdVolume1)<parseInt(holdT5))
                  ? parseInt(holdVolume1) * parseInt(holdR4)
                  : parseInt(holdVolume1)
                    ? parseInt(holdVolume1) * parseInt(holdR5):0
        case "t6":
                        return parseInt(holdVolume1) < parseInt(holdT1)
                        ? 0
                        : (parseInt(holdVolume1) >= parseInt(holdT1))&&(parseInt(holdVolume1)<parseInt(holdT2))
                          ? parseInt(holdVolume1) * parseInt(holdR1)
                          : (parseInt(holdVolume1) >= parseInt(holdT2))&&(parseInt(holdVolume1)<parseInt(holdT3))
                            ? parseInt(holdVolume1) * parseInt(holdR2)
                            : (parseInt(holdVolume1) >= parseInt(holdT3))&&(parseInt(holdVolume1)<parseInt(holdT4))
                              ? parseInt(holdVolume1) * parseInt(holdR3)
                              : (parseInt(holdVolume1) >= parseInt(holdT4))&&(parseInt(holdVolume1)<parseInt(holdT5))
                                ? parseInt(holdVolume1) * parseInt(holdR4)
                                : (parseInt(holdVolume1) >= parseInt(holdT5))&&(parseInt(holdVolume1)<parseInt(holdT6))
                                  ? parseInt(holdVolume1) * parseInt(holdR5)
                                  : parseInt(holdVolume1)
                                    ? parseInt(holdVolume1) * parseInt(holdR6)
                                    : 0;
      }
      
    };
    let calculateholdAmount = calculateholdAmount1();

    let TEPpertr1Calculate = calculateholdAmount / holdVolume1;

    //Calculate ClosingStock
    const calculateClosingStock = () => {
      return (oldOpeningStock + parseInt(holdBilling)) - holdInstallation;
    };
    const holdClosingStock = calculateClosingStock();

    //Calculate Closing OutStanding
    const calculateClosingOutStanding = () => {
      const holdClosingOutstanding = ((parseFloat(closingOutStandingMonth_1 * 100000)) 
        + (parseInt(holdBilling) * parseFloat(billingFactorCOS)))
        - (parseInt(holdCollection) * parseFloat(billingFactorCOS));

      return holdClosingOutstanding / 100000;
    };

    const holdClosingOutstanding = calculateClosingOutStanding().toFixed(2);

    let slabAchieve;

    if (holdVolume1 < holdT1) {
      slabAchieve = "";
    } else if (holdVolume1 >= holdT1 && ((holdVolume1 < holdT2) || validationSoftTarget == "t1")) {
      slabAchieve = "T1";
    } else if (holdVolume1 >= holdT2 && ((holdVolume1 < holdT3) || validationSoftTarget == "t2")) {
      slabAchieve = "T2";
    } else if (holdVolume1 >= holdT3 && ((holdVolume1 < holdT4) || validationSoftTarget == "t3")) {
      slabAchieve = "T3";
    } else if (holdVolume1 >= holdT4 && ((holdVolume1 < holdT5) || validationSoftTarget == "t4")) {
      slabAchieve = "T4";
    } else if (holdVolume1 >= holdT5 && ((holdVolume1 < holdT6) || validationSoftTarget == "t5")) {
      slabAchieve = "T5";
    } else if (holdVolume1 >= holdT6) {
      slabAchieve = "T6";
    }

    formik?.setFieldValue(index, {
      ...y,
      holdT2t1:
        +(
          holdT2 &&
          holdT1 &&
          parseInt(holdT2 || 0) -
          parseInt(holdT1 || 0)
        ) || 0,
      holdT3t2: +(
        (holdT3 &&
          holdT2 &&
          parseInt(holdT3 || 0) -
          parseInt(holdT2 || 0)) ||
        0
      ),
      holdT4t3:
        +(
          holdT4 &&
          holdT3 &&
          parseInt(holdT4 || 0) -
          parseInt(holdT3 || 0)
        ) || 0,
      holdT5t4:
        +(
          holdT5 &&
          holdT4 &&
          parseInt(holdT5 || 0) -
          parseInt(holdT4 || 0)
        ) || 0,
      holdT6t5:
        +(
          holdT6 &&
          holdT5 &&
          parseInt(holdT6 || 0) -
          parseInt(holdT5 || 0)
        ) || 0,
      holdP1:
        holdT1 && holdR1
          ? parseInt(holdT1 || 0) * parseInt(holdR1 || 0)
          : 0,
      holdP2:
        holdT2 && holdR2
          ? parseInt(holdT2 || 0) * parseInt(holdR2 || 0)
          : 0,
      holdP3:
        holdT3 && holdR3
          ? parseInt(holdT3 || 0) * parseInt(holdR3 || 0)
          : "",
      holdP4:
        holdT4 && holdR4
          ? parseInt(holdT4 || 0) * parseInt(holdR4 || 0)
          : "",
      holdP5:
        holdT5 && holdR5
          ? parseInt(holdT5 || 0) * parseInt(holdR5 || 0)
          : "",
      holdP6:
        holdT6 && holdR6
          ? parseInt(holdT6 || 0) * parseInt(holdR6 || 0)
          : "",
      holdPerTr: Math.round(TEPpertr1Calculate),
      holdAmount: isNaN(Math.round(calculateholdAmount)) ? 0 : Math.round(calculateholdAmount),
      slab: slabAchieve,
      holdClosingStock: holdClosingStock,
      holdClosingOutstanding: holdClosingOutstanding
    });

    if (
      holdT1 != null &&
      holdT2 != null &&
      +holdT2 <= +holdT1 &&
      (holdDrodownScheme?.includes("t2"))
    ) {
      handleErrorModal("T2", "T1", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   holdT2: "",
      // });
    }

    if (
      holdT2 != null &&
      holdT3 != null &&
      +holdT3 <= +holdT2 &&
      (holdDrodownScheme?.includes("t3"))
    ) {
      handleErrorModal("T3", "T2", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   holdT3: "",
      // });
    }

    if (
      holdT3 != null &&
      holdT4 != null &&
      +holdT4 <= +holdT3 &&
      (holdDrodownScheme?.includes("t4"))
    ) {
      handleErrorModal("T4", "T3", true);
      return false;
    }

    if (
      holdT4 != null &&
      holdT5 != null &&
      +holdT5 <= +holdT4 &&
      (holdDrodownScheme?.includes("t5"))
    ) {
      handleErrorModal("T5", "T4", true);
      return false;
    }
    if (
      holdT5 != null &&
      holdT6 != null &&
      +holdT6 <= +holdT5 &&
      (holdDrodownScheme?.includes("t6"))
    ) {
      handleErrorModal("T6", "T5", true);
      return false;
    }
    if (
      holdR2 != null &&
      holdR1 != null &&
      +holdR2 <= +holdR1 &&
      (holdDrodownScheme?.includes("t2"))
    ) {
      handleErrorModal("R2", "R1", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   holdR2: "",
      // });
    }

    if (
      holdR3 != null &&
      holdR2 != null &&
      +holdR3 <= +holdR2 &&
      (holdDrodownScheme?.includes("t3"))
    ) {
      handleErrorModal("R3", "R2", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   holdR3: "",
      // });
    }

    if (
      holdR4 != null &&
      holdR3 != null &&
      +holdR4 <= +holdR3 &&
      (holdDrodownScheme?.includes("t4"))
    ) {
      handleErrorModal("R4", "R3", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   holdR3: "",
      // });
    }

    if (
      holdR5 != null &&
      holdR4 != null &&
      +holdR5 <= +holdR4 &&
      (holdDrodownScheme?.includes("t5"))
    ) {
      handleErrorModal("R5", "R4", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   holdR3: "",
      // });
    }

    if (
      holdR6 != null &&
      holdR5 != null &&
      +holdR6 <= +holdR5 &&
      (holdDrodownScheme?.includes("t6"))
    ) {
      handleErrorModal("R6", "R5", true);
      return false;
      // formik?.setFieldValue(index, {
      //   ...y,
      //   holdR3: "",
      // });
    }
    //

  };

  const getStateHead = () => {
    if (userRole == "ZADM") {
      return [
        {
          title: (
            <div>
              State Head
            </div>
          ),
          dataIndex: "stateHeadName",
          width: "180px",
        },
      ]
    } else {
      return []
    }
  }
  const topTargetArr1 = (x) => [
    {
      name: "T1",
      value: "t1",

    },
    {
      name: "T2",
      value: "t2",

    },
    {
      name: "T3",
      value: "t3",

    },
    {
      name: "T4",
      value: "t4",
      disabled: !x?.t4 ? true : false,
    },
    {
      name: "T5",
      value: "t5",
      disabled: !x?.t5 ? true : false,
    },
    {
      name: "T6",
      value: "t6",
      disabled: !x?.t6 ? true : false,
    },
  ]

  const getDropdown = () =>
    [
      {
        title: "Top Slab",
        width: "150px",
        render: (x, y, index) => {
          const handleChange = (value) => {
            let dropdownValues = formik?.values?.[index]?.dropdownscheme;
            let checkValue1 = value?.includes("t1");
            let checkValue2 = value?.includes("t2");
            let checkValue3 = value?.includes("t3");
            let checkValue4 = value?.includes("t4");
            let checkValue5 = value?.includes("t5");
            let checkValue6 = value?.includes("t6");
            const handleDropDownScheme = () => {
              return checkValue6 ? ['t1', 't2', 't3', 't4', 't5', 't6']
                : checkValue5 ? ['t1', 't2', 't3', 't4', 't5'] :
                  checkValue4 ? ['t1', 't2', 't3', 't4']
                    : checkValue3 ? ['t1', 't2', 't3']
                      : checkValue2 ? ['t1', 't2']
                        : checkValue1 ? ['t1'] : [];
            };
            const handleDropdownSchemes = handleDropDownScheme()

            if (viewType == "hold") {
              formik.setFieldValue(index, {
                ...x,
                holdDrodownScheme: handleDropDownScheme() || null,
                ...(handleDropdownSchemes.includes("t6")
                ? {
                }:handleDropdownSchemes.includes("t5") ? {
                  t6_T5: null,
                  holdR6: null,
                  holdT6: null
                } :
                  handleDropdownSchemes.includes("t4") ? {
                    holdR5: null, holdT5: null,
                    holdR6: null, holdT6: null,
                    holdT5_T4: 0
                  } : handleDropdownSchemes.includes("t3") ? {
                    holdR4: null, holdT4: null,
                    holdR5: null, holdT5: null,
                    holdR6: null, holdT6: null,
                    holdT4_T3: 0,
                  } : handleDropdownSchemes.includes("t2") ? {
                    holdR3: null, holdT3: null,
                    holdR4: null, holdT4: null,
                    holdR5: null, holdT5: null,
                    holdR6: null, holdT6: null,
                    holdT3_T2: 0,
                  } : handleDropdownSchemes.includes("t1") && {
                    holdR2: null, holdT2: null,
                    holdR3: null, holdT3: null,
                    holdR4: null, holdT4: null,
                    holdR5: null, holdT5: null,
                    holdR6: null, holdT6: null,
                    holdT2_T1: 0,

                  }),

                holdT6_T5: null,
              });
            } else {
              formik.setFieldValue(index, {
                ...x,
                dropdownscheme: handleDropDownScheme() || null,
                ...(handleDropdownSchemes.includes("t6")
                ? {
                }:handleDropdownSchemes.includes("t5") ? {
                  t6_T5: null,
                  r6: null,
                  t6: null
                } :
                  handleDropdownSchemes.includes("t4") ? {
                    r5: null, t5: null,
                    r6: null, t6: null,
                    t5_T4: 0
                  } : handleDropdownSchemes.includes("t3") ? {
                    r4: null, t4: null,
                    r5: null, t5: null,
                    r6: null, t6: null,
                    t4_T3: 0,
                  } : handleDropdownSchemes.includes("t2") ? {
                    r3: null, t3: null,
                    r4: null, t4: null,
                    r5: null, t5: null,
                    r6: null, t6: null,
                    t3_T2: 0,
                  } : handleDropdownSchemes.includes("t1") && {
                    r2: null, t2: null,
                    r3: null, t3: null,
                    r4: null, t4: null,
                    r5: null, t5: null,
                    r6: null, t6: null,
                    t2_T1: 0,

                  }),

                t6_T5: null,
              });
            }
          };

          let arr1 = userRole == "ZADM" || userRole == "NSH" || userRole == "SADM" ? topTargetArr1(Array?.isArray(y?.topTargetArrState) ? y?.topTargetArrState[0] : y?.topTargetArrState)?.filter((x) => !x?.disabled) : topTargetArr
            ?.filter((x) => !x?.disabled)

          return (
            <Select
              onChange={handleChange}
              value={viewType == "hold" ? y?.holdDrodownScheme : y?.dropdownscheme}
              mode="multiple"
              autoClearSearchValue="multiple"
              className="w-100"
              allowClear
              onDeselect={false}
              showarrow={false}
              removeIcon={false}
            >

              {arr1
                ?.filter((x) => !x?.disabled)
                ?.map((x) => (
                  <Select.Option value={x?.value}>
                    {x?.name}
                  </Select.Option>
                ))}
            </Select>
          );
        },
      },
    ]

  const getTargetT1 = () => [
    {
      title: "T1",
      editable: true,
      width: "100px",
      dataIndex: viewType == "hold" ? "holdT1" : "t1",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdT1}
              value={formik?.values?.[index]?.holdT1 || ""}
              onBlur={() => {
                calculateFormulaHold(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  holdT1: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t1")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t1")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t1")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.t1}
              value={formik?.values?.[index]?.t1 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t1: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },

    },
  ]
  const getTargetT2 = () => [
    {
      title: "T2",
      editable: true,
      width: "100px",
      dataIndex: viewType == "hold" ? "holdT2" : "t2",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdT2}
              value={formik?.values?.[index]?.holdT2 || ""}
              onBlur={() => {
                calculateFormulaHold(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  holdT2: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t2")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t2")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t2")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.t2}
              value={formik?.values?.[index]?.t2 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t2: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },
    },
    {
      title: "T2-T1",
      dataIndex: viewType == "hold" ? "holdT2t1" : "t2_T1",
      width: "100px",
      render: (x, y, index) => {

        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t2") && formik?.values?.[index]?.holdDrodownScheme?.includes("t1") ? <div>{x}</div> :
            <div
              style={{ height: "26px" }}
              className={"input-disabled1"
              }>&nbsp;</div>
          )
        } else {
          return (formik?.values?.[index]?.dropdownscheme?.includes("t2") && formik?.values?.[index]?.dropdownscheme?.includes("t1") ? <div>{x}</div> :
            <div
              style={{ height: "26px" }}
              className={"input-disabled1"
              }>&nbsp;</div>
          )
        }
      }
    },
  ]
  const getTargetT3 = () => [
    {
      title: "T3",
      width: "100px",
      dataIndex: viewType == "hold" ? "holdT3" : "t3",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdT3}
              value={formik?.values?.[index]?.holdT3 || ""}
              onBlur={() => {
                calculateFormulaHold(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  holdT3: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t3")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t3")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t3")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.t3}
              value={formik?.values?.[index]?.t3 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t3: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },
    },
    {
      title: "T3-T2",

      dataIndex: viewType == "hold" ? "holdT3t2" : "t3_T2",
      width: "100px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t3") && formik?.values?.[index]?.holdDrodownScheme?.includes("t2") ? <div>{x}</div> :
            <div
              style={{ height: "26px" }}
              className={"input-disabled1"
              }>&nbsp;</div>
          )
        } else {
          return (formik?.values?.[index]?.dropdownscheme?.includes("t3") && formik?.values?.[index]?.dropdownscheme?.includes("t2") ? <div>{x}</div> :
            <div
              style={{ height: "26px" }}
              className={"input-disabled1"
              }>&nbsp;</div>
          )
        }
      }
    },
  ]
  const getTargetT4 = () => [
    {
      title: "T4",
      editable: true,
      width: "100px",
      dataIndex: viewType == "hold" ? "holdT4" : "t4",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdT4}
              value={formik?.values?.[index]?.holdT4 || ""}
              onBlur={() => {
                calculateFormulaHold(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  holdT4: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t4")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t4")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t4")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.t4}
              value={formik?.values?.[index]?.t4 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t4: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },
    },
    {
      title: "T4-T3",

      dataIndex: viewType == "hold" ? "holdT4t3" : "t4_T3",
      width: "100px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t4") && formik?.values?.[index]?.holdDrodownScheme?.includes("t3") ? <div>{x}</div> :
            <div
              style={{ height: "26px" }}
              className={"input-disabled1"
              }>&nbsp;</div>
          )
        } else {
          return (formik?.values?.[index]?.dropdownscheme?.includes("t4") && formik?.values?.[index]?.dropdownscheme?.includes("t3") ? <div>{x}</div> :
            <div
              style={{ height: "26px" }}
              className={"input-disabled1"
              }>&nbsp;</div>
          )
        }
      }
    },
  ]
  const getTargetT5 = () => [
    {
      title: "T5",
      width: "100px",
      dataIndex: viewType == "hold" ? "holdT5" : "t5",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdT5}
              value={formik?.values?.[index]?.holdT5 || ""}
              onBlur={() => {
                calculateFormulaHold(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  holdT5: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t5")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t5")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t5")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.t5}
              value={formik?.values?.[index]?.t5 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t5: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },
    },
    {
      title: "T5-T4",

      dataIndex: viewType == "hold" ? "holdT5t4" : "t5_T4",
      width: "100px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t5") && formik?.values?.[index]?.holdDrodownScheme?.includes("t4") ? <div>{x}</div> :
            <div
              style={{ height: "26px" }}
              className={"input-disabled1"
              }>&nbsp;</div>
          )
        } else {
          return (formik?.values?.[index]?.dropdownscheme?.includes("t5") && formik?.values?.[index]?.dropdownscheme?.includes("t4") ? <div>{x}</div> :
            <div
              style={{ height: "26px" }}
              className={"input-disabled1"
              }>&nbsp;</div>
          )
        }
      }
    },
  ]
  const getTargetT6 = () => [
    {
      title: "T6",

      editable: true,
      width: "100px",
      dataIndex: viewType == "hold" ? "holdT6" : "t6",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdT6}
              value={formik?.values?.[index]?.holdT6 || ""}
              onBlur={() => {
                calculateFormulaHold(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  holdT6: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t6")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t6")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t6")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.t6}
              value={formik?.values?.[index]?.t6 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  t6: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },
    },
    {
      title: "T6-T5",

      dataIndex: viewType == "hold" ? "holdT6t5" : "t6_T5",
      width: "100px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t6") && formik?.values?.[index]?.holdDrodownScheme?.includes("t5") ? <div>{x}</div> :
            <div
              style={{ height: "26px" }}
              className={"input-disabled1"
              }>&nbsp;</div>
          )
        } else {
          return (formik?.values?.[index]?.dropdownscheme?.includes("t6") && formik?.values?.[index]?.dropdownscheme?.includes("t5") ? <div>{x}</div> :
            <div
              style={{ height: "26px" }}
              className={"input-disabled1"
              }>&nbsp;</div>
          )
        }
      }
    },
  ]
  const getPayoutRateR1 = () => [
    {
      title: "R1",
      editable: false,
      width: "120px",
      dataIndex: viewType == "hold" ? "holdR1" : "r1",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdR1}
              value={formik?.values?.[index]?.holdR1 || ""}
              onBlur={() => {
                calculateFormulaHold(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  holdR1: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t1")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t1")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t1")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.r1}
              value={formik?.values?.[index]?.r1 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r1: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },

    },
  ]
  const getPayoutRateR2 = () => [
    {
      title: "R2",

      editable: false,
      width: "120px",
      dataIndex: viewType == "hold" ? "holdR2" : "r2",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdR2}
              value={formik?.values?.[index]?.holdR2 || ""}
              onBlur={() => {
                calculateFormulaHold(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  holdR2: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t2")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t2")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t2")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.r2}
              value={formik?.values?.[index]?.r2 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r2: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },
    },
  ]
  const getPayoutRateR3 = () => [
    {
      title: "R3",
      editable: true,
      width: "120px",
      dataIndex: viewType == "hold" ? "holdR3" : "r3",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdR3}
              value={formik?.values?.[index]?.holdR3 || ""}
              onBlur={() => {
                calculateFormulaHold(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  holdR3: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t3")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t3")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t3")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.r3}
              value={formik?.values?.[index]?.r3 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r3: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      },
    },
  ]
  const getPayoutRateR4 = () => [
    {
      title: "R4",
      // dataIndex: "r4",
      editable: true,
      width: "120px",
      dataIndex: viewType == "hold" ? "holdR4" : "r4",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdR4}
              value={formik?.values?.[index]?.holdR4 || ""}
              onBlur={() => {
                calculateFormulaHold(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  holdR4: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t4")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t4")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t4")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.r4}
              value={formik?.values?.[index]?.r4 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r4: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t4")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      }
    },
  ]
  const getPayoutRateR5 = () => [
    {
      title: "R5",
      editable: true,
      width: "120px",
      dataIndex: viewType == "hold" ? "holdR5" : "r5",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdR5}
              value={formik?.values?.[index]?.holdR5 || ""}
              onBlur={() => {
                calculateFormulaHold(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  holdR5: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t5")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t5")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t5")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.r5}
              value={formik?.values?.[index]?.r5 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r5: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t5")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      }
    },
  ]
  const getPayoutRateR6 = () => [
    {
      title: "R6",
      editable: true,
      width: "120px",
      dataIndex: viewType == "hold" ? "holdR6" : "r6",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (
            <input
              name={formik?.values?.[index]?.holdR6}
              value={formik?.values?.[index]?.holdR6 || ""}
              onBlur={() => {
                calculateFormulaHold(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  holdR6: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t6")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t6")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.holdDrodownScheme?.includes("t6")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)

        } else {
          return (
            <input
              name={formik?.values?.[index]?.r6}
              value={formik?.values?.[index]?.r6 || ""}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                if (value.length > 5) {
                  value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                }
                formik.setFieldValue(index, {
                  ...y,
                  r6: parseInt(value)||"",
                });
              }}
              disabled={
                !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                  ? true
                  : false
              }
              placeholder={
                !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                  ? ""
                  : "Type here"
              }
              className={
                !formik?.values?.[index]?.dropdownscheme?.includes("t6")
                  ? "input-disabled"
                  : "input1"
              }
              style={{ width: "90%", padding: "5px" }}
            />)
        }
      }
    },
  ]
  const getPayoutP1 = () => [
    {
      title: "P1",
      dataIndex: viewType == "hold" ? "holdP1" : "p1",

      width: "120px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t1") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>)
        } else {
          return (
            formik?.values?.[index]?.dropdownscheme?.includes("t1") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
          )
        }
      }
    },
  ]
  const getPayoutP2 = () => [
    {
      title: "P2",
      dataIndex: viewType == "hold" ? "holdP2" : "p2",
      width: "120px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t2") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>)
        } else {
          return (
            formik?.values?.[index]?.dropdownscheme?.includes("t2") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
          )
        }
      }

    },
  ]
  const getPayoutP3 = () => [
    {
      title: "P3",
      dataIndex: viewType == "hold" ? "holdP3" : "p3",
      width: "120px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t3") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>)
        } else {
          return (
            formik?.values?.[index]?.dropdownscheme?.includes("t3") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
          )
        }
      }
    },
  ]
  const getPayoutP4 = () => [
    {
      title: "P4",
      dataIndex: viewType == "hold" ? "holdP4" : "p4",
      width: "120px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t4") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>)
        } else {
          return (
            formik?.values?.[index]?.dropdownscheme?.includes("t4") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
          )
        }
      }
    },
  ]
  const getPayoutP5 = () => [
    {
      title: "P5",
      dataIndex: viewType == "hold" ? "holdP5" : "p5",
      width: "120px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t5") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>)
        } else {
          return (
            formik?.values?.[index]?.dropdownscheme?.includes("t5") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
          )
        }
      }
    },
  ]
  const getPayoutP6 = () => [
    {
      title: "P6",
      dataIndex: viewType == "hold" ? "holdP6" : "p6",
      width: "120px",
      render: (x, y, index) => {
        if (viewType == "hold") {
          return (formik?.values?.[index]?.holdDrodownScheme?.includes("t6") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>)
        } else {
          return (
            formik?.values?.[index]?.dropdownscheme?.includes("t6") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
          )
        }
      }

    },
  ]

  const defaultColumns = useMemo(
    () => [
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        fixed: "left",
        width: "120px",
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        fixed: "left",
        width: "180px",
        //  sorter: (a, b) => a.name && b.name && a.name.length - b.name.length,
      },
      {
        title: "Dealer Location",
        dataIndex: "dealerLocation",
        width: "120px",
        fixed: "left",
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: "120px",
      }, ...getStateHead(),
      {
        title: "Territory Manager",
        dataIndex: "territoryManager",
        width: "100px",
      },
      ...getDropdown(),
      {
        title: "Start Month",
        dataIndex: "startMonth",
        width: "120px",
        render: (_, item) => dayjs(item.startMonth).format("MMM'YY"),
      },
      {
        title: "End Month",
        dataIndex: "endMonth",
        width: "120px",
        render: (_, item) => dayjs(item.endMonth).format("MMM'YY"),
      },
      {
        title: "Industry",
        dataIndex: viewType == "hold" ? "holdIndustry" : "industry",
        width: "120px",
        render: (x, y, index) => {
          if (viewType == "hold") {
            return (
              <input
                name={formik?.values?.[index]?.holdIndustry}
                placeholder="Type here"
                value={formik?.values?.[index]?.holdIndustry}
                onBlur={() => {
                  calculateFormulaHold(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    holdIndustry: parseInt(value)||"",
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />)

          } else {
            return (
              <input
                name={formik?.values?.[index]?.industry}
                placeholder="Type here"
                value={formik?.values?.[index]?.industry}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    industry: parseInt(value)||"",
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />)
          }
        }
      },
      {
        title: "B",
        dataIndex: "oldBillingQty",
        width: "70px",
      },
      {
        title: "I",
        dataIndex: "oldInstallation",
        width: "70px",
      },
      {
        title: "C",
        dataIndex: "oldCollection",
        width: "70px",
      },
      {
        title: "Opening Stock",
        dataIndex: "stock",
        editable: false,
        children: [
          {
            title: "Nos",
            dataIndex: "oldOpeningStock",
            width: "100px",
          },
          {
            title: "Days",
            dataIndex: "openingStockDays",
            width: "100px",
            // render: (x, y, index) => (
            //   <input
            //     name={formik?.values?.[index]?.openingStockDays}
            //     placeholder="Type here"
            //     value={formik?.values?.[index]?.openingStockDays}
            //     onBlur={() => {
            //       calculateFormula(index);
            //     }}
            //     onChange={(e) => {
            //       let value = e.target?.value.replace(/[^\d]/g, "");
            //       formik.setFieldValue(index, {
            //         ...y,
            //         openingStockDays: parseInt(value),
            //       });
            //     }}
            //     className="input1"
            //     style={{ width: "90%", padding: "5px" }}
            //   />
            // ),
          },
        ],
      },

      {
        title: "Closing Os. (In Lacs)",
        dataIndex: "stock",
        editable: false,
        children: [
          {
  title: (
              <span>
                Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_3",
            width: "100px",
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_2",
            width: "100px",
          },
          {
            title: (
              <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
              </span>
            ),
            dataIndex: "closingOutStandingMonth_1",
            width: "100px",
          },

          {
            title: (
              <span>
                Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
              </span>
            ),
            dataIndex: "outStandingDays",
            width: "100px",
          },
        ],
      },
      {
        title: "BG",
        dataIndex: "bg",
        children: [
          {
            title: "Lacs",
            dataIndex: "bgLacsCurrentYear",
            width: "100px",
          },
        ],
      },

      {
        title: "BILLING DETAILS",
        dataIndex: "plan",
        editable: true,
        children: [
          {
            title: "B",
            dataIndex: viewType == "hold" ? "holdBilling" : "billing",
            render: (x, y, index) => {
              if (viewType == "hold") {
                return (
                  <input
                    name={formik?.values?.[index]?.holdBilling}
                    placeholder="Type here"
                    value={formik?.values?.[index]?.holdBilling}
                    onBlur={() => {
                      calculateFormulaHold(index);
                    }}
                    onChange={(e) => {
                      let value = e.target?.value.replace(/[^\d]/g, "");
                      formik.setFieldValue(index, {
                        ...y,
                        holdBilling: parseInt(value)||"",
                      });
                    }}
                    className="input1"
                    style={{ width: "90%", padding: "5px" }}
                  />)

              } else {
                return (
                  <input
                    name={formik?.values?.[index]?.billing}
                    placeholder="Type here"
                    value={formik?.values?.[index]?.billing}
                    onBlur={() => {
                      calculateFormula(index);
                    }}
                    onChange={(e) => {
                      let value = e.target?.value.replace(/[^\d]/g, "");
                      formik.setFieldValue(index, {
                        ...y,
                        billing: parseInt(value)||"",
                      });
                    }}
                    className="input1"
                    style={{ width: "90%", padding: "5px" }}
                  />)
              }
            },
            width: "100px",
          },
          {
            title: "I",
            dataIndex: viewType == "hold" ? "holdInstallation" : "installation",
            render: (x, y, index) => {
              if (viewType == "hold") {
                return (
                  <input
                    name={formik?.values?.[index]?.holdInstallation}
                    placeholder="Type here"
                    value={formik?.values?.[index]?.holdInstallation}
                    onBlur={() => {
                      calculateFormulaHold(index);
                    }}
                    onChange={(e) => {
                      let value = e.target?.value.replace(/[^\d]/g, "");
                      formik.setFieldValue(index, {
                        ...y,
                        holdInstallation: parseInt(value)||"",
                      });
                    }}
                    className="input1"
                    style={{ width: "90%", padding: "5px" }}
                  />)

              } else {
                return (
                  <input
                    name={formik?.values?.[index]?.installation}
                    placeholder="Type here"
                    value={formik?.values?.[index]?.installation}
                    onBlur={() => {
                      calculateFormula(index);
                    }}
                    onChange={(e) => {
                      let value = e.target?.value.replace(/[^\d]/g, "");
                      formik.setFieldValue(index, {
                        ...y,
                        installation: parseInt(value)||"",
                      });
                    }}
                    className="input1"
                    style={{ width: "90%", padding: "5px" }}
                  />)
              }
            },

            width: "100px",
          },
          {
            title: "C",
            dataIndex: viewType == "hold" ? "holdCollection" : "collection",
            render: (x, y, index) => {
              if (viewType == "hold") {
                return (
                  <input
                    name={formik?.values?.[index]?.holdCollection}
                    placeholder="Type here"
                    value={formik?.values?.[index]?.holdCollection}
                    onBlur={() => {
                      calculateFormulaHold(index);
                    }}
                    onChange={(e) => {
                      let value = e.target?.value.replace(/[^\d]/g, "");
                      formik.setFieldValue(index, {
                        ...y,
                        holdCollection: parseInt(value)||"",
                      });
                    }}
                    className="input1"
                    style={{ width: "90%", padding: "5px" }}
                  />)

              } else {
                return (
                  <input
                    name={formik?.values?.[index]?.collection}
                    placeholder="Type here"
                    value={formik?.values?.[index]?.collection}
                    onBlur={() => {
                      calculateFormula(index);
                    }}
                    onChange={(e) => {
                      let value = e.target?.value.replace(/[^\d]/g, "");
                      formik.setFieldValue(index, {
                        ...y,
                        collection: parseInt(value)||"",
                      });
                    }}
                    className="input1"
                    style={{ width: "90%", padding: "5px" }}
                  />)
              }
            },
            width: "100px",
          },
        ],
      },
      {
        title: "Installation plan",
        dataIndex: "instaPlan",
        width: "300px",
        children: [
          {
            title: "Nos",

            width: "200px",
            dataIndex: viewType == "hold" ? "holdDelayPlan" : "delayPlan",
            render: (x, y, index) => {
              if (viewType == "hold") {
                return (
                  <input
                    name={formik?.values?.[index]?.holdDelayPlan}
                    placeholder="Type here"
                    value={formik?.values?.[index]?.holdDelayPlan}
                    onBlur={() => {
                      calculateFormulaHold(index);
                    }}
                    onChange={(e) => {
                      let value = e.target?.value.replace(/[^\d]/g, "");
                      formik.setFieldValue(index, {
                        ...y,
                        holdDelayPlan: parseInt(value)||"",
                      });
                    }}
                    className="input1"
                    style={{ width: "90%", padding: "5px" }}
                  />)

              } else {
                return (
                  <input
                    name={formik?.values?.[index]?.delayPlan}
                    placeholder="Type here"
                    value={formik?.values?.[index]?.delayPlan}
                    onBlur={() => {
                      calculateFormula(index);
                    }}
                    onChange={(e) => {
                      let value = e.target?.value.replace(/[^\d]/g, "");
                      formik.setFieldValue(index, {
                        ...y,
                        delayPlan: parseInt(value)||"",
                      });
                    }}
                    className="input1"
                    style={{ width: "90%", padding: "5px" }}
                  />)
              }
            },
          },
        ],
      },
      {
        title: "Closing Stock",
        dataIndex: viewType == "hold" ? "holdClosingStock" : "closingStock",
        width: "120px",
        render: (x, y, index) => <div>{x}</div>
      },
      {
        title: "Closing OutStanding (In Lacs)",
        dataIndex: viewType == "hold" ? "holdClosingOutstanding" : "closingOutStanding",
        width: "120px",
        render: (x, y, index) => <div>{x}</div>
      },
      {
        title: "Books Collection Targets (No. of Trs.)",
        dataIndex: "BooksCollectionTarget",
        editable: true,
        children: [
          ...getTargetT1(),
          ...getTargetT2(),
          ...getTargetT3(),
          ...getTargetT4(),
          ...getTargetT5(),
          ...getTargetT6(),
        ],
      },
      {
        title: "Payout Rate",
        dataIndex: "pr",
        editable: true,
        children: [
          ...getPayoutRateR1(),
          ...getPayoutRateR2(),
          ...getPayoutRateR3(),
          ...getPayoutRateR4(),
          ...getPayoutRateR5(),
          ...getPayoutRateR6(),
        ],
      },
      {
        title: "Payout",
        dataIndex: "payout",
        editable: true,

        children: [
          ...getPayoutP1(),
          ...getPayoutP2(),
          ...getPayoutP3(),
          ...getPayoutP4(),
          ...getPayoutP5(),
          ...getPayoutP6(),
        ],
      },
      {
        title: "Remarks",

        dataIndex: viewType == "hold" ? "holdRemarks" : "reason",
        render: (x, y, index) => {
          if (viewType == "hold") {
            return (
              <input
                name={formik?.values?.[index]?.holdRemarks}
                placeholder="Type here"
                value={formik?.values?.[index]?.holdRemarks}
                onChange={(e) => {
                  let value = e.target?.value;
                  const sanitizedValue = value
                    ?.replace("<script>", "")
                    ?.replace("</script>", "")
                //    .replace(/[^a-zA-Z0-9\s]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    holdRemarks: sanitizedValue,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />)
          } else {
            return (
              <input
                name={formik?.values?.[index]?.reason}
                placeholder="Type here"
                value={formik?.values?.[index]?.reason}
                onChange={(e) => {
                  let value = e.target?.value;
                  const sanitizedValue = value
                    ?.replace("<script>", "")
                    ?.replace("</script>", "")
                //    .replace(/[^a-zA-Z0-9\s]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    reason: sanitizedValue,
                  });
                }}
                className="input1"
                style={{ width: "90%", padding: "5px" }}
              />)
          }
        },
        width: "150px",
      },
      {
        title: "Total Expected Payout",
        dataIndex: "TEP",
        children: [
          {
            title: "Expected Volume",

            dataIndex: viewType == "hold" ? "holdVolume" : "volume",
            render: (x, y, index) => {
              if (viewType == "hold") {
                return (
                  <input
                    name={formik?.values?.[index]?.holdVolume}
                    placeholder="Type here"
                    value={formik?.values?.[index]?.holdVolume}
                    onBlur={() => {
                      calculateFormulaHold(index);
                    }}
                    onChange={(e) => {
                      let value = e.target?.value.replace(/[^\d]/g, "");
                      formik.setFieldValue(index, {
                        ...y,
                        holdVolume: parseInt(value)||"",
                      });
                    }}
                    className="input1"
                    style={{ width: "90%", padding: "5px" }}
                  />)
              } else {
                return (
                  <input
                    name={formik?.values?.[index]?.volume}
                    placeholder="Type here"
                    value={formik?.values?.[index]?.volume}
                    onBlur={() => {
                      calculateFormula(index);
                    }}
                    onChange={(e) => {
                      let value = e.target?.value.replace(/[^\d]/g, "");
                      formik.setFieldValue(index, {
                        ...y,
                        volume: parseInt(value)||"",
                      });
                    }}
                    className="input1"
                    style={{ width: "90%", padding: "5px" }}
                  />)
              }
            },
            width: "120px",
          },
          {
            title: "Per Tr.",
            dataIndex: viewType == "hold" ? "holdPerTr" : "perTr",
            width: "120px",
          },
          {
            title: "Amount",
            dataIndex: viewType == "hold" ? "holdAmount" : "amount",
            width: "120px",
          },
        ],
      },
    ],
    [formik?.values, lockedStatus, codeError, viewType]
  );

  const checkValidationFun = () => {

    let extraValidation = {};
    const finalData=formik?.values?.filter((selectedSchemesFil)=>selectedRowKeys.includes(selectedSchemesFil?.key))
    if(finalData?.length==0){
      return ToastifyShow("Please select the checkbox","error")
    }
    finalData?.map((x, index) => {
      let topTargetDetails = x?.dropdownscheme;
      let errors = [];
      if (topTargetDetails?.length == 0) {
        errors.push("Please select top target");
      }
      if (!x.industry) {
        errors.push("Please enter industry");
      }
      // if (!x.openingStockDays) {
      //   errors.push("Please enter open stock days");
      // }
      // if (!x.outStandingDays) {
      //   errors.push("Please enter outstanding days");
      // }
      if (!x.billing) {
        errors.push("Please enter billing");
      }
      if (!x.installation) {
        errors.push("Please enter installation");
      }
      if (!x.collection) {
        errors.push("Please enter collection");
      }
      if (!x.delayPlan && x?.schemeTypeId == 1) {
        errors.push("Please enter Installtion Plan Nos");
      }
      if (!x.volume) {
        errors.push("Please enter volume");
      }
      if (
        topTargetDetails?.includes("t6") &&
        parseInt(x?.t6 || 0) <=
        parseInt(x?.t5 || 0)
      ) {
        errors.push("T6 should be greater than T5");
      }
      if (
        topTargetDetails?.includes("t5") &&
        parseInt(x?.t5 || 0) <=
        parseInt(x?.t4 || 0)
      ) {
        errors.push("T5 should be greater than T4");
      }
      if (
        topTargetDetails?.includes("t4") &&
        parseInt(x?.t4 || 0) <=
        parseInt(x?.t3 || 0)
      ) {
        errors.push("T4 should be greater than T3");
      }
      if (
        topTargetDetails?.includes("t3") &&
        parseInt(x?.t3 || 0) <=
        parseInt(x?.t2 || 0)
      ) {
        errors.push("T3 should be greater than T2");
      }
      if (
        topTargetDetails?.includes("t2") &&
        parseInt(x?.t2 || 0) <=
        parseInt(x?.t1 || 0)
      ) {
        errors.push("T2 should be greater than T1");
      }
      if (
        topTargetDetails?.includes("t6") &&
        parseInt(x?.r6 || 0) <= parseInt(x?.r5 || 0)
      ) {
        errors.push("R6 should be greater than R5");
      }
      if (
        topTargetDetails?.includes("t5") &&
        parseInt(x?.r5 || 0) <= parseInt(x?.r4 || 0)
      ) {
        errors.push("R5 should be greater than R4");
      }
      if (
        topTargetDetails?.includes("t4") &&
        parseInt(x?.r4 || 0) <= parseInt(x?.r3 || 0)
      ) {
        errors.push("R4 should be greater than R3");
      }
      if (
        topTargetDetails?.includes("t3") &&
        parseInt(x?.r3 || 0) <= parseInt(x?.r2 || 0)
      ) {
        errors.push("R3 should be greater than R2");
      }
      if (
        topTargetDetails?.includes("t2") &&
        parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0)
      ) {
        errors.push("R2 should be greater than R1");
      }
      if (!x.r1) {
        errors.push("Please enter R1");
      }
      if (!x.r2 && topTargetDetails?.includes("t2")) {
        errors.push("Please enter R2");
      }
      if (!x.r3 && topTargetDetails?.includes("t3")) {
        errors.push("Please enter R3");
      }
      if (!x.r4 && topTargetDetails?.includes("t4")) {
        errors.push("Please enter R4");
      }
      if (!x.r5 && topTargetDetails?.includes("t5")) {
        errors.push("Please enter R5");
      }
      if (!x.r6 && topTargetDetails?.includes("t6")) {
        errors.push("Please enter R6");
      }
      if (!x.t1) {
        errors.push("Please enter T1");
      }
      if (!x.t2 && topTargetDetails?.includes("t2")) {
        errors.push("Please enter T2");
      }
      if (!x.t3 && topTargetDetails?.includes("t3")) {
        errors.push("Please enter T3");
      }
      if (!x.t4 && topTargetDetails?.includes("t4")) {
        errors.push("Please enter T4");
      }
      if (!x.t5 && topTargetDetails?.includes("t5")) {
        errors.push("Please enter T5");
      }
      if (!x.t6 && topTargetDetails?.includes("t6")) {
        errors.push("Please enter T6");
      }
      if (errors.length > 0) {
        extraValidation[x?.dealerCode] = errors;
      }
    });

    setCodeError(Object?.keys(extraValidation || {}));

    if (Object?.keys?.(extraValidation)?.length != 0) {
      showErrorDialog(extraValidation);
    } else if (Object?.keys?.(extraValidation)?.length == 0) {
      showModal()
    }

  }

  const checkValidationHoldFun = () => {

    let extraValidation = {};
    const finalData=formik?.values?.filter((selectedSchemesFil)=>selectedRowKeys.includes(selectedSchemesFil?.key))
    if(finalData?.length==0){
      return ToastifyShow("Please select the checkbox","error")
    }
    finalData?.map((x, index) => {
      let topTargetDetails = x?.dropdownscheme;
      let errors = [];
      if (topTargetDetails?.length == 0) {
        errors.push("Please select top target");
      }

      if (!x.holdBilling) {
        errors.push("Please enter billing");
      }
      if (!x.holdIndustry) {
        errors.push("Please enter installation");
      }
      if (!x.holdCollection) {
        errors.push("Please enter collection");
      }
      if (!x.holdDelayPlan && x?.schemeTypeId == 1) {
        errors.push("Please enter Installtion Plan Nos");
      }
      if (!x.holdVolume) {
        errors.push("Please enter volume");
      }
      if (
        x?.holdDrodownScheme?.includes("t6") && x?.holdDrodownScheme?.includes("t5") &&
        parseInt(x?.holdT6 || 0) <=
        parseInt(x?.holdT5 || 0)
      ) {
        errors.push("T6 should be greater than T5");
      }
      if (
        x?.holdDrodownScheme?.includes("t5") && x?.holdDrodownScheme?.includes("t4") &&
        (parseInt(x?.holdT5 || 0) <=
          parseInt(x?.holdT4 || 0))
      ) {
        errors.push("T5 should be greater than T4");
      }
      if (
        x?.holdDrodownScheme?.includes("t4") && x?.holdDrodownScheme?.includes("t3") &&
        parseInt(x?.holdT4 || 0) <=
        parseInt(x?.holdT3 || 0)
      ) {
        errors.push("T4 should be greater than T3");
      }
      if (
        x?.holdDrodownScheme?.includes("t3") && x?.holdDrodownScheme?.includes("t2") &&
        parseInt(x?.holdT3 || 0) <=
        parseInt(x?.holdT2 || 0)
      ) {
        errors.push("T3 should be greater than T2");
      }
      if (
        x?.holdDrodownScheme?.includes("t2") && x?.holdDrodownScheme?.includes("t1") &&
        parseInt(x?.holdT2 || 0) <=
        parseInt(x?.holdT1 || 0)
      ) {
        errors.push("T2 should be greater than T1");
      }
      if (
        x?.holdDrodownScheme?.includes("t6") && x?.holdDrodownScheme?.includes("t5") &&
        parseInt(x?.holdR6 || 0) <= parseInt(x?.holdR5 || 0)
      ) {
        errors.push("R6 should be greater than R5");
      }
      if (
        x?.holdDrodownScheme?.includes("t5") && x?.holdDrodownScheme?.includes("t4") &&
        parseInt(x?.holdR5 || 0) <= parseInt(x?.holdR4 || 0)
      ) {
        errors.push("R5 should be greater than R4");
      }
      if (
        x?.holdDrodownScheme?.includes("t4") && x?.holdDrodownScheme?.includes("t3") &&
        parseInt(x?.holdR4 || 0) <= parseInt(x?.holdR3 || 0)
      ) {
        errors.push("R4 should be greater than R3");
      }
      if (
        x?.holdDrodownScheme?.includes("t3") && x?.holdDrodownScheme?.includes("t2") &&
        parseInt(x?.holdR3 || 0) <= parseInt(x?.holdR2 || 0)
      ) {
        errors.push("R3 should be greater than R2");
      }
      if (
        x?.holdDrodownScheme?.includes("t2") && x?.holdDrodownScheme?.includes("t1") &&
        parseInt(x?.holdR2 || 0) <= parseInt(x?.holdR1 || 0)
      ) {
        errors.push("R2 should be greater than R1");
      }
      if (!x.holdR1) {
        errors.push("Please enter R1");
      }
      if (!x.holdR2 && x?.holdDrodownScheme?.includes("t2")) {
        errors.push("Please enter R2");
      }
      if (!x.holdR3 && x?.holdDrodownScheme?.includes("t3")) {
        errors.push("Please enter R3");
      }
      if (!x.holdR4 && x?.holdDrodownScheme?.includes("t4")) {
        errors.push("Please enter R4");
      }
      if (!x.holdR5 && x?.holdDrodownScheme?.includes("t5")) {
        errors.push("Please enter R5");
      }
      if (!x.holdR6 && x?.holdDrodownScheme?.includes("t6")) {
        errors.push("Please enter R6");
      }
      if (!x.holdT1) {
        errors.push("Please enter T1");
      }
      if (!x.holdT2 && x?.holdDrodownScheme?.includes("t2")) {
        errors.push("Please enter T2");
      }
      if (!x.holdT3 && x?.holdDrodownScheme?.includes("t3")) {
        errors.push("Please enter T3");
      }
      if (!x.holdT4 && x?.holdDrodownScheme?.includes("t4")) {
        errors.push("Please enter T4");
      }
      if (!x.holdT5 && x?.holdDrodownScheme?.includes("t5")) {
        errors.push("Please enter T5");
      }
      if (!x.holdT6 && x?.holdDrodownScheme?.includes("t6")) {
        errors.push("Please enter T6");
      }
      if (errors.length > 0) {
        extraValidation[x?.dealerCode] = errors;
      }
    });

    setCodeError(Object?.keys(extraValidation || {}));

    if (Object?.keys?.(extraValidation)?.length != 0) {
      showErrorDialog(extraValidation);
    } else if (Object?.keys?.(extraValidation)?.length == 0) {
      showModal()
    }

  }
  const handleSubmit = (e) => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const userRole = userData.userRole;


    const handleDropDownSchemeCheckIndex = (x) => {
      let { t1, t2, t3, t4, t5, t6 } = x.reduce(
        (a, v) => ({ ...a, [v]: v }),
        {}
      );

      let data =
        t1 && !t2 && !t3 && !t4 && !t5 && !t6
          ? "t1"
          : t1 && t2 && !t3 && !t4 && !t5 && !t6
            ? "t2"
            : t1 && t2 && t3 && !t4 && !t5 && !t6
              ? "t3"
              : t1 && t2 && t3 && t4 && !t5 && !t6
                ? "t4"
                : t1 && t2 && t3 && t4 && t5 && !t6
                  ? "t5"
                  : t1 && t2 && t3 && t4 && t5 && t6
                    ? "t6"
                    : "";

      return data;
    };

    const finalData=formik?.values?.filter((selectedSchemesFil)=>selectedRowKeys.includes(selectedSchemesFil?.key))
    console.log(finalData,"finalDatafinalData")
    if(finalData?.length==0){
      return ToastifyShow("Please select the checkbox","error")
    }
    if (finalData?.[0]?.holdStatus == 9) {
      let data = finalData?.map((x) => {
        return {
          dealerCode: x.dealerCode,
          dealerId: x.dealerId,
          dealerName: x.dealerName,
          dealerLocation: x.dealerLocation,
          stateName: x.stateName,
          territoryManager: x.territoryManager || "",
          startMonth: x.startMonth, //moment(x.startMonth).format("MMM YY"),
          endMonth: x.endMonth, //moment(x.endMonth).format("MMM YY"),
          reason: x.reason,
          billing: x.holdBilling ? +x.holdBilling : 0,
          installation: x.holdInstallation ? +x.holdInstallation : 0,
          collection: Math.round(x.holdCollection ? +x.holdCollection : 0),
          delayPlan: x.holdDelayPlan || 0,
          schemeTypeId: x.schemeTypeId,
          bookCollectionId: x.bookCollectionId,
          payoutId: x.payoutId,
          schemeId: x.schemeId,
          schemeNo: x.schemeNo,
          schemePlanId: x.schemePlanId,
          openingStockDays: x.openingStockDays,
          outStandingDays: x.outStandingDays ? parseInt(x.outStandingDays) : 0,
          ...(handleDropDownSchemeCheckIndex(x?.holdDrodownScheme) == "t1"
            ? {

              T1: +x.holdT1 || 0,
              T2: 0,
              T3: 0,
              T4: 0,
              T5: 0,
              T6: 0,
              T2t1: 0,
              T3t2: 0,
              T4t3: 0,
              T5t4: 0,
              T6t5: 0,
              P1: +x.holdP1 || 0,
              P2: 0,
              P3: 0,
              p4: 0,
              P5: 0,
              P6: 0,
              R1: +x?.holdR1 || 0,
              R2: 0,
              R3: 0,
              R4: 0,
              R5: 0,
              R6: 0,
            }
            : handleDropDownSchemeCheckIndex(x?.holdDrodownScheme) == "t2"
              ? {
                T1: +x.holdT1 || 0,
                T2: +x.holdT2 || 0,
                T3: 0,
                T4: 0,
                T5: 0,
                T6: 0,
                T2t1: +x.holdT2t1 || 0,
                T3t2: 0,
                T4t3: 0,
                T5t4: 0,
                T6t5: 0,
                P1: +x.holdP1 || 0,
                P2: +x.holdP2 || 0,
                P3: 0,
                p4: 0,
                P5: 0,
                P6: 0,
                R1: +x?.holdR1 || 0,
                R2: +x?.holdR2 || 0,
                R3: 0,
                R4: 0,
                R5: 0,
                R6: 0,
              }
              : handleDropDownSchemeCheckIndex(x?.holdDrodownScheme) == "t3"
                ? {
                  T1: +x.holdT1 || 0,
                  T2: +x.holdT2 || 0,
                  T3: +x.holdT3 || 0,
                  T4: 0,
                  T5: 0,
                  T6: 0,
                  T2t1: +x.holdT2t1 || 0,
                  T3t2: +x.holdT3t2 || 0,
                  T4t3: 0,
                  T5t4: 0,
                  T6t5: 0,
                  P1: +x.holdP1 || 0,
                  P2: +x.holdP2 || 0,
                  P3: +x.holdP3 || 0,
                  p4: 0,
                  P5: 0,
                  P6: 0,
                  R1: +x?.holdR1 || 0,
                  R2: +x?.holdR2 || 0,
                  R3: +x?.holdR3 || 0,
                  R4: 0,
                  R5: 0,
                  R6: 0,
                }
                : handleDropDownSchemeCheckIndex(x?.holdDrodownScheme) == "t4"
                  ? {
                    T1: +x.holdT1 || 0,
                    T2: +x.holdT2 || 0,
                    T3: +x.holdT3 || 0,
                    T4: +x.holdT4 || 0,
                    T5: 0,
                    T6: 0,
                    T2t1: +x.holdT2t1 || 0,
                    T3t2: +x.holdT3t2 || 0,
                    T4t3: +x.holdT4t3 || 0,
                    T5t4: 0,
                    T6t5: 0,
                    P1: +x.holdP1 || 0,
                    P2: +x.holdP2 || 0,
                    P3: +x.holdP3 || 0,
                    P4: +x.holdP4 || 0,
                    P5: 0,
                    P6: 0,
                    R1: +x?.holdR1 || 0,
                    R2: +x?.holdR2 || 0,
                    R3: +x?.holdR3 || 0,
                    R4: +x?.holdR4 || 0,
                    R5: 0,
                    R6: 0,
                  }
                  : handleDropDownSchemeCheckIndex(x?.holdDrodownScheme) == "t5"
                    ? {
                      T1: +x.holdT1 || 0,
                      T2: +x.holdT2 || 0,
                      T3: +x.holdT3 || 0,
                      T4: +x.holdT4 || 0,
                      T5: +x.holdT5 || 0,
                      T6: 0,
                      T2t1: +x.holdT2t1 || 0,
                      T3t2: +x.holdT3t2 || 0,
                      T4t3: +x.holdT4t3 || 0,
                      T5t4: +x.holdT5t4 || 0,
                      T6t5: 0,
                      P1: +x.holdP1 || 0,
                      P2: +x.holdP2 || 0,
                      P3: +x.holdP3 || 0,
                      P4: +x.holdP4 || 0,
                      P5: +x.holdP5 || 0,
                      P6: 0,
                      R1: +x?.holdR1 || 0,
                      R2: +x?.holdR2 || 0,
                      R3: +x?.holdR3 || 0,
                      R4: +x?.holdR4 || 0,
                      R5: +x?.holdR5 || 0,
                      R6: 0,
                    }
                    : handleDropDownSchemeCheckIndex(x?.holdDrodownScheme) == "t6"
                      ? {
                        T1: +x.holdT1 || 0,
                        T2: +x.holdT2 || 0,
                        T3: +x.holdT3 || 0,
                        T4: +x.holdT4 || 0,
                        T5: +x.holdT5 || 0,
                        T6: +x?.holdT6 || 0,
                        T2t1: +x.holdT2t1 || 0,
                        T3t2: +x.holdT3t2 || 0,
                        T4t3: +x.holdT4t3 || 0,
                        T5t4: +x.holdT5t4 || 0,
                        T6t5: +x.holdT6t5 || 0,
                        P1: +x.holdP1 || 0,
                        P2: +x.holdP2 || 0,
                        P3: +x.holdP3 || 0,
                        P4: +x.holdP4 || 0,
                        P5: +x.holdP5 || 0,
                        P6: +x.holdP6 || 0,
                        R1: +x?.holdR1 || 0,
                        R2: +x?.holdR2 || 0,
                        R3: +x?.holdR3 || 0,
                        R4: +x?.holdR4 || 0,
                        R5: +x?.holdR5 || 0,
                        R6: +x?.holdR6 || 0,
                      }
                      : {
                        T1: 0,
                        T2: 0,
                        T3: 0,
                        T4: 0,
                        T5: 0,
                        T6: 0,
                        T2t1: 0,
                        T3t2: 0,
                        T4t3: 0,
                        T5t4: 0,
                        T6t5: 0,
                        P1: 0,
                        P2: 0,
                        P3: 0,
                        P4: 0,
                        P5: 0,
                        P6: 0,
                        R1: 0,
                        R2: 0,
                        R3: 0,
                        R4: 0,
                        R5: 0,
                        R6: 0,
                      }),
          Volume: x.holdVolume || 0,
          TopTarget: x?.holdDrodownScheme?.toString(),
          Industry: x?.holdIndustry || 0,
          DelayPlan: x?.holdDelayPlan || 0,
          Billing: x.holdBilling ? +x.holdBilling : 0,
          Installation: x.holdInstallation ? +x.holdInstallation : 0,
          Collection: Math.round(x.holdCollection ? +x.holdCollection : 0),
          PerTr: Math.round(x.holdPerTr) || 0,
          Amount: x.holdAmount,
          HoldReason: x?.holdRemarks,
          closingStock: x?.holdClosingStock,
          closingOutStanding: x?.holdClosingOutstanding,
          status: 1,
          userid: +userId,
          username: userName,
          userrole: userRole,
          UpperCap: x?.holdUpperCap,
          InsPerTarget: x?.holdInsPerTarget,
          InsTarget: x?.InsTarget,
          LastOneMonthSlabAchieved: x?.holdLastOneMonthSlabAchieved,
          LastMonthColQty: x?.holdLastMonthColQty,
          holdLastTwoMonthSlabAchieved: x?.holdLastTwoMonthSlabAchieved,
          LastTwoMonthColQty: x?.LastTwoMonthColQty,
          holdStatus: x?.holdStatus,
        }
      });
      dispatch(postUpdateHoldScheme(data)).then(async (data) => {
        if (data?.payload?.errorMessage == "Hold Scheme Updated successfully!!") {
          //  await dispatch(resetSaveModification())
          await message.success({
            content: "Scheme updated successfully!!!",
            className: "custom-message",
          });
          await backBtn()
        }
        // navigate(-1);
      });
    } else {
      let data = finalData?.map((x) => {
        return {
          ...x,
          dealerCode: x.dealerCode,
          dealerId: x.dealerId,
          dealerName: x.dealerName,
          dealerLocation: x.dealerLocation,
          stateName: x.stateName,
          territoryManager: x.territoryManager || "",
          startMonth: x.startMonth, //moment(x.startMonth).format("MMM YY"),
          endMonth: x.endMonth, //moment(x.endMonth).format("MMM YY"),
          reason: x.reason,
          // status: y != "draft" ? 1 : 0,
          // state: x.stateId,
          billing: x.billing ? +x.billing : 0,
          installation: x.installation ? +x.installation : 0,
          collection: Math.round(x.collection ? +x.collection : 0),
          delayPlan: x.delayPlan || 0,
          // isSAP: true,
          // isActive: true,
          // schemePlanStartMonth: startDate,
          // schemePlanEndMonth: endDate,
          schemeTypeId: x.schemeTypeId,
          bookCollectionId: x.bookCollectionId,
          payoutId: x.payoutId,
          schemeId: x.schemeId,
          schemeNo: x.schemeNo,
          schemePlanId: x.schemePlanId,
          openingStockDays: x.openingStockDays,
          outStandingDays: x.outStandingDays ? parseInt(x.outStandingDays) : 0,
          ...(handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1"
          ? {
            
            t1: +x.t1 || 0,
            t2: 0,
            t3: 0,
            t4: 0,
            t5: 0,
            t6: 0,
            t2_T1: 0,
            t3_T2: 0,
            t4_T3: 0,
            t5_T4: 0,
            t6_T5: 0,
            p1: +x.p1 || 0,
            p2: 0,
            p3: 0,
            p4: 0,
            p5: 0,
            p6: 0,
            r1: +x?.r1 || 0,
            r2: 0,
            r3: 0,
            r4: 0,
            r5: 0,
            r6: 0,
          }
          : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2"
            ? {
              t1: +x.t1 || 0,
              t2: +x.t2 || 0,
              t3: 0,
              t4: 0,
              t5: 0,
              t6: 0,
              t2_T1: +x.t2_T1 || 0,
              t3_T2: 0,
              t4_T3: 0,
              t5_T4: 0,
              t6_T5: 0,
              p1: +x.p1 || 0,
              p2: +x.p2 || 0,
              p3: 0,
              p4: 0,
              p5: 0,
              p6: 0,
              r1: +x?.r1 || 0,
              r2: +x?.r2 || 0,
              r3: 0,
              r4: 0,
              r5: 0,
              r6: 0,
            }
            : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3"
              ? {
                t1: +x.t1 || 0,
                t2: +x.t2 || 0,
                t3: +x.t3 || 0,
                t4: 0,
                t5: 0,
                t6: 0,
                t2_T1: +x.t2_T1 || 0,
                t3_T2: +x.t3_T2 || 0,
                t4_T3: 0,
                t5_T4: 0,
                t6_T5: 0,
                p1: +x.p1 || 0,
                p2: +x.p2 || 0,
                p3: +x.p3 || 0,
                p4: 0,
                p5: 0,
                p6: 0,
                r1: +x?.r1 || 0,
                r2: +x?.r2 || 0,
                r3: +x?.r3 || 0,
                r4: 0,
                r5: 0,
                r6: 0,
              }
              : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t4"
                ? {
                  t1: +x.t1 || 0,
                  t2: +x.t2 || 0,
                  t3: +x.t3 || 0,
                  t4: +x.t4 || 0,
                  t5: 0,
                  t6: 0,
                  t2_T1: +x.t2_T1 || 0,
                  t3_T2: +x.t3_T2 || 0,
                  t4_T3: +x.t4_T3 || 0,
                  t5_T4: 0,
                  t6_T5: 0,
                  p1: +x.p1 || 0,
                  p2: +x.p2 || 0,
                  p3: +x.p3 || 0,
                  p4: +x.p4 || 0,
                  p5: 0,
                  p6: 0,
                  r1: +x?.r1 || 0,
                  r2: +x?.r2 || 0,
                  r3: +x?.r3 || 0,
                  r4: +x?.r4 || 0,
                  r5: 0,
                  r6: 0,
                }
                : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t5"
                  ? {
                    t1: +x.t1 || 0,
                    t2: +x.t2 || 0,
                    t3: +x.t3 || 0,
                    t4: +x.t4 || 0,
                    t5: +x.t5 ? +x.t5 : 0,
                    t6: 0,
                    t2_T1: +x.t2_T1 || 0,
                    t3_T2: +x.t3_T2 || 0,
                    t4_T3: +x.t4_T3 || 0,
                    t5_T4: +x.t5_T4 || 0,
                    t6_T5: 0,
                    p1: +x.p1 || 0,
                    p2: +x.p2 || 0,
                    p3: +x.p3 || 0,
                    p4: +x.p4 || 0,
                    p5: +x.p5 || 0,
                    p6: 0,
                    r1: +x?.r1 || 0,
                    r2: +x?.r2 || 0,
                    r3: +x?.r3 || 0,
                    r4: +x?.r4 || 0,
                    r5: +x?.r5 || 0,
                    r6: 0,
                  }
                  : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t6"
                    ? {
                      t1: +x.t1 || 0,
                      t2: +x.t2 || 0,
                      t3: +x.t3 || 0,
                      t4: +x.t4 || 0,
                      t5: +x.t5 || 0,
                      t6: +x?.t6 || 0,
                      t2_T1: +x.t2_T1 || 0,
                      t3_T2: +x.t3_T2 || 0,
                      t4_T3: +x.t4_T3 || 0,
                      t5_T4: +x.t5_T4 || 0,
                      t6_T5: +x.t6_T5 || 0,
                      p1: +x.p1 || 0,
                      p2: +x.p2 || 0,
                      p3: +x.p3 || 0,
                      p4: +x.p4 || 0,
                      p5: +x.p5 || 0,
                      p6: +x.p6 || 0,
                      r1: +x?.r1 || 0,
                      r2: +x?.r2 || 0,
                      r3: +x?.r3 || 0,
                      r4: +x?.r4 || 0,
                      r5: +x?.r5 || 0,
                      r6: +x?.r6 || 0,
                    }
                    : {
                      t1: 0,
                      t2: 0,
                      t3: 0,
                      t4: 0,
                      t5: 0,
                      t6: 0,
                      t2_T1: 0,
                      t3_T2: 0,
                      t4_T3: 0,
                      t5_T4: 0,
                      t6_T5: 0,
                      p1: 0,
                      p2: 0,
                      p3: 0,
                      p4: 0,
                      p5: 0,
                      p6: 0,
                      r1: 0,
                      r2: 0,
                      r3: 0,
                      r4: 0,
                      r5: 0,
                      r6: 0,
                    }),
          perTr: Math.round(x.perTr) || 0,
          amount: x.amount,
          volume: x.volume,
          status: 1,
          industry: x.industry ? parseInt(x.industry) : 0,
          oldBillingQty: x.oldBillingQty,
          oldInstallation: x.oldInstallation,
          oldCollection: Math.round(x.oldCollection),
          oldOpeningStock: x.oldOpeningStock,
          closingOutStandingMonth_1: x.closingOutStandingMonth_1,
          closingOutStandingMonth_2: x.closingOutStandingMonth_2,
          closingOutStandingMonth_3: x.closingOutStandingMonth_3,
          bgLacsCurrentYear: x.bgLacsCurrentYear,
          userid: +userId,
          username: userName,
          userrole: userRole,
          TopTarget: x?.dropdownscheme?.toString(),
          closingStock: x?.closingStock,
          closingOutStanding: x?.closingOutStanding,
        }
      });
      dispatch(updateSchemeGenerateApiAsync(data))
        .then(async (result) => {

          if (result?.payload?.errorMessage == "Scheme updated successfully!!") {
            //  await dispatch(resetSaveModification())
            await message.success({
              content: "Scheme updated successfully!!!",
              className: "custom-message",
            });
            await backBtn()
          }
        })
    }
  };

  return (
    <div className="create_scheme_wrap general-bscheme">
      <Button type="primary" icon={<ArrowBackRounded />} size="middle" onClick={() => {
        backBtn(false)
      }} />
      {loadingSaveHoldState=="pending"||loadingScheme=="pending"&&<FullScreenLoader />}
      <div className="table_scheme_wrap generate-finace-report">
        <Form>
          <Table
            // className="finance"

            loading={loadingState == "pending" ? true : false}
            columns={defaultColumns}
            dataSource={formik?.values}
            bordered
            onChange={handleChange}
            pagination={false}
            rowClassName={(record, index) => codeError?.includes(record?.dealerCode) ? "data-row active-row" : ""}
            rowSelection={{
              selectedRowKeys,
              onChange: (newSelectedRowKeys, selectedRow) => {
                console.log(newSelectedRowKeys,"newSelectedRowKeys",selectedRow)
                setSelectedRowKeys(newSelectedRowKeys);
              },
              getCheckboxProps: (record) => {
                return {
                  dealerId: record.dealerId,
                };
              },
            }}
            scroll={{ x: 'max-content' }}
            size="middle"
          />
        </Form>
        <div className="pt-3 d-flex f-reverse">
          <Button
            type="default"
            shape="round"
            style={{
              margin: "10px",
              background: "#FCE838",
              fontWeight: "700",
              textTransform: "uppercase",
              border: "none",
              padding: "20px",
            }}
            size="large"
            onClick={() => viewType == "hold" ? checkValidationHoldFun() : checkValidationFun()}
          >
            Update & Submit
          </Button>
        </div>
      </div>

      <Modal open={isModalOpen}
        onCancel={() => {
          
          handleSubmit()
          handleOk()
        }} 
        onOk={handleCancel}
        maskClosable={false}
        destroyOnClose={true}
       cancelText="Yes"
        okText="No"
      >
        <h2>Are you sure you want to submit</h2>
      </Modal>



      <ValidationDifferentSchemeError

        open={openErrorDialog.open}
        onClose={() => {
          setOpenErrorDialog({ open: false, errorsFields: {} });
        }}
        errorsFields={openErrorDialog?.errorsFields}

      />
      <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
        <p className="custom-warning">Warning !</p>
        <p>
          {errorItem.firstItem} should be greater then {errorItem.secondItem}
        </p>
      </SMDialog>
    </div>
  );
};
export default AwaitingCollectionEdit;
