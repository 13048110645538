import { Table, Tag, Space, Row, Col, Button, message, Pagination, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import XLSX from 'xlsx-js-style';
import timezone from 'dayjs/plugin/timezone'
import mergeScheme from "../../../../utils/mergeUnmerge/mergeScheme";
import unMergeScheme from "../../../../utils/mergeUnmerge/unMergeScheme";
import { ReactComponent as MergeIcon } from "../../../../assets/vector/merge.svg";
import { ReactComponent as UnmergeIcon } from "../../../../assets/vector/unmerge.svg";
import MergeModal from "../../../../components/modal/alertModal/ConfirmModal";
import { generatedScheme } from "../../../../redux/installation/AllGeneratedScheme";
import axiosAPI from "../../../../services/axiosApi";
import { getCurrentFiscalYear, getCurrentFiscalYear1 } from "../../../../utils/dateFormatter";
import ToastifyShow, { TextLocalString } from "../../../../components/ToastifyShow";
import { ReactComponent as Download } from "../../../../assets/vector/download.svg";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";
import { getExpiredStatusAMGR, getExpiredStatusSADM, getExpiredStatusZADM } from "../../../../components/header/schemeExpiredDetails";
import { render } from "@testing-library/react";
dayjs.extend(utc)
dayjs.extend(timezone)


const SingleTarget = ({ payload, selectedStatus, selectedSchemeType, setSelectedRowKeys, selectedRowKeys, reference,startMonth,dealerDetails,dateValue  }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const currentFiscalYear = getCurrentFiscalYear1(startMonth)
  const [schemeIdNo, setSchemeIdNo] = useState([])
  const [loading, setLoading] = useState(false);
  const [openMergeModal, setOpenMergeModal] = useState(false)
  const [openUnMergeModal, setOpenUnMergeModal] = useState(false)
  const [disable, setDisable] = useState(false)
  const [enable, setEnable] = useState(false)
  const [edit, setEdit] = useState(true)
  const [listItem, setlistItem] = useState([]);
  const [messageApi, contextHolder] = message.useMessage()
  const InitAllSchemeData = {
    currentYear: dayjs().year(),
    // collectionType: 'Collection',
    schemeType: "",
    // dealerCode: [],
    month: 0,
    monthEnd: 0,
    pageSize: 150,
    pageNumber: 1,
    sortingOrder: "ascend",
    sortingType: "DealerCode"
  };
  const [collectionData, setCollectionData] = useState(InitAllSchemeData);
  const [mngrdata, setMngrdata] = useState({
    schemelist: [],
  });

  const listData = useSelector((state) => state.generatedScheme.data);
  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const userRole = userData.userRole;
  const userId = userData.userId;
  const userName = userData.userName;
  let dayExpired=userRole=="SADM"?getExpiredStatusSADM():userRole=="AMGR"?getExpiredStatusAMGR():getExpiredStatusZADM()
  const handleChange = (pagination, filters, sorter) => {
    setCollectionData({ ...collectionData, "sortingOrder": sorter?.order, "sortingType": sorter?.columnKey })
  }

  const handleSingleEdit = (record) => {
      navigate("/installation/allSchemes/edit",{state:record?.scheme});
  };
  
  const convertUtcToLocal = (utcDate) => {
    return utcDate;
    // const localDate = new Date(utcDate);
    // return localDate.toLocaleString(""); // Convert to local date and time string
  };

  const columns = [
    {
      title: "Scheme No.",
      dataIndex: "scheme",
      width: "130px",
      fixed: "left",
      defaultSortOrder: "ascend",
      //  sorter: (a, b) => a.name.length - b.name.length,
      sorter: true,
      key: "SchemeNo",
      render: (item) => {
        let color = " ";
        const dataItem=item?.holdStatus==9?item?.holdStatus:item.status
        switch (dataItem) {
          case 0:
            color = "#FCE838"; //inProgress
            break;
          case 1:
            color = "#FCE838"; //inProgress
            break;
          case 2:
            color = "#51DE4E"; //Approved
            break;
          case 3:
            color = "#9999ff"; //Withdraw
            break;
          case 4:
            color = "#FF3232"; //Cancel
            break;
          case 5:
            color = "#FF3232"; //Reject
            break;
          case 7:
            color = "#bcb38d"; //Deviation
            break;
          case 8:
            color = "rgb(255, 0, 255)" //Auto Reject
            break;
            case 9:
              color = "#9999ff"; //hold
              break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: "Dealer Code",
      dataIndex: "code",
      //   sorter: true,
      fixed: "left",
      width: "130px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerCode",
    },
    {
      title: "Dealer Name",
      dataIndex: "name",
      //   sorter: true,
      fixed: "left",
      width: "130px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerName"
    },
    {
      title: "State",
      dataIndex: "state",
      //   sorter: true,
      width: "130px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "StateName",
    },
    {
      title: "Dealer Location",
      dataIndex: "Location",
      width: "130px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "DealerLocation",
      // filters: [
      //   {
      //     text: "Amritsar",
      //     value: "Amritsar",
      //   },
      //   {
      //     text: "Zirakpur",
      //     value: "Zirakpur",
      //   },
      // ],
      onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },

    {
      title: "Territory Manager",
      dataIndex: "Manager",
      //   sorter: true,
      width: "130px",
      sorter: true,
      defaultSortOrder: "ascend",
      key: "TerritoryManager",
    },
    {
      title: "Start Month",
      dataIndex: "StartMonth",
      //   sorter: true,
      width: "70px",
    },
    {
      title: "End Month",
      dataIndex: "EndMonth",
      //   sorter: true,
      width: "70px",
    },
    {
      title: "Actual Installation Last Year Month",
      dataIndex: "actual_Installation_Last_Year_Month",
      //   sorter: true,
      width: "150px",
      render: (_, item) => item.actual_Installation_Last_Year_Month,
    },
    {
      title: "Installation Target",
      dataIndex: "Target",
      width: "70px",
      render:(x)=><div>{TextLocalString(x)}</div>
    },

    {
      title: "inc. Per Tractor",
      dataIndex: "incPerTarget",
      width: "80px",
      render:(x)=><div>{TextLocalString(x)}</div>
    },
    {
      title: "Payout",
      dataIndex: "p1",
      width: "80px",
      render:(x)=><div>{TextLocalString(x)}</div>
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      width: '120px'

    },
    {
      title: "HOLD REASON",
      dataIndex: "holdReason",
      key: "holdReason",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
                  width: '120px',
      shouldCellUpdate: (record, prevRecord) =>
        record.holdReason !== prevRecord.holdReason,
      render: (data) => <Tooltip title={data}>{data || ""}</Tooltip>,
    },
    {
      title: "Created Date",
      width: "130px",
      dataIndex: "createdDate",
      defaultSortOrder: "ascend",
      sorter: (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
      sortDirections: ['descend', 'ascend'],
      render: (createdDate) => convertUtcToLocal(createdDate),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: '90px',

      render: (_, record) => (
        !dayExpired&& <Button
          // type="primary"
          disabled={(record.status == 1 || record.status == 3 || record.status == 0 || schemeIdNo.length > 1)||record.scheme.holdStatus==10} // Disable the button based on the condition         
          onClick={() => handleSingleEdit(record)}> Edit
        </Button>
      ),
    },
  ]

  const fetchGeneratedScheme = async (demoParams) => {
    let result = await axiosAPI.post(`SchemeGenerate/GetSchemeGenerate`, demoParams)
    setlistItem(result.data.data)
    dispatch(generatedScheme(result.data));
    if (result?.data?.data?.data && result?.data?.data?.data?.length === 0) setDataSource([]);
  };

  useEffect(() => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    let lastTwoDigits = dateValue % 100;
    const demoParams = {
      ...payload,
      fyear:lastTwoDigits?("FY" + lastTwoDigits):currentFiscalYear,
      schemeType: selectedSchemeType,
      status: selectedStatus,
      userid: userId,
      year: new Date().getFullYear(),
      pageSize: collectionData?.pageSize,
      pageNumber: collectionData?.pageNumber,
      sortingOrder: collectionData.sortingOrder == "descend" ? "DESC" : "ASC",
      sortingType: collectionData.sortingType || "DealerCode",
      dealerLocation:dealerDetails?.location,
      dealerCode:dealerDetails?.code
    };

    if (selectedStatus === "") { delete demoParams.status }
    const timer=setTimeout(()=>{
    fetchGeneratedScheme(demoParams);
  },500)
  return () => clearTimeout(timer);
  }, [payload, selectedStatus, collectionData,dealerDetails,dateValue]);

  useEffect(() => {
    const data = [];
    listData.data?.data?.filter((item) => item.schemeNo != null).map((item, index) => {

      for (let i = 0; i < 1; i++) {
        let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
        let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
        let createDate = dayjs(item.createdDate).utc('z').local().tz(dayjs.tz.guess()).format("MMM D, YYYY");
        data.push({
          status: item.status,
          key: item.schemeId,
          scheme: item,
          code: item.dealerCode,
          name: item.dealerName,
          Location: item.dealerLocation,
          state: item.stateName,
          Manager: item.territoryManager,
          StartMonth: formattedStartDate,
          EndMonth: formattedEndDate,
          UC: item?.upperCap,
          Target: item?.installationTarget,
          p1: item?.p1,
          p2: item?.p2,
          r1: item?.r1,
          r2: item?.r2,
          t1: item?.t1,
          t2: item?.t2,
          incPerTarget: item?.incPerTarget,
          reason: item.reason,
          createdDate: createDate,
          actual_Installation_Last_Year_Month:item?.actual_Installation_Last_Year_Month,
          holdReason:item.holdReason
        })
      }

      setDataSource(data)
    })
  }, [listItem])

  const hasSelected = selectedRowKeys.length > 0;
  const handleUnMergeModal = () => {
    if (schemeIdNo.length === 0) {
      return message.warning("Select the merged scheme first")
    } else {
      setOpenUnMergeModal(!openUnMergeModal)
    }
  }
  useEffect(() => {
    const value = schemeIdNo.map(item => item.scheme.isMerge)
    const status = schemeIdNo.some(item => item.scheme.status === 1 || item.scheme.status === 3)

    value.includes(true) ? setEdit(false) : setEdit(edit)
    setDisable(status ? status : value.includes(true) && !value.includes(false) ? true : value.includes(true))
    setEnable(status ? status : !value.includes(true) && value.includes(false) ? true : value.includes(false))
  }, [schemeIdNo])

  const handleOk = async () => {
    await mergeScheme(schemeIdNo, userName, userId)
    setLoading(false)
    setOpenMergeModal(false)
  }
  const checkDate = async () => {
    let startMonth = ''
    let endMonth = ''
    for (let i = 0; i <= schemeIdNo.length - 1; i++) {
      for (let j = i + 1; j <= schemeIdNo.length - 1; j++) {
        if (schemeIdNo[i].StartMonth === schemeIdNo[j].StartMonth && schemeIdNo[i].EndMonth === schemeIdNo[j].EndMonth) {
          const monthStart = schemeIdNo[i].StartMonth.split("'")[0]
          const startYear = schemeIdNo[i].StartMonth.split("'")[1]
          const monthEnd = schemeIdNo[i].EndMonth.split("'")[0]
          const endYear = schemeIdNo[i].EndMonth.split("'")[1]
          startMonth = dayjs(`${monthStart} 20${startYear}`).format()
          endMonth = dayjs(`${monthEnd} 20${endYear}`).format()

        }
      }
    }
    if (startMonth === '') {
      return {
        error: "Scheme can't be merged due to different start  and end month"
      }
      // return message.warning("Scheme can't be merged due to different start  and end month")
    }
    return {
      error: ''
    }
  }
  const handleMerge = async (e, allData) => {
    try {
      if (schemeIdNo.length === 0) {
        return message.warning("Select Schemes to be merged")
      }
      const errorMessage = await checkDate()
      if (!!errorMessage?.error) {
        throw new Error(errorMessage.error)
      }
      if (schemeIdNo.length <= 4) {
        setOpenMergeModal(!openMergeModal)
      }
      else {
        message.warning("More than 4 Schemes can't be merged")
      }
    } catch (e) {
      message.warning(e.message)
    }


  }
  const handleUnMerge = async (e, allData) => {

    await unMergeScheme(schemeIdNo, userName, userId)
    setLoading(false)
    setOpenUnMergeModal(false)
  }
  const captalizeObjKeys = obj => Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
  );

  const handleDownload = () => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };

    const data = dataSource.map(rec => rec.scheme).map(item => {
      let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
      let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
      let createDate = dayjs(item.createdDate).utc('z').local().tz(dayjs.tz.guess()).format("MMM D, YYYY");
      return captalizeObjKeys({
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month: formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: item.installationTarget,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason,
        holdReason: item.holdReason||"",
        Created_Date: createDate,
      })
    })
    if (data?.length == 0) {
      ToastifyShow("Something went wrong", "error")
      return false
    }
    let tab_text = `<table>
    <thead>
    <tr>
        <th>SCHEME NO</th>
        <th>DEALER CODE</th>
        <th>DEALER NAME</th>
        <th>STATE</th>
        <th>DEALER LOCATION</th>
        <th>TERRITORY MANAGER</th>
        <th>START MONTH</th>
        <th>END MONTH</th>
        <th>ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th>INSTALLATION TARGET</th>
        <th>INC.PER TRACTOR</th>
        <th>PAYOUT</th>
        <th>REMARKS</th>
                 <th>HOLD REASON</th>
        <th>CREATED DATE</th>
        </tr>
    </thead>`;

    data?.forEach((x) => {
      tab_text += `<tr ><td>${x?.SCHEME_NO}</td>
  <td>${x?.DEALER_CODE}</td>
  <td>${x?.DEALER_NAME}</td>
  <td>${x?.STATE}</td>
  <td>${x?.DEALER_LOCATION}</td>
  <td>${x?.TERRITORY_MANAGER}</td>
  <td>${x?.START_MONTH}</td>
  <td>${x?.END_MONTH}</td>
  <td>${x?.ACTUAL_INST_LAST_YEAR_MONTH}</td>
  <td>${x?.INSTALLATION_TARGET}</td>
  <td>${x?.INC_PER_TRACTOR}</td>
  <td>${x?.PAYOUT}</td>
  <td>${x?.REMARKS}</td>
      <td>${x?.HOLDREASON}</td>
  <td>${x?.CREATED_DATE}</td>
  </tr>`;
    });


    let totals = {
      installationTarget: 0,
      incPerTarget: 0,
      p1: 0,
      reason:""
     
  };

  dataSource.forEach((item) => {
      for (let key in totals) {
          totals[key] += parseInt(item.scheme[key]) || 0 ;
      }
  });
  totals.incPerTarget =  totals.installationTarget !== 0 ? parseInt(totals.p1 / totals.installationTarget):0;

    let totalsRow = "<tr><td colspan='9' >Total</td>";
    for (let key in totals) {
      if(key != 'reason'){
        if (key.startsWith('incPer') ) {
          totalsRow += `<td>${totals[key]}</td>`;
      } else {
          totalsRow += `<td>${totals[key]}</td>`;
      }
      }else{
       totalsRow  += `<td></td>`;
      } 
    }
 
    totalsRow += "</tr>";
    tab_text += totalsRow;
    tab_text += "</table>";
    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    document.body.removeChild(elt);

     // Add style to total row
     const allColumns = data.reduce((columns, item) => {
      Object.keys(item).forEach(key => {
        if (!columns.includes(key)) {

          columns.push(key);
        }
      });
      return columns;
    }, []);

    function getAlphabetEquivalent(number) {
      if (number < 1 || number > 26) { return "Invalid input"; }
      return String.fromCharCode(number + 64);
    }
    let excelArr = []
    for (let index = 0; index < allColumns.length; index++) {
      let ele = `${getAlphabetEquivalent(index + 1)}${(data.length + 2).toString()}`
      excelArr.push(ele);

    }

    Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
      excelArr
        .includes(cell)).forEach(cell => {
          wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
        });


    // Dynamically set column widths
    wb.Sheets[wb.SheetNames[0]]['!cols'] = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
     
    ];
    // generate file
    XLSX.writeFile(wb, `Single_target_scheme_${new Date().toJSON().slice(0, 10)}.xlsx`);

  }

  const tableHeader = (d) => {

    return (
      <Row className="table_sub_buttons">
        <Col>
          {/* <Button
            disabled={disable}
            icon={<MergeIcon />}
            type="text"
            onClick={(e) => handleMerge(e, d)}
          >
            <span>Merge</span>
          </Button> */}
        </Col>
        <Col>
          {/* <Button
            disabled={enable}
            icon={<UnmergeIcon />}
            type="text"
            onClick={(e) => handleUnMergeModal()}
          >
            Unmerge
          </Button> */}
        </Col>
        <Col className="d-none">
          <Button
            disabled={dataSource?.length == 0}
            icon={<Download />}
            type="text"
            ref={reference}
            onClick={handleDownload}
          >
            Download
          </Button>
        </Col>
      </Row>
    );
  };
  return (
    <>
      <div>
        <Table

          title={tableHeader}
          summary={(pageData) => {

            const fieldSum = {
              target: 0,
              incPerTarget: 0,
              payout: 0,
            }
            pageData.forEach(({
              Target,
              incPerTarget,
              p1,
            }) => {
              fieldSum.target += Target ? parseInt(Target) : 0;
              fieldSum.incPerTarget += incPerTarget ? parseInt(incPerTarget) : 0;
              fieldSum.payout += p1 ? parseInt(p1) : 0
            });
            return (
              <Table.Summary.Row style={{ fontWeight: '600', fontSize: '18px' }}>
                <Table.Summary.Cell fixed index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                {/* <Table.Summary.Cell index={9}></Table.Summary.Cell> */}
                <Table.Summary.Cell index={10}>
                  {fieldSum.target ? fieldSum.target : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11}>
                  {Math.round(fieldSum.payout / fieldSum.target)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={12}>
                  {fieldSum.payout ? fieldSum.payout : ''}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={13}></Table.Summary.Cell>
                <Table.Summary.Cell index={14}></Table.Summary.Cell>
                <Table.Summary.Cell index={15}></Table.Summary.Cell>
                <Table.Summary.Cell index={16}></Table.Summary.Cell>
              </Table.Summary.Row>
            )
          }}
          dataSource={dataSource}
          columns={columns}
          onChange={handleChange}
          pagination={false}
          // locale = {{emptyText: 'No Data'}}
          // rowSelection={{
          //   selectedRowKeys,
          //   onChange: (newSelectedRowKeys, selectedRow) => {

          //     setSelectedRowKeys(newSelectedRowKeys);
          //     setSchemeIdNo(selectedRow)
          //     // getSelectionCheck(newSelectedRowKeys, dataSource);
          //     sessionStorage.setItem('rowData', JSON.stringify(selectedRow))
          //   },
          //   getCheckboxProps: (record) => ({ disabled: record.status == 1 ||  record.status == 8 || record.status == 3 || record.status == 0 || record.status == 8 }),
          // }}

          scroll={{
            x: 2000,
            y: 800,
          }}
          bordered
        />

        <Pagination
          className="paginationDesign"
          // style={{ position: "absolute", right: "37px", marginTop: "-47px" }}
          defaultCurrent={1}
          current={collectionData?.pageNumber}
          onChange={(pageNumber, pageSize) => setCollectionData({ ...collectionData, pageSize, pageNumber })}
          total={listData?.data?.totalCountScheme}
          showSizeChanger
          pageSize={collectionData?.pageSize}
            pageSizeOptions={['150', '250', '350']}
        />

        <MergeModal
          open={openMergeModal}
          handleCancel={handleMerge}
          handleOk={handleOk}
          confirmLoading={loading}
          modalText={'Do you want to merge?'}
        />
        <MergeModal
          open={openUnMergeModal}
          handleCancel={handleUnMergeModal}
          handleOk={handleUnMerge}
          confirmLoading={loading}
          modalText={'Do you want to Unmerge?'}
        />
      </div>
      {contextHolder}
    </>
  );
};
export default SingleTarget;
