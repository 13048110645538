import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import "../../createSchemes/createScheme.css";
import { Button, Table, Form, message, Select, Modal, Space, Tooltip, Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";

import { getCurrentFiscalYear } from "../../../../utils/dateFormatter";
import moment from "moment";
import { ArrowBack, ArrowBackRounded } from "@mui/icons-material";
import {
  getSaveModifiedDashboardApi,
  resetCollection,
} from "../../../../redux/modification/modificationSlice";
import SMDialog from "../../../../components/modal/alertModal/CommonModal";
import ValidationErrorsModal from "../../../../components/modal/alertModal/ValidationErrorsModal";
import { CodeSandboxCircleFilled } from "@ant-design/icons";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";
import { getLockedSchemeTypeAsync, getLockedZonalHeadApiAsync } from "../../../../redux/stateData/stateDataSlice";
import { postUpdateHoldScheme } from "../../../../redux/hold/holdDataSlice";
import ToastifyShow from "../../../../components/ToastifyShow";
import { isValidContentType, isValidFileHeader } from "../../../../config";
import XLSX from "xlsx-js-style";
import { parseFiveDigitNumber } from "../../../../components/download/collectionSchemeUpload";
import { ModificationInstallationDownload } from "../ModificationInstallationDownload";


const currentFiscalYear = getCurrentFiscalYear();

const ModificationEditSchemes = ({ backBtn, selectedSchemeType, editCheckbox, selectedLabel }) => {
  const dispatch = useDispatch();
  const { userId, userName, userRole } = decryptData(sessionStorage.getItem("userInfo"));
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const {loadingSaveHoldState}=useSelector((state)=>state.holdData)

  const { loadingStateLocked } = useSelector((state) => state?.stateData)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [checkCalculate, setCheckCalculate] = useState(false);
  const [checkCalculateArr, setCheckCalculateArr] = useState([]);
  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });
  const [openErrorDialog, setOpenErrorDialog] = useState({
    open: false,
    errorsFields: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState([])
  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOk = () => {

    if (updateData?.[0].holdStatus == 9) {
      dispatch(postUpdateHoldScheme(updateData)).then((result) => {
        if (result?.payload?.errorMessage == "Hold Scheme Updated successfully!!") {
          message.success({
            content: "Record Updated Successfully",
            className: "custom-message",
          });
          backBtn("1");
        }
      });
    } else {
      dispatch(getSaveModifiedDashboardApi(updateData)).then((result) => {
        if (result?.payload?.errorMessage == "Data Saved successfully!!") {
          message.success({
            content: "Record Updated Successfully",
            className: "custom-message",
          });
          backBtn("1");
        }
      });
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  }; const formik = useFormik({
    initialValues: [],
  });
  const [schemeLockedStatus, setSchemeLockedStatus] = useState({
    t1: false,
    t2: false,
    t3: false
  })
  useEffect(() => {

    if (userRole == "ZADM") {
      dispatch(getLockedZonalHeadApiAsync({ "fyear": "FY24", "userid": userId })).then((x) => {
        if (Array?.isArray(x?.payload?.data)) {

          setSchemeLockedStatus(x?.payload?.data)
        }
      })
    } else {
      dispatch(getLockedSchemeTypeAsync(userId)).then((x) => {
        if (x?.payload?.[0]) {
          setSchemeLockedStatus(x?.payload?.[0] ? {

            t1: true,
            t2: true,
            t3: x?.payload?.[0]?.t3I ? true : false,
          } : {
            t1: true,
            t2: true, t3: false
          })
        }
      })
    }
  }, [])
  const { editDashboard, loadingState,loadingModificationSaveState } = useSelector(
    (state) => state.modifyCollection
  );
  const editData = editDashboard?.data?.result;


  useEffect(() => {
    if (Object?.keys(editData)?.value != 0) {

      let data1 = [editData].map((x) => ({
        ...x,
        dealerCode: x.dealerCode,
        dealerId: x.dealerId,
        dealerName: x.dealerName,
        dealerLocation: x.dealerLocation,
        stateName: x.stateName,
        teritoryManager: x.territoryManager || "",
        installationTarget: parseInt(x?.installationTarget) || 0,
        incPerTarget: parseInt(x?.incPerTarget) || 0,
        actual_Installation_Last_Year_Month:
          x?.actual_Installation_Last_Year_Month,
        startMonth: moment(x.startMonth).format("MMM YY"),
        endMonth: moment(x.endMonth).format("MMM YY"),
        reason: x.reason,
        payout: x?.payout,
        t1: +x.t1,
        t2: +x.t2,
        t3: +x.t3,
        p1: x.p1,
        p2: x.p2,
        p3: x.p3,
        r1: +x.r1,
        r2: +x.r2,
        r3: +x.r3,
        volume: x.volume,
        perTr: Math.round(x.perTr),
        amount: x.amount,
        topTarget: x.topTarget,
        dropdownscheme: x?.topTarget?.split(","),
        ...(userRole == "ZADM") && {
          topTargetArrState: userRole == "ZADM" && Array?.isArray(schemeLockedStatus) ? (schemeLockedStatus?.filter((y) => y?.stateId == x?.stateId)?.[0] || {
            t1: true,
            t2: true,
            t3: false,
          }) : {
            t1: true,
            t2: true,
            t3: false,
          }
        },
      }));
      formik?.setValues(data1);
    }
  }, [editData, schemeLockedStatus]);

  const handleErrorModal = (firstItem, secondItem, opt = false) => {
    setOpenErrorModal(opt ? opt : !openErrorModal);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };
  const showErrorDialog = (errFields) => {
    setOpenErrorDialog((openErrorDialog) => ({
      open: true,
      errorsFields: errFields ? errFields : [],
    }));
  };

  const { lockedStates } = useSelector((state) => state?.stateData);

  // useEffect(() => {
  //   if(userRole=="ZADM"){
  //     dispatch(getLockedZonalHeadApiAsync({"fyear":"FY24","userid":userId})).then((x) => {
  //       if (x?.payload?.data?.[0]) {

  //         setSchemeLockedStatus(x?.payload?.data)
  //       }
  //     })   
  //   }else{
  //     dispatch(getLockedSchemeTypeAsync(userId)).then((x)=>{
  //       if (x?.payload?.[0]) {
  //         setSchemeLockedStatus(x?.payload?.[0]?{
  //           t1: true,
  //           t2: true,
  //           t3: x?.payload?.[0]?.t3I ? true : false,
  //         }:{
  //           t1: false,
  //           t2: false,
  //           t3: false, t4: false, t5: false, t6: false 
  //         })
  //       }
  //     })
  // }   
  // }, [formik?.values])
  const topTargetArr = [
    {
      name: "T1",
      value: "t1",

    },
    {
      name: "T2",
      value: "t2",

    },
    {
      name: "T3",
      value: "t3",
      disabled: !schemeLockedStatus?.t3 ? true : false,
    }
  ];
  const topTargetArr1 = (x) => [
    {
      name: "T1",
      value: "t1",

    },
    {
      name: "T2",
      value: "t2",

    },
    {
      name: "T3",
      value: "t3",
      disabled: !x?.t3I ? true : false,
    }
  ];
  const getDropdown = () =>
    ((schemeLockedStatus?.t1 && selectedSchemeType == 3) || (userRole == "ZADM" && selectedSchemeType == 3))
      ? [
        {
          title: "Top Slab",
          width: "150px",
          render: (x, y, index) => {
            const handleChange = (value) => {
              let dropdownValues = formik?.values?.[index]?.dropdownscheme;
              let checkValue1 = value?.includes("t1");
              let checkValue2 = value?.includes("t2");
              let checkValue3 = value?.includes("t3");
              const handleDropDownScheme = () => {
                return checkValue3 ? ['t1', 't2', 't3']
                  : checkValue2 ? ['t1', 't2']
                    : checkValue1 ? ['t1'] : [];
              };
              const handleDropdownSchemes = handleDropDownScheme()
              formik.setFieldValue(index, {
                ...x,
                dropdownscheme: handleDropDownScheme() || null,
                ...(handleDropdownSchemes.includes("t3")
                  ? {
                  } :
                  handleDropdownSchemes.includes("t2")
                    ? {
                      BooksCollectionTargetT3T2: null,
                      r3: null,
                      t3: null,
                    }
                    : handleDropdownSchemes.includes("t1")
                      ? {
                        r2: null,
                        r3: null,
                        t2: null,
                        t3: null,
                        BooksCollectionTargetT2T1: null,
                      }
                      : {}),
                BooksCollectionTargetT3T2: null,
              });

            };
            let dataOption = userRole == "ZADM" ? topTargetArr1(y?.topTargetArrState) : topTargetArr
            return (
              <Select
                onChange={handleChange}
                value={y?.dropdownscheme}
                mode="multiple"
                autoClearSearchValue="multiple"
                className="w-100"
                allowClear
                onDeselect={false}
                showArrow={false}
                removeIcon={false}
              >
                {dataOption
                  ?.filter((x) => !x?.disabled)
                  ?.map((x) => (
                    <Select.Option value={x?.value}>
                      {x?.name}
                    </Select.Option>
                  ))}
              </Select>
            );
          },
        },
      ]
      : [];

  const calculateFormula = (index) => {
    let y = formik?.values[index];
    if (selectedSchemeType == 3) {
      let { t2, t1, t3, r1, r2, r3 } = y;

      let dropdownShow = y?.dropdownscheme

      formik?.setFieldValue(index, {
        ...y,
        p1: (parseInt(t1) * parseInt(r1)) || 0,
        p2: (parseInt(t2) * parseInt(r2)) || 0,
        p3: (parseInt(t3) * parseInt(r3)) || 0,
      });
      if ((((t2 == "" || t2 == null) || (t1 == "" || t1 == null)) || (parseInt(t2) < parseInt(t1))) && dropdownShow?.includes("t2")) {
        handleErrorModal("T2", "T1", true);
      }
      if ((((t3 == "" || t3 == null) || (t2 == "" || t2 == null)) || (parseInt(t3) < parseInt(t2))) && dropdownShow?.includes("t3")) {
        handleErrorModal("T3", "T2", true);
      }
      if ((((r2 == "" || r2 == null) || (r1 == "" || r1 == null)) || (parseInt(r2) < parseInt(r1))) && dropdownShow?.includes("t2")) {
        handleErrorModal("R2", "R1", true);
      }
      if ((((r3 == "" || r3 == null) || (r2 == "" || r2 == null)) || (parseInt(r3) < parseInt(r2))) && dropdownShow?.includes("t3")) {
        handleErrorModal("R3", "R2", true);
      }



    } else if (selectedSchemeType == 4) {
      let { upperCap, incPerTarget, installationTarget } =
        formik?.values[index];

      if (
        upperCap != null &&
        incPerTarget != null &&
        parseInt(incPerTarget) <= parseInt(upperCap)
      ) {
        handleErrorModal("Inc Per Target", "Upper Cap", true);
      }
      formik?.setFieldValue(index, {
        ...y,
        installationTarget: parseInt(installationTarget) || 0,
        incPerTarget: parseInt(incPerTarget) || 0,
        upperCap: parseInt(upperCap) || 0,
        // UC:parseInt(UC),
        // IPT:parseInt(IPT),
        payout: (parseInt(upperCap) * parseInt(incPerTarget)) || 0,
      });
    } else if (selectedSchemeType == 5) {
      let { installationTarget, incPerTarget } = formik?.values[index];

      if (
        installationTarget != null &&
        incPerTarget != null &&
        parseInt(incPerTarget) <= parseInt(installationTarget)
      ) {
        handleErrorModal("incPerTarget", "installationTarget", true);
      }

      formik?.setFieldValue(index, {
        ...y,
        installationTarget: parseInt(installationTarget) || 0,
        incPerTarget: parseInt(incPerTarget) || 0,
        p1: (parseInt(installationTarget) * parseInt(incPerTarget)) || 0,
      });
    } else {
      let { installationTarget, incPerTarget } = formik?.values[index];
      if (
        installationTarget != null &&
        incPerTarget != null &&
        parseInt(incPerTarget) <= parseInt(installationTarget)
      ) {
        handleErrorModal("incPerTarget", "installationTarget", true);
      }

      formik?.setFieldValue(index, {
        ...y,
        installationTarget: parseInt(installationTarget) || "",
        incPerTarget: parseInt(incPerTarget) || "",
        p1: (parseInt(installationTarget) * parseInt(incPerTarget)) || "",
      });
    }
  };
  const getCols = (schemeType) => {
    const getTargetT1 = () =>
      (schemeLockedStatus?.t1 || userRole == "ZADM")
        ? [
          {
            title: "T1",
            dataIndex: "t1",
            editable: true,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.t1}
                value={formik?.values?.[index]?.t1 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    t1: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
        : [];

    const getTargetT2 = () =>
      (schemeLockedStatus?.t2 || userRole == "ZADM")
        ? [
          {
            title: "T2",
            dataIndex: "t2",
            editable: true,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.t2}
                value={formik?.values?.[index]?.t2 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    t2: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },

        ]
        : [];
    const getTargetT3 = () =>
      (schemeLockedStatus?.t3 || userRole == "ZADM")
        ? [
          {
            title: "T3",
            dataIndex: "t3",
            editable: true,
            width: "100px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.t3}
                value={formik?.values?.[index]?.t3 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? true
                    : false
                }
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    t3: value,
                  });
                }}
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },

        ]
        : [];


    const getPayoutRateR1 = () =>
      (schemeLockedStatus?.t1 || userRole == "ZADM")
        ? [
          {
            title: "R1",
            dataIndex: "r1",
            editable: false,
            width: "120px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.r1}
                value={formik?.values?.[index]?.r1 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    r1: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
        : [];
    const getPayoutRateR2 = () =>
      (schemeLockedStatus?.t2 || userRole == "ZADM")
        ? [
          {
            title: "R2",
            dataIndex: "r2",
            editable: false,
            width: "120px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.r2}
                value={formik?.values?.[index]?.r2 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    r2: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
        : [];
    const getPayoutRateR3 = () =>
      (schemeLockedStatus?.t3 || userRole == "ZADM")
        ? [
          {
            title: "R3",
            dataIndex: "r3",
            editable: true,
            width: "120px",
            render: (x, y, index) => (
              <input
                name={formik?.values?.[index]?.r3}
                value={formik?.values?.[index]?.r3 || ""}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
                  if (value.length > 5) {
                    value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
                  }
                  formik.setFieldValue(index, {
                    ...y,
                    r3: value,
                  });
                }}
                disabled={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? true
                    : false
                }
                placeholder={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? ""
                    : "Type here"
                }
                className={
                  !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                    ? "input-disabled"
                    : "input1"
                }
                style={{ width: "90%", padding: "5px" }}
              />
            ),
          },
        ]
        : [];

    const getPayoutP1 = () =>
      (schemeLockedStatus?.t1 || userRole == "ZADM")
        ? [
          {
            title: "P1",
            dataIndex: "p1",
            width: "120px",
            render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t1") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
          },
        ]
        : [{
          title: "P1",
          dataIndex: "p1",
          width: "120px",
          render: (x) => <div className="h-24 input-disabled"></div>
        }];
    const getPayoutP2 = () =>
      (schemeLockedStatus?.t2 || userRole == "ZADM")
        ? [
          {
            title: "P2",
            dataIndex: "p2",
            width: "120px",
            render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t2") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
          },
        ]
        : [[{
          title: "P2",
          dataIndex: "p2",
          width: "120px",
          render: (x) => <div className="h-24 input-disabled"></div>
        }]];
    const getPayoutP3 = () =>
      (schemeLockedStatus?.t3 || userRole == "ZADM")
        ? [
          {
            title: "P3",
            dataIndex: "p3",
            width: "120px",
            render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t3") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
          },
        ]
        : [{
          title: "P3",
          dataIndex: "p3",
          width: "120px",
          render: (x) => <div className="h-24 input-disabled"></div>
        }];
    if (schemeType == 3) {
      return [
        {
          title: "Targets",
          dataIndex: "Target",
          width: "180px",
          children: [
            ...getTargetT1(),
            ...getTargetT2(),
            ...getTargetT3(),

          ],
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "IPT",
          width: "180px",
          children: [
            ...getPayoutRateR1(),
            ...getPayoutRateR2(),
            ...getPayoutRateR3(),
          ],
        },
        {
          title: "Payout",
          dataIndex: "Payout",
          width: "180px",
          children: [
            ...getPayoutP1(),
            ...getPayoutP2(),
            ...getPayoutP3(),
          ],
        },
      ];
    }
    if (schemeType == 4) {
      return [
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.installationTarget}
              placeholder="Type here"
              value={formik?.values?.[index]?.installationTarget}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  installationTarget: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),
          width: "190px",
        },
        {
          title: "Upper Cap",
          dataIndex: "upperCap",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.upperCap}
              placeholder="Type here"
              value={formik?.values?.[index]?.upperCap}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  upperCap: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),
          width: "120px",
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.incPerTarget}
              placeholder="Type here"
              value={formik?.values?.[index]?.incPerTarget}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  incPerTarget: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),
          width: "190px",
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
        },
      ];
    }
    if (schemeType == 8) {
      return [
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.installationTarget}
              placeholder="Type here"
              value={formik?.values?.[index]?.installationTarget}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  installationTarget: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),
          width: "190px",
        },

        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.incPerTarget}
              placeholder="Type here"
              value={formik?.values?.[index]?.incPerTarget}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  incPerTarget: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),
          width: "190px",
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
        },
      ];
    }
    if (schemeType == 5) {
      return [
        {
          title: "Installation Expected",
          dataIndex: "installationTarget",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.installationTarget}
              placeholder="Type here"
              value={formik?.values?.[index]?.installationTarget}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  installationTarget: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),

          width: "200px",
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          render: (x, y, index) => (
            <input
              name={formik?.values?.[index]?.incPerTarget}
              placeholder="Type here"
              value={formik?.values?.[index]?.incPerTarget}
              onBlur={() => {
                calculateFormula(index);
              }}
              onChange={(e) => {
                let value = e.target?.value.replace(/[^\d]/g, "");
                formik.setFieldValue(index, {
                  ...y,
                  incPerTarget: value,
                });
              }}
              className="input1"
              style={{ width: "90%", padding: "5px" }}
            />
          ),
          width: "190px",
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "120px",
        },
      ];
    }
    return [];
  };
  const getStateHead = () => userRole != "SADM" ? [
    {
      title: (
        <div>
          State <br /> Head
        </div>
      ),
      dataIndex: "stateHeadName",
      width: "180px",
    },
  ] : []
  const defaultColumns = useMemo(
    () => [
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        fixed: "left",
        width: "120px",
        sorter: true,
        key: "DealerCode",
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        fixed: "left",
        width: "180px",
        sorter: true,
        key: "DealerName"
        //  defaultSortOrder: "descend",
        //  sorter: (a, b) => a.name && b.name && a.name.length - b.name.length,
      },
      {
        title: "Dealer Location",
        dataIndex: "dealerLocation",
        width: "120px",
        fixed: "left",
        sorter: true,
        key: "DealerLocation",
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: "120px",
        sorter: true,
        key: "StateName",
      }, ...getStateHead(),
      {
        title: "Territory Manager",
        dataIndex: "teritoryManager",
        width: "100px",
        sorter: true,
        key: "TerritoryManager",
      },
      ...getDropdown(),
      {
        title: "Start Month",
        dataIndex: "startMonth",
        width: "120px",
      },
      {
        title: "End Month",
        dataIndex: "endMonth",
        width: "120px",
      },
      {
        title: "Actual Installation Last Year Month",
        dataIndex: "actual_Installation_Last_Year_Month",
        width: "250px",
      },
      ...getCols(selectedSchemeType),

      {
        title: "Remarks",
        dataIndex: "reason",
        render: (x, y, index) => (
          <input
            name={formik?.values?.[index]?.reason}
            placeholder="Type here"
            value={formik?.values?.[index]?.reason}
            onChange={(e) => {
              let value = e.target?.value;
              const sanitizedValue = value
                ?.replace("<script>", "")
                ?.replace("</script>", "")
              //  .replace(/[^a-zA-Z0-9\s]/g, "");
              formik.setFieldValue(index, {
                ...y,
                reason: sanitizedValue,
              });
            }}
            className="input1"
            style={{ width: "90%", padding: "5px" }}
          />
        ),
        width: "150px",
      },
    ],
    [formik?.values, schemeLockedStatus]
  );

  const handleSubmit = (e) => {

    const handleDropDownSchemeCheckIndex = (x) => {

      let { t1, t2, t3 } = x.reduce((a, v) => ({ ...a, [v]: v }), {});

      let data = t1 && !t2 && !t3
        ? "t1"
        : t1 && t2 && !t3
          ? "t2"
          : t1 && t2 && t3
            ? "t3"
            : "";
      return data
    };
    if (selectedSchemeType == 3) {
      let data = [];
      formik?.values.forEach((x) => {
        data.push({
          ...x,
          dealerCode: x.dealerCode,
          dealerId: +x.dealerId,
          startMonth: x.startMonth,
          endMonth: x.endMonth,
          isSAP: true,
          isActive: true,
          state: x.stateId,
          status: 1,
          isSubmitted: 1,
          fyear: currentFiscalYear,
          schemeType: "Slabbased",
          incPerTarget: parseInt(x.incPerTarget) || 0,
          installationTarget: parseInt(x?.installationTarget) || 0,
          // schemeTypeId: schemeType,
          userid: +userId,
          username: userName,
          userrole: userRole,
          topTarget: x?.dropdownscheme?.toString(),
          t1: +x.t1 || 0,
          t2: +x.t2 || 0,
          t3: +x.t3 || 0,
          r1: +x.r1 || 0,
          r2: +x.r2 || 0,
          r3: +x.r3 || 0,
          p1: +x.p1 || 0,
          p2: +x.p2 || 0,
          p3: +x.p3 || 0,
          reason: x.reason,
          userid: +userId,
          username: userName,
          userrole: userRole,
          HoldReason: x.reason,
          holdStatus: x?.holdStatus,
          IsModified: selectedLabel == "Hold Schemes" ? true : false
        });
      });
      if (selectedLabel === 'Regular Schemes' || selectedLabel === 'Schemes') {
        data = data.map(d => {
          delete d.HoldReason;
          return d;
        });
      } else if (selectedLabel === "Hold Schemes") {
        data = data.map(d => {
          delete d.reason;
          return d;
        });

      }



      let checkValidation = formik?.values?.filter(
        (x) =>
          handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1" ?
            (
              !x.t1 ||
              !x.r1) : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2" ?
              (
                !x.t1 ||
                !x.t2 ||
                !x.r1 ||
                !x.r2 ||
                parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0) ||
                parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0) ||

                !x.TEPvol) : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3" ?
                (
                  !x.t1 ||
                  !x.t2 ||
                  !x.t3 ||
                  !x.r1 ||
                  !x.r2 ||
                  !x.r3 ||
                  parseInt(x?.t3 || 0) <= parseInt(x?.t2 || 0) ||
                  parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0) ||
                  parseInt(x?.r3 || 0) <= parseInt(x?.r2 || 0) ||
                  parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0) ||
                  !x.TEPvol) : (
                  !x.t1 ||
                  !x.t2 ||
                  !x.t3 ||
                  !x.r1 ||
                  !x.r2 ||
                  !x.r3
                )
      )
      let errorDetails = Object.entries(
        checkValidation?.[0] ? checkValidation?.[0] : {}
      )
        ?.filter(
          (x) => checkValidation?.[0]?.dropdownscheme?.includes("t3") ?
            (!x[1] &&
              (x[0] == "t1" ||
                x[0] == "t2" ||
                x[0] == "t3" ||
                x[0] == "r1" ||
                x[0] == "r2" ||
                x[0] == "r3"
              )) : checkValidation?.[0]?.dropdownscheme?.includes("t2") ? (
                !x[1] &&
                (x[0] == "t1" ||
                  x[0] == "t2" ||
                  x[0] == "r1" ||
                  x[0] == "r2"
                )
              ) : checkValidation?.[0]?.dropdownscheme?.includes("t1") ? (
                !x[1] &&
                (x[0] == "t1" ||
                  x[0] == "r1"
                )
              ) : (
              !x[0]
            )

        ).map((x) =>
          x[0] == "t1"
            ? "T1"
            : x[0] == "t2"
              ? "T2"
              : x[0] == "t3"
                ? "T3"
                : x[0] == "r1"
                  ? "R1"
                  : x[0] == "r2"
                    ? "R2"
                    : x[0] == "r3"
                      ? "R3"
                      : x[0]
        )
      let extraValidation = [];



      if (checkValidation?.[0]?.dealerCode) {
        formik?.values?.filter((x) => x?.dealerCode == checkValidation?.[0]?.dealerCode).map((x, index) => {

          let topTargetDetails = x?.dropdownscheme

          let t3Details = x?.t3 != "" ? parseInt(x?.t3 || 0) : 0
          let t2Details = x?.t2 != "" ? parseInt(x?.t2 || 0) : 0
          let t1Details = x?.t1 != "" ? parseInt(x?.t1 || 0) : 0

          let r3Details = x?.r3 != "" ? parseInt(x?.r3 || 0) : 0
          let r2Details = x?.r2 != "" ? parseInt(x?.r2 || 0) : 0
          let r1Details = x?.r1 != "" ? parseInt(x?.r1 || 0) : 0

          if (topTargetDetails?.length == 0) {
            extraValidation.push("Please select Top Target");
          }
          if (topTargetDetails?.includes("t3") && (parseInt(t3Details) <= parseInt(t2Details))) {

            extraValidation.push("T3 should be greater than T2");
          }
          if (topTargetDetails?.includes("t2") && parseInt(t2Details) <= parseInt(t1Details)) {
            extraValidation.push("T2 should be greater than T1");

          }
          if (topTargetDetails?.includes("t3") && parseInt(r3Details) <= parseInt(r2Details)) {
            extraValidation.push("R3 should be greater than R2");

          }
          if (topTargetDetails?.includes("t2") && parseInt(r2Details) <= parseInt(r1Details)) {
            extraValidation.push("R2 should be greater than R1");


          }
        })

      }
      let errorShow = errorDetails?.concat(extraValidation);
      if (Object.keys(errorShow ? errorShow : {})?.length != 0) {
        showErrorDialog(errorShow);
      } else {
        showModal()
        setUpdateData(data)
      }
    } else if (selectedSchemeType == 4) {
      let checkValidation = formik?.values?.filter(

        (x) => !x?.installationTarget || !x?.incPerTarget || !x?.upperCap || !x?.reason
      );
      let errorDetails = Object.entries(
        checkValidation?.[0] ? checkValidation?.[0] : {}
      )
        ?.filter(
          (x, index) =>
            !x[1] &&
            (x[0] === "installationTarget" ||
              x[0] === "incPerTarget" ||
              x[0] === "upperCap" ||
              x[0] === "reason")
        )
        ?.map((x) =>
          x[0] === "installationTarget"
            ? "installationTarget"
            : x[0] === "incPerTarget"
              ? "incPerTarget"
              : x[0] === "upperCap"
                ? "upperCap"
                : x[0] === "reason"
                  ? "reason"
                  : ""
        );

      let data = [];
      formik?.values.forEach((x) => {
        data.push({
          ...x,
          dealerCode: x.dealerCode,
          dealerId: +x.dealerId,
          StartMonth: moment(x.startMonth).format("MMM YY"),
          endMonth: moment(x.endMonth).format("MMM YY"),
          isSAP: true,
          isActive: true,
          state: x.stateId,
          status: 1,
          isSubmitted: 1,
          fyear: currentFiscalYear,
          userid: +userId,
          username: userName,
          userrole: userRole,
          payout: x.p1 ? parseInt(x.p1) : 0,
          incPerTarget: parseInt(x.incPerTarget) || 0,
          installationTarget: parseInt(x.Target),
          p1: +x.p1 ? parseInt(x.p1) : 0,
          reason: x.reason,
          upperCap: +x.upperCap,
          installationTarget: parseInt(x?.installationTarget) || 0,
          InsPerTarget: parseInt(x.incPerTarget) || 0,
          InsTarget: parseInt(x?.installationTarget) || 0,
          P1: +x.p1 ? parseInt(x.p1) : 0,
          UpperCap: +x.upperCap,
          HoldReason: x.reason,
          holdStatus: x?.holdStatus,
          IsModified: selectedLabel == "Hold Schemes" ? true : false
        });
      });
      if (selectedLabel === 'Regular Schemes' || selectedLabel === 'Schemes') {
        data = data.map(d => {
          delete d.HoldReason;
          return d;
        });
      } else if (selectedLabel === "Hold Schemes") {
        data = data.map(d => {
          delete d.reason;
          return d;
        });

      }
      if (Object.keys(errorDetails ? errorDetails : {}).length !== 0) {
        showErrorDialog(errorDetails);
      } else {
        setUpdateData(data)
        showModal()

      }
    } else if (selectedSchemeType == 5) {
      let checkValidation = formik?.values?.filter(
        (x) => !x?.Target || !x?.IPT || !x?.reason
      );

      let errorDetails = Object.entries(
        checkValidation?.[0] ? checkValidation?.[0] : {}
      )
        ?.filter(
          (x, index) =>
            !x[1] && (x[0] === "Target" || x[0] === "IPT" || x[0] === "reason")
        )
        ?.map((x) =>
          x[0] === "Target"
            ? "Target"
            : x[0] === "IPT"
              ? "inc. Per Tractor"
              : x[0] === "reason"
                ? "Reason"
                : ""
        );

      let data = [];
      formik?.values.forEach((x) => {
        data.push({
          ...x,
          dealerCode: x.dealerCode,
          dealerId: +x.dealerId,
          StartMonth: moment(x.startMonth).format("MMM YY"),
          endMonth: moment(x.endMonth).format("MMM YY"),
          isSAP: true,
          isActive: true,
          state: x.stateId,
          status: 1,
          isSubmitted: 1,
          fyear: currentFiscalYear,
          userid: +userId,
          username: userName,
          userrole: userRole,
          payout: x.p1 ? parseInt(x.p1) : 0,
          incPerTarget: parseInt(x.incPerTarget) || 0,
          installationTarget: +x.Target,
          p1: +x.p1 ? parseInt(x.p1) : 0,
          InsPerTarget: parseInt(x.incPerTarget) || 0,
          InsTarget: parseInt(x?.installationTarget) || 0,
          P1: +x.p1 ? parseInt(x.p1) : 0,
          reason: x.reason,
          installationTarget: parseInt(x?.installationTarget) || 0,
          HoldReason: x.reason,
          holdStatus: x?.holdStatus,
          IsModified: selectedLabel == "Hold Schemes" ? true : false
        });
      });
      if (selectedLabel === 'Regular Schemes' || selectedLabel === 'Schemes') {
        data = data.map(d => {
          delete d.HoldReason;
          return d;
        });
      } else if (selectedLabel === "Hold Schemes") {
        data = data.map(d => {
          delete d.reason;
          return d;
        });

      }
      if (Object.keys(errorDetails ? errorDetails : {}).length !== 0) {
        showErrorDialog(errorDetails);
      } else {
        setUpdateData(data)
        showModal()

      }
    } else {
      let checkValidation = formik?.values?.filter(
        (x) => !x?.Target || !x?.IPT || !x?.reason
      );
      let errorDetails = Object.entries(
        checkValidation?.[0] ? checkValidation?.[0] : {}
      )
        ?.filter(
          (x, index) =>
            !x[1] && (x[0] === "Target" || x[0] === "IPT" || x[0] === "reason")
        )
        ?.map((x) =>
          x[0] === "Target"
            ? "Target"
            : x[0] === "IPT"
              ? "inc. Per Tractor"
              : x[0] === "reason"
                ? "Reason"
                : ""
        );

      let data = [];
      formik?.values.forEach((x) => {
        data.push({
          ...x,
          dealerCode: x.dealerCode,
          dealerId: +x.dealerId,
          StartMonth: moment(x.startMonth).format("MMM YY"),
          endMonth: moment(x.endMonth).format("MMM YY"),
          isSAP: true,
          isActive: true,
          state: x.stateId,
          status: 1,
          isSubmitted: 1,
          fyear: currentFiscalYear,
          userrole: userRole,
          // schemeTypeId: schemeType,
          userid: +userId,
          username: userName,
          payout: x.payout ? parseInt(x.payout) : 0,
          incPerTarget: parseInt(x.incPerTarget) || 0,
          InsPerTarget: parseInt(x.incPerTarget) || 0,
          InsTarget: parseInt(x?.installationTarget) || 0,
          P1: +x.p1 ? parseInt(x.p1) : 0,
          installationTarget: +x.Target,
          p1: +x.p1 ? parseInt(x.p1) : 0,
          reason: x.reason,
          installationTarget: parseInt(x?.installationTarget) || 0,
          HoldReason: x.reason,
          holdStatus: x?.holdStatus,
          IsModified: selectedLabel == "Hold Schemes" ? true : false
        });
      });
      if (selectedLabel === 'Regular Schemes' || selectedLabel === 'Schemes') {
        data = data.map(d => {
          delete d.HoldReason;
          return d;
        });
      } else if (selectedLabel === "Hold Schemes") {
        data = data.map(d => {
          delete d.reason;
          return d;
        });

      }
      if (Object.keys(errorDetails ? errorDetails : {}).length !== 0) {
        showErrorDialog(errorDetails);
      } else {
        setUpdateData(data)
        showModal()

      }
    }
  };
  const handleRemoveFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };
  const handleFileUpload = (e) => {
    const maxFileSize = 5 * 1024 * 1024; // Set maximum file size to 5MB
    const files = e.target.files;

    // Check if any file exceeds the maximum file size limit
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxFileSize) {
        ToastifyShow(`File ${files[i].name} is too large. Maximum file size is 5MB.`, "error");
        return;
      }
  
      // Check file headers for XLSX files
      if (!isValidFileHeader(files[i])) {
        ToastifyShow(`Invalid file header for ${files[i].name}.`, "error");
        return;
      }
  
      // Check content type for XLSX files
      if (!isValidContentType(files[i])) {
        ToastifyShow(`Invalid content type for ${files[i].name}.`, "error");
        return;
      }
  
    }

    if (files[0].name.split('.')?.[1] !== 'xlsx') {
      ToastifyShow("Please upload an XLSX file only.", "error");
      return;
    }
  
    const reader = new FileReader();
    reader.readAsBinaryString(files[0]);
  
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  
    reader.onload = async(e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];  
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      let schemeType=formik?.values?.[0]?.schemeTypeId||3
      let calArr = [];

      const validationFileCheck=Object.keys(parsedData[0]||{})

      const isSchemeType3OrEmpty = schemeType == "3" || schemeType == "";
      const hasT1T2T3 = validationFileCheck?.includes("Targets") && validationFileCheck?.includes("INC.PER TRACTOR");
      const hasT1T2 = validationFileCheck?.includes("T1") && validationFileCheck?.includes("T2");
      const hasInstallTargetIncPerTractor = validationFileCheck?.includes("INSTALLATION TARGET") && validationFileCheck?.includes("INC. PER TRACTOR");
      const hasUpperCap = validationFileCheck?.includes("UPPER CAP");
      const hasInstallationExpected = validationFileCheck?.includes("INSTALLATION EXPECTED");

      if (
        !( 
          (isSchemeType3OrEmpty && schemeLockedStatus?.t3 && hasT1T2T3) ||
          (isSchemeType3OrEmpty && !schemeLockedStatus?.t3 && hasT1T2) ||
          (schemeType == "8" && hasInstallTargetIncPerTractor) ||
          (schemeType == "4" && hasInstallTargetIncPerTractor && hasUpperCap) ||
          (schemeType == "5" && hasInstallationExpected && validationFileCheck?.includes("INC. PER TRACTOR"))
        )
      ) {
        return ToastifyShow("Please upload a valid file", "error");
      }

      
      let dataArr = formik?.values?.map((y, index) => {
        if (
          parsedData.find(
            (x) =>
              x[(schemeType == "3" || schemeType == "") ? "-" : "DEALER CODE"] ==
              y?.dealerCode
          )
        ) {

          let z = parsedData.find(
            (x) =>
              x[schemeType == "3" || schemeType == "" ? "-" : "DEALER CODE"] ===
              y?.dealerCode&& x[schemeType == "3" || schemeType == "" ? "__EMPTY_5":"START MONTH"] === y.startMonth &&x[schemeType == "3" || schemeType == "" ? "__EMPTY_6":"END MONTH"] === y.endMonth
          );

          if (schemeType == "3" || schemeType == "") {
            let dropdownscheme = z?.["__EMPTY_4"]?.toUpperCase() == "T3"&&(userRole == "ZADM" || userRole == "NSH")&&(y?.topTargetArrState?.t3I||y?.topTargetArrState?.t3) ? ["t1", "t2", "t3"] :
            z?.["__EMPTY_4"]?.toUpperCase() == "T3"&&schemeLockedStatus?.t3 ? ["t1", "t2", "t3"] :
              z?.["__EMPTY_4"]?.toUpperCase() == "T2" ? ["t1", "t2"] :
                z?.["__EMPTY_4"]?.toUpperCase() == "T1" ? ["t1"] : []
            let paget1 = dropdownscheme.includes("t1" || "t2" || "t3")
            let paget2 = dropdownscheme.includes("t2" || "t3")
            let paget3 = dropdownscheme.includes("t3")
            calArr.push(index);
            return {
              ...y,
              dropdownscheme: dropdownscheme,
              t1: paget1 ? z?.Targets : "",
              t2: paget2 ? z?.["__EMPTY_8"] : "",
              t3: paget3 ? z?.["__EMPTY_9"] : "",
              r1: paget1 ? parseFiveDigitNumber(z?.["INC.PER TRACTOR"]) : "",
              r2: paget2 ? parseFiveDigitNumber(z?.["__EMPTY_10"]) : "",
              r3: paget3 ? parseFiveDigitNumber(z?.["__EMPTY_11"]) : "",
              p1: (paget1) ? (parseInt(z?.Targets || 0) * parseInt(z?.["INC.PER TRACTOR"] || 0)) : 0,
              p2: (paget2) ? (parseInt(z?.__EMPTY_8 || 0) * parseInt(z?.["__EMPTY_10"] || 0)) : 0,
              p3: (paget3) ? (parseInt(z?.__EMPTY_9 || 0) * parseInt(z?.__EMPTY_11 || 0)) : 0,
              reason: z?.["-_1"] || "",
            };
          } else if (schemeType == "8") {
            return {
              ...y,
              installationTarget: z?.["INSTALLATION TARGET"] || "",
              incPerTarget: z?.["INC. PER TRACTOR"] || "",
              reason: z?.["REMARKS"] || "",
            };
          } else if (schemeType == "4") {
            console.log(schemeType,"schemeType",z)
            return {
              ...y,
              installationTarget: z?.["INSTALLATION TARGET"] || "",
              upperCap: z?.["UPPER CAP"] || "",
              incPerTarget: z?.["INC. PER TRACTOR"] || "",
              reason: z?.["REMARKS"] || "",
            };
          } else {
            return {
              ...y,
              installationTarget: z?.["INSTALLATION EXPECTED"] || "",
              incPerTarget: z?.["INC. PER TRACTOR"] || "",
              reason: z?.["REMARKS"] || "",
            };
          }
        } else {
          return y;
        }
      });
      await setCheckCalculate(true);
      await setCheckCalculateArr(calArr);
      await formik?.setValues(dataArr);
      ToastifyShow("File uploaded successfully", "success");

    };
  };


  useEffect(() => {
    if (checkCalculate) {
      checkCalculateArr?.forEach((x) => calculateFormula(x));
      setCheckCalculate(false);
    }
  }, [checkCalculate]);

  const handleSampleFile = (e) => {

    const headerStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
        diagonal: { diagonalUp: true, diagonalDown: true },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
    if (formik?.values?.length == 0) {
      ToastifyShow("Something went wrong", "error");
      return false;
    }
let schemeType=formik?.values?.[0]?.schemeTypeId||3

let tab_text = ModificationInstallationDownload({
      schemeType:schemeType
    })
    {
      schemeType==3?formik?.values?.length != 0 &&
      formik?.values.forEach((header) => {
          tab_text += `<tr ><td>${header?.dealerCode}</td>
  <td>${header?.dealerName}</td>
  <td>${header?.stateName}</td>
  <td>${header?.dealerLocation}</td>
  <td>${header?.territoryManager}</td>
  <td>${header?.dropdownscheme?.includes("t3")?"t3":header?.dropdownscheme?.includes("t2")?"t2":header?.dropdownscheme?.includes("t1")?"t1":""}</td>
  <td>${header.startMonth || ""}</td>
  <td>${header.endMonth || ""}</td>
  <td>${header?.actual_Installation_Last_Year_Month || 0}</td>
  <td>${header.t1}</td>
  <td>${header.t2}</td>
  <td>${header.t3}</td>
  <td>${header.r1}</td>
  <td>${header.r2}</td>  
    <td>${header.r3}</td>  
    <td>${header.reason}</td>
  <td></td>      
  </tr>`
        }):  schemeType==8? formik?.values?.length != 0 &&
        formik?.values
            ?.forEach((header) => {
              tab_text += `<tr ><td>${header?.dealerCode}</td>
      <td>${header?.dealerName}</td>
      <td>${header?.stateName}</td>
      <td>${header?.dealerLocation}</td>
      <td>${header?.territoryManager}</td>
      <td>${header.startMonth || ""}</td>
      <td>${header.endMonth || ""}</td>
      <td>${header?.actual_Installation_Last_Year_Month || 0}</td>
      <td>${header?.installationTarget || 0}</td>
      <td>${header?.incPerTarget || 0}</td>
      <td>${header?.reason || ''}</td>
      <td></td>
      <td></td>      
      </tr>`;
            }): schemeType==4? formik?.values?.length != 0 &&
            formik?.values
                ?.forEach((header) => {
                  tab_text += `<tr ><td>${header?.dealerCode}</td>
          <td>${header?.dealerName}</td>
          <td>${header?.stateName}</td>
          <td>${header?.dealerLocation}</td>
          <td>${header?.territoryManager}</td>
          <td>${header.startMonth || ""}</td>
          <td>${header.endMonth || ""}</td>
          <td>${header?.actual_Installation_Last_Year_Month || 0}</td>
          <td>${header?.installationTarget || 0}</td>
          <td>${header?.upperCap || 0}</td>
          <td>${header?.incPerTarget || 0}</td>
          <td>${header?.reason || ''}</td>
          <td></td>      
          </tr>`;
                }): formik?.values?.length != 0 &&
                formik?.values
                    ?.forEach((header) => {
                      tab_text += `<tr ><td>${header?.dealerCode}</td>
              <td>${header?.dealerName}</td>
              <td>${header?.stateName}</td>
              <td>${header?.dealerLocation}</td>
              <td>${header?.territoryManager}</td>
              <td>${header.startMonth || ""}</td>
              <td>${header.endMonth || ""}</td>
              <td>${header?.actual_Installation_Last_Year_Month || 0}</td>
              <td>${header?.installationTarget || 0}</td>
              <td>${header?.incPerTarget || 0}</td>
              <td>${header?.reason || ''}</td>
              <td></td>      
              </tr>`;
                    });
      }
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
  
      // Apply styles to the table using a class
      elt.getElementsByClassName("custom-table")[0].style.width = "100%";
  
      // generate workbook with XLSXStyle
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });

      function xSeparateKeys(obj) {
        return Object.keys(obj).filter(key => 
          schemeType==3? ((key.startsWith('K') && key !== 'K2')|| 
          (key.startsWith('L') && key !== 'L2')|| 
          (key.startsWith('J') && key !== 'J2'&&key!=='J1')|| 
          (key.startsWith('M') && key !== 'M2'&&key!=='M1')|| 
          (key.startsWith('N') && key !== 'N2')|| 
          (key.startsWith('O') && key !== 'O2')|| 
          (key.startsWith('P') && key !== 'P2'&&key!=='P1')|| 
          (key.startsWith('F') && key !== 'F2') 
          ):schemeType==8?((key.startsWith('I') && key !== 'I1')|| 
          (key.startsWith('J') && key !== 'J1')|| 
          (key.startsWith('K') &&key!=='K1')
          ):schemeType==4?((key.startsWith('I') && key !== 'I1')|| 
          (key.startsWith('J') && key !== 'J1')|| 
          (key.startsWith('K') &&key!=='K1')||
          (key.startsWith('L') &&key!=='L1')
          ):((key.startsWith('I') && key !== 'I1')|| 
          (key.startsWith('J') && key !== 'J1')|| 
          (key.startsWith('K') &&key!=='K1')||
          (key.startsWith('L') &&key!=='L1')
          ))
      }

      const xKeys= xSeparateKeys(wb?.Sheets?.Sheet1);


      Object?.keys(wb?.Sheets?.Sheet1 || {})
        ?.filter(
          (x) =>
            x != "!rows" &&
            x != "!cols" &&
            x != "!merges" &&
            x != "!ref" &&
            x != "!fullref" &&
            !xKeys?.includes(x)
        )
        ?.forEach((x) => {
          wb.Sheets[wb.SheetNames[0]][x]["s"] = headerStyle;
        })
  
      // Dynamically set column widths
      wb.Sheets[wb.SheetNames[0]]["!cols"] = [
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
  
      // Dynamically set row heights
      wb.Sheets[wb.SheetNames[0]]["!rows"] = [
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
  
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        // Add more row heights as needed
      ];
  
      // remove element
      document.body.removeChild(elt);
  
      // generate file
      XLSX.writeFile(
        wb,
        (schemeType == "3" || schemeType == "")
          ? "slab_based_scheme.xlsx"
          : schemeType == "8"
          ? "Single_target.xlsx"
          : schemeType == "4"
          ? "Single_target_Upper_Cap.xlsx"
          : "Unconditional_installation.xlsx"
      );
  };
  const menu = (
    <Menu>
      <Menu.Item>
        {selectedFiles?.length == 0 && (
          <div className="custom-file-input">
            <input type="file" accept=".xlsx" onChange={handleFileUpload} />
            <label htmlFor="fileInput">Choose File</label>
          </div>
        )}
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>
              {file.name}
              <Button onClick={() => handleRemoveFile(index)} className="mx-1">
                Remove
              </Button>
            </li>
          ))}
        </ul>
      </Menu.Item>
      <Menu.Item onClick={handleSampleFile}>Download Sample File</Menu.Item>
    </Menu>
  );
  return (
    <div className="create_scheme_wrap general-bscheme">
           <div className="d-flex justify-content-between">
      <Button
        type="primary"
        icon={<ArrowBackRounded />}
        size="middle"
        onClick={() => {
          backBtn(false);
        }}
      />
          <Space
              style={{
                padding: "0",
                paddingTop: "14px",
              }}
              direction="vertical"
            >
              <Tooltip title={"Import/Export to Excel"}>
              <Dropdown overlay={menu} placement="bottomLeft">
                <Button className="h-2">Import/Export to Excel</Button>
              </Dropdown>
              </Tooltip> 
            </Space>
            </div>
      <div className="table_scheme_wrap generate-finace-report">
        <Form>
          <Table
            className="finance"
            loading={(loadingStateLocked == "pending" || loadingState == "pending"||loadingSaveHoldState=="pending"||loadingModificationSaveState=="pending") ? true : false}
            columns={defaultColumns}
            dataSource={formik?.values}
            bordered
            pagination={false}
            scroll={{
              x: 1000,
              y: 300,
            }}
            size="middle"
          />
        </Form>
        <div className="pt-3 d-flex f-reverse">
          <Button
            type="default"
            shape="round"
            style={{
              margin: "10px",
              background: "#FCE838",
              fontWeight: "700",
              textTransform: "uppercase",
              border: "none",
              padding: "20px",
            }}
            size="large"
            disabled={loadingModificationSaveState=="pending"||loadingState == "pending"||loadingSaveHoldState=="pending"?true:false}
            onClick={handleSubmit}
          >
            Update & Submit
          </Button>
        </div>
      </div>

      <Modal open={isModalOpen} onOk={() => {
        handleOk()
      }}        maskClosable={false}
      destroyOnClose={true} onCancel={showModal}>
        <h2>Are you sure you want to submit!</h2>
      </Modal>

      <ValidationErrorsModal
        open={openErrorDialog.open}
        onClose={() => {
          setOpenErrorDialog({ open: false, errorsFields: [] });
        }}
        errorsFields={openErrorDialog?.errorsFields}
      />
      <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
        <p className="custom-warning">Warning !</p>
        <p>
          {errorItem.firstItem} should be greater then {errorItem.secondItem}
        </p>
      </SMDialog>
    </div>
  );
};
export default ModificationEditSchemes;
