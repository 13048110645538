import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import "../../createSchemes/createScheme.css";
import { Button, Table, Form, message, Select, Tag, Tooltip, Modal, Space, Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import axiosAPI from "../../../../services/axiosApi";

import { useFormik } from "formik";

import { getCurrentFiscalYear } from "../../../../utils/dateFormatter";
import moment from "moment";
import { ArrowBack, ArrowBackRounded } from "@mui/icons-material";
import XLSX from "xlsx-js-style";
import {
  getUpdateModifiedAwaitingDataDetails,
  resetCollection,
  resetSaveModification,
} from "../../../../redux/modification/modificationSlice";
import SMDialog from "../../../../components/modal/alertModal/CommonModal";
import ValidationErrorsModal from "../../../../components/modal/alertModal/ValidationErrorsModal";
import { decryptData } from "../../../../utils/localStorageEncodeDecode";

import { getLockedSchemeTypeAsync, getLockedZonalHeadApiAsync } from "../../../../redux/stateData/stateDataSlice";
import { postUpdateHoldScheme } from "../../../../redux/hold/holdDataSlice";
import ToastifyShow from "../../../../components/ToastifyShow";
import { ModificationInstallationDownload } from "../ModificationInstallationDownload";
import { parseFiveDigitNumber } from "../../../../components/download/collectionSchemeUpload";
import { isValidContentType, isValidFileHeader } from "../../../../config";
const currentFiscalYear = getCurrentFiscalYear();

const EditModifiedAwaitingInstallation = ({ backBtn, selectedSchemeType, editCheckbox, schemeDetails, selectedLabel }) => {

  const dispatch = useDispatch();
  const { userId, userName, userRole } = decryptData(sessionStorage.getItem("userInfo"));
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [checkCalculate, setCheckCalculate] = useState(false);
  const [checkCalculateArr, setCheckCalculateArr] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [errorItem, setErrorItem] = useState({
    firstItem: null,
    secondItem: null,
  });
  const {loadingSaveHoldState}=useSelector((state)=>state.holdData)
  const [openErrorDialog, setOpenErrorDialog] = useState({
    open: false,
    errorsFields: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitData, setSubmitData] = useState({})
  const showModal = () => {
    setIsModalOpen(true);
  };
  const { editDashboard, loadingState,loadingModificationAwaitingState } = useSelector(
    (state) => state.modifyCollection
  );
  const editData = editDashboard?.data?.result;
  const formik = useFormik({
    initialValues: [],
  });

  const { lockedStates } = useSelector((state) => state?.stateData);
  const [schemeLockedStatus, setSchemeLockedStatus] = useState({
    t1: false,
    t2: false,
    t3: false
  })

  useEffect(() => {
    if (userRole == "ZADM" || userRole == "NSH") {
      dispatch(getLockedZonalHeadApiAsync({ "fyear": "FY24", "userid": userId })).then((x) => {
        if (x?.payload?.data?.[0]) {
          setSchemeLockedStatus(x?.payload?.data)
        }
      })
    }
    if (userRole == "NSH") {
      axiosAPI
        .post(`Dashboard/GetSchemeStatus`)
        .then((result) => {
          setSchemeLockedStatus(result.data.data)
        })

    }
  }, [])


  const topTargetArr = [
    {
      name: "T1",
      value: "t1",

    },
    {
      name: "T2",
      value: "t2",

    },
    {
      name: "T3",
      value: "t3",
      disabled: !schemeLockedStatus?.t3 ? true : false,
    }
  ];
  const topTargetArr1 = (x) => [
    {
      name: "T1",
      value: "t1",

    },
    {
      name: "T2",
      value: "t2",

    },
    {
      name: "T3",
      value: "t3",
      disabled: !x?.t3I ? true : false,
    }
  ];

  const getDropdown = () =>
    ((schemeLockedStatus?.t1 && selectedSchemeType == 3) || ((userRole == "ZADM" || userRole == "NSH") && selectedSchemeType == 3)) ?
      [
        {
          title: "Top Slab",
          width: "150px",
          render: (x, y, index) => {
            const handleChange = (value) => {
              let dropdownValues = formik?.values?.[index]?.dropdownscheme;
              let checkValue1 = value?.includes("t1");
              let checkValue2 = value?.includes("t2");
              let checkValue3 = value?.includes("t3");
              const handleDropDownScheme = () => {
                return checkValue3 ? ['t1', 't2', 't3']
                  : checkValue2 ? ['t1', 't2']
                    : checkValue1 ? ['t1'] : [];
              };
              const handleDropdownSchemes = handleDropDownScheme()
              formik.setFieldValue(index, {
                ...x,
                dropdownscheme: handleDropDownScheme() || null,
                ...(handleDropdownSchemes.includes("t3")
                  ? {
                  } :
                  handleDropdownSchemes.includes("t2")
                    ? {
                      ...(selectedLabel === 'Hold Schemes' ? { holdT3T2: '' } : { modifiedT3T2: '' }),
                      modifiedR3: 0,
                      modifiedT3: 0,
                      holdR3: 0,
                      holdT3: 0,
                    }
                    : handleDropdownSchemes.includes("t1")
                      ? {
                        modifiedR2: 0,
                        modifiedR3: 0,
                        modifiedT2: 0,
                        modifiedT3: 0,
                        holdR2: 0,
                        holdR3: 0,
                        holdT2: 0,
                        holdT3: 0,
                        ...(selectedLabel === 'Hold Schemes' ? { holdT2T1: '' } : { modifiedT2T1: '' })
                      }
                      : {}),
                BooksCollectionTargetT3T2: null,
              });

            };
            let arr1 = userRole == "ZADM" || userRole == "NSH" || userRole == "SADM" ? topTargetArr1(Array?.isArray(y?.topTargetArrState) ? y?.topTargetArrState[0] : y?.topTargetArrState)?.filter((x) => !x?.disabled) : topTargetArr
              ?.filter((x) => !x?.disabled)
            return (
              <Select
                onChange={handleChange}
                value={y?.dropdownscheme}
                mode="multiple"
                autoClearSearchValue="multiple"
                className="w-100"
                allowClear
                onDeselect={false}
                showArrow={false}
                removeIcon={false}
              >
                {arr1
                  ?.filter((x) => !x?.disabled)
                  ?.map((x) => (
                    <Select.Option value={x?.value}>
                      {x?.name}
                    </Select.Option>
                  ))}
              </Select>
            );
          },
        },
      ] : []

  const getTargetT1 = () => [
    {
      title: "T1",
      dataIndex: "t1",
      render: (x, y, index) => (<div>
        {/* <Tag className="w-70 input1">{y?.t1}</Tag> */}
        <input
          // style={{ backgroundColor: y?.t1 != y?.modifiedT1 ? "#ffcfcf" : "", padding: "5px" }}
          name={formik?.values?.[index]?.modifiedT1}
          placeholder="Type here"
          value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT1 : formik?.values?.[index]?.modifiedT1}
          onBlur={() => {
            calculateFormula(index);
          }}
          onChange={(e) => {
            let value = e.target?.value.replace(/[^\d]/g, "");
            formik.setFieldValue(index, {
              ...y,
              modifiedT1: value,
              holdT1: value
            });
          }}
          disabled={
            !formik?.values?.[index]?.dropdownscheme?.includes("t1")
              ? true
              : false
          }
          className={
            !formik?.values?.[index]?.dropdownscheme?.includes("t1")
              ? "input-disabled"
              : "my-1 input1 w-70"
          }
        />
      </div>
      ),
      width: "200px",
    },
  ]

  const getTargetT2 = () => [
    {
      title: "T2",
      dataIndex: "t2",
      render: (x, y, index) => (
        <div >
          {/* <Tag className="w-70 input1">{y?.t2}</Tag> */}
          <input
            // style={{ backgroundColor: y?.t2 != y?.modifiedT2 ? "#ffcfcf" : "", padding: "5px" }}
            name={formik?.values?.[index]?.modifiedT2}
            value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT2 : formik?.values?.[index]?.modifiedT2}
            onBlur={() => {
              calculateFormula(index);
            }}
            onChange={(e) => {
              let value = e.target?.value.replace(/[^\d]/g, "");
              formik.setFieldValue(index, {
                ...y,
                modifiedT2: value,
                holdT2: value
              });
            }}
            disabled={
              !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                ? true
                : false
            }
            className={
              !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                ? "input-disabled"
                : "my-1 input1 w-70"
            }
            placeholder={
              !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                ? ""
                : "Type here"
            }
          />
        </div>
      ),
      width: "200px",
    },
  ];
  const getTargetT3 = () => [
    {
      title: "T3",
      dataIndex: "t3",
      render: (x, y, index) => (
        <div >
          {/* <Tag className="w-70 input1">{y?.t3}</Tag> */}
          <input
            // style={{ backgroundColor: y?.t3 != y?.modifiedT3 ? "#ffcfcf" : "", padding: "5px" }}
            name={formik?.values?.[index]?.modifiedT3}
            value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT3 : formik?.values?.[index]?.modifiedT3}
            onBlur={() => {
              calculateFormula(index);
            }}
            onChange={(e) => {
              let value = e.target?.value.replace(/[^\d]/g, "");
              formik.setFieldValue(index, {
                ...y,
                modifiedT3: value,
                holdT3: value
              });
            }}
            placeholder={
              !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                ? ""
                : "Type here"
            }
            disabled={
              !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                ? true
                : false
            }
            className={
              !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                ? "input-disabled"
                : "my-1 input1 w-70"
            }
          />
        </div>
      ),
      width: "200px",
    },
  ];
  const getPayoutRateR1 = () => [
    {
      title: "R1",
      dataIndex: "r1",
      render: (x, y, index) => (
        <div>
          {/* <Tag className="w-70 input1">{y?.r1}</Tag> */}
          <input
            // style={{ backgroundColor: y?.r1 != y?.modifiedR1 ? "#ffcfcf" : "", padding: "5px" }}
            name={formik?.values?.[index]?.modifiedR1}
            value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR1 : formik?.values?.[index]?.modifiedR1}
            onBlur={() => {
              calculateFormula(index);
            }}
            onChange={(e) => {
              let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
              if (value.length > 5) {
                value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
              }
              formik.setFieldValue(index, {
                ...y,
                modifiedR1: value,
                holdR1: value
              });
            }}
            disabled={
              !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                ? true
                : false
            }
            className={
              !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                ? "input-disabled"
                : "my-1 input1 w-70"
            }
            placeholder={
              !formik?.values?.[index]?.dropdownscheme?.includes("t1")
                ? ""
                : "Type here"
            }
          />
        </div>
      ),
      width: "200px",
    },
  ]
  const getPayoutRateR2 = () => [
    {
      title: "R2",
      dataIndex: "r2",
      render: (x, y, index) => (
        <div>
          {/* <Tag className="w-70 input1">{y?.r2}</Tag> */}
          <input
            // style={{ backgroundColor: y?.r1 != y?.modifiedR2 ? "#ffcfcf" : "", padding: "5px" }}
            name={formik?.values?.[index]?.modifiedR2}
            value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR2 : formik?.values?.[index]?.modifiedR2}
            onBlur={() => {
              calculateFormula(index);
            }}
            onChange={(e) => {
              let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
              if (value.length > 5) {
                value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
              }
              formik.setFieldValue(index, {
                ...y,
                modifiedR2: value,
                holdR2: value
              });
            }}
            disabled={
              !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                ? true
                : false
            }
            className={
              !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                ? "input-disabled"
                : "my-1 input1 w-70"
            }
            placeholder={
              !formik?.values?.[index]?.dropdownscheme?.includes("t2")
                ? ""
                : "Type here"
            }

          />
        </div>
      ),
      width: "200px",
    },
  ]
  const getPayoutRateR3 = () => [
    {
      title: "R3",
      dataIndex: "r3",
      render: (x, y, index) => (

        <div >
          {/* <Tag className="w-70 input1">{y?.r3}</Tag> */}
          <input
            // style={{ backgroundColor: y?.r3 != y?.modifiedR3 ? "#ffcfcf" : "", padding: "5px" }}
            name={formik?.values?.[index]?.modifiedR3}
            value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR3 : formik?.values?.[index]?.modifiedR3}
            onBlur={() => {
              calculateFormula(index);
            }}
            onChange={(e) => {
              let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
      
              if (value.length > 5) {
                value = value.slice(0, 5); // Trim the value to a maximum of 5 digits
              }
              formik.setFieldValue(index, {
                ...y,
                modifiedR3: value,
                holdR3: value
              });
            }}
            disabled={
              !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                ? true
                : false
            }
            className={
              !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                ? "input-disabled"
                : "my-1 input1 w-70"
            }
            placeholder={
              !formik?.values?.[index]?.dropdownscheme?.includes("t3")
                ? ""
                : "Type here"
            }
          />
        </div>
      ),
      width: "200px",
    },
  ]
  const getPayoutP1 = () => [
    {
      title: "P1",
      dataIndex: "p1",
      width: "300px",
      render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t1") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
    },
  ]
  const getPayoutP2 = () => [
    {
      title: "P2",
      dataIndex: "p2",
      width: "300px",
      render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t2") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
    },
  ];
  const getPayoutP3 = () => [
    {
      title: "P3",
      dataIndex: "p3",
      width: "300px",
      render: (x, y, index) => formik?.values?.[index]?.dropdownscheme?.includes("t3") ? <div>{x}</div> : <div className="h-24 input-disabled"></div>
    },
  ];
  useEffect(() => {
    if (schemeDetails) {
      let data1 = schemeDetails.map((x) => ({
        ...x,
        dealerCode: x.dealerCode,
        dealerId: x.dealerId,
        key:x.schemeId,
        dealerName: x.dealerName,
        dealerLocation: x.dealerLocation,
        stateName: x.stateName,
        teritoryManager: x.teritoryManager || "",
        installationTarget: parseInt(x?.installationTarget) || 0,
        incPerTarget: parseInt(x?.incPerTarget) || 0,
        actual_Installation_Last_Year_Month:
          x?.actual_Installation_Last_Year_Month,
        startMonth: moment(x.startMonth).format("MMM YY"),
        endMonth: moment(x.endMonth).format("MMM YY"),
        reason: x.reason,
        payout: x?.payout,
        t1: +x.t1,
        t2: +x.t2,
        t3: +x.t3,
        p1: x.p1,
        p2: x.p2,
        p3: x.p3,
        r1: +x.r1,
        r2: +x.r2,
        r3: +x.r3,
        volume: x.volume,
        perTr: Math.round(x.perTr),
        amount: x.amount,
        dropdownscheme: selectedLabel === 'Hold Schemes' ? x?.holdTopTarget?.split(",") : x?.modifiedTopTarget?.split(",") || [],
        TopTarget: x?.dropdownscheme?.toString() || "",
        topTargetArrState: (Array?.isArray(schemeLockedStatus)) ? schemeLockedStatus.filter((y) => y?.stateId == x?.stateId)?.[0] || [{
          t1: false,
          t2: false,
          t3: false,

        }] : [{
          t1: false,
          t2: false,
          t3: false,

        }],
        //   ...(userRole=="ZADM" || userRole=="NSH")&&{topTargetArrState: (userRole=="ZADM" || userRole=="NSH" )&&Array?.isArray(schemeLockedStatus)?(schemeLockedStatus?.filter((y) => y?.stateId == x?.stateId)?.[0]||{
        //     t1: true,
        //       t2: true,
        //       t3: false, 
        //   }):{
        //      t1: true,
        //       t2: true,
        //       t3: false,
        //   }
        // },
        // topTargetArrState: schemeLockedStatus?.filter((y) => y?.stateId == x?.stateId)?.[0] || {}
      }));
      formik?.setValues(data1);

    }
  }, [schemeDetails, schemeLockedStatus]);


  const handleErrorModal = (firstItem, secondItem, opt = false) => {
    setOpenErrorModal(opt ? opt : !openErrorModal);
    setErrorItem({
      firstItem,
      secondItem,
    });
  };
  const showErrorDialog = (errFields) => {
    setOpenErrorDialog((openErrorDialog) => ({
      open: true,
      errorsFields: errFields ? errFields : [],
    }));
  };
  const calculateFormula = (index) => {
    let y = formik?.values[index];
    if (selectedSchemeType == 3) {
      // let { t3,t2, t1 } = formik?.values[index];
      let t1 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT1 : formik?.values?.[index]?.modifiedT1;
      let t2 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT2 : formik?.values?.[index]?.modifiedT2;
      let t3 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdT3 : formik?.values?.[index]?.modifiedT3;
      let r1 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR1 : formik?.values?.[index]?.modifiedR1;
      let r2 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR2 : formik?.values?.[index]?.modifiedR2;
      let r3 = selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdR3 : formik?.values?.[index]?.modifiedR3;

      formik?.setFieldValue(index, {
        ...y,
        t1: parseInt(t1),
        t2: parseInt(t2),
        t3: parseInt(t3),
        r1: parseInt(r1),
        r2: parseInt(r2),
        r3: parseInt(r3),
        p1: parseInt(t1) * parseInt(r1),
        p2: parseInt(t2) * parseInt(r2),
        p3: parseInt(t3) * parseInt(r3),
        holdP1: parseInt(t1) * parseInt(r1),
        holdP2: parseInt(t2) * parseInt(r2),
        holdP3: parseInt(t3) * parseInt(r3),
      });
      if (r2 != null && r1 != null && parseInt(r2) <= parseInt(r1)) {
        handleErrorModal("R2", "R1", true);
      }

      if (t2 != null && t1 != null && parseInt(t2) <= parseInt(t1)) {
        handleErrorModal("T2", "T1", true);
      }
    } else if (selectedSchemeType == 4) {
      let { upperCap, incPerTarget, installationTarget } =
        formik?.values[index];

      if (
        upperCap != null &&
        incPerTarget != null &&
        parseInt(incPerTarget) <= parseInt(upperCap)
      ) {
        handleErrorModal("Inc Per Target", "Upper Cap", true);
      }
      formik?.setFieldValue(index, {
        ...y,
        installationTarget: parseInt(installationTarget),
        incPerTarget: parseInt(incPerTarget),
        upperCap: parseInt(upperCap),
        // UC:parseInt(UC),
        // IPT:parseInt(IPT),
        payout: parseInt(upperCap) * parseInt(incPerTarget),
        holdP1: parseInt(upperCap) * parseInt(incPerTarget),

      });
    } else if (selectedSchemeType == 5) {

      let { installationTarget, incPerTarget } = formik?.values[index];

      if (
        installationTarget != null &&
        incPerTarget != null &&
        parseInt(incPerTarget) <= parseInt(installationTarget)
      ) {
        handleErrorModal("incPerTarget", "installationTarget", true);
      }

      formik?.setFieldValue(index, {
        ...y,
        installationTarget: parseInt(installationTarget),
        incPerTarget: parseInt(incPerTarget),
        payout: parseInt(installationTarget) * parseInt(incPerTarget),
        holdP1: parseInt(installationTarget) * parseInt(incPerTarget),
      });
    } else {
      let { installationTarget, incPerTarget } = formik?.values[index];
      if (
        installationTarget != null &&
        incPerTarget != null &&
        parseInt(incPerTarget) <= parseInt(installationTarget)

      ) {
        handleErrorModal("incPerTarget", "installationTarget", true);
      }

      formik?.setFieldValue(index, {
        ...y,
        installationTarget: parseInt(installationTarget),
        incPerTarget: parseInt(incPerTarget),
        p1: parseInt(installationTarget) * parseInt(incPerTarget),
        holdP1: parseInt(installationTarget) * parseInt(incPerTarget),
      });
    }
  };


  const getCols = (schemeType) => {
    if (schemeType == 3) {
      return [
        {
          title: "Targets",
          dataIndex: "Target",
          width: "600px",
          children: [
            ...getTargetT1(),
            ...getTargetT2(),
            ...getTargetT3(),
          ],
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "IPT",
          width: "600px",
          children: [
            ...getPayoutRateR1(),
            ...getPayoutRateR2(),
            ...getPayoutRateR3(),
          ],
        },
        {
          title: "Payout",
          dataIndex: "Payout",
          width: "600px",
          children: [
            ...getPayoutP1(),
            ...getPayoutP2(),
            ...getPayoutP3(),


          ],
        },
      ];
    }
    if (schemeType == 4) {
      return [
        {
          title: "Installation Target",
          dataIndex: "modifiedInstallationTarget",
          render: (x, y, index) => (
            <div className="d-flex py-2">
              {/* <Tag className="w-70 input1">{y?.installationTarget}</Tag> */}
              <input
                name={formik?.values?.[index]?.modifiedInstallationTarget}
                placeholder="Type here"
                value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdInstallationTarget : formik?.values?.[index]?.modifiedInstallationTarget}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    installationTarget: value,
                    holdInstallationTarget: value
                  });
                }}
                className="input1 w-70"

              />
            </div>
          ),
          width: "300px",
        },
        {
          title: "Upper Cap",
          dataIndex: "upperCap",
          render: (x, y, index) => (
            <div className="d-flex py-2">
              {/* <Tag className="w-70 input1">{y?.upperCap}</Tag> */}
              <input
                // style={{ backgroundColor: y?.modifiedUpperCap != y?.upperCap ? "#ffcfcf" : "", padding: "5px" }}
                name={formik?.values?.[index]?.modifiedUpperCap}
                placeholder="Type here"
                value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdUpperCap : formik?.values?.[index]?.modifiedUpperCap}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    upperCap: value,
                    holdUpperCap: value,
                  });
                }}
                className="input1 w-70"
              />
            </div>
          ),
          width: "300px",
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          render: (x, y, index) => (
            <div className="d-flex py-2">
              {/* <Tag className="w-70 input1">{y?.incPerTarget}</Tag> */}
              <input
                // style={{ backgroundColor: y?.modifiedIncPerTarget != y?.incPerTarget ? "#ffcfcf" : "", padding: "5px" }}
                name={formik?.values?.[index]?.modifiedIncPerTarget}
                placeholder="Type here"
                value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdIncPerTarget : formik?.values?.[index]?.modifiedIncPerTarget}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    incPerTarget: value,
                    holdIncPerTarget: value
                  });
                }}
                className="input1 w-70"

              />
            </div>
          ),
          width: "300px",
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "300px",
          render: (x, y, index) => (
            <div className="d-flex py-2">
              {/* <Tag className="w-70 input1">{y?.p1}</Tag> */}
              <Tag className="w-70 input1">{selectedLabel === 'Hold Schemes' ? y.holdP1 : y?.p1}</Tag>
            </div>
          )
        },
      ];
    }
    if (schemeType == 8) {
      return [
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          render: (x, y, index) => (
            <div className="d-flex py-2">
              {/* <Tag className="w-70 input1">{y?.installationTarget}</Tag> */}
              <input
                // style={{ backgroundColor: y?.installationTarget != y?.modifiedInstallationTarget ? "#ffcfcf" : "", padding: "5px" }}
                name={formik?.values?.[index]?.modifiedInstallationTarget}
                placeholder="Type here"
                value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdInstallationTarget : formik?.values?.[index]?.modifiedInstallationTarget}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    installationTarget: value,
                    holdInstallationTarget: value
                  });
                }}
                className="input1 w-70"
              />
            </div>
          ),
          width: "300px",
        },

        {
          title: "inc. Per Tractor",
          dataIndex: "incPerTarget",
          render: (x, y, index) => (
            <div className="d-flex py-2">
              {/* <Tag className="w-70 input1">{y?.incPerTarget}</Tag> */}
              <input
                // style={{ backgroundColor: y?.modifiedIncPerTarget != y?.incPerTarget ? "#ffcfcf" : "", padding: "5px" }}
                name={formik?.values?.[index]?.modifiedIncPerTarget}
                placeholder="Type here"
                value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdIncPerTarget : formik?.values?.[index]?.modifiedIncPerTarget}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    incPerTarget: value,
                    holdIncPerTarget: value
                  });
                }}
                className="input1 w-70"
              />
            </div>
          ),
          width: "300px",
        },
        {
          title: "Payout",
          dataIndex: "p1",
          width: "300px",
          render: (x, y, index) => (
            <div className="d-flex py-2">
              <Tag className="w-70 input1">{selectedLabel === 'Hold Schemes' ? y.holdP1 : y?.p1}</Tag>
              {/* <Tag className="w-70 input1">{y?.p1}</Tag> */}
            </div>
          )
        },
      ];
    }
    if (schemeType == 5) {
      return [
        {
          title: "Installation Expected",
          dataIndex: "modifiedInstallationTarget",

          render: (x, y, index) => (
            <div className="d-flex py-2">

              {/* <Tag className="w-70 input1">
                <Tooltip title={y?.installationTarget}>{y?.installationTarget}</Tooltip>
              </Tag> */}

              <input
                // style={{ backgroundColor: y?.installationTarget != y?.modifiedInstallationTarget ? "#ffcfcf" : "", padding: "5px" }}
                name={formik?.values?.[index]?.modifiedInstallationTarget}
                placeholder="Type here"
                value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdInstallationTarget : formik?.values?.[index]?.modifiedInstallationTarget}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    installationTarget: value,
                    holdInstallationTarget: value,
                  });
                }}
                className="w-70 input1"

              />
            </div>
          ),
          width: "300px",
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "modifiedIncPerTarget",
          render: (x, y, index) => (
            <div className="d-flex py-2">

              {/* {y?.incPerTarget && (
                <Tag className="w-70 input1">
                  <Tooltip title={y?.incPerTarget}>{y?.incPerTarget}</Tooltip>
                </Tag>
              )} */}

              <input
                // style={{ backgroundColor: y?.modifiedIncPerTarget != y?.incPerTarget ? "#ffcfcf" : "", padding: "5px" }}
                name={formik?.values?.[index]?.modifiedIncPerTarget}
                placeholder="Type here"
                value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdIncPerTarget : formik?.values?.[index]?.modifiedIncPerTarget}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    incPerTarget: value,
                    holdIncPerTarget: value,
                  });
                }}
                className="w-70 input1"

              />
            </div>
          ),
          width: "300px",
        },
        {
          title: "Payout",
          dataIndex: "payout",
          width: "300px",
          render: (x, y, index) => (
            <div className="d-flex py-2">
              {/* <Tag className="w-70 input1">{y?.payout || 0}</Tag> */}
              <Tag className="w-70 input1">{selectedLabel === 'Hold Schemes' ? y.holdP1 : y?.payout || 0}</Tag>
            </div>
          )
        },
      ];
    }
    return [];
  };
  const getStateHead = () => userRole != "SADM" ? [
    {
      title: (
        <div>
          State <br /> Head
        </div>
      ),
      dataIndex: "stateHeadName",
      width: "180px",
    },
  ] : []
  const defaultColumns = useMemo(
    () => [
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        fixed: "left",
        width: "120px",
        sorter: true,
        key: "DealerCode",
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        fixed: "left",
        width: "180px",
        sorter: true,
        key: "DealerName"
        //  defaultSortOrder: "descend",
        //  sorter: (a, b) => a.name && b.name && a.name.length - b.name.length,
      },
      {
        title: "Dealer Location",
        dataIndex: "dealerLocation",
        width: "120px",
        fixed: "left",
        sorter: true,
        key: "DealerLocation",
      },
      {
        title: "State",
        dataIndex: "stateName",
        width: "120px",
        sorter: true,
        key: "StateName",
      }, ...getStateHead(),
      {
        title: "Territory Manager",
        dataIndex: "territoryManager",
        width: "100px",
        sorter: true,
        key: "TerritoryManager",
      },
      ...getDropdown(),
      {
        title: "Start Month",
        dataIndex: "startMonth",
        width: "120px",
      },
      {
        title: "End Month",
        dataIndex: "endMonth",
        width: "120px",
      },
      {
        title: "Actual Installation Last Year Month",
        dataIndex: "actual_Installation_Last_Year_Month",
        width: "250px",
      },
      ...getCols(selectedSchemeType),

      {
        title: "Remarks",
        dataIndex: "reason",
        render: (x, y, index) => (

          <div style={{ display: "flex" }}>

            {/* <Tag className="w-70 input1">
                <Tooltip title={y?.reason}>{y?.reason}</Tooltip>
              </Tag> */}


            <Tooltip title={y?.modifiedReason}>
              <input

                // style={{ backgroundColor: y?.modifiedReason != y?.reason ? "#ffcfcf" : "", padding: "5px" }}
                name={formik?.values?.[index]?.modifiedReason}
                placeholder="Type here"
                value={selectedLabel === 'Hold Schemes' ? formik?.values?.[index]?.holdRemarks : formik?.values?.[index]?.modifiedReason}
                onBlur={() => {
                  calculateFormula(index);
                }}
                onChange={(e) => {
                  let value = e.target?.value;
                  const sanitizedValue = value
                    ?.replace("<script>", "")
                    ?.replace("</script>", "")
                  //  .replace(/[^a-zA-Z0-9\s]/g, "");
                  formik.setFieldValue(index, {
                    ...y,
                    modifiedReason: sanitizedValue,
                    holdRemarks: sanitizedValue,
                  });
                }}
                className="input1 w-70"
              />
            </Tooltip>
          </div>

        ),
        width: "300px",
      },
    ],
    [formik?.values]
  );


  const handleOk = () => {
    if (submitData?.[0]?.holdStatus == 9) {
      dispatch(postUpdateHoldScheme(submitData)).then(async (data) => {
        if (data.payload?.errorMessage == "Hold Scheme Updated successfully!!") {
          message.success({
            content: "Record Updated Successfully",
            className: "custom-message",
          });
          await backBtn(false)
        }
      });
      setIsModalOpen(false);
    } else {

      dispatch(getUpdateModifiedAwaitingDataDetails(submitData))

        .then(async (result) => {
          if (result.payload?.errorMessage == "Data Saved successfully!!") {
            message.success({
              content: "Record Updated Successfully",
              className: "custom-message",
            });
            await backBtn(false)
            await dispatch(resetSaveModification())
          }
        })
      setIsModalOpen(false);
    }
  };
  const handleSubmit = (e) => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    const userRole = userData.userRole;
    const handleDropDownSchemeCheckIndex = (x) => {

      let { t1, t2, t3 } = x.reduce((a, v) => ({ ...a, [v]: v }), {});

      let data = t1 && !t2 && !t3
        ? "t1"
        : t1 && t2 && !t3
          ? "t2"
          : t1 && t2 && t3
            ? "t3"
            : "";
      return data
    };

    const finalData=formik?.values?.filter((selectedSchemesFil)=>selectedRowKeys.includes(selectedSchemesFil?.key))
 
    if(finalData?.length==0){
      return ToastifyShow("Please select the checkbox","error")
    }

    if (selectedSchemeType == 3) {
      let data = [];

      if (finalData?.[0]?.holdStatus == 9) {
        finalData.forEach((x) => {
          data.push({
            ...x,
            dealerCode: x.dealerCode,
            dealerId: +x.dealerId,
            startMonth: x.startMonth,
            endMonth: x.endMonth,
            isSAP: true,
            isActive: true,
            state: x.stateId,
            status: 1,
            isSubmitted: 1,
            fyear: currentFiscalYear,
            schemeType: 3,
            // schemeTypeId: schemeType,
            userid: +userId,
            username: userName,
            userrole: userRole,
            TopTarget: x?.dropdownscheme?.toString(),
            T1: +x.holdT1 || 0,
            T2: +x.holdT2 || 0,
            T3: +x.holdT3 || 0,
            R1: +x.holdR1 || 0,
            R2: +x.holdR2 || 0,
            R3: +x.holdR3 || 0,
            P1: +x.holdP1 || 0,
            P2: +x.holdP2 || 0,
            P3: +x.holdP3 || 0,
            reason: x.reason,
            userid: +userId,
            username: userName,
            userrole: userRole,
            HoldReason: x.holdRemarks,
            holdStatus: x?.holdStatus,
            IsModified: true
          });
        });
      } else {
        finalData.forEach((x) => {

          data.push({
            ...x,
            dealerCode: x.dealerCode,
            dealerId: +x.dealerId,
            startMonth: x.startMonth,
            endMonth: x.endMonth,
            isSAP: true,
            isActive: true,
            state: x.stateId,
            status: 1,
            isSubmitted: 1,
            fyear: currentFiscalYear,
            schemeType: 3,
            // schemeTypeId: schemeType,
            userid: +userId,
            username: userName,
            userrole: userRole,
            topTarget: x?.dropdownscheme?.toString(),
            t1: +x.modifiedT1 || 0,
            t2: +x.modifiedT2 || 0,
            t3: +x.modifiedT3 || 0,
            r1: +x.modifiedR1 || 0,
            r2: +x.modifiedR2 || 0,
            r3: +x.modifiedR3 || 0,
            p1: +x.modifiedP1 || 0,
            p2: +x.modifiedP2 || 0,
            p3: +x.modifiedP3 || 0,
            reason: x.reason,
            userid: +userId,
            username: userName,
            userrole: userRole,
            IsModified: selectedLabel == "Hold Schemes" ? true : false
          });
        });
      }


      let checkValidation = selectedSchemeType == 3 ? finalData?.filter(
        (x) =>

          handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t1" ?
            (
              !x.t1 ||
              !x.r1) : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t2" ?
              (
                !x.t1 ||
                !x.t2 ||
                !x.r1 ||
                !x.r2 ||
                parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0) ||
                parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0) ||

                !x.TEPvol) : handleDropDownSchemeCheckIndex(x?.dropdownscheme) == "t3" ?
                (
                  !x.t1 ||
                  !x.t2 ||
                  !x.t3 ||
                  !x.r1 ||
                  !x.r2 ||
                  !x.r3 ||
                  parseInt(x?.t3 || 0) <= parseInt(x?.t2 || 0) ||
                  parseInt(x?.t2 || 0) <= parseInt(x?.t1 || 0) ||
                  parseInt(x?.r3 || 0) <= parseInt(x?.r2 || 0) ||
                  parseInt(x?.r2 || 0) <= parseInt(x?.r1 || 0) ||
                  !x.TEPvol) : (
                  !x.t1 ||
                  !x.t2 ||
                  !x.t3 ||
                  !x.r1 ||
                  !x.r2 ||
                  !x.r3
                )
      ) : selectedSchemeType == 4 ? finalData?.filter(
        (x) => ((!x?.installationTarget) ||
          (!x.upperCap) ||
          (!x.incPerTarget)
        )) : finalData?.filter(
          (x) => (
            (!x?.installationTarget) || (!x.incPerTarget)
          ))
      let errorDetails = Object.entries(
        checkValidation?.[0] ? checkValidation?.[0] : {}
      )
        ?.filter(
          (x) => checkValidation?.[0]?.dropdownscheme?.includes("t3") ?
            (!x[1] &&
              (x[0] == "t1" ||
                x[0] == "t2" ||
                x[0] == "t3" ||
                x[0] == "r1" ||
                x[0] == "r2" ||
                x[0] == "r3"
              )) : checkValidation?.[0]?.dropdownscheme?.includes("t2") ? (
                !x[1] &&
                (x[0] == "t1" ||
                  x[0] == "t2" ||
                  x[0] == "r1" ||
                  x[0] == "r2"
                )
              ) : checkValidation?.[0]?.dropdownscheme?.includes("t1") ? (
                !x[1] &&
                (x[0] == "t1" ||
                  x[0] == "r1"
                )
              ) : (
              !x[0]
            )

        ).map((x) =>
          x[0] == "t1"
            ? "T1"
            : x[0] == "t2"
              ? "T2"
              : x[0] == "t3"
                ? "T3"
                : x[0] == "r1"
                  ? "R1"
                  : x[0] == "r2"
                    ? "R2"
                    : x[0] == "r3"
                      ? "R3"
                      : x[0]
        )
      let extraValidation = [];



      if (selectedSchemeType == 3) {

        if (checkValidation?.[0]?.dealerCode) {
          finalData?.filter((x) => x?.dealerCode == checkValidation?.[0]?.dealerCode).map((x, index) => {

            let topTargetDetails = x?.dropdownscheme

            let t3Details = x?.t3 != "" ? parseInt(x?.t3 || 0) : 0
            let t2Details = x?.t2 != "" ? parseInt(x?.t2 || 0) : 0
            let t1Details = x?.t1 != "" ? parseInt(x?.t1 || 0) : 0

            let r3Details = x?.r3 != "" ? parseInt(x?.r3 || 0) : 0
            let r2Details = x?.r2 != "" ? parseInt(x?.r2 || 0) : 0
            let r1Details = x?.r1 != "" ? parseInt(x?.r1 || 0) : 0

            if (topTargetDetails?.length == 0) {
              extraValidation.push("Please select top target");
            }
            if (topTargetDetails?.includes("t3") && (parseInt(t3Details) <= parseInt(t2Details))) {

              extraValidation.push("T3 should be greater than T2");
            }
            if (topTargetDetails?.includes("t2") && parseInt(t2Details) <= parseInt(t1Details)) {
              extraValidation.push("T2 should be greater than T1");

            }
            if (topTargetDetails?.includes("t3") && parseInt(r3Details) <= parseInt(r2Details)) {
              extraValidation.push("R3 should be greater than R2");

            }
            if (topTargetDetails?.includes("t2") && parseInt(r2Details) <= parseInt(r1Details)) {
              extraValidation.push("R2 should be greater than R1");


            }
          })
        }
      }
      let errorShow = errorDetails?.concat(extraValidation);
      if (Object.keys(errorShow ? errorShow : {})?.length != 0) {
        showErrorDialog(errorShow);
      } else {
        setSubmitData(data);
        showModal()

      }
    } else if (selectedSchemeType == 4) {
      let checkValidation = finalData?.filter(
        (x) => !x?.Target || !x?.IPT || !x?.upperCap || !x?.reason
      );
      let errorDetails = Object.entries(
        checkValidation?.[0] ? checkValidation?.[0] : {}
      )
        ?.filter(
          (x, index) =>
            !x[1] &&
            (x[0] === "Target" ||
              x[0] === "IPT" ||
              x[0] === "upperCap" ||
              x[0] === "reason")
        )
        ?.map((x) =>
          x[0] === "Target"
            ? "Target"
            : x[0] === "IPT"
              ? "inc. Per Tractor"
              : x[0] === "upperCap"
                ? "upperCap"
                : x[0] === "reason"
                  ? "Reason"
                  : ""
        );

      let data = [];

      if (finalData?.[0]?.holdStatus == 9) {
        finalData.forEach((x) => {
          data.push({
            ...x,
            dealerCode: x.dealerCode,
            dealerId: +x.dealerId,
            StartMonth: moment(x.startMonth).format("MMM YY"),
            endMonth: moment(x.endMonth).format("MMM YY"),
            isSAP: true,
            isActive: true,
            state: x.stateId,
            status: 1,
            isSubmitted: 1,
            fyear: currentFiscalYear,
            userid: +userId,
            username: userName,
            userrole: userRole,
            payout: x.payout ? parseInt(x.payout) : 0,
            InsPerTarget: parseInt(x.incPerTarget) || 0,
            installationTarget: parseInt(x.Target),
            p1: +x.p1 ? parseInt(x.p1) : 0,
            P1: +x.p1 ? parseInt(x.p1) : 0,
            reason: x.reason,
            UpperCap: +x.upperCap,
            InsTarget: parseInt(x?.installationTarget) || 0,
            userid: +userId,
            username: userName,
            userrole: userRole,
            ModificationId: x?.modifiedId,
            HoldReason: x.holdRemarks,
            holdStatus: x?.holdStatus,
            IsModified: true
          });
        });
      }
      else {
        finalData.forEach((x) => {
          data.push({
            ...x,
            dealerCode: x.dealerCode,
            dealerId: +x.dealerId,
            StartMonth: moment(x.startMonth).format("MMM YY"),
            endMonth: moment(x.endMonth).format("MMM YY"),
            isSAP: true,
            isActive: true,
            state: x.stateId,
            status: 1,
            isSubmitted: 1,
            fyear: currentFiscalYear,
            userid: +userId,
            username: userName,
            userrole: userRole,
            payout: x.payout ? parseInt(x.payout) : 0,
            incPerTarget: parseInt(x.incPerTarget) || 0,

            installationTarget: parseInt(x.Target),
            p1: +x.payout ? parseInt(x.payout) : 0,
            reason: x.reason,
            upperCap: +x.upperCap,
            installationTarget: parseInt(x?.installationTarget) || 0,

            userid: +userId,
            username: userName,
            userrole: userRole,
            ModificationId: x?.modifiedId,
            IsModified: selectedLabel == "Hold Schemes" ? true : false

          });
        });
      }

      if (Object.keys(errorDetails ? errorDetails : {}).length !== 0) {
        showErrorDialog(errorDetails);
      } else {
        setSubmitData(data);
        showModal()

      }
    } else if (selectedSchemeType == 5) {
      let checkValidation = finalData?.filter(
        (x) => !x?.Target || !x?.IPT || !x?.reason
      );

      let errorDetails = Object.entries(
        checkValidation?.[0] ? checkValidation?.[0] : {}
      )
        ?.filter(
          (x, index) =>
            !x[1] && (x[0] === "Target" || x[0] === "IPT" || x[0] === "reason")
        )
        ?.map((x) =>
          x[0] === "Target"
            ? "Target"
            : x[0] === "IPT"
              ? "inc. Per Tractor"
              : x[0] === "reason"
                ? "Reason"
                : ""
        );

      let data = [];

      finalData.forEach((x) => {
        data.push({
          ...x,
          dealerCode: x.dealerCode,
          dealerId: +x.dealerId,
          StartMonth: moment(x.startMonth).format("MMM YY"),
          endMonth: moment(x.endMonth).format("MMM YY"),
          isSAP: true,
          isActive: true,
          state: x.stateId,
          status: 1,
          isSubmitted: 1,
          fyear: currentFiscalYear,
          userid: +userId,
          username: userName,
          userrole: userRole,
          payout: x.payout ? parseInt(x.payout) : 0,
          incPerTarget: parseInt(x.incPerTarget) || 0,
          InsPerTarget: parseInt(x.incPerTarget) || 0,
          p1: +x.payout ? parseInt(x.payout) : 0,
          P1: +x.payout ? parseInt(x.payout) : 0,
          reason: x.reason,
          installationTarget: parseInt(x?.installationTarget) || 0,
          InsTarget: parseInt(x?.installationTarget) || 0,
          userid: +userId,
          username: userName,
          userrole: userRole,
          ModificationId: x?.modifiedId,
          HoldReason: x.modifiedReason,
          holdStatus: x?.holdStatus,
          IsModified: selectedLabel == "Hold Schemes" ? true : false
        });
      });
      if (selectedLabel === 'Regular Schemes' || selectedLabel === 'Schemes') {
        data = data.map(d => {
          delete d.HoldReason;
          return d;
        });
      } else if (selectedLabel === "Hold Schemes") {

        data = data.map(d => {
          delete d.reason;
          return d;
        });


      }

      if (Object.keys(errorDetails ? errorDetails : {}).length !== 0) {
        showErrorDialog(errorDetails);
      } else {
        setSubmitData(data);
        showModal()
        //   if(data?.[0].holdStatus == 9){
        //     dispatch(postUpdateHoldScheme(data)).then((result)=>{
        //       if (result?.payload?.errorMessage == "Hold Scheme Updated successfully!!")  {
        //         message.success({
        //           content: "Record Updated Successfully",
        //           className: "custom-message",
        //         });
        //         backBtn("1");
        //       }
        //   });
        //   }else{

        //     dispatch(getUpdateModifiedAwaitingDataDetails(data)).then((result) => {
        //       if (result?.payload?.errorMessage == "Data Saved successfully!!") {
        //         message.success({
        //           content: "Record Updated Successfully",
        //           className: "custom-message",
        //         });
        //         backBtn("1");
        //       }
        //     });
        //   }
      }
    } else {
      let checkValidation = finalData?.filter(
        (x) => !x?.Target || !x?.IPT || !x?.reason
      );
      let errorDetails = Object.entries(
        checkValidation?.[0] ? checkValidation?.[0] : {}
      )
        ?.filter(
          (x, index) =>
            !x[1] && (x[0] === "Target" || x[0] === "IPT" || x[0] === "reason")
        )
        ?.map((x) =>
          x[0] === "Target"
            ? "Target"
            : x[0] === "IPT"
              ? "inc. Per Tractor"
              : x[0] === "reason"
                ? "Reason"
                : ""
        );

      let data = [];
      finalData.forEach((x) => {
        data.push({
          ...x,
          dealerCode: x.dealerCode,
          dealerId: +x.dealerId,
          StartMonth: moment(x.startMonth).format("MMM YY"),
          endMonth: moment(x.endMonth).format("MMM YY"),
          isSAP: true,
          isActive: true,
          state: x.stateId,
          status: 1,
          isSubmitted: 1,
          fyear: currentFiscalYear,
          // schemeTypeId: schemeType,
          userid: +userId,
          username: userName,
          payout: x.payout ? parseInt(x.payout) : 0,
          incPerTarget: parseInt(x.incPerTarget) || 0,
          InsPerTarget: parseInt(x.incPerTarget) || 0,
          p1: +x.p1 ? parseInt(x.p1) : 0,
          P1: +x.p1 ? parseInt(x.p1) : 0,
          reason: x.reason,
          installationTarget: parseInt(x?.installationTarget) || 0,
          InsTarget: parseInt(x?.installationTarget) || 0,
          userid: +userId,
          username: userName,
          userrole: userRole,
          ModificationId: x?.modifiedId,
          HoldReason: x.modifiedReason,
          holdStatus: x?.holdStatus,
          IsModified: selectedLabel == "Hold Schemes" ? true : false
        });
      });
      if (selectedLabel === 'Regular Schemes' || selectedLabel === 'Schemes') {
        data = data.map(d => {
          delete d.HoldReason;
          return d;
        });
      } else if (selectedLabel === "Hold Schemes") {

        data = data.map(d => {
          delete d.reason;
          return d;
        });


      }

      if (Object.keys(errorDetails ? errorDetails : {}).length !== 0) {
        showErrorDialog(errorDetails);
      }
      else {
        // if(data?.[0].holdStatus == 9){
        //   dispatch(postUpdateHoldScheme(data)).then((result)=>{
        //     if (result?.payload?.errorMessage == "Hold Scheme Updated successfully!!")  {
        //       message.success({
        //         content: "Record Updated Successfully",
        //         className: "custom-message",
        //       });
        //       backBtn("1");
        //     }
        // });
        // }else{

        //   dispatch(getUpdateModifiedAwaitingDataDetails(data)).then((result) => {
        //     if (result?.payload?.errorMessage == "Data Saved successfully!!") {
        //       message.success({
        //         content: "Record Updated Successfully",
        //         className: "custom-message",
        //       });
        //       backBtn("1");
        //     }
        //   });
        // }
        setSubmitData(data);
        showModal()
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleRemoveFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };
  const handleSampleFile = (e) => {

    const headerStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
        diagonal: { diagonalUp: true, diagonalDown: true },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
    if (formik?.values?.length == 0) {
      ToastifyShow("Something went wrong", "error");
      return false;
    }
let schemeType=formik?.values?.[0]?.schemeTypeId||3

let tab_text = ModificationInstallationDownload({
      schemeType:schemeType
    })
    {
      schemeType==3?formik?.values?.length != 0 &&
      formik?.values.forEach((header) => {
          tab_text += `<tr ><td>${header?.dealerCode}</td>
  <td>${header?.dealerName}</td>
  <td>${header?.stateName}</td>
  <td>${header?.dealerLocation}</td>
  <td>${header?.territoryManager}</td>
  <td>${header?.dropdownscheme?.includes("t3")?"t3":header?.dropdownscheme?.includes("t2")?"t2":header?.dropdownscheme?.includes("t1")?"t1":""}</td>
  <td>${header.startMonth || ""}</td>
  <td>${header.endMonth || ""}</td>
  <td>${header?.actual_Installation_Last_Year_Month || 0}</td>
  <td>${selectedLabel === 'Hold Schemes' ? header?.holdT1 : (header?.modifiedT1||"")}</td>
  <td>${selectedLabel === 'Hold Schemes' ? header?.holdT2 : (header?.modifiedT2||"")}</td>
  <td>${selectedLabel === 'Hold Schemes' ? header?.holdT3 : (header?.modifiedT3||"")}</td>
  <td>${selectedLabel === 'Hold Schemes' ? header?.holdR1 : (header?.modifiedR1||"")}</td>
  <td>${selectedLabel === 'Hold Schemes' ? header?.holdR2 : (header?.modifiedR2||"")}</td>  
  <td>${selectedLabel === 'Hold Schemes' ? header?.holdR3 : (header?.modifiedR3||"")}</td>  
    <td>${selectedLabel === 'Hold Schemes' ? header?.holdRemarks : header?.modifiedReason}</td>
  <td></td>      
  </tr>`
        }):  schemeType==8? formik?.values?.length != 0 &&
        formik?.values
            ?.forEach((header) => {
              tab_text += `<tr ><td>${header?.dealerCode}</td>
      <td>${header?.dealerName}</td>
      <td>${header?.stateName}</td>
      <td>${header?.dealerLocation}</td>
      <td>${header?.territoryManager}</td>
      <td>${header.startMonth || ""}</td>
      <td>${header.endMonth || ""}</td>
      <td>${header?.actual_Installation_Last_Year_Month || 0}</td>
     <td>${selectedLabel === 'Hold Schemes' ? header?.holdInstallationTarget : header?.modifiedInstallationTarget}</td>
      <td>${selectedLabel === 'Hold Schemes' ? header?.holdIncPerTarget : header?.modifiedIncPerTarget}</td>
      <td>${selectedLabel === 'Hold Schemes' ? header?.holdRemarks : header?.modifiedReason}</td>
      <td></td>
      <td></td>      
      </tr>`;
            }): schemeType==4? formik?.values?.length != 0 &&
            formik?.values
                ?.forEach((header) => {
                  tab_text += `<tr ><td>${header?.dealerCode}</td>
          <td>${header?.dealerName}</td>
          <td>${header?.stateName}</td>
          <td>${header?.dealerLocation}</td>
          <td>${header?.territoryManager}</td>
          <td>${header.startMonth || ""}</td>
          <td>${header.endMonth || ""}</td>
          <td>${header?.actual_Installation_Last_Year_Month || 0}</td>
           <td>${selectedLabel === 'Hold Schemes' ? header?.holdInstallationTarget : header?.modifiedInstallationTarget}</td>
          <td>${selectedLabel === 'Hold Schemes' ? header?.holdUpperCap : header?.modifiedUpperCap}</td>
          <td>${selectedLabel === 'Hold Schemes' ? header?.holdIncPerTarget : header?.modifiedIncPerTarget}</td>
          <td>${selectedLabel === 'Hold Schemes' ? header?.holdRemarks : header?.modifiedReason}</td>
          <td></td>      
          </tr>`;
                }): formik?.values?.length != 0 &&
                formik?.values
                    ?.forEach((header) => {
                      tab_text += `<tr ><td>${header?.dealerCode}</td>
              <td>${header?.dealerName}</td>
              <td>${header?.stateName}</td>
              <td>${header?.dealerLocation}</td>
              <td>${header?.territoryManager}</td>
              <td>${header.startMonth || ""}</td>
              <td>${header.endMonth || ""}</td>
              <td>${header?.actual_Installation_Last_Year_Month || 0}</td>
              <td>${selectedLabel === 'Hold Schemes' ? header?.holdInstallationTarget : header?.modifiedInstallationTarget}</td>
              <td>${selectedLabel === 'Hold Schemes' ? header?.holdIncPerTarget : header?.modifiedIncPerTarget}</td>
              <td>${selectedLabel === 'Hold Schemes' ? header?.holdRemarks : header?.modifiedReason}</td>
              <td></td>      
              </tr>`;
                    });
      }
      tab_text += "</table>";
  
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
  
      // Apply styles to the table using a class
      elt.getElementsByClassName("custom-table")[0].style.width = "100%";
  
      // generate workbook with XLSXStyle
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });

      function xSeparateKeys(obj) {
        return Object.keys(obj).filter(key => 
          schemeType==3? ((key.startsWith('K') && key !== 'K2')|| 
          (key.startsWith('L') && key !== 'L2')|| 
          (key.startsWith('J') && key !== 'J2'&&key!=='J1')|| 
          (key.startsWith('M') && key !== 'M2'&&key!=='M1')|| 
          (key.startsWith('N') && key !== 'N2')|| 
          (key.startsWith('O') && key !== 'O2')|| 
          (key.startsWith('P') && key !== 'P2'&&key!=='P1')|| 
          (key.startsWith('F') && key !== 'F2') 
          ):schemeType==8?((key.startsWith('I') && key !== 'I1')|| 
          (key.startsWith('J') && key !== 'J1')|| 
          (key.startsWith('K') &&key!=='K1')
          ):schemeType==4?((key.startsWith('I') && key !== 'I1')|| 
          (key.startsWith('J') && key !== 'J1')|| 
          (key.startsWith('K') &&key!=='K1')||
          (key.startsWith('L') &&key!=='L1')
          ):((key.startsWith('I') && key !== 'I1')|| 
          (key.startsWith('J') && key !== 'J1')|| 
          (key.startsWith('K') &&key!=='K1')||
          (key.startsWith('L') &&key!=='L1')
          ))
      }

      const xKeys= xSeparateKeys(wb?.Sheets?.Sheet1);


      Object?.keys(wb?.Sheets?.Sheet1 || {})
        ?.filter(
          (x) =>
            x != "!rows" &&
            x != "!cols" &&
            x != "!merges" &&
            x != "!ref" &&
            x != "!fullref" &&
            !xKeys?.includes(x)
        )
        ?.forEach((x) => {
          wb.Sheets[wb.SheetNames[0]][x]["s"] = headerStyle;
        })
  
      // Dynamically set column widths
      wb.Sheets[wb.SheetNames[0]]["!cols"] = [
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
  
      // Dynamically set row heights
      wb.Sheets[wb.SheetNames[0]]["!rows"] = [
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
  
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        { hpx: 20 },
        // Add more row heights as needed
      ];
  
      // remove element
      document.body.removeChild(elt);
  
      // generate file
      XLSX.writeFile(
        wb,
        (schemeType == "3" || schemeType == "")
          ? "slab_based_scheme.xlsx"
          : schemeType == "8"
          ? "Single_target.xlsx"
          : schemeType == "4"
          ? "Single_target_Upper_Cap.xlsx"
          : "Unconditional_installation.xlsx"
      );
  };
  const handleFileUpload=(e)=>{
    const maxFileSize = 5 * 1024 * 1024; // Set maximum file size to 5MB
    const files = e.target.files;

    // Check if any file exceeds the maximum file size limit
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxFileSize) {
        ToastifyShow(`File ${files[i].name} is too large. Maximum file size is 5MB.`, "error");
        return;
      }
  
      // Check file headers for XLSX files
      if (!isValidFileHeader(files[i])) {
        ToastifyShow(`Invalid file header for ${files[i].name}.`, "error");
        return;
      }
  
      // Check content type for XLSX files
      if (!isValidContentType(files[i])) {
        ToastifyShow(`Invalid content type for ${files[i].name}.`, "error");
        return;
      }
  
    }

    if (files[0].name.split('.')?.[1] !== 'xlsx') {
      ToastifyShow("Please upload an XLSX file only.", "error");
      return;
    }
  
    const reader = new FileReader();
    reader.readAsBinaryString(files[0]);
  
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  
    reader.onload = async(e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];  
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      let schemeType=formik?.values?.[0]?.schemeTypeId||3
      let calArr = [];

      const validationFileCheck=Object.values(parsedData[0]||{})

      const isSchemeType3OrEmpty = schemeType == "3" || schemeType == "";
      const hasT1T2T3 = validationFileCheck?.includes("T1") && validationFileCheck?.includes("T2") && validationFileCheck?.includes("T3");
      const hasT1T2 = validationFileCheck?.includes("T1") && validationFileCheck?.includes("T2");
      const hasInstallTargetIncPerTractor = validationFileCheck?.includes("INSTALLATION TARGET") && validationFileCheck?.includes("INC. PER TRACTOR");
      const hasUpperCap = validationFileCheck?.includes("UPPER CAP");
      const hasInstallationExpected = validationFileCheck?.includes("INSTALLATION EXPECTED");
      
      if (
        !( 
          (isSchemeType3OrEmpty && schemeLockedStatus?.t3I && hasT1T2T3) ||
          (isSchemeType3OrEmpty && !schemeLockedStatus?.t3I && hasT1T2) ||
          (schemeType == "8" && hasInstallTargetIncPerTractor) ||
          (schemeType == "4" && hasInstallTargetIncPerTractor && hasUpperCap) ||
          (schemeType == "5" && hasInstallationExpected && hasInstallTargetIncPerTractor)
        )
      ) {
        return ToastifyShow("please upload a valid file", "error");
      }
      
      let dataArr = formik?.values?.map((y, index) => {
        if (
          parsedData.find(
            (x) =>
              x[schemeType == "3" || schemeType == "" ? "-" : "DEALER CODE"] ===
              y?.dealerCode
          )
        ) {
          let z = parsedData.find(
            (x) =>
              x[schemeType == "3" || schemeType == "" ? "-" : "DEALER CODE"] ===
              y?.dealerCode
          );

          if (schemeType == "3" || schemeType == "") {
            let dropdownscheme = z?.["__EMPTY_4"]?.toUpperCase() == "T3"&&y?.topTargetArrState?.t3I ? ["t1", "t2", "t3"] :
              z?.["__EMPTY_4"]?.toUpperCase() == "T2" ? ["t1", "t2"] :
                z?.["__EMPTY_4"]?.toUpperCase() == "T1" ? ["t1"] : []
            let paget1 = dropdownscheme.includes("t1" || "t2" || "t3")
            let paget2 = dropdownscheme.includes("t2" || "t3")
            let paget3 = dropdownscheme.includes("t3")
            calArr.push(index);
            return {
              ...y,
              dropdownscheme: dropdownscheme,
              holdT1: paget1 ? z?.Targets : "",
              holdT2: paget2 ? z?.["__EMPTY_8"] : "",
              holdT3: paget3 ? z?.["__EMPTY_9"] : "",
              holdR1: paget1 ? parseFiveDigitNumber(z?.["INC.PER TRACTOR"]) : "",
              holdR2: paget2 ? parseFiveDigitNumber(z?.["__EMPTY_10"]) : "",
              holdR3: paget3 ? parseFiveDigitNumber(z?.["__EMPTY_11"]) : "",
              holdP1: (paget1) ? (parseInt(z?.Targets || 0) * parseInt(z?.["INC.PER TRACTOR"] || 0)) : 0,
              holdP2: (paget2) ? (parseInt(z?.__EMPTY_8 || 0) * parseInt(z?.["__EMPTY_10"] || 0)) : 0,
              holdP3: (paget3) ? (parseInt(z?.__EMPTY_9 || 0) * parseInt(z?.__EMPTY_11 || 0)) : 0,
              [`modifiedReason`]:z?.["-_1"] || "",
              [`holdRemarks`]:z?.["-_1"] || "",
              
              modifiedT1: paget1 ? z?.Targets : "",
              modifiedT2: paget2 ? z?.["__EMPTY_8"] : "",
              modifiedT3: paget3 ? z?.["__EMPTY_9"] : "",
              modifiedR1: paget1 ? parseFiveDigitNumber(z?.["INC.PER TRACTOR"]) : "",
              modifiedR2: paget2 ? parseFiveDigitNumber(z?.["__EMPTY_10"]) : "",
              modifiedR3: paget3 ? parseFiveDigitNumber(z?.["__EMPTY_11"]) : "",
              modifiedP1: (paget1) ? (parseInt(z?.Targets || 0) * parseInt(z?.["INC.PER TRACTOR"] || 0)) : 0,
              modifiedP2: (paget2) ? (parseInt(z?.__EMPTY_8 || 0) * parseInt(z?.["__EMPTY_10"] || 0)) : 0,
              modifiedP3: (paget3) ? (parseInt(z?.__EMPTY_9 || 0) * parseInt(z?.__EMPTY_11 || 0)) : 0,
            };
          } else if (schemeType == "8") {
            return {
              ...y,
              holdInstallationTarget: z?.["INSTALLATION TARGET"] || "",
              holdIncPerTarget: z?.["INC. PER TRACTOR"] || "",
              [`modifiedReason`]:z?.["REMARKS"] || "",
              [`holdRemarks`]:z?.["REMARKS"] || "",
              modifiedIncPerTarget:z?.["INC. PER TRACTOR"] || "",
              modifiedInstallationTarget: z?.["INSTALLATION TARGET"] || "",
            };
          } else if (schemeType == "4") {
            return {
              ...y,
              holdInstallationTarget: z?.["INSTALLATION TARGET"] || "",
              holdIncPerTarget: z?.["INC. PER TRACTOR"] || "",
              holdUpperCap: z?.["UPPER CAP"] || "",
              [`modifiedReason`]:z?.["REMARKS"] || "",
              modifiedUpperCap: z?.["UPPER CAP"] || "",
              modifiedIncPerTarget: z?.["INC. PER TRACTOR"] || "",
              [`holdRemarks`]:z?.["REMARKS"] || "",
              modifiedInstallationTarget: z?.["INSTALLATION TARGET"] || "",
            };
          } else {
            return {
              ...y,
              modifiedInstallationTarget: z?.["INSTALLATION TARGET"] || "",
              modifiedIncPerTarget: z?.["INC. PER TRACTOR"] || "",
              [`modifiedReason`]:z?.["REMARKS"] || "",
              holdInstallationTarget: z?.["INSTALLATION TARGET"] || "",
              holdIncPerTargett: z?.["INC. PER TRACTOR"] || "",
              [`holdRemarks`]:z?.["REMARKS"] || "",
            };
          }
        } else {
          return y;
        }
      });
      await setCheckCalculate(true);
      await setCheckCalculateArr(calArr);
      await formik?.setValues(dataArr);
      ToastifyShow("File uploaded successfully", "success");

    };
  }
  useEffect(() => {
    if (checkCalculate) {
      checkCalculateArr?.forEach((x) => calculateFormula(x));
      setCheckCalculate(false);
    }
  }, [checkCalculate]);
  const menu = (
    <Menu>
      <Menu.Item>
        {selectedFiles?.length == 0 && (
          <div className="custom-file-input">
            <input type="file" accept=".xlsx" onChange={handleFileUpload} />
            <label htmlFor="fileInput">Choose File</label>
          </div>
        )}
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>
              {file.name}
              <Button onClick={() => handleRemoveFile(index)} className="mx-1">
                Remove
              </Button>
            </li>
          ))}
        </ul>
      </Menu.Item>
      <Menu.Item onClick={handleSampleFile}>Download Sample File</Menu.Item>
    </Menu>
  );
  return (
    <div className="create_scheme_wrap general-bscheme">
      <Button
        type="primary"
        icon={<ArrowBackRounded />}
        size="middle"
        onClick={() => {
          backBtn(false);
        }}
      />
        <Space
              style={{
                padding: "0",
                paddingTop: "14px",
              }}
              direction="vertical"
            >
              <Tooltip title={"Import/Export to Excel"}>
              <Dropdown overlay={menu} placement="bottomLeft">
                <Button className="h-2">Import/Export to Excel</Button>
              </Dropdown>
              </Tooltip> 
            </Space>
      {/* <div className="table_scheme_wrap generate-finace-report"> */}
      {/* <Form> */}
      <Table
        // className="finance"
        loading={loadingState == "pending"||loadingModificationAwaitingState=="pending"||loadingSaveHoldState=="pending" ? true : false}
        columns={defaultColumns}
        dataSource={formik?.values}
        bordered
        pagination={false}
        scroll={{
          x: 1000,
          y: 300,
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, selectedRow) => {
            console.log(newSelectedRowKeys,"newSelectedRowKeys",selectedRow)
            setSelectedRowKeys(newSelectedRowKeys);
          },
          getCheckboxProps: (record) => {
            return {
              dealerId: record.dealerId,
            };
          },
        }}
        size="middle"
      />
      {/* </Form> */}
      <div className="pt-3 d-flex f-reverse">
        <Button
          type="default"
          shape="round"
          style={{
            margin: "10px",
            background: "#FCE838",
            fontWeight: "700",
            textTransform: "uppercase",
            border: "none",
            padding: "20px",
          }}
          size="large"
          disabled={loadingModificationAwaitingState=="pending"||loadingSaveHoldState=="pending"?true:false}
          onClick={handleSubmit}
        >
          Update & Submit
        </Button>
      </div>
      {/* </div> */}

      <ValidationErrorsModal
        open={openErrorDialog.open}
        onClose={() => {
          setOpenErrorDialog({ open: false, errorsFields: [] });
        }}
        errorsFields={openErrorDialog?.errorsFields}
      />
      <SMDialog open={openErrorModal} onCancel={handleErrorModal}>
        <p className="custom-warning">Warning !</p>
        <p>
          {errorItem.firstItem} should be greater then {errorItem.secondItem}
        </p>
      </SMDialog>
      <Modal open={isModalOpen} onOk={() => {

        handleOk()
      }}         maskClosable={false}
      destroyOnClose={true} onCancel={handleCancel}>
        <h2>Are you sure you want to submit!</h2>
      </Modal>
    </div>
  );
};
export default EditModifiedAwaitingInstallation;
