import React, { useState } from "react";
import ApprovalSchemesTab from "./ApprovalSchemesTab";
import { Divider } from "antd";
import "./approvalSchemes.css";
import AllSchemeHeader from "../allSchemes/AllSchemeHeader";
import dayjs from "dayjs";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";

const currentFiscalYear = getCurrentFiscalYear();


const initPayloadData = {
  fyear: currentFiscalYear,
  schemeType: 1,
  // state: 1,
  // userid: 0,
  month: dayjs().month() + 1,
  monthEnd: dayjs().month() + 1,
};

const CollectionApprovalSchemes = () => {
  const [payload, setPayload] = useState(initPayloadData);
  const [startMonth, setStartMonth] = useState(dayjs());
  return (
    <>
      <div className="create_scheme_wrap collection-schme-box">
        <Divider />

        <div className="approval_heading">Scheme Progress</div>
        <AllSchemeHeader
          payload={payload}
          startMonth={startMonth} 
          setStartMonth={setStartMonth}
          onChangeFilter={(key, val) => setPayload({ ...payload, [key]: val })}
          showEnforcedMonth={true}
        />
        <ApprovalSchemesTab payload={payload} />
      </div>
    </>
  );
};

export default CollectionApprovalSchemes;
