import { MoreOutlined } from "@ant-design/icons";
import {
  Collapse,
  Space,
  Pagination,
  Dropdown,
  message,
  Button,
  Input,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import InstallationAllScheme from "./InstallationTable";
import { useEffect, useRef, useState } from "react";
import Status from "../../../components/allSchemeStatus/allSchemeStatus";
import ActionButton from "../../../components/actionButton/actionButton";
import SingleTarget from "../../../pages/installation/allSchemes/schemeTables/SingleTargetScheme";
import SingleTargetWithUpperCap from "../../../pages/installation/allSchemes/schemeTables/SingleTargetWithUpperCapScheme";
import UnconditionalInstallation from "../../../pages/installation/allSchemes/schemeTables/UnconditionalInstallationScheme";
import SlabBased from "../../../pages/installation/allSchemes/schemeTables/SlabBasedScheme";
import InstallationFilter from "../../../components/installation/installationFilter";
import axiosAPI from "../../../services/axiosApi";
import { getCurrentFiscalYear } from "../../../utils/dateFormatter";
const { Panel } = Collapse;
// const collapseProps = {
//   items,
//   trigger:['click']

// };

const currentFiscalYear = getCurrentFiscalYear();
const InstallationCollapseTab = ({ payload, startMonth, setStartMonth,dealerDetails,dateValue }) => {
  const [dropdownItems, setDropdownItems] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedSchemeType, setSelectedSchemeType] = useState(3);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const navigate = useNavigate();
  const childref = useRef();

  useEffect(() => {
    const items = [
      {
        label: (
          <div
            onClick={() => {
              if (selectedRowKeys?.length == 0) {
                message.error({
                  content: "Please select from the checkbox",
                  className: "error",
                });
              } else {
                navigate("/installation/editScheme");
              }
            }}
          >
            Edit
          </div>
          // <Link className="link" to='/installation/editScheme'>Edit</Link>
        ),
        key: "1",
      },
    ];
    setDropdownItems(items);
  }, []);

  const fetchGeneratedScheme = (demoParams) => {
    setSelectedSchemeType(demoParams.schemeType);
  };
  useEffect(() => {
    if (selectedSchemeType) {
      setSelectedRowKeys([]);
    }
  }, [selectedSchemeType]);
  const defaultActiveKey = ["1", "2"];
  const genExtra = () => (
    <a onClick={(e) => e.preventDefault()}>
      <Dropdown
        menu={{
          items: dropdownItems,
          trigger: ["click"],
        }}
      >
        <MoreOutlined
          className="more_option"
          style={{ height: "20px", height: "20px" }}
        />
      </Dropdown>
    </a>
  );
  const getSchemeType = (schemeType) => {
    if (schemeType == 3) {
      return "Installation - Slab Based";
    }
    if (schemeType == 8) {
      return "Installation - Single Target";
    }
    if (schemeType == 4) {
      return "Installation - Single Target With Upper Cap";
    }
    if (schemeType == 5) {
      return "Installation - Unconditional Installation";
    }
  };

  return (
    <>
      <Space
        className="collapse-items instalttion-collapse-items collapse-box-cell-table"
        direction="vertical"
        size="large"
        style={{
          display: "flex",
        }}
      >
        <Collapse
          className="all_scheme_collapse"
          defaultActiveKey={defaultActiveKey}
        >
          <Panel
            className="panel_heading"
            size="large"
            header="Incentive Installation "
            key="1"
          >
            <h2 style={{ fontSize: "18px", padding: "20px" }}>
              {getSchemeType(selectedSchemeType)}
            </h2>
            <div className="allSchmeStatus d-flex justify-content-between">
              <div>
                <Status
                  selectedStatus={selectedStatus}
                  handleStatusDropdown={(statusId) =>
                    setSelectedStatus(statusId)
                  }
                />
              </div>
              <div className="d-flex">
                <InstallationFilter onGetData={fetchGeneratedScheme} />

                <Button
                  className="mx-1"
                  onClick={() => childref.current.click()}
                >
                  Download
                </Button>
              </div>
            </div>
            {selectedSchemeType == 3 && (
              <SlabBased
                setSelectedRowKeys={setSelectedRowKeys}
                dateValue={dateValue}
                selectedRowKeys={selectedRowKeys}
                payload={payload}
                selectedSchemeType={selectedSchemeType}
                selectedStatus={selectedStatus}
                reference={childref}
                startMonth={startMonth}
                dealerDetails={dealerDetails}
              />
            )}
            {selectedSchemeType == 8 && (
              <SingleTarget
                setSelectedRowKeys={setSelectedRowKeys}
                dateValue={dateValue}
                selectedRowKeys={selectedRowKeys}
                payload={payload}
                selectedSchemeType={selectedSchemeType}
                selectedStatus={selectedStatus}
                reference={childref}
                startMonth={startMonth}
                dealerDetails={dealerDetails}
              />
            )}
            {selectedSchemeType == 4 && (
              <SingleTargetWithUpperCap
                setSelectedRowKeys={setSelectedRowKeys}
                dateValue={dateValue}
                selectedRowKeys={selectedRowKeys}
                payload={payload}
                selectedSchemeType={selectedSchemeType}
                selectedStatus={selectedStatus}
                reference={childref}
                startMonth={startMonth}
                dealerDetails={dealerDetails}
              />
            )}
            {selectedSchemeType == 5 && (
              <UnconditionalInstallation
                setSelectedRowKeys={setSelectedRowKeys}
                dateValue={dateValue}
                selectedRowKeys={selectedRowKeys}
                payload={payload}
                selectedSchemeType={selectedSchemeType}
                selectedStatus={selectedStatus}
                reference={childref}
                startMonth={startMonth}
                dealerDetails={dealerDetails}
              />
            )}
            {/* <InstallationAllScheme selectedStatus={selectedStatus} payload={payload}/> */}
          </Panel>
        </Collapse>
      </Space>
    </>
  );
};
export default InstallationCollapseTab;
