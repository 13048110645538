import React  from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import {
  Dashboard,
  CollectionAllSchemes,
  CollectionCreateNewScheme,
  CollectionEditScheme,
  CollectionDraftSchemes,
  CollectionDraftEditScheme,
  CollectionApprovalEditScheme,
  SchemeStatus,
  InstallationAllSchemes,
  InstallationCreateNewScheme,
  InstallationEdit,
  InstallationDraftEditScheme,
  InstallationDraftSchemes,
  SignInSide as Login,
  RequestDeviation,
  Unauthorized,
  TrackCollection,
  ModificationCollection,
  Merge
} from "./pages";
import Template from "./components/template";
import { Navigate } from "react-router-dom";
import GenerateFinanceReport from "./pages/dashboard/pmisDashboard/GenerateFinanceReport";
import CollectionApprovalLogSchemes from "./pages/collection/awaitingApprovalLog";
import AuthenticatedRoute from "./components/authenticatedRoute";
import UnauthenticatedRoute from "./components/unauthenticateRoute";
import TrackInstallation from "./pages/collection/track/Installation/TrackInstallation";
import ModificationInstallation from "./pages/collection/modificationApproval/ModificationInstallation";
import { ModificationAwaitingApproval} from "./pages/collection";
import AwaitingLogsModification from "./pages/collection/modificationApproval/awaitingLogsModification";
import TrackModification from "./pages/collection/modificationApproval/trackModification";
import PageNotFound from "./components/error/PageNotFound";
import EditModificationAwaiting from "./pages/collection/modificationApproval/EditSchemes/EditModificationAwaiting";
import AwaitingApprovalList from "./pages/collection/awatingApproval/AwaitingApprovalList";
import Withdrawal from "./pages/pmis/withdrawal/withdrawal";
import SchemeHold from "./pages/hold/SchemeHold";



const App=()=> {


  return (
    <>
     <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
        <Route exact path="/login"  element={<UnauthenticatedRoute><Login /></UnauthenticatedRoute>}/>
        <Route exact path="unauthorized" element={<Unauthorized /> } />
        <Route exact path="/" element={<AuthenticatedRoute><Template /></AuthenticatedRoute>}>
          <Route exact path="dashboard" element={<Dashboard />} />
          <Route exact path="merge" element={< Merge/>} />
          <Route exact path="collection/allSchemes" element={<CollectionAllSchemes />} />
          <Route exact path="collection/createNewScheme" element={<CollectionCreateNewScheme />} />
          <Route exact path="collection/editScheme" element={<CollectionEditScheme />} />
                   <Route exact path="collection/draftSchemes" element={<CollectionDraftSchemes />} />
          <Route exact path="collection/draftSchemes/edit" element={<CollectionDraftEditScheme />} />
          <Route exact path="collection/allSchemes/edit" element={<CollectionDraftEditScheme />} />
          <Route exact path="schemeProgress/edit" element={<CollectionApprovalEditScheme />} />
          <Route exact path="collection/schemeStatus" element={<SchemeStatus />} />
           <Route exact path="installation/allSchemes"  element={<InstallationAllSchemes />}/>
          <Route exact path="installation/allSchemes/edit"  element={<InstallationDraftEditScheme />}/>
          <Route exact path="installation/createNewScheme"  element={<InstallationCreateNewScheme />}/>
          <Route exact path="installation/editScheme"  element={<InstallationEdit />}/>
          <Route exact path="installation/draftSchemes"  element={<InstallationDraftSchemes />}/>
          <Route exact path="installation/draftSchemes/edit" element={<InstallationDraftEditScheme />} />
          
          <Route exact path="trackinstallation" element={<TrackInstallation />} />
          <Route exact path="awatingLogsModification" element={<AwaitingLogsModification />} />
          <Route exact path="generate-finance" element={<GenerateFinanceReport /> } />
          
          <Route exact path="schemeProgress" element={<AwaitingApprovalList />} />
          <Route exact path ="approvedschemeslog" element ={<CollectionApprovalLogSchemes/>}/>
          <Route exact path="requestDeviation" element={<RequestDeviation />} />
          <Route exact path="requestDeviationLog" element={<RequestDeviation />} />
          <Route exact path="track" element={<TrackCollection />} />
          <Route exact path="trackModification" element={<TrackModification />} />
          <Route exact path="modificationCollection" element={<ModificationCollection />} />
          <Route exact path="installationModification" element={<ModificationInstallation />} /> 
          <Route exact path="modificationAwatingApproval" element={<ModificationAwaitingApproval />} />
          <Route exact path="editApprovalModification" element={<EditModificationAwaiting /> } />
          <Route exact path="holdApproval" element={<SchemeHold /> } />
          <Route exact path="WithdrawalScheme" element={<Withdrawal /> } />
          
          <Route path="/*" element={<PageNotFound />} />
        </Route>
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
