import React, { useState, useEffect } from "react";
import { Divider } from "antd";
import CollapseTab from "../allSchemes/collapseTab";
import AllSchemeHeader from "./AllSchemeHeader";
import "./allScheme.css";
import dayjs from "dayjs";
import { getFinancialYearStartYear } from "../../../utils/dateFormatter";
import TableColorDetails from "../../../components/table/TableColorDetails";

const initPayloadData = {
  schemeType: 1,
  // state: 1,
  // userid: 0,
  month: new Date().getMonth() + 1,
  monthEnd: new Date().getMonth() + 1,
  enforcedMonth: 0,
};

const CollectionAllSchemes = () => {
  const [payload, setPayload] = useState(initPayloadData);
  const [startMonth, setStartMonth] = useState(dayjs());

  const [dealerDetails, setDealerDetails] = useState({
    code: "",
    location: "",
  });
const fyYear=getFinancialYearStartYear()

  const [dateValue, setDateValue] = useState(fyYear);
  useEffect(() => {
    setDateValue(fyYear);
  }, [fyYear]);
  console.log(dateValue, "dateValuedateValue");
  return (
    <>
      <div className="create_scheme_wrap collection-schme-box creating-schema-box">
        <Divider />

        <div className="d-flex align-items-center flex-end pb-3">
          <AllSchemeHeader
            onChangeFilter={(key, val) =>
              setPayload({ ...payload, [key]: val })
            }
            dateValue={dateValue}
            setDateValue={setDateValue}
            startMonth={startMonth}
            setStartMonth={setStartMonth}
            payload={payload}
            showEnforcedMonth={true}
            setDealerDetails={setDealerDetails}
            dealerDetails={dealerDetails}
            showFilter={true}
          />
        </div>

        <CollapseTab
          payload={payload}
          dateValue={dateValue}
          startMonth={startMonth}
          dealerDetails={dealerDetails}
        />
      </div>
      <TableColorDetails />
    </>
  );
};
export default CollectionAllSchemes;
