import React, {  useState } from "react";
import CommonCard from "../../../components/commonCard/CommonCard";
import CustomTable from "../../../components/table/Table";
import FinancialYearButton from "../../../components/financialYearButton/FinancialYearButton";
import SubmittedCard from "../../../assets/images/schemeSubmitted.png";
import ApprovedCard from '../../../assets/images/schemeApproved.png';
import PendingCard from '../../../assets/images/schemePending.png';
import RejectedCard from '../../../assets/images/rejected.png';
import dayjs from "dayjs";
import { useEffect } from "react"; 
import { Select } from "antd";
import { Option } from "antd/es/mentions";
import ZonalHeadTable from "../zonalHead/ZonalTable";


const AreaManager = ({SetTotalCountShow,totalCountShow}) => {
  const [mngrdata, setMngrdata] = useState({
    totalCount:0,
    totalSchemeSubmitted:0,
    totalcount:0,
    pendingCount: 0,
    approvedCount: 0,
    submittedCount: 0,
    rejectedCount:0,
    autoRejectCount:0
  });
  const [dashboardType, setDashboardType] = useState();
  const [toggleDashboard, setToggleDashboard] = useState(false);
  const [open, setOpen] = useState(false);
  const [typeOfScheme, setTypeOfScheme] = useState(1);
  const [dashboardTypeShow, setDashboardTypeShow] = useState(false);
  const currentDate = dayjs();
  const items = [
    {
      label: "State wise Dashboard",
      value: "0",
    },
    {
      label: "Dealer wise Dashboard",
      value: "1",
    },
  ];
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  
  // Determine the start month of the financial year (April)
  const financialYearStartMonth = 3; // April (0-indexed)
  
  // If the current month is before April, the financial year starts from the previous year
  const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
  
  // The financial year will be the start year + 1
  const financialYear = financialYearStartYear + 1;
  
    const [dateValue, setDateValue] = useState(financialYear);
  
    useEffect(() => {
      setDateValue(financialYear);
    }, [financialYear]);
  
  const handleTypeOfScheme = (data) => {
    setTypeOfScheme(data);
  };

  const handleSelectDropdown = (value, option) => {
    setDashboardType(value);
  };


  
  return (
    <>
      <div className="amgr_year d-flex">
      <FinancialYearButton       handleDate={(x)=>setDateValue(x)} />
      <Select
              // className="select_state stateWise"
                       className="state-wise-select"
              placeholder={
                dashboardType ? dashboardType : "State wise Dashboard"
              }
              onSelect={handleSelectDropdown}
              trigger={["click"]}
              open={open}
              onClick={() => {
                setOpen(!open);
              }}
              size="large"
              defaultValue={
                !toggleDashboard
                  ? { label: "State wise Dashboard", value: "0" }
                  : {
                      label: "Dealer wise Dashboard",
                      value: "1",
                    }
              }
              onChange={(event) =>
                event == "State wise Dashboard"
                  ? setDashboardTypeShow(false)
                  : setDashboardTypeShow(true)
              }
            >
              {items?.map((option) => (
                <Option key={option.value} value={option.label}>
                  {option.label}
                </Option>
              ))}
            </Select>
      </div>
      
      {dashboardTypeShow ? (
        <>
          <ZonalHeadTable
            onGetData={() => {}}
            onSetCardData={(data) => {
              setMngrdata(data);
            }}
            SetTotalCountShow={(data) => SetTotalCountShow(data)}
            // selectedStateId={selectedStateId}
            dashboardType={
              dashboardTypeShow
                ? "Dealer wise Dashboard"
                : "State wise Dashboard"
            }
            dateValue={dateValue}
            // dateValue={dateValue}
            setDateValue={setDateValue}
            sendDataToParent={handleTypeOfScheme}
            fyear={dateValue}
          />
        </>
      ) : (
      <>
      <div className="cards_container">
        <CommonCard
         submittedNumber={totalCountShow?mngrdata.submittedCount:!totalCountShow?mngrdata.totalSubmittedCount: 0}
          // submittedNumber={mngrdata.submittedCount || 0}
          schemeState="Schemes Submitted"
          colorCode="black"
          modalTitle="Schemes Submitted"
          backgroundImage= {`url(${SubmittedCard})`}
          backgroundSize = 'cover'
          typeOfScheme={typeOfScheme}
        />
        <CommonCard
          submittedNumber={totalCountShow?mngrdata.approvedCount:!totalCountShow?mngrdata.totalApprovedCount :0}
          // submittedNumber={mngrdata.approvedCount || 0}
          schemeState="Schemes Approved"
          colorCode="white"
          modalTitle="Schemes Approved"
          backgroundImage= {`url(${ApprovedCard})`}
          backgroundSize = 'cover'
          typeOfScheme={typeOfScheme}
        />
        <CommonCard
          submittedNumber={totalCountShow?mngrdata.pendingCount:!totalCountShow?mngrdata.totalPendingCount :0}
          // submittedNumber={mngrdata.pendingCount || 0}
          schemeState="Schemes Pending"
          colorCode="black"
          modalTitle="Schemes Pending"
          backgroundImage= {`url(${PendingCard})`}
          backgroundSize = 'cover'
          typeOfScheme={typeOfScheme}
        />
          <CommonCard
           submittedNumber={totalCountShow?mngrdata.rejectedCount:!totalCountShow?mngrdata.totalRejectedCount :0 }
          schemeState="Schemes Rejected"
          colorCode="black"
          modalTitle="Schemes Rejected"
          backgroundImage= {`url(${RejectedCard})`}
          backgroundSize = 'cover'
          typeOfScheme={typeOfScheme}
        />
        </div>
      <div>
  
        <CustomTable onSetCardData={data => {
          setMngrdata(data)}} SetTotalCountShow={(data)=>SetTotalCountShow(data)} sendDataToParent={handleTypeOfScheme} 
          fyear={dateValue}
          />
        
      </div>

   </>)}
    </>
  );
};
export default AreaManager;
