import { Pagination, Table } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { STATE_WISE_DASHBOARD, STATE_WISE_DASHBOARD_LABEL } from "../../config";
import useColumns from "./columns";
import axiosAPI from "../../services/axiosApi";
import {
  getCurrentFiscalYear,
  getCurrentFiscalYear1,
} from "../../utils/dateFormatter";
import { getStateWiseDataApi } from "../../redux/stateData/stateDataSlice";
import { useLocation } from "react-router-dom";
import TableColorDetails from "./TableColorDetails";
import { decryptData } from "../../utils/localStorageEncodeDecode";
import FullScreenLoader from "../loader/FullScreenLoader";
import { TextLocalString } from "../ToastifyShow";
import { getNotificationSadm } from "../../redux/notification/notificationSlice";

const InitCollectionData = {
  currentYear: "2023",
  pageSize: "150",
  pageNumber: 1,
  sortingOrder: "ascend",
  sortingType: "DealerCode",
};

const CollectionTable = ({
  selectedSchemeType,
  onSetCardData,
  selectedStateId,
  selectedStatus,
  dashboardType,
  monthlyFilters,
  setDataSource,
  dataSource,
  yearShow,
  startMonth,
  fyear,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [toggleCheck, setToggleCheck] = useState(false);
  const currPath = location.pathname;
  const isTrack = currPath == "/track";
  const { userRole, userId } = sessionStorage.getItem("userInfo")
    ? decryptData(sessionStorage.getItem("userInfo"))
    : {};

  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const columns = useColumns(dashboardType, userRole, "dashboard");
  const { stateWiseData, loadingState,loadingStateLoader } = useSelector(
    (state) => state.stateData
  );

  // const listData = useSelector((state)=> state.generatedScheme.data)

  const [mngrdata, setMngrdata] = useState([]);
  const listAreaMngrData = useSelector(
    (state) => state.areaMngrSchemeList.data
  );
  const { loadingScheme, schemeApprove } = useSelector(
    (state) => state.schemagenerate
    
  );
  
  const currentYear = dayjs().year() - 1;


  useEffect(() => {
    if (schemeApprove?.data?.result?.data?.length != 0) {
      setMngrdata(schemeApprove?.data?.result?.data);
    }
  }, [schemeApprove]);

  const handleChange = (pagination, filters, sorter) => {
    setCollectionData({
      ...collectionData,
      sortingOrder: sorter?.order,
      sortingType: sorter?.columnKey,
    });
  };
  const fetchGeneratedScheme = async (demoParams) => {
    setIsLoading(true);
    // if (userRole == "ZADM") {
    //   let apiData = {
    //     fYear: currentFiscalYear,
    //     stateId: selectedStateId,
    //     year: currentYear,
    //     schemeType: selectedSchemeType,
    //     userid: userId,
    //     userRole: userData?.userRole,
    //     PageNumber: collectionData.pageNumber,
    //     PageSize: collectionData.pageSize
    //   };
    //   dispatch(schemeListApproveApi(apiData));
    //   return;
    // }

    const url = isTrack
      ? "Controller/getCollectionDealersList"
      : "Dashboard/DashboardDetail";
    await axiosAPI
      .post(url, {
        ...demoParams,
        PageNumber: parseInt(collectionData?.pageNumber || 1),
        PageSize: parseInt(collectionData?.pageSize || 150),
      })
      .then((x) => {
        setMngrdata(x?.data?.data || {});
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (stateWiseData?.lisitems?.length != 0) {
      setMngrdata(stateWiseData?.lisitems);
    } else {
      setMngrdata([]);
    }
  }, [stateWiseData]);

  const getApiData = () => {
    const currentFiscalYear = getCurrentFiscalYear1(dayjs(startMonth).format());
    if (toggleCheck) {
      let lastTwoDigits = fyear % 100;
      const demoParams = {
        year:
          typeof monthlyFilters?.year == "string" && monthlyFilters?.year
            ? monthlyFilters?.year
            : yearShow
            ? yearShow
            : dayjs().year(),
        fyear: "FY" + lastTwoDigits,
        schemeType: selectedSchemeType,
        userid: userId,
        state: selectedStateId,
        status: selectedStatus || 0,
        userRole: userRole,
        enforcedMonth: monthlyFilters?.enforcedMonth,
        month: monthlyFilters?.month,
        endMonth: monthlyFilters?.monthEnd,
        PageNumber: collectionData.pageNumber,
        PageSize: collectionData.pageSize,
        sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
        sortingType: collectionData.sortingType || "DealerCode",
      };

      if (selectedStatus === "") {
        delete demoParams.status;
      } if (dashboardType === STATE_WISE_DASHBOARD_LABEL) {
          const fetchStateWiseReport = async (demoParams) => {
            let lastTwoDigits = fyear % 100;
            demoParams = {
              ...demoParams,
              fYear: "FY" + lastTwoDigits,
              stateId: selectedStateId,
              year:
                typeof monthlyFilters?.year == "string"
                  ? monthlyFilters?.year
                  : yearShow - 1,
              enforcedMonth: monthlyFilters?.enforcedMonth,
              month: monthlyFilters?.month || 0,
              endMonth: monthlyFilters?.monthEnd || 0,
              userid: userId,
              userRole: userRole,
              PageNumber: collectionData.pageNumber,
              PageSize: collectionData.pageSize,
              sortingOrder:
                collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
              sortingType: collectionData.sortingType || "DealerCode",
            };
            delete demoParams.fyear;
            delete demoParams.state;
            await dispatch(getStateWiseDataApi(demoParams));
          };
          //schemeListApprove

          fetchStateWiseReport(demoParams);
        } else {
          fetchGeneratedScheme(demoParams);
        }
    } else {
      setToggleCheck(true);
    }
  };



  const getnotificationApi = async()=>{
    let lastTwoDigits = fyear % 100;
    const demoParams = {
      fYear: "FY" + lastTwoDigits,
      userid: userId,
      userRole: userRole,
    };
await dispatch(getNotificationSadm(demoParams));
    

  }
  useEffect(() => {
    
    getApiData();
    getnotificationApi();
  }, [
    selectedSchemeType,
    selectedStateId,
    selectedStatus,
    dashboardType,
    monthlyFilters,
    collectionData,
    startMonth,
    toggleCheck,
    fyear,
  ]);


  useEffect(() => {
    const data = [];
    let schemeListData =
      userRole == "ZADM"&&mngrdata?.schemelist
        ? mngrdata?.schemelist:
        userRole == "ZADM"&&mngrdata?.lisitems
        ? mngrdata?.schemelist
        : dashboardType === STATE_WISE_DASHBOARD_LABEL
        ? mngrdata
        : mngrdata?.schemelist;
    if (isTrack) {
      schemeListData = mngrdata?.collectionList;
    }

    onSetCardData({
      pendingCount: mngrdata?.pendingCount || 0,
      approvedCount: mngrdata?.approvedCount || 0,
      submittedCount: mngrdata?.submittedCount || 0,
      rejectedCount: mngrdata?.rejectedCount || 0,
      totalApprovedCount: mngrdata?.totalApprovedCount || 0,
      totalPendingCount: mngrdata?.totalPendingCount || 0,
      totalRejectedCount: mngrdata?.totalRejectedCount || 0,
      totalSubmittedCount: mngrdata?.totalSubmittedCount || 0,
      rejectedCount: mngrdata?.rejectedCount || 0,
      autoRejectCount: mngrdata?.totalAutoRejectCount || 0,
    });

    if (schemeListData?.length == 0 || !schemeListData) {
      setIsLoading(false);
      setDataSource([]);
      // userRole != "ZADM" &&
      // onSetCardData({
      //   pendingCount: 0,
      //   approvedCount: 0,
      //   submittedCount: 0,
      //   totalApprovedCount:0,
      //   totalPendingCount:0,
      //   totalSubmittedCount:0
      // });
      return;
    }
    Array?.isArray(schemeListData) &&
      schemeListData
        ?.filter((item) => item.schemeNo != null)
        .map((item, index) => {
          for (let i = 0; i < 1; i++) {
            let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
            let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
            data.push({
              status: item.status,
              key: item.schemeId,
              scheme: item,
              code: item.dealerCode,
              name: item.dealerName,
              Location: item.dealerLocation,
              state: item.stateName,
              Manager: item.territoryManager,
              TopTarget: item.TopTarget,
              StartMonth:
                formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
              EndMonth:
                formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
              industry: item.industry,
              oldBillingQty: item.oldBillingQty ? item.oldBillingQty : 0,
              oldCollection: Math.round(item.oldCollection),
              oldInstallation: item.oldInstallation ? item.oldInstallation : 0,
              b: item.billing,
              i: item.installation,
              c: Math.round(item.collection),
              lastThirdMonth: item.closingOutStandingMonth_3,
              lastTwoMonth: item.closingOutStandingMonth_2,
              lastOneMonth: item.closingOutStandingMonth_1,
              target: "",
              delayPlan: item.delayPlan,
              bgLacsCurrentYear:
                item?.bGLacsCurrentYear || item.bgLacsCurrentYear || 0,
              outStandingDays: item.outStandingDays,
              openingStockDays: item.openingStockDays,
              oldOpeningStock: item.oldOpeningStock,
              BooksCollectionTargetT1: item.t1,
              BooksCollectionTargetT2: item.t2,
              BooksCollectionTargetT2T1: item.t2_T1,
              BooksCollectionTargetT3: item.t3,
              BooksCollectionTargetT3T2: item.t3_T2,
              BooksCollectionTargetT4: item.t4,
              BooksCollectionTargetT4T3: item.t4_T3,
              BooksCollectionTargetT5: item.t5,
              BooksCollectionTargetT5T4: item.t5_T4,
              BooksCollectionTargetT6: item.t6,
              BooksCollectionTargetT6T5: item.t6_T5,
              PayoutRatep1: item.r1,
              PayoutRatep2: item.r2,
              PayoutRatep3: item.r3,
              PayoutRatep4: item.r4,
              PayoutRatep5: item.r5,
              PayoutRatep6: item.r6,
              payoutR1: item.p1,
              payoutR2: item.p2,
              payoutR3: item.p3,
              payoutR4: item.p4,
              payoutR5: item.p5,
              payoutR6: item.p6,
              reason: item.reason,
              holdReason:item.holdReason,
              TEPvol: item.volume,
              TEPpertr: item.perTr,
              TEPamount: item.amount,
              actualBillingAmount: item.actualBillingAmount,
              actualBillingQuantity: item.actualBillingOty,
              billingFactor: item.billingFactor,
              actualCollection: item.actualCollection,
              collectionQuantity: item.collectionQuantity,
              achievedSlab: item?.achievedSlab || "No Slab",
              payout: item?.payout,
              payout2: item?.payout2,
              payout3: item?.payout3,
              payout4: item?.payout4,
              closingStock: item?.closingStock,
              closingOutStanding: item?.closingOutStanding,
              holdStatus: item?.holdStatus,
              // LMSA: 'T2',
              // LCQ: 55,
              // LMSA1: 'T1',
              // LCQ1: 18000
            });
          }

          setDataSource(data);
        });
    // userRole != "ZADM" &&
  }, [mngrdata, dashboardType]);

  return (
    <>
    {(loadingStateLoader=="pending")&&<FullScreenLoader />}
      {/* <button id="downloadbtn" className="action_button" style={{color: 'white'}} disabled={dataSource?.length == 0} onClick={handleDownload}>Download</button> */}
      <Table
        // size="small"
        loading={
          (isLoading||loadingScheme == "pending" || loadingState == "pending") ? true : false
        }
        summary={(pageData) => {
          const fieldSum = {
            b: 0,
            i: 0,
            c: 0,
            totalR1: 0,
            totalR2: 0,
            totalR3: 0,
            totalR4: 0,
            totalR5: 0,
            totalR6: 0,
            t1: 0,
            t2: 0,
            t3: 0,
            t4: 0,
            t5: 0,
            t6: 0,
            T2_T1: 0,
            T3_T2: 0,
            T4_T3: 0,
            T5_T4: 0,
            T6_T5: 0,
            vol: 0,
            perTr: 0,
            totalAmount: 0,
            actualCollection: 0,
            actualBillingAmount: 0,
            actualBillingOty: 0,
            billingFactor: 0,
            collectionQuantity: 0,
            achievedSlab: 0,
            payout: 0,
            r1: 0,
            r2: 0,
            r3: 0,
            r4: 0,
            r5: 0,
            r6: 0,
            delayPlan: 0,
            b: 0,
            i: 0,
            c: 0,
            payout2: 0,
            payout3: 0,
            payout4: 0,
          };

          pageData.forEach(
            ({
              delayPlan,
              b,
              i,
              c,
              PayoutRatep1,
              PayoutRatep2,
              PayoutRatep3,
              PayoutRatep4,
              PayoutRatep5,
              PayoutRatep6,
              payoutR1,
              payoutR2,
              payoutR3,
              payoutR4,
              payoutR5,
              payoutR6,
              TEPamount,
              BooksCollectionTargetT1,
              BooksCollectionTargetT2,
              BooksCollectionTargetT3,
              BooksCollectionTargetT4,
              BooksCollectionTargetT5,
              BooksCollectionTargetT6,
              BooksCollectionTargetT2T1,
              BooksCollectionTargetT3T2,
              BooksCollectionTargetT4T3,
              BooksCollectionTargetT5T4,
              BooksCollectionTargetT6T5,
              TEPvol,
              actualCollection,
              actualBillingAmount,
              actualBillingOty,
              actualBillingQuantity,
              billingFactor,
              collectionQuantity,
              TEPpertr,
              payout,
              payout2,
              payout3,
              payout4,
              scheme,
            }) => {
              fieldSum.b += b ? parseInt(b) : 0;
              fieldSum.i += i ? parseInt(i) : 0;
              fieldSum.c += c ? parseInt(c) : 0;
              fieldSum.delayPlan += delayPlan ? parseInt(delayPlan) : 0;
              fieldSum.r1 +=
                PayoutRatep1 && scheme?.topTarget?.includes("t1")
                  ? parseInt(PayoutRatep1 || 0)
                  : 0;
              fieldSum.r2 +=
                PayoutRatep2 && scheme?.topTarget?.includes("t2")
                  ? parseInt(PayoutRatep2 || 0)
                  : 0;
              fieldSum.r3 +=
                PayoutRatep3 && scheme?.topTarget?.includes("t3")
                  ? parseInt(PayoutRatep3 || 0)
                  : 0;
              fieldSum.r4 +=
                PayoutRatep4 && scheme?.topTarget?.includes("t4")
                  ? parseInt(PayoutRatep4 || 0)
                  : 0;
              fieldSum.r5 +=
                PayoutRatep5 && scheme?.topTarget?.includes("t5")
                  ? parseInt(PayoutRatep5 || 0)
                  : 0;
              fieldSum.r6 +=
                PayoutRatep6 && scheme?.topTarget?.includes("t6")
                  ? parseInt(PayoutRatep6 || 0)
                  : 0;
              fieldSum.totalR1 +=
                payoutR1 && scheme?.topTarget?.includes("t1")
                  ? parseInt(payoutR1 || 0)
                  : 0;
              fieldSum.totalR2 +=
                payoutR2 && scheme?.topTarget?.includes("t2")
                  ? parseInt(payoutR2 || 0)
                  : 0;
              fieldSum.totalR3 +=
                payoutR3 && scheme?.topTarget?.includes("t3")
                  ? parseInt(payoutR3 || 0)
                  : 0;
              fieldSum.totalR4 +=
                payoutR4 && scheme?.topTarget?.includes("t4")
                  ? parseInt(payoutR4 || 0)
                  : 0;
              fieldSum.totalR5 +=
                payoutR5 && scheme?.topTarget?.includes("t5")
                  ? parseInt(payoutR5 || 0)
                  : 0;
              fieldSum.totalR6 +=
                payoutR6 && scheme?.topTarget?.includes("t6")
                  ? parseInt(payoutR6 || 0)
                  : 0;
              fieldSum.totalAmount += TEPamount ? parseInt(TEPamount) : 0;
              fieldSum.t1 +=
                BooksCollectionTargetT1 && scheme?.topTarget?.includes("t1")
                  ? parseInt(BooksCollectionTargetT1 || 0)
                  : 0;
              fieldSum.t2 +=
                BooksCollectionTargetT2 && scheme?.topTarget?.includes("t2")
                  ? parseInt(BooksCollectionTargetT2 || 0)
                  : 0;
              fieldSum.t3 +=
                BooksCollectionTargetT3 && scheme?.topTarget?.includes("t3")
                  ? parseInt(BooksCollectionTargetT3 || 0)
                  : 0;
              fieldSum.t4 +=
                BooksCollectionTargetT4 && scheme?.topTarget?.includes("t4")
                  ? parseInt(BooksCollectionTargetT4 || 0)
                  : 0;
              fieldSum.t5 +=
                BooksCollectionTargetT5 && scheme?.topTarget?.includes("t5")
                  ? parseInt(BooksCollectionTargetT5 || 0)
                  : 0;
              fieldSum.t6 +=
                BooksCollectionTargetT6 && scheme?.topTarget?.includes("t6")
                  ? parseInt(BooksCollectionTargetT6 || 0)
                  : 0;
              fieldSum.T2_T1 +=
                BooksCollectionTargetT2T1 && scheme?.topTarget?.includes("t2")
                  ? parseInt(BooksCollectionTargetT2T1 || 0)
                  : 0;
              fieldSum.T3_T2 +=
                BooksCollectionTargetT3T2 && scheme?.topTarget?.includes("t3")
                  ? parseInt(BooksCollectionTargetT3T2 || 0)
                  : 0;
              fieldSum.T4_T3 +=
                BooksCollectionTargetT4T3 && scheme?.topTarget?.includes("t4")
                  ? parseInt(BooksCollectionTargetT4T3 || 0)
                  : 0;
              fieldSum.T5_T4 +=
                BooksCollectionTargetT5T4 && scheme?.topTarget?.includes("t5")
                  ? parseInt(BooksCollectionTargetT5T4 || 0)
                  : 0;
              fieldSum.T6_T5 +=
                BooksCollectionTargetT6T5 && scheme?.topTarget?.includes("t6")
                  ? parseInt(BooksCollectionTargetT6T5 || 0)
                  : 0;
              fieldSum.vol += TEPvol ? parseInt(TEPvol) : 0;
              fieldSum.perTr = parseInt(fieldSum.totalAmount / fieldSum.vol);
              fieldSum.actualCollection += actualCollection
                ? parseInt(actualCollection)
                : 0;
              fieldSum.actualBillingAmount += actualBillingAmount
                ? parseInt(actualBillingAmount)
                : 0;
              fieldSum.actualBillingOty += actualBillingQuantity
                ? parseInt(actualBillingQuantity)
                : 0;
              fieldSum.billingFactor += billingFactor
                ? parseInt(billingFactor)
                : 0;
              fieldSum.collectionQuantity += collectionQuantity
                ? parseInt(collectionQuantity)
                : 0;
              fieldSum.payout += payout ? parseInt(payout) : 0;
              fieldSum.payout2 += payout2 ? parseInt(payout2) : 0;
              fieldSum.payout3 += payout3 ? parseInt(payout3) : 0;
              fieldSum.payout4 += payout4 ? parseInt(payout4) : 0;
            }
          );
          const totalValues = pageData
          ?.filter(data => data?.scheme?.status == 1)
          .reduce((acc, curr) => {
            acc.billingFactor += curr.billingFactor || 0;
            acc.actualCollection += curr.actualCollection || 0;
            acc.collectionQuantity+=curr.collectionQuantity||0;
            acc.actualBillingQuantity+=curr.actualBillingQuantity||0;
            acc.actualBillingAmount+=curr.actualBillingAmount||0;
            return acc;
          }, { billingFactor: 0, actualCollection: 0,collectionQuantity:0,actualBillingQuantity:0,actualBillingAmount:0 });
        
          return (
            <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
              <Table.Summary.Cell fixed index={0}>
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}></Table.Summary.Cell>
              <Table.Summary.Cell index={10}></Table.Summary.Cell>
              <Table.Summary.Cell index={11}></Table.Summary.Cell>
              <Table.Summary.Cell index={12}></Table.Summary.Cell>
              <Table.Summary.Cell index={13}></Table.Summary.Cell>
              <Table.Summary.Cell index={14}></Table.Summary.Cell>
              <Table.Summary.Cell index={15}></Table.Summary.Cell>
              <Table.Summary.Cell index={16}></Table.Summary.Cell>
              <Table.Summary.Cell index={17}></Table.Summary.Cell>
              <Table.Summary.Cell index={18}></Table.Summary.Cell>
              <Table.Summary.Cell index={19}></Table.Summary.Cell>
              <Table.Summary.Cell index={20}>
                {TextLocalString(fieldSum.delayPlan,true) || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={21}>
                {TextLocalString(fieldSum.b,true) || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={22}>
                {TextLocalString(fieldSum.i,true) || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={23}>
                {TextLocalString(fieldSum.c,true) || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={19}></Table.Summary.Cell>
              <Table.Summary.Cell index={19}></Table.Summary.Cell>
              <Table.Summary.Cell index={24}>
                {fieldSum.t1 ? TextLocalString(fieldSum.t1,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={25}>
                {fieldSum.t2 ? TextLocalString(fieldSum.t2,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={26}>
                {fieldSum.T2_T1 ? TextLocalString(fieldSum.T2_T1,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={27}>
                {fieldSum.t3 ? TextLocalString(fieldSum.t3,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={28}>
                {fieldSum.T3_T2 ? TextLocalString(fieldSum.T3_T2,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={29}>
                {fieldSum.t4 ? TextLocalString(fieldSum.t4,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={30}>
                {fieldSum.T4_T3 ? TextLocalString(fieldSum.T4_T3,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={31}>
                {fieldSum.t5 ? TextLocalString(fieldSum.t5,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={32}>
                {fieldSum.T5_T4 ? TextLocalString(fieldSum.T5_T4,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={33}>
                {fieldSum.t6 ? TextLocalString(fieldSum.t6,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={34}>
                {fieldSum.T6_T5 ? TextLocalString(fieldSum.T6_T5,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={35}>

                {TextLocalString(Math.round(fieldSum.totalR1 / fieldSum.t1),true) ||
                  ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={36}>
                {TextLocalString(Math.round(fieldSum.totalR2 / fieldSum.t2),true) ||
                  ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={37}>
                {TextLocalString(Math.round(fieldSum.totalR3 / fieldSum.t3),true) ||
                  ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={38}>
                {TextLocalString(Math.round(fieldSum.totalR4 / fieldSum.t4),true) ||
                  ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={39}>
                {TextLocalString(Math.round(fieldSum.totalR5 / fieldSum.t5),true) ||
                  ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={40}>
                {TextLocalString(Math.round(fieldSum.totalR6 / fieldSum.t6),true) ||
                  ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={41}></Table.Summary.Cell>
              <Table.Summary.Cell index={41}></Table.Summary.Cell>
              <Table.Summary.Cell index={42}>
                {fieldSum.totalR1 ? TextLocalString(fieldSum.totalR1,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={43}>
                {fieldSum.totalR2 ? TextLocalString(fieldSum.totalR2,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={44}>
                {fieldSum.totalR3 ? TextLocalString(fieldSum.totalR3,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={45}>
                {fieldSum.totalR4 ? TextLocalString(fieldSum.totalR4,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={46}>
                {fieldSum.totalR5 ? TextLocalString(fieldSum.totalR5,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={47}>
                {fieldSum.totalR6 ? TextLocalString(fieldSum.totalR6,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={48}>
                {fieldSum.vol ? TextLocalString(fieldSum.vol,true) : ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={49}>
                {/* {fieldSum.perTr ? fieldSum.perTr : ""} */}
                {TextLocalString(
                  Math.round(fieldSum.totalAmount / fieldSum.vol),true
                ) || ""}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={50}>
                {fieldSum.totalAmount
                  ? TextLocalString(fieldSum.totalAmount,true)
                  : ""}
              </Table.Summary.Cell>
              {/* billingFactor: 0, actualCollection: 0,collectionQuantity:0,actualBillingQuantity:0  */}
              {dashboardType === STATE_WISE_DASHBOARD_LABEL && (
                <>
                  <Table.Summary.Cell index={53}>
                    {totalValues.actualBillingAmount
                      ? TextLocalString(totalValues.actualBillingAmount)
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={52}>
                  {totalValues.actualBillingQuantity
                      ? TextLocalString(totalValues.actualBillingQuantity)
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={55}>
                  {totalValues.billingFactor
                      ? TextLocalString(totalValues.billingFactor)
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={54}>
                  {totalValues.actualCollection
                      ? TextLocalString(totalValues.actualCollection)
                      : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={55}>
                  {totalValues.collectionQuantity
                      ? TextLocalString(totalValues.collectionQuantity)
                      : ""}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={56}></Table.Summary.Cell>
                  <Table.Summary.Cell index={57}>
                    {TextLocalString(fieldSum?.payout,true) || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={58}>
                    {TextLocalString(fieldSum?.payout2,true) || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={59}>
                    {TextLocalString(fieldSum?.payout3,true) || ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={60}>
                    {TextLocalString(fieldSum?.payout4,true) || ""}
                  </Table.Summary.Cell>
                </>
              )}
            </Table.Summary.Row>
          );
        }}
        onChange={handleChange}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{
          x: (userRole=="Finance"||userRole=="PMIS"||userRole=="NSH")?7000:5000,
          y: 1000,
        }}
      />
      <div className="d-flex flex-column">
        <TableColorDetails />
        <div>
          <Pagination
            defaultCurrent={1}
            className="paginationDesign"
            // style={{ marginTop: "-43px", position:"absolute", right: "14px" }}
            showSizeChanger
            current={collectionData.pageNumber}
            onChange={(pageNumber, pageSize) =>
              setCollectionData({ ...collectionData, pageNumber, pageSize })
            }
            total={
              stateWiseData?.totalcount ||
              mngrdata?.totalCount ||
              schemeApprove?.data?.result?.totalCountScheme
            }
            pageSize={collectionData.pageSize}
            pageSizeOptions={['150', '250', '350']}
          />
        </div>
      </div>
      {isLoading && <FullScreenLoader />}
    </>
  );
};
export default CollectionTable;
