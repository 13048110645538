import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Tag,
  DatePicker,
  Dropdown,
  Space,
  Select,
  Input,
  Pagination,
  message,
  Tooltip,
} from "antd";
import { DownOutlined, FilterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import XLSX from "xlsx-js-style";
import { useDispatch, useSelector } from "react-redux";
import ModificationCollectionTable from "../modificationApproval/ModificationCollectionTable";
import Status from "../../../components/allSchemeStatus/allSchemeStatus";
import "../modificationApproval/ModificationInstallation";
import axiosAPI from "../../../services/axiosApi";

import ModificationInstallationTable from "./modificationInstallationTable";
import {
  getEditDashboardDetailApi,
  getModificationCollectionAsync,
  getModificationHoldAsync,
} from "../../../redux/modification/modificationSlice";
import {
  getCurrentFiscalYear,
  getCurrentFiscalYear1,
} from "../../../utils/dateFormatter";
import ModificationEditSchemes from "./EditSchemesInstallation/ModificationEditSchemes";
import ModificationEditAllSchemes from "./EditSchemes/ModificationEditAllSchemes";
import { Download, Edit } from "@mui/icons-material";
import ModificationEditAllSchemesInstallation from "./EditSchemesInstallation/ModificationEditAllSchemes";
import ToastifyShow from "../../../components/ToastifyShow";
import moment from "moment";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import FinancialYearButton from "../../../components/financialYearButton/FinancialYearButton";
import {
  getLockedSchemeTypeAsync,
  getLockedZonalHeadApiAsync,
} from "../../../redux/stateData/stateDataSlice";
import FullScreenLoader from "../../../components/loader/FullScreenLoader";

const { RangePicker } = DatePicker;
const InitCollectionData = {
  currentYear: "2024",
  schemeType: 3,
  dealerCode: [],
  month: 0,
  monthEnd: 0,
  currentMonth: 0,
  selectedState: "",
  dealerLocation: "",
  schemeNumber: "",
  dealerCode: "",
  pageSize: "150",
  pageNumber: 1,
  sortingOrder: "ascend",
  sortingType: "DealerCode",
};
const ModificationInstallation = () => {
  const dispatch = useDispatch();
  // const currentFiscalYear = getCurrentFiscalYear();
  const { Option } = Select;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [enforcedMonth, setEnforcedMonth] = useState();
  const [stateListArr, setStateListArr] = useState([]);
  const [schemeIdNo, setSchemeIdNo] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("Schemes");
  const [zonalHeadData, setZonalHeadData] = useState([]);
  const [selectedZH, setSelectedZH] = useState("All Zonal Head");
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const financialYearStartMonth = 3;
  const financialYearStartYear =
    currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
  const financialYear = financialYearStartYear + 1;
  const [dateValue, setDateValue] = useState(financialYear);
  useEffect(() => {
    setDateValue(financialYear);
  }, [financialYear]);
  const [toggleEdit, setToggleEdit] = useState(1);
  const {
    getAreaManager,
    getStateHead,
    getZonalHead,
    getZonalHeadSH,
    loadingTrack,
  } = useSelector((state) => state.schemeTrack);
  const [selectedStateId, setSelectedStateId] = useState(0);
  const [selectedSchemeType, setSelectedSchemeType] = useState(3);
  const { userRole, userId, userName } = decryptData(
    sessionStorage.getItem("userInfo")
  );
  const [selectedStatus, setSelectedStatus] = useState("");
  const [areaMngrData, setAreaMngrData] = useState([]);
  const [stateHeadData, setStateHeadData] = useState([]);
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const currentFiscalYear = getCurrentFiscalYear1(
    collectionData?.month || new Date()
  );
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedSH, setSelectedSH] = useState("");
  const [selectedSHID, setSelectedSHID] = useState(0);
  const [selectedAMID, setSelectedAMID] = useState(0);
  const [selectedZHID, setSelectedZHID] = useState(0);
  const [selectedAM, setSelectedAM] = useState("");
  const [areaDropdown, setAreaDropdown] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [monthlyFilters, setMonthlyFilters] = useState({
    monthStart: new Date().getMonth() + 1,
    monthEnd: new Date().getMonth() + 1,
    enforcedMonth: 0,
  });
  const { stateList, loadingState } = useSelector((state) => state.stateData);

  const getAmId = () => {
    if (userRole == "ZADM") {
      return selectedAMID;
    }
    if (userRole == "SADM") {
      return selectedAMID;
    }
    if (userRole == "AMGR") {
      return userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedAMID;
    }
  };
  const getShId = () => {
    if (userRole == "ZADM") {
      return selectedSHID;
    }
    if (userRole == "SADM") {
      return userId;
    }
    if (userRole == "AMGR" && getStateHead?.data?.userId) {
      return getStateHead?.data?.userId;
    }
    if (["PMIS", "Finance", "COSM", "NSH"].includes(userRole)) {
      return selectedSHID;
    }
  };
  dataSource?.forEach((x) => {});
  const handleClearFilter = () => {
    setCollectionData({ ...InitCollectionData });
    setEnforcedMonth(null);
    setSelectedMonth(null);
    setSelectedStatus(0);
    setSelectedStateId(null);
    setSelectedLabel("Regular Scheme");
    setSelectedAM(0);
    setSelectedZH(0);
    setSelectedSH(0);
    document.querySelector(".select_state .ant-select-selector").innerText =
      "All States";
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      getAM(true);
      getSH(true);
      return;
    }
    if (userRole == "ZADM") {
      getAM(true);
      getSH(true);
      return;
    }
    if (userRole == "SADM") {
      getAM(true);
      return;
    }
  };

  let lastTwoDigits = dateValue % 100;
  const payload = {
    month: collectionData?.month
      ? new Date(collectionData?.month)?.getMonth() + 1
      : collectionData?.month,
    monthEnd: collectionData?.monthEnd
      ? new Date(collectionData?.monthEnd)?.getMonth() + 1
      : collectionData?.monthEnd,
    userid: userId,
    stateId: selectedStateId,
    stateHeadId: getShId(),
    areaManagerId: getAmId(),
    enforcedMonth: collectionData?.currentMonth,
    userRole: userRole,
    fyear: "FY" + lastTwoDigits,
    schemeType: collectionData?.schemeType,
    state: selectedStateId,
    dealerLocation: collectionData?.dealerLocation,
    schemeNumber: collectionData?.schemeNumber,
    dealerCode: collectionData?.dealerCode,
    pageSize: collectionData?.pageSize,
    pageNumber: collectionData?.pageNumber,
    sortingOrder: collectionData.sortingOrder == "ascend" ? "ASC" : "DESC",
    sortingType: collectionData.sortingType || "TerritoryManager",
  };

  let getApiCall = () => {
    dispatch(getModificationCollectionAsync(payload));
  };

  let getHoldApiCall = () => {
    dispatch(getModificationHoldAsync(payload));
  };

  useEffect(() => {
    if (toggleEdit == 1) {
      if (selectedLabel === "Hold Schemes") {
        const timeout = setTimeout(() => {
          getHoldApiCall();
        }, 300);
        return () => {
          clearTimeout(timeout);
        };
      } else {
        const timeout = setTimeout(() => {
          getApiCall();
        }, 300);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [toggleEdit]);

  useEffect(() => {
    const timeout = setTimeout(() => {
    if (selectedLabel === "Hold Schemes") {
      getHoldApiCall();
    } else {
      getApiCall();
    }
  },300)
  return () => {
    clearTimeout(timeout);
  };
  
  }, [
    selectedStateId,
    monthlyFilters,
    collectionData,
    selectedSHID,
    selectedAMID,
    getZonalHeadSH,
    getStateHead,
    getZonalHead,
    selectedZHID,
    dateValue,
  ]);

  const handlePanelChange = (value) => {
    setSelectedMonth(value);
  };
  // const disabledDate = (current, selectedMonth) => {
  //   // lock
  //   if (false) return true;
  //   const today = new Date();
  //   const currentYear = today.getFullYear() -1;
  //   const currentMonth = today.getMonth();
  //   const futureYear = currentYear + 1;

  //   //disableDateRestriction
  //   if (false) {
  //     return null;
  //   }

  //   return (
  //     current &&
  //     (current.year() < currentYear ||
  //       (current.year() === currentYear && current.month() < 3) ||
  //       current.year() > futureYear ||
  //       (current.year() === futureYear && current.month() > 2))
  //   );
  // };
  const disabledDate = (current) => {
    const today = dayjs().year(dateValue - 1);
    const currentYear = today.year();

    // Calculate the start date of the current financial year (April of the current year)
    const startFinancialYear = new Date(currentYear, 3, 1); // Months are zero-based, so April is 3

    // Calculate the end date of the current financial year (March of the next year)
    const endFinancialYear = new Date(currentYear + 1, 2, 31); // March is 2

    // Convert current date to Date object if it's not already
    const currentDate = current instanceof Date ? current : new Date(current);

    // Disable dates that are outside the current financial year
    return currentDate < startFinancialYear || currentDate > endFinancialYear;
  };

  const { collection, loadingState1,loadingModificationSaveState } = useSelector(
    (state) => state.modifyCollection
  );
  const {loadingSaveHoldState}=useSelector((state)=>state.holdData)
  useEffect(() => {
    setDataSource(collection?.data?.result?.approvedSchemeList);
  }, [collection]);

  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
      setStateListArr(stateList?.data);
    } else {
      setStateListArr([]);
    }
  }, [stateList]);

  const getAmDropdownData = (userId) => {
    axiosAPI.post(`SchemeGenerate/GetDropdownAM`, { userId }).then((result) => {
      setAreaDropdown(result?.data?.data);
      setAreaMngrData([
        {
          key: "0",
          label: (
            <span
              onClick={() => {
                setSelectedAM("All Area Managers");
                setSelectedAMID(0);
              }}
            >
              All Area Managers
            </span>
          ),
        },
        ...result?.data?.data?.map((item) => {
          return {
            key: item?.id,
            label: (
              <span onClick={() => setSelectedAMID(item.id)}>{item.aM}</span>
            ),
          };
        }),
      ]);
    });
  };
  const getShDropdownData = (userId) => {
    axiosAPI
      .post(`SchemeGenerate/GetDropdownStateHead`, { userId })
      .then((result) => {
        setStateHeadData([
          {
            key: "0",
            label: (
              <span
                onClick={() => {
                  setSelectedSH("All State Heads");
                  setSelectedSHID(0);
                  setSelectedAMID(0);
                  setSelectedAM("All Area Managers");
                  setAreaMngrData([]);
                }}
              >
                All State Heads
              </span>
            ),
          },
          ...result?.data?.data?.map((item) => {
            return {
              key: item.id,
              label: (
                <span
                  onClick={() => {
                    setSelectedSH(item.stateHeadName);
                    setSelectedSHID(item.id);
                    setSelectedAMID(0);
                    setSelectedAM("All Area Managers");
                    getAmDropdownData(item.id);
                  }}
                >
                  {item.stateHeadName}
                </span>
              ),
            };
          }),
        ]);
      });
  };
  useEffect(() => {
    if (userRole == "ZADM") {
      getShDropdownData(userId);
    }
    if (userRole == "SADM") {
      getAmDropdownData(userId);
    }
  }, []);

  const getStateByStateHeadId = (SHID) => {
    const payload = {
      fyear: currentFiscalYear,
      userid: SHID ? SHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatebyStateHeadId`, payload)
      .then((result) => {
        const data = result?.data?.data;
        setStateListArr([
          {
            stateID: data ? data?.stateID : 0,
            stateName: data ? data?.stateName : "stateName",
          },
        ]);
      });
  };

  const getStateByZonalHeadId = (ZHID) => {
    const payload = {
      fyear: currentFiscalYear,
      userid: ZHID ? ZHID : 0,
    };
    axiosAPI
      .post(`SchemeGenerate/GetStatesbyZonalHeadId`, payload)
      .then((result) => {
        const data = result.data.data;

        setStateListArr(data);
      });
  };

  useEffect(() => {
    if (userRole == "SADM") {
      getStateByStateHeadId(userId);
      return;
    }
    if (selectedSHID) {
      getStateByStateHeadId(selectedSHID);
    }
    if (userRole == "ZADM") {
      getStateByZonalHeadId(userId);
      return;
    }
  }, [selectedSHID]);

  const getSH = () => {
    // if(userRole == 'ZADM') {
    //   return userName;
    // };
    if (userRole == "SADM" && userName) {
      return userName;
    }
    if (userRole == "ZADM") {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedSH == "" ? "All State Heads" : selectedSH;
    }
    if (userRole == "AMGR" && getStateHead?.data?.userName) {
      return getStateHead?.data?.userName;
    }
    return selectedSH == "All State Heads" ? "All State Heads" : selectedSH;
  };

  const handleStateSelect = (value, option) => {
    const id = option.key;
    setSelectedStateId(parseInt(id));
  };
  const getDealerDropdownData = (userId, type) => {
    const monthStart = collectionData?.startDate
      ? dayjs(collectionData?.startDate).month() + 1
      : dayjs().month() + 1;
    const monthEnd = collectionData?.endDate
      ? dayjs(collectionData?.endDate).month() + 1
      : dayjs().month() + 1;

    if (type === "amID") {
      setCollectionData((collectionData) => ({
        ...collectionData,
        amID: userId,
        pageNumber: 1,
      }));
    }
  };
  const handleAMClick = (event) => {
    const selectedAm = areaDropdown.find(
      (value) => value.id === parseInt(event.key)
    );
    if (selectedAm?.aM) {
      setSelectedAM(selectedAm.aM);
    }
    getDealerDropdownData(parseInt(event.key), "amID");
  };
  const getAM = () => {
    if (userRole == "ZADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if (userRole == "SADM") {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }
    if ((userRole == "PMIS", "COSM", "NSH", "Finance")) {
      return selectedAM == "" ? "All Area Managers" : selectedAM;
    }

    return selectedAM == "All Area Managers" ? "All Area Managers" : selectedAM;
  };

  useEffect(() => {
    axiosAPI.post(`SchemeGenerate/GetzonalHead`).then((result) => {
      setZonalHeadData([
        {
          key: "0",
          label: (
            <span
              onClick={() => {
                setSelectedZH("All Zonal Head");
                setStateHeadData([]);
                setSelectedZHID(0);
                setSelectedSHID(0);
                setSelectedSH("All State Heads");
                setAreaMngrData([]);
                setSelectedAMID(0);
                setSelectedAM("All Area Managers");
              }}
            >
              All Zonal Head
            </span>
          ),
        },
        ...result.data.data?.map((item) => {
          return {
            key: item.id,
            label: (
              <span
                onClick={() => {
                  setSelectedZH(item.zonalHeadName);
                  setSelectedZHID(item.id);
                  setSelectedSHID(0);
                  setSelectedSH("All State Heads");
                  getShDropdownData(item.id);
                  setAreaMngrData([]);
                  setSelectedAMID(0);
                  setSelectedAM("All Area Managers");
                }}
              >
                {item.zonalHeadName}
              </span>
            ),
          };
        }),
      ]);
    });

    if (["SADM"].includes(userRole)) {
      getStateByStateHeadId(userId);
    }
  }, []);

  const subColType = [
    {
      key: "0",
      label: (
        <a
          onClick={() => {
            setSelectedRowKeys([]);
            setCollectionData({
              ...collectionData,
              schemeType: 3,
              pageNumber: 1,
            });
          }}
        >
          Slab Based Scheme
        </a>
      ),
    },
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            setSelectedRowKeys([]);
            setCollectionData({
              ...collectionData,
              schemeType: 8,
              pageNumber: 1,
            });
          }}
        >
          Single Target Scheme
        </a>
      ),
      disabled: false,
    },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            setSelectedRowKeys([]);
            setCollectionData({
              ...collectionData,
              schemeType: 4,
              pageNumber: 1,
            });
          }}
        >
          Single Target With Upper Cap Scheme
        </a>
      ),
      disabled: false,
    },
    {
      key: "3",
      label: (
        <a
          onClick={() => {
            setSelectedRowKeys([]);
            setCollectionData({
              ...collectionData,
              schemeType: 5,
              pageNumber: 1,
            });
          }}
        >
          Unconditional Scheme
        </a>
      ),
      disabled: false,
    },
  ];

  const getSchemeType = (schemeType) => {
    if (schemeType == 3) {
      return "Slab Based";
    }
    if (schemeType == 8) {
      return "Single Target";
    }
    if (schemeType == 4) {
      return "Single Target With Upper Cap";
    }
    if (schemeType == 5) {
      return "Unconditional Installation";
    }
  };

  const handleModifyData1 = (x) => {
    const userInfo = decryptData(sessionStorage.getItem("userInfo"));
    dispatch(
      getEditDashboardDetailApi({
        schemeId: x?.schemeId,
        userName: userInfo.userName,
        schemeNo: x?.schemeNo,
      })
    ).then((x) => {
      if (x?.payload?.data?.result) {
        setToggleEdit(2);
        
      }
    });
  };
  const captalizeObjKeys = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
    );

  const getCol = (item) => {
    let formattedStartDate = dayjs(item.startMonth).format("MMM'YY");
    let formattedEndDate = dayjs(item.endMonth).format("MMM'YY");
    // Slab based scheme
    if (collectionData.schemeType == 3) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        Dealer_Location: item.dealerLocation,
        State: item.stateName,
        state_head: item.stateHeadName,
        Territory_Manager: item.territoryManager,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Target_T1: item.t1,
        Target_T2: item.t2,
        Target_T3: item.t3,
        Inc_Per_Tractor_R1: item.r1,
        Inc_Per_Tractor_R2: item.r2,
        Inc_Per_Tractor_R3: item.r3,
        Payout_P1: item.p1,
        Payout_P2: item.p2,
        Payout_P3: item.p3,
        Remarks: item.reason,
      };
    }
    // Single Target Scheme
    if (collectionData.schemeType == 8) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: item.installationTarget,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason,
      };
    }
    // Single Target With Upper Cap Scheme
    if (collectionData.schemeType == 4) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Target: item.installationTarget,
        Upper_Cap: item.upperCap,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason,
      };
    }
    // Unconditional Scheme
    if (collectionData.schemeType == 5) {
      return {
        Scheme_No: item.schemeNo,
        Dealer_Code: item.dealerCode,
        Dealer_Name: item.dealerName,
        State: item.stateName,
        Dealer_Location: item.dealerLocation,
        Territory_Manager: item.territoryManager,
        Start_Month:
          formattedStartDate == "Invalid Date" ? "" : formattedStartDate,
        End_Month: formattedEndDate == "Invalid Date" ? "" : formattedEndDate,
        Actual_Inst_Last_Year_Month: item.actual_Installation_Last_Year_Month,
        Installation_Expected: item.installationTarget,
        Inc_Per_Tractor: item.incPerTarget,
        Payout: item.p1,
        Remarks: item.reason,
      };
    }
  };

  const handleDownload = (e) => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };
    if (dataSource?.length == 0) {
      ToastifyShow("Something went wrong", "error");
      return false;
    }

    if (selectedSchemeType == 5) {
      let tab_text = `<table class="custom-table">
        <thead>
        <tr>
            <th >SCHEME NO</th>
            <th >DEALER CODE</th>
            <th >DEALER NAME</th>
            <th >STATE</th>
            <th >DEALER LOCATION</th>
            <th >TERRITORY MANAGER</th>
            <th >START MONTH</th>
            <th >END MONTH</th>
            <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
            <th >INSTALLATION EXPECTED</th>
            <th >INC. PER TRACTOR</th>
            <th >PAYOUT</th>
            <th >REMARKS</th>
            </tr>
        </thead>`;

      dataSource?.forEach((x) => {
        tab_text += `<tr >

        <td>${x?.schemeNo}</td>
        <td>${x?.dealerCode}</td>
        <td>${x?.dealerName}</td>
        <td>${x?.stateName}</td>
        <td>${x?.dealerLocation}</td>
        <td>${x?.territoryManager}</td>
        <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
        <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
        <td>${x?.actual_Installation_Last_Year_Month}</td>
        <td>${x?.installationTarget}</td>
        <td>${x?.perTr}</td>
        <td>${x?.payout}</td>
        <td>${x?.reason||""}</td>
      </tr>`;
      });

      let totals = {
        installationTarget: 0,
        incPerTarget: 0,
        p1: 0,
        reason: "",
      };

      dataSource.forEach((item) => {
        for (let key in totals) {
          totals[key] += parseInt(item[key]) || 0;
        }
      });
      totals.incPerTarget = parseInt(totals.p1 / totals.installationTarget);

      let totalsRow = "<tr><td colspan='9' >Total</td>";
      for (let key in totals) {
        if (key != "reason") {
          if (key.startsWith("incPer")) {
            totalsRow += `<td>${totals[key]}</td>`;
          } else {
            totalsRow += `<td>${totals[key]}</td>`;
          }
        } else {
          totalsRow += `<td></td>`;
        }
      }

      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";

      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      const allColumns = dataSource.reduce((columns, item) => {
        Object.keys(item).forEach((key) => {
          if (!columns.includes(key)) {
            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) {
          return "Invalid input";
        }
        return String.fromCharCode(number + 64);
      }
      let excelArr = [];
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(
          dataSource.length + 2
        ).toString()}`;
        excelArr.push(ele);
      }

      Object.keys(wb.Sheets[wb.SheetNames[0]])
        .filter((cell) => excelArr.includes(cell))
        .forEach((cell) => {
          wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
        });

      // generate file
      XLSX.writeFile(
        wb,
        `Unconditional_Installation_${new Date().toJSON().slice(0, 10)}.xlsx`
      );
    } else if (selectedSchemeType == 4) {
      let tab_text = `<table>
      <thead>
      <tr>
          <th >SCHEME NO</th>
          <th >DEALER CODE</th>
          <th >DEALER NAME</th>
          <th >STATE</th>
          <th >DEALER LOCATION</th>
          <th >TERRITORY MANAGER</th>
          <th >START MONTH</th>
          <th >END MONTH</th>
          <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th >INSTALLATION TARGET</th>
          <th>UPPER CAP</th>
          <th >INC. PER TRACTOR</th>
          <th >PAYOUT</th>
          <th >REMARKS</th>
          </tr>
      </thead>`;

      dataSource?.forEach((x) => {
        tab_text += `<tr >
        <td>${x?.schemeNo}</td>
        <td>${x?.dealerCode}</td>
        <td>${x?.dealerName}</td>
        <td>${x?.stateName}</td>
        <td>${x?.dealerLocation}</td>
        <td>${x?.territoryManager}</td>
        <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
        <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
        <td>${x?.actual_Installation_Last_Year_Month}</td>
        <td>${x?.installationTarget}</td>
        <td>${x?.upperCap}</td>
        <td>${x?.perTr}</td>
        <td>${x?.payout}</td>
        <td>${x?.reason||""}</td>
    </tr>`;
      });

      let totals = {
        installationTarget: 0,
        upperCap: 0,
        incPerTarget: 0,
        p1: 0,
        reason: "",
      };

      dataSource.forEach((item) => {
        for (let key in totals) {
          totals[key] += parseInt(item[key]) || 0;
        }
      });
      totals.incPerTarget = parseInt(totals.p1 / totals.installationTarget);

      let totalsRow = "<tr><td colspan='9' >Total</td>";
      for (let key in totals) {
        if (key != "reason") {
          if (key.startsWith("incPer")) {
            totalsRow += `<td>${totals[key]}</td>`;
          } else {
            totalsRow += `<td>${totals[key]}</td>`;
          }
        } else {
          totalsRow += `<td></td>`;
        }
      }

      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      const allColumns = dataSource.reduce((columns, item) => {
        Object.keys(item).forEach((key) => {
          if (!columns.includes(key)) {
            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) {
          return "Invalid input";
        }
        return String.fromCharCode(number + 64);
      }
      let excelArr = [];
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(
          dataSource.length + 2
        ).toString()}`;
        excelArr.push(ele);
      }

      Object.keys(wb.Sheets[wb.SheetNames[0]])
        .filter((cell) => excelArr.includes(cell))
        .forEach((cell) => {
          wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
        });

      // generate file
      XLSX.writeFile(
        wb,
        `Installation_Single_Target_With_Upper_Cap${new Date()
          .toJSON()
          .slice(0, 10)}.xlsx`
      );
    } else if (selectedSchemeType == 8) {
      let tab_text = `<table>
    <thead>
    <tr>
        <th >SCHEME NO</th>
        <th >DEALER CODE</th>
        <th >DEALER NAME</th>
        <th >STATE</th>
        <th >DEALER LOCATION</th>
        <th >TERRITORY MANAGER</th>
        <th >START MONTH</th>
        <th >END MONTH</th>
        <th >ACTUAL INSTALLATION LAST YEAR MONTH</th>
        <th >INSTALLATION TARGET</th>
        <th >INC. PER TRACTOR</th>
        <th >PAYOUT</th>
        <th >REMARKS</th>
        </tr>
    </thead>`;

      dataSource?.forEach((x) => {
        tab_text += `<tr >
        <td>${x?.schemeNo}</td>
        <td>${x?.dealerCode}</td>
        <td>${x?.dealerName}</td>
        <td>${x?.stateName}</td>
        <td>${x?.dealerLocation}</td>
        <td>${x?.territoryManager}</td>
        <td>${moment(x?.startMonth).format("MMM-YY") || ""}</td>
        <td>${moment(x?.endMonth).format("MMM-YY") || ""}</td>
        <td>${x?.actual_Installation_Last_Year_Month}</td>
        <td>${x?.installationTarget}</td>
        <td>${x?.perTr}</td>
        <td>${x?.payout}</td>
        <td>${x?.reason||""}</td>
  </tr>`;
      });

      let totals = {
        installationTarget: 0,
        incPerTarget: 0,
        p1: 0,
        reason: "",
      };

      dataSource.forEach((item) => {
        for (let key in totals) {
          totals[key] += parseInt(item[key]) || 0;
        }
      });
      totals.incPerTarget = parseInt(totals.p1 / totals.installationTarget);

      let totalsRow = "<tr><td colspan='9' >Total</td>";
      for (let key in totals) {
        if (key != "reason") {
          if (key.startsWith("incPer")) {
            totalsRow += `<td>${totals[key]}</td>`;
          } else {
            totalsRow += `<td>${totals[key]}</td>`;
          }
        } else {
          totalsRow += `<td></td>`;
        }
      }

      totalsRow += "</tr>";
      tab_text += totalsRow;

      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      const allColumns = dataSource.reduce((columns, item) => {
        Object.keys(item).forEach((key) => {
          if (!columns.includes(key)) {
            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) {
          return "Invalid input";
        }
        return String.fromCharCode(number + 64);
      }
      let excelArr = [];
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(
          dataSource.length + 2
        ).toString()}`;
        excelArr.push(ele);
      }

      Object.keys(wb.Sheets[wb.SheetNames[0]])
        .filter((cell) => excelArr.includes(cell))
        .forEach((cell) => {
          wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
        });

      // generate file
      XLSX.writeFile(
        wb,
        `Installation_Single_Target_${new Date()
          .toJSON()
          .slice(0, 10)}.xlsx`
      );
    } else if (selectedSchemeType == 3) {
      let tab_text = `<table>
      <thead>
      <tr>
          <th rowspan="2">SCHEME NO</th>
          <th rowspan="2">DEALER CODE</th>
          <th rowspan="2">DEALER NAME</th>
          <th rowspan="2">STATE</th>
          <th rowspan="2">DEALER LOCATION</th>
          <th rowspan="2">TERRITORY MANAGER</th>
          <th rowspan="2">TOP SLAB</th>
          <th rowspan="2">START MONTH</th>
          <th rowspan="2">END MONTH</th>
          <th rowspan="2">ACTUAL INSTALLATION LAST YEAR MONTH</th>
          <th colspan="3">TARGETS</th>
          <th colspan="3">INC. PER TRACTOR</th>
          <th colspan="3">PAYOUT</th>
          <th rowspan="2">REMARKS</th>
          </tr>
      <tr>
         <th>T1</th>
         <th>T2</th>
         <th>T3</th>
         <th>R1</th>
         <th>R2</th>
         <th>R3</th>
         <th>P1</th>
         <th>P2</th>
         <th>P3</th>
        </tr>
      </thead>`;

      dataSource?.forEach((header) => {
        tab_text += `<tr >
  <td>${header?.schemeNo}</td>
  <td>${header?.dealerCode}</td>
  <td>${header?.dealerName}</td>
  <td>${header?.stateName}</td>
  <td>${header?.dealerLocation}</td>
  <td>${header?.territoryManager}</td>
  <td>${header?.topTarget}</td>
  <td>${moment(header?.startMonth).format("MMM-YY") || ""}</td>
  <td>${moment(header?.endMonth).format("MMM-YY") || ""}</td>
  <td>${header?.actual_Installation_Last_Year_Month}</td>
  <td>${header?.t1}</td>
  <td>${header?.t2}</td>
  <td>${header?.t3}</td>
  <td>${header?.r1}</td>
  <td>${header?.r2}</td>
  <td>${header?.r3}</td>
  <td>${header?.p1}</td>
  <td>${header?.p2}</td>
  <td>${header?.p3}</td>
  <td>${header?.reason||""}</td>
  </tr>`;
      });
      let totals = {
        t1: 0,
        t2: 0,
        t3: 0,
        r1: 0,
        r2: 0,
        r3: 0,
        p1: 0,
        p2: 0,
        p3: 0,
        reason: "",
      };

      dataSource.forEach((item) => {
        for (let key in totals) {
          totals[key] += parseInt(item[key]) || 0;
        }
      });

      totals.r1 = parseInt(totals.p1 / totals.t1);
      totals.r2 = parseInt(totals.p2 / totals.t2);
      totals.r3 = parseInt(totals.p3 / totals.t3);
      let totalsRow = "<tr><td colspan='10' >Total</td>";
      for (let key in totals) {
        if (key != "reason") {
          if (key.startsWith("r")) {
            totalsRow += `<td>${totals[key]}</td>`;
          } else {
            totalsRow += `<td>${totals[key]}</td>`;
          }
        } else {
          totalsRow += `<td></td>`;
        }
      }

      totalsRow += "</tr>";
      tab_text += totalsRow;
      tab_text += "</table>";
      var elt = document.createElement("div");
      elt.innerHTML = tab_text;
      document.body.appendChild(elt);
      var tbl = elt.getElementsByTagName("TABLE")[0];
      var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
      document.body.removeChild(elt);
      // Add style to Roral row
      const allColumns = dataSource.reduce((columns, item) => {
        Object.keys(item).forEach((key) => {
          if (!columns.includes(key)) {
            columns.push(key);
          }
        });
        return columns;
      }, []);

      function getAlphabetEquivalent(number) {
        if (number < 1 || number > 26) {
          return "Invalid input";
        }
        return String.fromCharCode(number + 64);
      }

      let excelArr = [];
      for (let index = 0; index < allColumns.length; index++) {
        let ele = `${getAlphabetEquivalent(index + 1)}${(
          dataSource.length + 3
        ).toString()}`;
        excelArr.push(ele);
      }

      Object.keys(wb.Sheets[wb.SheetNames[0]])
        .filter((cell) => excelArr.includes(cell))
        .forEach((cell) => {
          wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
        });

      // generate file
      XLSX.writeFile(
        wb,
        `Slab_based_scheme_${new Date().toJSON().slice(0, 10)}.xlsx`
      );
    }
  };
  const handleEditClick = () => {
    if (selectedRowKeys.length > 0) {
      setToggleEdit("3");
      setSelectedRowKeys([]);
    } else {
      message.warning({
        content: "Please select the checkbox",
        className: "custom-message",
      });
    }
  };
  const items = [
    {
      label: (
        <Button
          className="link"
          type="link"
          onClick={(e) => handleEditClick(e)}
        >
          Edit
        </Button>
      ),
      key: "1",
      icon: <Edit />,
    },
    {
      label: (
        <Link
          className="link"
          disabled={dataSource?.length == 0}
          onClick={(e) => handleDownload(e)}
        >
          Download
        </Link>
      ),
      icon: <Download />,
      key: "3",
    },
    {
      label: (
        <Link className="link" onClick={(e) => handleClearFilter(e)}>
          Clear Filters
        </Link>
      ),
      icon: <FilterOutlined />,
      key: "4",
    },
  ];

  const itemsforstatus = [
    {
      label: (
        <a
          onClick={() => {
            getApiCall();
            setSelectedLabel("Regular Schemes");
          }}
        >
          Regular Schemes
        </a>
      ),

      key: "1",
    },
    {
      label: (
        <a
          onClick={() => {
            getHoldApiCall();
            setSelectedLabel("Hold Schemes");
          }}
        >
          Hold Schemes
        </a>
      ),
      key: "2",
    },
  ];

  return (
    <div
      id="table"
      className="trackcollection create_scheme_wrap onemoth-date-selector view-schela news"
    >
      {toggleEdit == 2 ? (
        <ModificationEditSchemes
          selectedSchemeType={collectionData?.schemeType}
          backBtn={() => {
            setToggleEdit(1);
            setSelectedRowKeys([]);
          }}
          selectedLabel={selectedLabel}
        />
      ) : toggleEdit == 3 ? (
        <ModificationEditAllSchemesInstallation
          editCheckbox={schemeIdNo}
          selectedSchemeType={collectionData?.schemeType}
          backBtn={() => {
            setToggleEdit(1);
            setSelectedRowKeys([]);
          }}
          selectedLabel={selectedLabel}
        />
      ) : (
        <>
          <div
            id="space"
            className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
          >
            <Col>
              <Dropdown menu={{ items: itemsforstatus }}>
                <Button>
                  <Space>
                    {selectedLabel == "Schemes"
                      ? "Regular Scheme"
                      : selectedLabel}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
            <Col>
              <Dropdown menu={{ items: subColType }}>
                <Button>
                  <Space>
                    {getSchemeType(collectionData?.schemeType)}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>

            <div className="marginauto">
              <Tooltip title={"Running Month"}>
                <DatePicker
                  // onChange={(date) => handleInputChange('enforcedMonth', date)}
                  onChange={(date, formatDate) => {
                    if (formatDate == "") {
                      setCollectionData({
                        ...collectionData,
                        currentMonth: 0,
                        pageNumber: 1,
                      });
                      return;
                    }
                    setCollectionData({
                      ...collectionData,
                      currentMonth: new Date(formatDate).getMonth() + 1,
                      pageNumber: 1,
                    });
                  }}
                  className="start"
                  placeholder="Running Month"
                  picker="month"
                  value={enforcedMonth}
                  allowClear
                  disabled={
                    collectionData.month != "" || collectionData.monthEnd != ""
                  }
                />
              </Tooltip>
            </div>
            <div className="marginauto">
              <Tooltip title={"Financial Year"}>
                <FinancialYearButton
                  handleDate={(x) => {
                    setDateValue(x);
                  }}
                />
              </Tooltip>
            </div>
            <div className="month ">
              <Tooltip title={"Start Month       End Month"}>
                <RangePicker
                  mode={["month", "month"]}
                  picker="month"
                  disabledDate={disabledDate}
                  onChange={(date, formatDate) => {
                    setCollectionData((collectionData) => ({
                      ...collectionData,
                      month: date && date[0] ? date[0].format() : 0,
                      monthEnd: date && date[1] ? date[1].format() : 0,
                      pageNumber: 1,
                    }));
                  }}
                  //disabledDate={(current) => disabledDate(current, selectedMonth)}
                  // disabled={[!showStartMonth, !showEndMonth]}
                  onPanelChange={handlePanelChange}
                  disabled={collectionData.currentMonth > 0}
                />
              </Tooltip>
            </div>
            {true && (
              <Col>
                <Tooltip title={"State Head"}>
                  <Dropdown
                    menu={{ items: stateHeadData }}
                    disabled={["SADM"].includes(userRole)}
                  >
                    <Button>
                      <Space>
                        {getSH()}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Tooltip>
              </Col>
            )}
            {true && (
              <Col>
                <Tooltip title={"Area Manager"}>
                  <Dropdown
                    menu={{ items: areaMngrData, onClick: handleAMClick }}
                  >
                    <Button>
                      <Space>
                        {getAM()}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Tooltip>
              </Col>
            )}
          </div>
          <div
            style={{ marginTop: "0" }}
            id="space"
            className="ant-space css-dev-only-do-not-override-190m0jy ant-space-horizontal ant-space-align-center all_Scheme_header"
          >
            <Tooltip title={"Select State"}>
              <Select
                showSearch
                className="select_state heightauto"
                placeholder="Select State"
                optionFilterProp="children"
                onSelect={handleStateSelect}
                defaultValue={0}
              >
                <Option key={0} value={0}>
                  All States
                </Option>
                {stateListArr?.map((option) => (
                  <Option key={option.stateID} value={option.stateName}>
                    {option.stateName}
                  </Option>
                ))}
              </Select>
            </Tooltip>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
              <Input
                placeholder="Scheme No."
                value={collectionData.schemeNumber}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    schemeNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
              <Input
                style={{ marginLeft: "10px" }}
                placeholder="Dealer Code"
                value={collectionData.dealerCode}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    dealerCode: e.target.value,
                  })
                }
              />
            </div>
            <div className="ant-select select_state heightauto css-18iikkb ant-select-single ant-select-show-arrow ant-select-show-search">
              <Input
                style={{ marginLeft: "20px" }}
                placeholder="Dealer Location"
                value={collectionData.dealerLocation}
                onChange={(e) =>
                  setCollectionData({
                    ...collectionData,
                    dealerLocation: e.target.value,
                  })
                }
              />
            </div>
            <Col className="actionbuttons rightmargin">
              <Dropdown menu={{ items: items }}>
                <Button>
                  <Space>
                    Actions
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </div>
          <ModificationInstallationTable
            setSchemeIdNo={setSchemeIdNo}
            handleModifyData={handleModifyData1}
            dataSource={dataSource}
            selectedStatus={selectedStatus}
            selectedSchemeType={collectionData.schemeType}
            monthlyFilters={monthlyFilters}
            selectedStateId={selectedStateId}
            loading={
              loadingState1 == "pending" ||
              loadingState == "pending" ||
              loadingTrack == "pending"
            }
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedLabel={selectedLabel}
            handleChange={(pagination, filters, sorter) => {
              setCollectionData({
                ...collectionData,
                sortingOrder: sorter?.order,
                sortingType: sorter?.columnKey,
              });
            }}
          />
          <Pagination
            defaultCurrent={1}
            className="paginationDesign"
            // style={{ position: "absolute", right: "20px", bottom: "100px" }}
            current={collectionData.pageNumber}
            onChange={(pageNumber, pageSize) =>
              setCollectionData({ ...collectionData, pageNumber, pageSize })
            }
            total={collection?.data?.totalCount}
            showSizeChanger
            pageSize={collectionData.pageSize}
            pageSizeOptions={["150", "250", "350"]}
          />
        </>
      )}
      {(loadingState1 == "pending" ||
        loadingState == "pending" ||
        loadingTrack == "pending"||loadingModificationSaveState=="pending"||loadingSaveHoldState=="pending") && <FullScreenLoader />}
      <div className="All_scheme_status">
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "green" }}
          ></div>
          Modified Data
        </div>
        <div className="Schemes_status">
          <div
            className="Status_color"
            style={{ backgroundColor: "#9999ff" }}
          ></div>
          Hold Data
        </div>
      </div>
    </div>
  );
};
export default ModificationInstallation;
