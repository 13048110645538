import React, { useEffect, useState } from "react";
import {
  Table, Divider, Col, Row, Form, Input, Button, Pagination, DatePicker, Tooltip, Dropdown,
  Space,
} from "antd";
import ThemeButton from "../../../components/button/buttons";
import SMDialog from "../../../components/modal/alertModal/CommonModal";
import StatusModal from "../../../components/modal/alertModal/StatusModal";
import { message } from "antd";
import axiosAPI from "../../../services/axiosApi";
import { getCurrentFiscalYear, getCurrentFiscalYear1, getCurrentFiscalYearCurrent } from "../../../utils/dateFormatter";
import dayjs, { Dayjs } from "dayjs";
import { getNHSDashboardDetailsApi, getNHSDashboardDetailsForInstallationApi, getNHSDashboardDetailsApiForZadm, getNHSDashboardDetailsApiForInstallationForZadm } from "../../../redux/dashboard/dashboardDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { ReactComponent as Download } from "../../../assets/vector/download.svg";
import ToastifyShow, { TextLocalString } from "../../../components/ToastifyShow";
import { GetStatesbyZonalHeadIdApi } from "../../../redux/stateData/stateDataSlice";
import XLSX from 'xlsx-js-style';
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import {
  DownOutlined,
  FilterOutlined,

} from "@ant-design/icons";
import { ENV_TYPE } from "../../../config";

const InitCollectionData = {
  currentYear: dayjs().year(),
  // collectionType: 'Collection',
  schemeType: "",
  dealerCode: [],
  startDate: "",
  endDate: "",
  pageSize: 150,
  pageNumber: 1
};

const OneMonthScheme = ({ dateValue, selectedSchemeType }) => {
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const dispatch = useDispatch();
  const [selectedStateId, setSelectedStateId] = useState(0);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { nhsDashboardDetails, nhsDashboardDetailsForInstallation, loadingNhs, nhsDashboardDetailsForZadm, nhsDashboardDetailsForInstallationForZadm } = useSelector(
    (state) => state?.dashboardDetails
  );
  const { stateList, loadingState, ZonalHeadStateList } = useSelector((state) => state.stateData);

  const handleInputChange = (rec, e) => {
    const { name, value } = e.target;
    const updateDataSource = [...dataSource];
    updateDataSource[rec.id][name] = value;
    if (userRole == "NSH") {
      updateDataSource[rec.id]["payout"] =
        +updateDataSource[rec.id]["billingQty"] *
        +updateDataSource[rec.id]["perTractorBilling"];
    }
    setDataSource(updateDataSource);
  };

  const currentFiscalYear = getCurrentFiscalYearCurrent(dateValue ? dayjs().year(dateValue) : collectionData?.startDate ? collectionData?.startDate : new Date());

  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const userId = userData.userId;
  const userRole = userData.userRole;

  const getSchemeType = (schemeType) => {

    if (schemeType == 6 || schemeType == "") {
      return "Collection Schemes";
    }
    if (schemeType == 3) {
      return "Slab Based";
    }
    if (schemeType == 8) {
      return "Single Target";
    }
    if (schemeType == 4) {
      return "Single Target With Upper Cap";
    }
    if (schemeType == 5) {
      return "Unconditional";
    }
  };

  const subColType = [
    {
      key: "1",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 6,
              pageNumber: 1,
            })
          }
        >
          Collection Schemes
        </a>
      ),
      disabled: false,
    },
    {
      key: "2",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 3,
              pageNumber: 1,
            })
          }
        >
          Slab Based Scheme
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 8,
              pageNumber: 1,
            })
          }
        >
          Single Target Scheme
        </a>
      ),
      disabled: false,
    },
    {
      key: "4",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 4,
              pageNumber: 1,
            })
          }
        >
          Single Target With Upper Cap Scheme
        </a>
      ),
      disabled: false,
    },
    {
      key: "5",
      label: (
        <a
          onClick={() =>
            setCollectionData({
              ...collectionData,
              schemeType: 5,
              pageNumber: 1,
            })
          }
        >
          Unconditional Scheme
        </a>
      ),
      disabled: false,
    },
  ];

  const columns = (schemeType) => {
    if (schemeType == 6 || schemeType == "") {
      return [
        {
          title: "State",
          dataIndex: "stateName",
          fixed: "left",
          width: "100px",
        },
        { title: "T1", dataIndex: "t1", width: "100px", render: (x) => TextLocalString(x) },
        { title: "T2", dataIndex: "t2", width: "100px", render: (x) => TextLocalString(x) },
        { title: "T3", dataIndex: "t3", width: "100px", render: (x) => TextLocalString(x) },
        { title: "T4", dataIndex: "t4", width: "100px", render: (x) => TextLocalString(x) },
        { title: "T5", dataIndex: "t5", width: "100px", render: (x) => TextLocalString(x) },
        { title: "T6", dataIndex: "t6", width: "100px", render: (x) => TextLocalString(x) },
        { title: "R1", dataIndex: "r1", width: "100px", render: (x) => TextLocalString(x) },
        { title: "R2", dataIndex: "r2", width: "100px", render: (x) => TextLocalString(x) },
        { title: "R3", dataIndex: "r3", width: "100px", render: (x) => TextLocalString(x) },
        { title: "R4", dataIndex: "r4", width: "100px", render: (x) => TextLocalString(x) },
        { title: "R5", dataIndex: "r5", width: "100px", render: (x) => TextLocalString(x) },
        { title: "R6", dataIndex: "r6", width: "100px", render: (x) => TextLocalString(x) },
        {
          title: "Expected SH Sheet",
          dataIndex: "",
          className:"perTr-table",
          children: [
            {
              title: (
                <div>
                  Collection
                  <br />
                  Quantity
                </div>
              ),
              className:"perTr-table",
              dataIndex: "volume", //collectionQty maaped with volume param of api
              width: "120px"
              , render: (x) => TextLocalString(x)
            },
            {
              title: "Per Tractor",
              dataIndex: "perTr",
              className:"perTr-table",
              width: "120px"
              , render: (x) => TextLocalString(x)
            },
          ],
        },
        {
          title: "Finance",
          dataIndex: "",
          className:"finance-table",
          
          children: [
            {
              className:"finance-table",
              title: (
                <div>
                  Coll Incentive
                  <br />
                  Same Month
                  <br />
                  Last Year
                </div>
              ),
              dataIndex: "collIncentiveLastyear",
              width: "140px"
              , render: (x) => TextLocalString(x||0)||0,

            },
            {
              title: (
                <div>
                  Coll Incentive
                  <br />
                  Last Month
                </div>
              ),className:"finance-table",
              dataIndex: "incentivelastmonth",
              width: "140px"
              , render: (x) => TextLocalString(x)
            },
            {
              title: (
                <div>
                  YTD
                  <br />
                  Incentive PT
                </div>
              ),className:"finance-table",
              dataIndex: "yTDIncentivePT",
              width: "120px"
              , render: (x) => TextLocalString(x)
            },
          ],
        },
        {
          title: "NSH Input",
          dataIndex: "",
          editable: true,
          className:"nsh-table",
          children: [
            {
              title: (
                <div>
                  Collection
                  <br />
                  Quantity
                </div>
              ),   className:"nsh-table",
              dataIndex: "collectionQty",
              width: "120px",
              // ediatble:true,
              render: (x, record) => {
                if (userRole == "NSH") {
                  return (
                    <Input
                      name="collectionQty"
                      value={record.collectionQty}
                      onChange={(value) => {
                        handleInputChange(record, value);
                      }}
                    />
                  );
                } else {
                  return <span>{TextLocalString(record?.collectionQty) || 0}</span>;
                }
              },
            },
            {
              title: (
                <div>
                  Billing
                  <br />
                  Quantity
                </div>
              ),   className:"nsh-table",
              dataIndex: "billingQty",
              width: "120px",
              render: (_, record) => {
                if (userRole == "NSH") {
                  return (
                    <Input
                      name="billingQty"
                      value={record.billingQty}
                      onChange={(value) => {
                        handleInputChange(record, value);
                      }}
                    />
                  );
                } else {
                  return <span>{TextLocalString(record.billingQty) || 0}</span>;
                }
              },
            },
            {
              title: (
                <div>
                  Per Tractor
                  <br />
                  Incentive
                </div>
              ),   className:"nsh-table",
              dataIndex: "perTractorBilling",
              width: "120px",
              render: (_, record) => {
                if (userRole == "NSH") {
                  return (
                    <Input
                      name="perTractorBilling"
                      value={record.perTractorBilling}
                      onChange={(value) => {
                        handleInputChange(record, value);
                      }}
                    />
                  );
                } else {
                  return <span>{TextLocalString(record.perTractorBilling) || 0}</span>;
                }
              },
            },
          ],
        },
        {
          title: "Payout",
          dataIndex: "payout",
          width: "120px",
          render: (_, record) => <span>{TextLocalString(record.payout) || 0}</span>,
        },

      ];
    }
    if (schemeType == 3) {
      return [
        {
          title: "State",
          dataIndex: "stateName",
          fixed: "left",
          width: "100px",
        },
        {
          title: "Targets",
          dataIndex: "Target",
          children: [
            {
              title: "T1",
              dataIndex: "t1",
              width: "150px",
            },
            {
              title: "T2",
              dataIndex: "t2",
              width: "150px",
            },
            {
              title: "T3",
              dataIndex: "t3",
              width: "150px",
            },
          ],
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "IPT",
          children: [
            {
              title: "R1",
              dataIndex: "r1",
              width: "150px",
            },
            {
              title: "R2",
              dataIndex: "r2",
              width: "150px",
            },
            {
              title: "R3",
              dataIndex: "r3",
              width: "150px",
            },
          ],
        },
        {
          title: "Finance",
          dataIndex: "",
          className:"finance-table",
          children: [
            {
              title: (
                <div>
                  Installation
                  <br />
                  Last Year
                </div>
              ),
              className:"finance-table",
              dataIndex: "installationLastyear",
              width: "140px",
              //render: (x) => TextLocalString(x)
              render: (x) => TextLocalString(x === null ? 0 : x)
            },
            {
              title: (
                <div>
                  Incentive
                  <br />
                  Last Month
                </div>
              ),
              className:"finance-table",
              dataIndex: "incentivelastmonth",
              width: "140px"
              , render: (x) => TextLocalString(x)
            },
            {
              title: (
                <div>
                  YTD
                  <br />
                  Incentive PT
                </div>
              ),
              className:"finance-table",
              dataIndex: "yTDIncentivePT",
              width: "120px"
              , render: (x) => TextLocalString(x)
            },
          ],
        },

      ];
    }
    if (schemeType == 4) {
      return [
        {
          title: "State",
          dataIndex: "stateName",
          fixed: "left",
          width: "100px",
        },
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          width: "190px",
        },
        {
          title: "Upper Cap",
          dataIndex: "upperCap",
          width: "120px",
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "insPerTarget",
          width: "190px",
        },
        {
          title: "Finance",
          dataIndex: "",
          className:"finance-table",
          children: [
            {
              title: (
                <div>
                  Installation
                  <br />
                  Last Year
                </div>
              ),
              className:"finance-table",
              dataIndex: "installationLastyear",
              width: "140px",
              //render: (x) => TextLocalString(x)
              render: (x) => TextLocalString(x === null ? 0 : x)
            },
            {
              title: (
                <div>
                  Incentive
                  <br />
                  Last Month
                </div>
              ),
              className:"finance-table",
              dataIndex: "incentivelastmonth",
              width: "140px"
              , render: (x) => TextLocalString(x)
            },
            {
              title: (
                <div>
                  YTD
                  <br />
                  Incentive PT
                </div>
              ),
              className:"finance-table",
              dataIndex: "yTDIncentivePT",
              width: "120px"
              , render: (x) => TextLocalString(x)
            },
          ],
        },
      ];
    }
    //UnConditional
    if (schemeType == 5) {
      return [
        {
          title: "State",
          dataIndex: "stateName",
          fixed: "left",
          width: "100px",
        },
        {
          title: "Installation Expected",
          dataIndex: "installationTarget",
          width: "190px",
        },
        {
          title: "inc. Per Tractor",
          dataIndex: "insPerTarget",
          width: "190px",
        },
        {
          title: "Finance",
          dataIndex: "",
          className:"finance-table",
          children: [
            {
              title: (
                <div>
                  Installation
                  <br />
                  Last Year
                </div>
              ),
              className:"finance-table",
              dataIndex: "installationLastyear",
              width: "140px",
              //render: (x) => TextLocalString(x)
              render: (x) => TextLocalString(x === null ? 0 : x)
            },
            {
              title: (
                <div>
                  Incentive
                  <br />
                  Last Month
                </div>
              ),
              className:"finance-table",
              dataIndex: "incentivelastmonth",
              width: "140px"
              , render: (x) => TextLocalString(x)
            },
            {
              title: (
                <div>
                  YTD
                  <br />
                  Incentive PT
                </div>
              ),
              className:"finance-table",
              dataIndex: "yTDIncentivePT",
              width: "120px"
              , render: (x) => TextLocalString(x)
            },
          ],
        },
      ];
    }
    //SingleTarget
    if (schemeType == 8) {
      return [
        {
          title: "State",
          dataIndex: "stateName",
          fixed: "left",
          width: "100px",
        },
        {
          title: "Installation Target",
          dataIndex: "installationTarget",
          width: "190px",
        },

        {
          title: "inc. Per Tractor",
          dataIndex: "insPerTarget",
          width: "190px",
        },
        {
          title: "Finance",
          dataIndex: "",
          className:"finance-table",
          children: [
            {
              title: (
                <div>
                  Installation
                  <br />
                  Last Year
                </div>
              ),
              dataIndex: "installationLastyear",
              width: "140px",
              className:"finance-table",
              // render: (x) => TextLocalString(x)
              render: (x) => TextLocalString(x === null ? 0 : x)
            },
            {
              title: (
                <div>
                  Incentive
                  <br />
                  Last Month
                </div>
              ),
              className:"finance-table",
              dataIndex: "incentivelastmonth",
              width: "140px"
              , render: (x) => TextLocalString(x)
            },
            {
              title: (
                <div>
                  YTD
                  <br />
                  Incentive PT
                </div>
              ),
              className:"finance-table",
              dataIndex: "yTDIncentivePT",
              width: "120px"
              , render: (x) => TextLocalString(x)
            },
          ],
        },
      ];
    }
  }

  useEffect(() => {
    if (!collectionData.startDate) {
      return;
    }
    let lastTwoDigits = dateValue % 100;
    const payload = {
      // year: new Date(collectionData.startDate).getFullYear(),
      // fyear: currentFiscalYear,
      fYear: currentFiscalYear,
      userid: userId,
      month: new Date(collectionData.startDate).getMonth() + 1,
      monthEnd: new Date(collectionData.startDate).getMonth() + 1,
      stateId: selectedStateId || 0,
      pageSize: collectionData.pageSize,
      pageNumber: collectionData.pageNumber
    };
    const payloadForInstallationSchemes = {
      // year: new Date(collectionData.startDate).getFullYear(),
      schemeType: collectionData.schemeType,
      fYear: currentFiscalYear,
      userid: userId,
      month: new Date(collectionData.startDate).getMonth() + 1,
      monthEnd: new Date(collectionData.startDate).getMonth() + 1,
      stateId: selectedStateId || 0,
      pageSize: collectionData.pageSize,
      pageNumber: collectionData.pageNumber
    };
    // let fetchdata = async () => {
    //   await dispatch(getNHSDashboardDetailsApi(payload));
    // };

    let fetchdata = async () => {
      if (["NSH", "COSM", "Finance", "PMIS"].includes(userRole)) {
        if (collectionData.schemeType == "3" || collectionData.schemeType == "4" || collectionData.schemeType == "5" || collectionData.schemeType == "8") {
          await dispatch(getNHSDashboardDetailsForInstallationApi(payloadForInstallationSchemes));
        }
        else
          await dispatch(getNHSDashboardDetailsApi(payload));
        return
      }
      if (userRole == "ZADM") {
        if (collectionData.schemeType == "3" || collectionData.schemeType == "4" || collectionData.schemeType == "5" || collectionData.schemeType == "8") {
          await dispatch(getNHSDashboardDetailsApiForInstallationForZadm(payloadForInstallationSchemes));
        }
        else
          await dispatch(getNHSDashboardDetailsApiForZadm(payload));
        return;
      }
    };
    fetchdata();
    // axiosAPI.post(
    //     `Dashboard/GetNSHDashboardDetail`,
    //     payload
    //   )
    //   .then((result) => {
    //     if(userRole == "NSH"){
    //      setDataSource(result.data.data.map((item, ind) => ({...item, id: ind, payout: '', collectionQty: '',billingQty: '',perTractorBilling: ''})));
    //     }else{
    //       setDataSource(result.data.data)
    //     }
    //   });
  }, [collectionData,
    selectedStateId,
    dateValue
  ]);
  useEffect(() => {
    setCollectionData({
      ...collectionData,
      startDate: "",
        endDate: ""
    });
    setDataSource([])
  }, [currentFiscalYear])

  useEffect(() => {
    if (collectionData.schemeType == "3" || collectionData.schemeType == "4" || collectionData.schemeType == "5" || collectionData.schemeType == "8") {
      if(collectionData.startDate === "" &&  collectionData.endDate === ""){
          return setDataSource([]); // Use an empty array as the default if nhsDashboardDetails.data is undefined
        
      }else{
        if (nhsDashboardDetailsForInstallation && (nhsDashboardDetailsForInstallation?.listitems && nhsDashboardDetailsForInstallation?.listitems.length !== 0)) {
          return setDataSource(
            nhsDashboardDetailsForInstallation?.listitems.map((item, ind) => ({ ...item, id: ind }))
          );
        } else if (nhsDashboardDetailsForInstallationForZadm && (nhsDashboardDetailsForInstallationForZadm?.listitems && nhsDashboardDetailsForInstallationForZadm?.listitems.length !== 0)) {
          return setDataSource(
            nhsDashboardDetailsForInstallationForZadm?.listitems.map((item, ind) => ({ ...item, id: ind }))
          );
        }
      }
   
    }
    else {
      if(collectionData.startDate === "" &&  collectionData.endDate === ""){
          return setDataSource([]); // Use an empty array as the default if nhsDashboardDetails.data is undefined
        
      }else{
      if (nhsDashboardDetails && (nhsDashboardDetails?.listitems && nhsDashboardDetails?.listitems.length !== 0)) {
        return setDataSource(
          nhsDashboardDetails?.listitems.map((item, ind) => ({ ...item, id: ind }))
        );
      }  else if (nhsDashboardDetailsForZadm && (nhsDashboardDetailsForZadm?.listitems && nhsDashboardDetailsForZadm?.listitems.length !== 0)) {
        return setDataSource(
          nhsDashboardDetailsForZadm?.listitems.map((item, ind) => ({ ...item, id: ind }))
        );
      }
     } 
    }

  }, [nhsDashboardDetails, nhsDashboardDetailsForInstallation, nhsDashboardDetailsForZadm, nhsDashboardDetailsForInstallationForZadm]);

  const handleConfirmationModal = async (confirm = false) => {
    setOpenConfirmationModal((openConfirmationModal) => !openConfirmationModal);
    if (confirm === true) {
      submitData();
    }
  };


  const submitData = () => {
    const userData = decryptData(sessionStorage.getItem("userInfo"));
    const userId = userData.userId;
    const userName = userData.userName;
    // const dataShell = []
    const finalPayload = dataSource.map((item) => ({
      fyYear: currentFiscalYear,
      collectionQuantity: +item.collectionQty,
      billingQuantity: +item.billingQty,
      perTractorBilling: +item.perTractorBilling,
      payout: +item.billingQty * +item.perTractorBilling,
      stateId: item.stateId,
      startMonth: collectionData.startDate,
      endMonth: collectionData.startDate,
      userId: userId,
      userName: userName,
    }));

    axiosAPI
      .post(`Dashboard/NSHInputDashboard`, finalPayload)
      .then((result) => {
        if (result.status == 200) {
          setTimeout(() => {
            message.loading("scheme successfully Generated");
          }, -1000);
        }
        // setDataSource(result.data.data)
      });
  };
  const { Option } = Select;

  const [stateListArr, setStateListArr] = useState([]);

  useEffect(() => {
    let lastTwoDigits = dateValue % 100;
    let payload = {
      fyear: "FY" + lastTwoDigits,
      userid: userId ? userId : 0
    }
    if(userRole!="ZADM"){
    dispatch(GetStatesbyZonalHeadIdApi(payload))
    }
  }, [dateValue])

  useEffect(() => {
    if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
      return setStateListArr(stateList?.data);
    } if (Array?.isArray(ZonalHeadStateList?.data) && ZonalHeadStateList?.data?.length != 0) {
      return setStateListArr(ZonalHeadStateList.data)
    } else {
      return setStateListArr([]);
    }
  }, [stateList, ZonalHeadStateList]);

  const handleSelect = (value, option) => {
    const id = option.key;
    setSelectedStateId(parseInt(id));
  };
  const captalizeObjKeys = obj => Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
  );
  const handleDownload = () => {
    const TotalStyle = {
      fill: {
        fgColor: { rgb: "FFFACD" }, // Yellow color, you can change it as per your preference
      },
      font: {
        color: { rgb: "212121" }, // Black font color
        bold: true,
        sz: "12",
      },
    };

    const data = dataSource?.length != 0 && dataSource.map(item => {
      return captalizeObjKeys({
        STATE: item.stateName,
        T1: item.t1,
        T2: item.t2,
        T3: item.t3,
        T4: item.t4,
        T5: item.t5,
        T6: item.t6,
        R1: item.r1,
        R2: item.r2,
        R3: item.r3,
        R4: item.r4,
        R5: item.r5,
        R6: item.r6,
        ESS_COLLECTION_QUANTITY: item.volume,
        ESS_PER_TRACTOR: item.perTr,
        ESS_INCENTIVE_LAST_MONTH: item.incentivelastmonth,
        ESS_YTD_INCENTIVE_PT: item.yTDIncentivePT,
        NSH_INPUT_COLLECTION_QUANTITY: item.collectionQty,
        NSH_INPUT_BILLING_QUANTITY: item.billingQty,
        NSH_INPUT_PER_TRATOR_INCENTIVE: item.perTractorBilling,
        PAYOUT: item.payout,
        INSTALLATIONLASTYEAR:item.installationLastyear||0,
        INCENTIVELASTMONTH:item.incentivelastmonth||0,
        YTDINCENTIVE:item.yTDIncentivePT||0,
        INSPERTARGET:item.insPerTarget||0,
        INSTALLATIONTAREGT:item?.installationTarget,
        UPPERCAP:item?.upperCap
      })
    })
    if (data?.length == 0) {
      ToastifyShow("Something went wrong", "error")
      return false
    }



    let tab_text =collectionData.schemeType === 5?`<table>
    <thead>
    <tr>

        <th >STATE</th>
        <th >Installation Target</th>
        <th >inc. Per Tractor</th>
        <th>INSTALLATION LAST YEAR</th>
       <th>INCENTIVE LAST MONTH</th>
       <th>YTD
       INCENTIVE PT</th>
        </tr>
    </thead>`:collectionData.schemeType === 4?`<table>
    <thead>
    <tr>

        <th >STATE</th>
        <th >Installation Target</th>
        <th >UPPER CAP</th>
        <th >inc. Per Tractor</th>
        <th>INSTALLATION LAST YEAR</th>
       <th>INCENTIVE LAST MONTH</th>
       <th>YTD
       INCENTIVE PT</th>
        </tr>
    </thead>`:collectionData.schemeType === 8?`<table>
    <thead>
    <tr>

        <th >STATE</th>
        <th >Installation Target</th>
        <th >inc. Per Tractor</th>
        <th>INSTALLATION LAST YEAR</th>
       <th>INCENTIVE LAST MONTH</th>
       <th>YTD
       INCENTIVE PT</th>
        </tr>
    </thead>`: collectionData.schemeType === 3?`<table>
    <thead>
    <tr>
        <th rowspan="2">STATE</th>
        <th rowspan="2">T1</th>
        <th rowspan="2">T2</th>
        <th rowspan="2">T3</th>
        <th rowspan="2">R1</th>
        <th rowspan="2">R2</th>
        <th rowspan="2">R3</th>
        <th colspan="3">FINANCE</th>
        </tr>
    <tr>
       <th>INSTALLATION LAST YEAR</th>
       <th>INCENTIVE LAST MONTH</th>
       <th>YTD
       INCENTIVE PT</th>
      </tr>
    </thead>`:`<table>
    <thead>
    <tr>
        <th rowspan="2">STATE</th>
        <th rowspan="2">T1</th>
        <th rowspan="2">T2</th>
        <th rowspan="2">T3</th>
        <th rowspan="2">T4</th>
        <th rowspan="2">T5</th>
        <th rowspan="2">T6</th>
        <th rowspan="2">R1</th>
        <th rowspan="2">R2</th>
        <th rowspan="2">R3</th>
        <th rowspan="2">R4</th>
        <th rowspan="2">R5</th>
        <th rowspan="2">R6</th>
        <th colspan="2">EXPECTED SH SHEET</th>
        <th colspan="2">FINANCE</th>
        <th colspan="3">NSH INPUT</th>
        <th rowspan="2">PAYOUT</th>
        </tr>
    <tr>
       <th>COLLECTION QUANTITY</th>
       <th>PER TRACTOR</th>
       <th>INCENTIVE
       LAST MONTH</th>
       <th>YTD
       INCENTIVE PT</th>
       <th>COLLECTION
       QUANTITY</th>
       <th>BILLING
       QUANTITY</th>
       <th>PER TRACTOR
       INCENTIVE</th>
      </tr>
    </thead>`;
    data?.forEach((header) => {
      tab_text += collectionData.schemeType === 5?`<tr ><td>${header?.STATE}</td>
      <td>${header?.INSTALLATIONTAREGT}</td>
      <td>${header?.INSPERTARGET}</td>
      <td>${header?.INSTALLATIONLASTYEAR}</td>
      <td>${header?.INCENTIVELASTMONTH}</td>
      <td>${header?.YTDINCENTIVE}</td>
      </tr>`:collectionData.schemeType === 4?`<tr ><td>${header?.STATE}</td>
      <td>${header?.INSTALLATIONTAREGT}</td>
            <td>${header?.UPPERCAP}</td>
      <td>${header?.INSPERTARGET}</td>
      <td>${header?.INSTALLATIONLASTYEAR}</td>
      <td>${header?.INCENTIVELASTMONTH}</td>
      <td>${header?.YTDINCENTIVE}</td>
      </tr>`:collectionData.schemeType === 8?`<tr ><td>${header?.STATE}</td>
      <td>${header?.INSTALLATIONTAREGT}</td>
      <td>${header?.INSPERTARGET}</td>
      <td>${header?.INSTALLATIONLASTYEAR}</td>
      <td>${header?.INCENTIVELASTMONTH}</td>
      <td>${header?.YTDINCENTIVE}</td>
      </tr>`:
      collectionData.schemeType === 3?`<tr ><td>${header?.STATE}</td>
<td>${header?.T1}</td>
<td>${header?.T2}</td>
<td>${header?.T3}</td>
<td>${header?.R1}</td>
<td>${header?.R2}</td>
<td>${header?.R3}</td>
<td>${header?.INSTALLATIONLASTYEAR}</td>
<td>${header?.INCENTIVELASTMONTH}</td>
<td>${header?.YTDINCENTIVE}</td>
</tr>`:`<tr ><td>${header?.STATE}</td>
<td>${header?.T1}</td>
<td>${header?.T2}</td>
<td>${header?.T3}</td>
<td>${header?.T4}</td>
<td>${header?.T5}</td>
<td>${header?.T6}</td>
<td>${header?.R1}</td>
<td>${header?.R2}</td>
<td>${header?.R3}</td>
<td>${header?.R4}</td>
<td>${header?.R5}</td>
<td>${header?.R6}</td>
<td>${header?.ESS_COLLECTION_QUANTITY}</td>
<td>${header?.ESS_PER_TRACTOR}</td>
<td>${header?.ESS_INCENTIVE_LAST_MONTH}</td>
<td>${header?.ESS_YTD_INCENTIVE_PT}</td>
<td>${header?.NSH_INPUT_COLLECTION_QUANTITY}</td>
<td>${header?.NSH_INPUT_BILLING_QUANTITY}</td>
<td>${header?.NSH_INPUT_PER_TRATOR_INCENTIVE}</td>
<td>${header?.PAYOUT}</td>
</tr>`;
    });
// UPPERCAP
    let totals =collectionData.schemeType === 5?{
      INSTALLATIONTAREGT: 0,
      INSPERTARGET: 0,
      INSTALLATIONLASTYEAR: 0,
      INCENTIVELASTMONTH: 0,
      YTDINCENTIVE: 0,
    }:collectionData.schemeType === 4?{
      INSTALLATIONTAREGT: 0,
      UPPERCAP:0,
      INSPERTARGET: 0,
      INSTALLATIONLASTYEAR: 0,
      INCENTIVELASTMONTH: 0,
      YTDINCENTIVE: 0,
    }: collectionData.schemeType === 8?{
      INSTALLATIONTAREGT: 0,
      INSPERTARGET: 0,
      INSTALLATIONLASTYEAR: 0,
      INCENTIVELASTMONTH: 0,
      YTDINCENTIVE: 0,
    }: collectionData.schemeType === 3?{
      T1: 0,
      T2: 0,
      T3: 0,
      R1: 0,
      R2: 0,
      R3: 0,
      INSTALLATIONLASTYEAR: 0,
      INCENTIVELASTMONTH: 0,
      YTDINCENTIVE: 0,
    }:{
      T1: 0,
      T2: 0,
      T3: 0,
      T4: 0,
      T5: 0,
      T6: 0,
      R1: 0,
      R2: 0,
      R3: 0,
      R4: 0,
      R5: 0,
      R6: 0,
      ESS_COLLECTION_QUANTITY: 0,
      ESS_PER_TRACTOR: 0,
      ESS_INCENTIVE_LAST_MONTH: 0,
      ESS_YTD_INCENTIVE_PT: 0,
      
      NSH_INPUT_COLLECTION_QUANTITY: 0,
      NSH_INPUT_BILLING_QUANTITY: 0,
      NSH_INPUT_PER_TRATOR_INCENTIVE: 0,
      PAYOUT: 0,
    };

    data.forEach((item) => {
      for (let key in totals) {
        totals[key] += parseInt(item[key]) || 0;
      }
    });
        const totalValues=dataSource.reduce(
      (
        sum,
        {
          p1,
          p2,
          p3,
          p4,
          p5,
          p6,
          t1,
          t2,
          t3,
          t4,
          t5,
          t6,
        }
      ) => ({

        p1: sum.p1 + parseInt(p1),
        p2: sum.p2 + parseInt(p2),
        p3: sum.p3 + parseInt(p3),
        p4: sum.p4 + parseInt(p4),
        p5: sum.p5 + parseInt(p5),
        p6: sum.p6 + parseInt(p6),
        t1: sum.t1 + parseInt(t1),
        t2: sum.t2 + parseInt(t2),
        t3: sum.t3 + parseInt(t3),
        t4: sum.t4 + parseInt(t4),
        t5: sum.t5 + parseInt(t5),
        t6: sum.t6 + parseInt(t6),
      }),
      {
 
        p1: 0,
        p2: 0,
        p3: 0,
        p4: 0,
        p5: 0,
        p6: 0,
        t1: 0,
        t2: 0,
        t3: 0,
        t4: 0,
        t5: 0,
        t6: 0,
        
      }
    );

    let totalsRow = "<tr><td colspan='1' >Total</td>";
    for (let key in totals) {
      if (key == 'R1') {
        totalsRow += `<td>${Math.round(totalValues?.p1/totalValues?.t1)||0}</td>`;
      }else if (key == 'R2') {
        totalsRow += `<td>${Math.round(totalValues?.p2/totalValues?.t2)||0}</td>`;
      }else if (key == 'R3') {
        totalsRow += `<td>${Math.round(totalValues?.p3/totalValues?.t3)||0}</td>`;
      }else if (key == 'R4') {
        totalsRow += `<td>${Math.round(totalValues?.p4/totalValues?.t4)||0}</td>`;
      }else if (key == 'R5') {
        totalsRow += `<td>${Math.round(totalValues?.p5/totalValues?.t5)||0}</td>`;
      }else if (key == 'R6') {
        totalsRow += `<td>${Math.round(totalValues?.p6/totalValues?.t6)||0}</td>`;
      }else if (key != 'reason') {
        totalsRow += `<td>${totals[key]}</td>`;
      } else {
        totalsRow += `<td></td>`;
      }
    }

    totalsRow += "</tr>";
    tab_text += totalsRow;
    tab_text += "</table>";

    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    document.body.removeChild(elt);

    // Add style to total row color
    const allColumns = dataSource.reduce((columns, item) => {
      Object.keys(item).forEach(key => {
        if (!columns.includes(key)) {
          columns.push(key);
        }
      });
      return columns;
    }, []);

    function getAlphabetEquivalent(number) {

      if (number < 1) {
        return "Invalid input";
      } else if (number <= 26) {
        return String.fromCharCode(number + 64);
      } else {
        let firstChar = String.fromCharCode(((number - 1) / 26) + 64);
        let secondChar = String.fromCharCode(((number - 1) % 26) + 65);
        return firstChar + secondChar;
      }

    }

    let excelArr = []
    for (let index = 0; index < allColumns.length; index++) {
      let ele = `${getAlphabetEquivalent(index + 1)}${(dataSource.length + 3).toString()}`
      excelArr.push(ele);

    }
    Object.keys(wb.Sheets[wb.SheetNames[0]]).filter(cell =>
      excelArr
        .includes(cell)).forEach(cell => {
          wb.Sheets[wb.SheetNames[0]][cell].s = TotalStyle;
        });
    let nameFile = "One_Month_Scheme.xlsx"
    // generate file
    XLSX.writeFile(wb, nameFile);
  }

  const tableHeader = (d) => {
    return (
      <Row className="table_sub_buttons">
        <Col>
          <Button
            disabled={dataSource?.length == 0}
            icon={<Download />}
            type="text"
            onClick={handleDownload}
          >
            Download
          </Button>
        </Col>
      </Row>
    );
  };

  const hasSelected = selectedRowKeys.length > 0;
  const disabledDate = (current) => {
    const today = dayjs().year(dateValue - 1);
    const currentYear = today.year();
    const startFinancialYear = new Date(currentYear, 3, 1);
    const endFinancialYear = new Date(currentYear + 1, 2, 31)
    const currentDate = current instanceof Date ? current : new Date(current);
    return currentDate < startFinancialYear || currentDate > endFinancialYear;
  };

  return (
    <div className="table_scheme_wrap onemoth-date-selector">
      <Divider />
      <grid>
        <Row>
          <Col flex="auto">
            <h2 loading={loading} style={{ fontSize: "18px", padding: "10px" }}>
              One Month Scheme
            </h2>
          </Col>

          <Col style={{ display: "flex", alignItems: "center" }}>
            {/* {(userRole!="ZADM")&&     */}
            <Col className="calendar_zonalHeadOneMonth">
              <Tooltip title={"Scheme Type"}>
                <Dropdown menu={{ items: subColType }}>
                  <Button style={{ width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    <Space>
                      {getSchemeType(collectionData?.schemeType)}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Tooltip>
            </Col>

            <div className={"calendar_zonalHeadOneMonth"} style={{ paddingLeft: "0 !important" }}>
              <Select
                showSearch
                placeholder="Select State"
                optionFilterProp="children"
                onSelect={handleSelect}
                defaultValue={0}
              >
                <Option key={0} value={0}>
                  All States
                </Option>
                {stateListArr?.map((option) => (
                  <Option key={option.stateID} value={option.stateName}>
                    {option.stateName}
                  </Option>
                ))}
              </Select>
            </div>
            {/* } */}
            <DatePicker
              mode="month"
              onChange={(date, dateString) => {
                setCollectionData({
                  ...collectionData,
                  startDate: date == null ? "" : date.format(),
                });
              }}
              placeholder="Start month"
              className="start h-100"
              picker="month"
              disabledDate={disabledDate}
            // onPanelChange={(value)=>setCollectionData(({
            //   ...collectionData,
            //   startDate: value == null ? "" : value.format(),
            //   }))}
            // value ={startMonth}
            />
            {/* 
            <ThemeButton
              showDealer={false}
              showSchemeType={false}
              showStartMonth={true}
              showEndMonth={false}
              collectionData={collectionData}
              listData={[]}
              disableStartYear={disabledDate}
              onSelectSchemeType={(schemeType) =>
                setCollectionData({ ...collectionData, schemeType })
              }
              handleStartChange={(date) => {
                setCollectionData({
                  ...collectionData,
                  startDate: date == null ? "" : date.format(),
                });
              }}
              handleEndChange={(date) =>
                setCollectionData({
                  ...collectionData,
                  endDate: date == null ? "" : date.format(),
                })
              }
              
              onSelectDealer={(dealerCode, currentSelected) => { }}
              onGetDealerDropdownData={() => { }}
              handleDateRange={(date, formatDate) =>
                setCollectionData({
                  ...collectionData,
                  startDate: date && date[0] ? date[0].format() : "",
                  endDate: date && date[1] ? date[1].format() : "",
                })
              }
            /> */}

            <Button className="mx-1 h-full"
              onClick={handleDownload} disabled={dataSource?.length != 0 ? false : true}>Download</Button>

            {/* <Col>
          <Button
            disabled={dataSource?.length == 0}
            icon={<Download />}
            type="text"
            onClick={handleDownload}
          >
            Download
          </Button>
        </Col> */}

          </Col>
        </Row>

        <span>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </grid>

      <Form>

        <Table
          loading={loadingNhs == "pending" ? true : false}
          rowClassName={() => "editable-row"}
          // title={tableHeader}
          summary={(pageData) => {
            const fieldSum = {
              b: 0,
              i: 0,
              c: 0,
              totalR1: 0,
              totalR2: 0,
              totalR3: 0,
              totalR4: 0,
              totalR5: 0,
              totalR6: 0,
              totalT1: 0,
              totalT2: 0,
              totalT3: 0,
              totalT4: 0,
              totalT5: 0,
              totalT6: 0,
              totalP1: 0,
              totalP2: 0,
              totalP3: 0,
              totalP4: 0,
              totalP5: 0,
              totalP6: 0,
              SHcollectionQuantity: 0,
              NSHcollectionQuantity: 0,
              perTractor: 0,
              totalcollIncentiveLastyear: 0,
              totalinstallationLastyear: 0,
              totalIncentivelastmonth: 0,
              totalYTDIncentivePT: 0,
              totalbillingQty: 0,
              totalperTractorBilling: 0,
              totalpayout: 0,
              T2_T1: 0,
              T3_T2: 0,
              T4_T3: 0,
              T5_T4: 0,
              T6_T5: 0,
              vol: 0,
              perTr: 0,
              totalAmount: 0,
              actualCollection: 0,
              actualBillingAmount: 0,
              actualBillingOty: 0,
              billingFactor: 0,
              amount: 0,
              totalinsPerTarget: 0,
              totalinstallationTarget: 0,
              totalupperCap: 0,
            };

            pageData.forEach(

              ({
                t1,
                t2,
                t3,
                t4,
                t5,
                t6,
                r1,
                r2,
                r3,
                r4,
                r5,
                r6,
                p1,
                p2,
                p3,
                p4,
                p5,
                p6,
                collectionQuantity,
                perTr,
                collIncentiveLastyear,
                installationLastyear,
                incentivelastmonth,
                ytdIncentivePT,
                collectionQty,
                billingQty,
                perTractorBilling,
                payout,
                BooksCollectionTargetT2T1,
                BooksCollectionTargetT3T2,
                BooksCollectionTargetT4T3,
                BooksCollectionTargetT5T4,
                BooksCollectionTargetT6T5,
                TEPvol,
                actualCollection,
                actualBillingAmount,
                actualBillingOty,
                actualBillingQuantity,
                billingFactor,
                amount, // this is used just calculate the value of per tractor
                TEPpertr,
                volume,
                insPerTarget,
                installationTarget,
                upperCap
              }) => {
                fieldSum.totalR1 += r1 ? parseInt(r1) : 0;
                fieldSum.totalR2 += r2 ? parseInt(r2) : 0;
                fieldSum.totalR3 += r3 ? parseInt(r3) : 0;
                fieldSum.totalR4 += r4 ? parseInt(r4) : 0;
                fieldSum.totalR5 += r5 ? parseInt(r5) : 0;
                fieldSum.totalR6 += r6 ? parseInt(r6) : 0;
                // fieldSum.totalAmount += TEPamount ? parseInt(TEPamount) : 0;
                //
                fieldSum.totalT1 += t1 ? parseInt(t1) : 0;
                fieldSum.totalT2 += t2 ? parseInt(t2) : 0;
                fieldSum.totalT3 += t3 ? parseInt(t3) : 0;
                fieldSum.totalT4 += t4 ? parseInt(t4) : 0;
                fieldSum.totalT5 += t5 ? parseInt(t5) : 0;
                fieldSum.totalT6 += t6 ? parseInt(t6) : 0;
                fieldSum.totalP1 += p1 ? parseInt(p1) : 0;
                fieldSum.totalP2 += p2 ? parseInt(p2) : 0;
                fieldSum.totalP3 += p3 ? parseInt(p3) : 0;
                fieldSum.totalP4 += p4 ? parseInt(p4) : 0;
                fieldSum.totalP5 += p5 ? parseInt(p5) : 0;
                fieldSum.totalP6 += p6 ? parseInt(p6) : 0;
                fieldSum.SHcollectionQuantity += volume ? parseInt(volume) : "";
                fieldSum.perTractor += perTr ? parseInt(perTr) : "";
                fieldSum.totalIncentivelastmonth += incentivelastmonth ? parseInt(incentivelastmonth) : 0;
                fieldSum.totalinstallationLastyear += installationLastyear ? parseInt(installationLastyear) : 0;
                fieldSum.totalcollIncentiveLastyear += collIncentiveLastyear ? parseInt(collIncentiveLastyear) : 0;
                fieldSum.totalYTDIncentivePT += ytdIncentivePT ? parseInt(ytdIncentivePT) : 0;
                fieldSum.NSHcollectionQuantity += collectionQty ? parseInt(collectionQty) : 0;
                fieldSum.totalbillingQty += billingQty ? parseInt(billingQty) : 0;
                fieldSum.totalperTractorBilling += perTractorBilling ? parseInt(perTractorBilling) : 0;
                fieldSum.totalpayout += payout ? parseInt(payout) : 0;
                fieldSum.amount += amount ? parseInt(amount) : "";
                fieldSum.T2_T1 += BooksCollectionTargetT2T1
                  ? parseInt(BooksCollectionTargetT2T1)
                  : 0;
                fieldSum.T3_T2 += BooksCollectionTargetT3T2
                  ? parseInt(BooksCollectionTargetT3T2)
                  : 0;
                fieldSum.T4_T3 += BooksCollectionTargetT4T3
                  ? parseInt(BooksCollectionTargetT4T3)
                  : 0;
                fieldSum.T5_T4 += BooksCollectionTargetT5T4
                  ? parseInt(BooksCollectionTargetT5T4)
                  : 0;
                fieldSum.T6_T5 += BooksCollectionTargetT6T5
                  ? parseInt(BooksCollectionTargetT6T5)
                  : 0;
                fieldSum.vol += TEPvol ? parseInt(TEPvol) : 0;
                fieldSum.perTr = parseInt(fieldSum.totalAmount / fieldSum.vol);
                fieldSum.actualCollection += actualCollection
                  ? parseInt(actualCollection)
                  : 0;
                fieldSum.actualBillingAmount += actualBillingAmount
                  ? parseInt(actualBillingAmount)
                  : 0;
                fieldSum.actualBillingOty += actualBillingQuantity
                  ? parseInt(actualBillingQuantity)
                  : 0;
                fieldSum.billingFactor += billingFactor
                  ? parseInt(billingFactor)
                  : 0;
                fieldSum.collectionQuantity += collectionQuantity
                  ? parseInt(collectionQuantity)
                  : 0;
                fieldSum.totalinsPerTarget += insPerTarget ? parseInt(insPerTarget) : "";
                fieldSum.totalinstallationTarget += installationTarget ? parseInt(installationTarget) : "";
                fieldSum.totalupperCap += upperCap ? parseInt(upperCap) : "";
              }
            );
 
            // R=(P1/T1)
            //Collection Schemes
            if (collectionData.schemeType == 6 || collectionData.schemeType == "") {
              return (
                <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
                  <Table.Summary.Cell index={1} fixed>Total</Table.Summary.Cell>
                  <Table.Summary.Cell  index={2}>{fieldSum.totalT1 !== null && fieldSum.totalT1 !== 0 ? TextLocalString(fieldSum.totalT1) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={3}>{fieldSum.totalT2 !== null && fieldSum.totalT2 !== 0 ? TextLocalString(fieldSum.totalT2) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={4}>{fieldSum.totalT3 !== null && fieldSum.totalT3 !== 0 ? TextLocalString(fieldSum.totalT3) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={5}>{fieldSum.totalT4 !== null && fieldSum.totalT4 !== 0 ? TextLocalString(fieldSum.totalT4) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={6}>{fieldSum.totalT5 !== null && fieldSum.totalT5 !== 0 ? TextLocalString(fieldSum.totalT5) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={7}>{fieldSum.totalT6 !== null && fieldSum.totalT6 !== 0 ? TextLocalString(fieldSum.totalT6) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={8}>{(fieldSum.totalP1/fieldSum.totalT1)?TextLocalString(Math.round(fieldSum.totalP1/fieldSum.totalT1)) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={9}>{(fieldSum.totalP2/fieldSum.totalT2)?TextLocalString(Math.round(fieldSum.totalP2/fieldSum.totalT2)) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={10}>{(fieldSum.totalP3/fieldSum.totalT3)?TextLocalString(Math.round(fieldSum.totalP3/fieldSum.totalT3)) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={11}>{(fieldSum.totalP4/fieldSum.totalT4)?TextLocalString(Math.round(fieldSum.totalP4/fieldSum.totalT4)) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={12}>{(fieldSum.totalP5/fieldSum.totalT5)?TextLocalString(Math.round(fieldSum.totalP5/fieldSum.totalT5)) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={13}>{(fieldSum.totalP6/fieldSum.totalT6)?TextLocalString(Math.round(fieldSum.totalP6/fieldSum.totalT6)) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={14}>{fieldSum.SHcollectionQuantity !== null && fieldSum.SHcollectionQuantity !== 0 ? TextLocalString(fieldSum.SHcollectionQuantity) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={15}>{fieldSum.perTractor !== null && fieldSum.perTractor !== 0 ? TextLocalString(fieldSum.perTractor/pageData?.length) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={15}>{fieldSum.totalcollIncentiveLastyear !== null && fieldSum.totalcollIncentiveLastyear !== 0 ? TextLocalString(fieldSum.totalcollIncentiveLastyear) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={16}>{fieldSum.totalIncentivelastmonth !== null && fieldSum.totalIncentivelastmonth !== 0 ? TextLocalString(fieldSum.totalIncentivelastmonth) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={17}>{fieldSum.totalYTDIncentivePT !== null && fieldSum.totalYTDIncentivePT !== 0 ? TextLocalString(fieldSum.totalYTDIncentivePT) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={18}>{fieldSum.NSHcollectionQuantity !== null && fieldSum.NSHcollectionQuantity !== 0 ? TextLocalString(fieldSum.NSHcollectionQuantity) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={19}>{fieldSum.totalbillingQty !== null && fieldSum.totalbillingQty !== 0 ? TextLocalString(fieldSum.totalbillingQty) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={20}>{fieldSum.totalperTractorBilling !== null && fieldSum.totalperTractorBilling !== 0 ? TextLocalString(fieldSum.totalperTractorBilling) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell  index={21}>{fieldSum.totalpayout !== null && fieldSum.totalpayout !== 0 ? TextLocalString(fieldSum.totalpayout) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell index={22}></Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }
            //Slab Based
            else if (collectionData.schemeType === 3) {
              return (
                <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={2}>{fieldSum.totalT1 !== null && fieldSum.totalT1 !== 0 ? TextLocalString(fieldSum.totalT1) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={3}>{fieldSum.totalT2 !== null && fieldSum.totalT2 !== 0 ? TextLocalString(fieldSum.totalT2) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={4}>{fieldSum.totalT3 !== null && fieldSum.totalT3 !== 0 ? TextLocalString(fieldSum.totalT3) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={5}>{(fieldSum.totalP1/fieldSum.totalT1)?TextLocalString(Math.round(fieldSum.totalP1/fieldSum.totalT1)) : ""} </Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={6}>{(fieldSum.totalP2/fieldSum.totalT2)?TextLocalString(Math.round(fieldSum.totalP2/fieldSum.totalT2)) : ""} </Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={7}>{(fieldSum.totalP3/fieldSum.totalT3)?TextLocalString(Math.round(fieldSum.totalP3/fieldSum.totalT3)) : ""}   </Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={8}>{fieldSum.totalinstallationLastyear !== null && fieldSum.totalinstallationLastyear !== 0 ? TextLocalString(fieldSum.totalinstallationLastyear) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={9}>{fieldSum.totalIncentivelastmonth !== null && fieldSum.totalIncentivelastmonth !== 0 ? TextLocalString(fieldSum.totalIncentivelastmonth) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={10}>{fieldSum.totalYTDIncentivePT !== null && fieldSum.totalYTDIncentivePT !== 0 ? TextLocalString(fieldSum.totalYTDIncentivePT) : ""}</Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }
            //Single Target With Upper Case
            else if (collectionData.schemeType === 4) {
              return (
                <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={2}>{fieldSum.totalinstallationTarget !== null && fieldSum.totalinstallationTarget !== 0 ? TextLocalString(fieldSum.totalinstallationTarget) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={3}>{fieldSum.totalupperCap !== null && fieldSum.totalupperCap !== 0 ? TextLocalString(fieldSum.totalupperCap) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={4}>{fieldSum.totalinsPerTarget !== null && fieldSum.totalinsPerTarget !== 0 ? TextLocalString(fieldSum.totalinsPerTarget) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={5}>{fieldSum.totalinstallationLastyear !== null && fieldSum.totalinstallationLastyear !== 0 ? TextLocalString(fieldSum.totalinstallationLastyear) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={6}>{fieldSum.totalIncentivelastmonth !== null && fieldSum.totalIncentivelastmonth !== 0 ? TextLocalString(fieldSum.totalIncentivelastmonth) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={7}>{fieldSum.totalYTDIncentivePT !== null && fieldSum.totalYTDIncentivePT !== 0 ? TextLocalString(fieldSum.totalYTDIncentivePT) : ""}</Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }
            //UnConditional
            else if (collectionData.schemeType === 5) {
              return (
                <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={2}>{fieldSum.totalinstallationTarget !== null && fieldSum.totalinstallationTarget !== 0 ? TextLocalString(fieldSum.totalinstallationTarget) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={3}>{fieldSum.totalinsPerTarget !== null && fieldSum.totalinsPerTarget !== 0 ? TextLocalString(fieldSum.totalinsPerTarget) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={4}>{fieldSum.totalinstallationLastyear !== null && fieldSum.totalinstallationLastyear !== 0 ? TextLocalString(fieldSum.totalinstallationLastyear) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={5}>{fieldSum.totalIncentivelastmonth !== null && fieldSum.totalIncentivelastmonth !== 0 ? TextLocalString(fieldSum.totalIncentivelastmonth) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={6}>{fieldSum.totalYTDIncentivePT !== null && fieldSum.totalYTDIncentivePT !== 0 ? TextLocalString(fieldSum.totalYTDIncentivePT) : ""}</Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }
            //Single target
            else if (collectionData.schemeType === 8) {
              return (
                <Table.Summary.Row style={{ fontWeight: "500", fontSize: "18px" }}>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={2}>{fieldSum.totalinstallationTarget !== null && fieldSum.totalinstallationTarget !== 0 ? TextLocalString(fieldSum.totalinstallationTarget) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={3}>{fieldSum.totalinsPerTarget !== null && fieldSum.totalinsPerTarget !== 0 ? TextLocalString(fieldSum.totalinsPerTarget) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={4}>{fieldSum.totalinstallationLastyear !== null && fieldSum.totalinstallationLastyear !== 0 ? TextLocalString(fieldSum.totalinstallationLastyear) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={5}>{fieldSum.totalIncentivelastmonth !== null && fieldSum.totalIncentivelastmonth !== 0 ? TextLocalString(fieldSum.totalIncentivelastmonth) : ""}</Table.Summary.Cell>
                  <Table.Summary.Cell fixed index={6}>{fieldSum.totalYTDIncentivePT !== null && fieldSum.totalYTDIncentivePT !== 0 ? TextLocalString(fieldSum.totalYTDIncentivePT) : ""}</Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }
          }}
          columns={columns(collectionData.schemeType)}
          dataSource={dataSource}
          pagination={false}
          bordered
          scroll={{
            x: 1000,
            y: 200,
          }}

        />
      </Form>
      {userRole == "NSH" ? (
        <Row
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Col>
            <Button
              type="default"
              shape="round"
              className="schemi-btn-all"
              style={{
                margin: "10px",
                background: "#FCE838",
                fontWeight: "700",
                textTransform: "uppercase",
                border: "none",
              }}
              size="large"
              onClick={handleConfirmationModal}
            >
              Save & Submit
            </Button>
          </Col>
        </Row>
      ) : null}


      <Pagination
        defaultCurrent={1}
        className='paginationDesign'
        current={collectionData.pageNumber}
        onChange={(pageNumber, pageSize) =>
          setCollectionData({ ...collectionData, pageNumber, pageSize })
        }
        total={nhsDashboardDetails?.totalCount}
        showSizeChanger
        pageSize={collectionData.pageSize}
          pageSizeOptions={['150', '250', '350']}
      />
      <StatusModal
        open={openConfirmationModal}
        onOk={() => handleConfirmationModal(true)}
        onCancel={() => handleConfirmationModal(false)}
      >
        <p>Are you sure you want to save</p>
      </StatusModal>
      {/* <grid>
      <Row style={{display: "flex", alignContent: 'center', justifyContent: 'flex-end', alignItems: "center"}}>
        <Col ><Button type="default" shape="round" style={{ margin: "10px",background:"#FCE838", fontWeight:'700', textTransform: "uppercase", border: 'none',
          padding: "20px",
          }} size="large" onClick={submitData}>Save & Submit</Button> </Col>
      </Row>
      </grid> */}
    </div>
  );
};
export default OneMonthScheme;
