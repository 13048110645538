import React, { useEffect, useState } from "react";
import { Table, Divider, Col, Row, Form, Pagination, Button, DatePicker } from "antd";
import ThemeButton from "../../../components/button/buttons";
import axiosAPI from "../../../services/axiosApi";
import { getCurrentFiscalYear, getCurrentFiscalYear1, getCurrentFiscalYearCurrent, getCurrentFiscalYearShow } from "../../../utils/dateFormatter";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { getDashboardCOSMStateWiseReportApi } from "../../../redux/dashboard/dashboardDataSlice";
import { ReactComponent as Download } from "../../../assets/vector/download.svg";
import XLSX from "xlsx-js-style";
import { decryptData } from "../../../utils/localStorageEncodeDecode";
import { render } from "@testing-library/react";
import { TextLocalString } from "../../../components/ToastifyShow";


const InitCollectionData = {
  currentYear: dayjs().year(),
  // collectionType: 'Collection',
  schemeType: "",
  dealerCode: [],
  startDate: "",
  endDate: "",
  pageSize: 150,
  pageNumber: 1,
};

const StateWiseScheme = ({ dateValue }) => {
  const [collectionData, setCollectionData] = useState(InitCollectionData);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStateId, setSelectedStateId] = useState(0);
  const { dashboardCOSMStateWiseReport, loadingCosmstateWise } = useSelector(
    (state) => state?.dashboardDetails
  );
  const [dataSource, setDataSource] = useState([]);
  const { stateList, loadingState } = useSelector((state) => state.stateData);
  const dispatch = useDispatch();
  const { startDate, endDate } = collectionData;
  const { Option } = Select;

  const currentFiscalYear = getCurrentFiscalYearCurrent(dateValue ? dayjs().year(dateValue) : collectionData?.startDate ? collectionData?.startDate : new Date());

  useEffect(() => {
    setCollectionData({
      ...collectionData,
      startDate: "",
    });
    setDataSource([])
  }, [currentFiscalYear])
  const [stateListArr, setStateListArr] = useState();
  useEffect(() => {
    if (Array?.isArray(stateList?.data) && stateList?.data?.length != 0) {
      setStateListArr(stateList?.data);
    } else {
      setStateListArr([]);
    }
  }, [stateList]);
  const handleSelect = (value, option) => {
    const id = option.key;
    setSelectedStateId(parseInt(id));
  };
  const selectedFirstMonth = new Date(startDate);
  const selectedFirstMonthString = selectedFirstMonth.toLocaleString("en-IN", {
    year: "2-digit",
    month: "short",
  });

  // Columns for table
  const columns = [
    {
      title: "State",
      dataIndex: "stateName",
      fixed: "left",
      width: "180px",
      render(text, record) {
        return {
          props: {
            style: { background: text ? "" : "#f2f2f2",fontWeight:  text ? "400" : "500", }
          },
          children: <div>{text ? text : "WITHOUT INSTITUTIONAL TOTAL"}</div>
        };
      }
    },
    {
      title: `${selectedFirstMonthString == "Invalid Date"
          ? "Month"
          : selectedFirstMonthString
        }`,
      dataIndex: "",
      children: [
        {
          title: " Billing Qty",
          dataIndex: "",
          children: [
            {
              title: "Total",
              dataIndex: "totalQuantity",
              width: "120px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Institutional Qty",
              dataIndex: "institutionalQty",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Net of Institutional Qty",
              dataIndex: "netInstitutionalQty",
              width: "250px",
              render:(x)=>TextLocalString(x)
            },
          ],
        },
        {
          title: "Amt",
          dataIndex: "",
          children: [
            {
              title: "Coll Incentive",
              dataIndex: "collIncentiveAmt",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Installation",
              dataIndex: "installationAmt",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Price support",
              dataIndex: "priceSupportAmt",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "BTL",
              dataIndex: "bTLAmt",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Total",
              dataIndex: "totalAmt",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
          ],
        },
        {
          title: "Amt Per Tractor",
          dataIndex: "",
          children: [
            {
              title: "Coll Incentive",
              dataIndex: "collIncentiveAmtPerTr",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Installation",
              dataIndex: "installationAmtPerTr",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Price support",
              dataIndex: "priceSupportAmtPerTr",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "BTL",
              dataIndex: "bTLAmtPerTr",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Total",
              dataIndex: "totalAmtPerTr",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
          ],
        },
      ],
    },
    {
      title: `April ${dayjs().year(dateValue - 1).format("YY")||(dayjs(new Date(collectionData?.startDate))).format("YY")} - ${selectedFirstMonthString == "Invalid Date"
          ? " Month"
          : selectedFirstMonthString
        }`,
      dataIndex: "",
      children: [
        {
          title: "Billing Qty",
          dataIndex: "",
          children: [
            {
              title: "Total",
              dataIndex: "yTDTotalQuantity",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Institutional Qty",
              dataIndex: "yTDInstitutionalQty",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Net of Institutional Qty",
              dataIndex: "yTDNetInstitutionalQty",
              width: "250px",
              render:(x)=>TextLocalString(x)
            },
          ],
        },
        {
          title: "Amt",
          dataIndex: "",
          children: [

            {
              title: "Coll Incentive",
              dataIndex: "yTDCollIncentiveAmt",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Installation",
              dataIndex: "yTDInstallationAmt",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Price support",
              dataIndex: "yTDPriceSupportAmt",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "BTL",
              dataIndex: "yTDBTLAmt",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Total",
              dataIndex: "yTDTotalAmt",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
          ],
        },
        {
          title: "Amt Per Tractor",
          dataIndex: "",
          children: [
            {
              title: "Coll Incentive",
              dataIndex: "yTDCollIncentiveAmtPerTr",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Installation",
              dataIndex: "yTDInstallationAmtPerTr",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Price support",
              dataIndex: "yTDPriceSupportAmtPerTr",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "BTL",
              dataIndex: "yTDBTLAmtPerTr",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
            {
              title: "Total",
              dataIndex: "yTDTotalAmtPerTr",
              width: "150px",
              render:(x)=>TextLocalString(x)
            },
          ],
        },
      ],
    },
  ];

  const userData = decryptData(sessionStorage.getItem("userInfo"));
  const userId = userData.userId;

  useEffect(() => {
    if (!collectionData.startDate) {
      return;
    }
    const payload = {
      pageSize: collectionData.pageSize,
      pageNumber: collectionData.pageNumber,
      fyear: currentFiscalYear,
      userid: userId,
      month: collectionData.startDate
        ? new Date(collectionData.startDate).getMonth() + 1
        : 0,
      monthEnd: collectionData.endDate
        ? new Date(collectionData.endDate).getMonth() + 1
        : new Date(collectionData.startDate).getMonth() + 1,
      stateId: selectedStateId || 0,
    };
    const fetchData = async () => {
      await dispatch(getDashboardCOSMStateWiseReportApi(payload));
    };
    fetchData();
  }, [collectionData, selectedStateId]);
  console.log(collectionData,"collectionData")
  useEffect(() => {
    if (
      dashboardCOSMStateWiseReport?.listitems &&
      dashboardCOSMStateWiseReport?.listitems?.length != 0
    ) {
   
      let data1=dashboardCOSMStateWiseReport?.listitems?.length==1?dashboardCOSMStateWiseReport?.listitems:[]
      data1 = data1.map(obj => {
        const { stateName, ...rest } = obj; // Use destructuring to exclude the key
        return rest;
      });
      setDataSource(dashboardCOSMStateWiseReport?.listitems?.length==1?dashboardCOSMStateWiseReport?.listitems?.concat(data1):dashboardCOSMStateWiseReport?.listitems);
    } else {
      setDataSource([]);
    }
  }, [dashboardCOSMStateWiseReport]);

  const hasSelected = selectedRowKeys.length > 0;





  const handleDownload = () => {
    let tab_text = `<table class="custom-table">
    <thead >
    <tr >
        <th rowspan="3">STATE</th>
        <th colspan="13">${selectedFirstMonthString}</th>
        <th colspan="13">APRIL ${new Date().getFullYear()} -${selectedFirstMonthString}</th>
        </tr>
    <tr>
       <th colspan="3">QTY</th>
       <th colspan="10">AMT PER TRACTOR</th>
       <th colspan="3">QTY</th>
       <th colspan="5">AMT</th>
       <th colspan="5">AMT PER TRACTOR</th>
      </tr>
      <tr>
      <th >TOTAL</th>
      <th>INSTITUTIONAL QTY</th>
      <th >NET OF INSTITUTIONAL QTY</th>
      <th >COLL INCENTIVE</th>
      <th >INSTALLATION</th>

      <th >PRICE SUPPORT</th>
      <th>BTL</th>
      <th >TOTAL</th>
      <th >COLL INCENTIVE</th>
      <th >INSTALLATION</th>
      <th >PRICE SUPPORT</th>
      <th>BTL</th>
      <th >TOTAL</th>
      <th >TOTAL</th>
      <th >INSTITUTIONAL QTY</th>
      <th >NET OF INSTITUTIONAL QTY</th>
      <th >COLL INCENTIVE</th>
      <th >INSTALLATION</th>
      <th >PRICE SUPPORT</th>

      <th>BTL</th>
      <th >TOTAL</th>
      <th >COLL INCENTIVE</th>
      <th >INSTALLATION</th>
      <th >PRICE SUPPORT</th>
      <th>BTL</th>
      <th >TOTAL</th>
     </tr>
    </thead>`;
    {
      (dataSource?.length != 0) &&
        dataSource.forEach((header) => {
          tab_text += `<tr >
          <td>${header?.stateName || "WITHOUT INSTITUTIONAL TOTAL"}</td>
          <td>${header?.totalQuantity}</td>
    <td>${header?.institutionalQty}</td>
    <td>${header?.netInstitutionalQty || ""}</td>
    <td>${header?.collIncentiveAmt}</td>
    <td>${header?.installationAmt}</td>
    <td>${header?.priceSupportAmt || ""}</td>
    <td>${header?.bTLAmt || ""}</td>
    <td>${header?.totalAmt}</td>
    <td>${header?.collIncentiveAmtPerTr}</td>
    <td>${header?.installationAmtPerTr}</td>
    <td>${header?.priceSupportAmtPerTr}</td>
    <td>${header?.bTLAmtPerTr || 0}</td>
      <td>${header?.totalAmtPerTr}</td>
      <td>${header?.yTDTotalQuantity}</td>
      <td>${header?.yTDInstitutionalQty}</td>
      <td>${header?.yTDNetInstitutionalQty || ""}</td>
      <td>${header?.yTDCollIncentiveAmt}</td>
      <td>${header?.yTDInstallationAmt}</td>
      <td>${header?.yTDPriceSupportAmt}</td>
      <td>${header?.yTDBTLAmt}</td>
      <td>${header?.yTDTotalAmt || 0}</td>
        <td>${header?.yTDCollIncentiveAmtPerTr}</td>
        <td>${header?.yTDInstallationAmtPerTr}</td>
        <td>${header?.yTDPriceSupportAmtPerTr}</td>
        <td>${header?.yTDBTLAmtPerTr || ""}</td>
        <td>${header?.yTDTotalAmtPerTr || ""}</td>
    </tr>`;
        });
    }

    const totalData = dataSource?.filter((data) => !data?.stateName)?.[0] || {}

tab_text += `<tr >

        <td>WITH INSTITUTIONAL TOTAL</td>
        <td>${TextLocalString(totalData?.totalQuantity||"",true)}</td>
        <td>${TextLocalString(totalData?.institutionalQty||"",true)}</td>
        <td>${TextLocalString(totalData?.netInstitutionalQty||"",true)}</td>
        <td>${TextLocalString(totalData?.collIncentiveAmt||"",true)}</td>
        <td>${TextLocalString(totalData?.installationAmt||"",true)}</td>
        <td>${TextLocalString(totalData?.priceSupportAmt||"",true)}</td>
        <td>${TextLocalString(totalData?.bTLAmt||"",true)}</td>
        <td>${TextLocalString(totalData?.yTDTotalAmt||"",true)}</td>
        <td>${TextLocalString(Math.round((totalData?.collIncentiveAmt / totalData?.totalQuantity)||""),true)}</td>
        <td>${TextLocalString(Math.round((totalData?.installationAmt / totalData?.totalQuantity) ||""),true)}</td>
        <td>${TextLocalString(Math.round((totalData?.priceSupportAmt / totalData?.totalQuantity) ||""),true)}</td>
        <td>${TextLocalString(Math.round((totalData?.bTLAmt / totalData?.totalQuantity) ||""),true)}</td>
       <td>${TextLocalString(Math.round((totalData?.totalAmt / totalData?.totalQuantity) ||""),true)}</td>
        <td>${TextLocalString(totalData?.yTDTotalQuantity,true)||""}</td>
        <td></td>
        <td></td>
        <td>${TextLocalString(totalData?.yTDCollIncentiveAmt,true)||""}</td>
        <td>${TextLocalString(totalData?.yTDInstallationAmt,true)||""}</td>
        <td>${TextLocalString(totalData?.yTDPriceSupportAmt,true)||""}</td>
        <td>${TextLocalString(totalData?.yTDBTLAmt,true)||""}</td>
        <td>${TextLocalString(totalData?.yTDTotalAmt,true)||""}</td>
        <td>${TextLocalString(Math.round((totalData?.yTDCollIncentiveAmt / totalData?.yTDTotalQuantity) || ""),true)}</td>
       <td>${TextLocalString(Math.round((totalData?.yTDInstallationAmt / totalData?.yTDTotalQuantity) || ""),true)}</td>
        <td>${TextLocalString(Math.round((totalData?.yTDPriceSupportAmt / totalData?.totalQuantity) || ""),true)}</td>
        <td>${TextLocalString(Math.round((totalData?.yTDBTLAmt / totalData?.totalQuantity) || ""),true)}</td>
        <td>${TextLocalString(Math.round((totalData?.yTDTotalAmt / totalData?.totalQuantity) || ""),true)}</td>
  </tr>`;
  
    tab_text += "</table>";
    var elt = document.createElement("div");
    elt.innerHTML = tab_text;
    document.body.appendChild(elt);

    // Apply styles to the table using a class
    elt.getElementsByClassName("custom-table")[0].style.width = "100%";

    // generate workbook with XLSXStyle
    var tbl = elt.getElementsByTagName("TABLE")[0];
    var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    wb.Sheets[wb.SheetNames[0]]['!cols'] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    // var wb = XLSX.utils.table_to_book(tbl, { cellStyles: true });
    document.body.removeChild(elt);
    let nameFile = "state_wise_report.xlsx";
    // generate file
    XLSX.writeFile(wb, nameFile);
  };
  const disabledDate = (current) => {
    const today = dayjs().year(dateValue - 1);
    const currentYear = today.year();

    // Calculate the start date of the current financial year (April of the current year)
    const startFinancialYear = new Date(currentYear, 3, 1); // Months are zero-based, so April is 3

    // Calculate the end date of the current financial year (March of the next year)
    const endFinancialYear = new Date(currentYear + 1, 2, 31); // March is 2

    // Convert current date to Date object if it's not already
    const currentDate = current instanceof Date ? current : new Date(current);

    // Disable dates that are outside the current financial year
    return currentDate < startFinancialYear || currentDate > endFinancialYear;
  };

  const tableHeader = (d) => {
    return (
      <Row className="table_sub_buttons">
        <Col>
          <Button
            disabled={dataSource?.length == 0}
            icon={<Download />}
            type="text"
            onClick={handleDownload}
          >
            Download
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <div className="table_scheme_wrap spend-box-row onemoth-date-selector ">
      <Divider />
      <grid>
        <Row>
          <Col flex="auto">
            <h2 loading={loading} style={{ fontSize: "18px", padding: "10px" }}>
              State Wise Spend Report
            </h2>
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }}>
            <div className={"calendar_zonalHead"}>
              <Select
                showSearch
                placeholder="Select State"
                optionFilterProp="children"
                onSelect={handleSelect}
                defaultValue={0}
              >
                <Option key={0} value={0}>
                  All States
                </Option>
                {stateListArr?.map((option) => (
                  <Option key={option.stateID} value={option.stateName}>
                    {option.stateName}
                  </Option>
                ))}
              </Select>
            </div>
            {/* <ThemeButton
              showDealer={false}
              showSchemeType={false}
              showStartMonth={true}
              showEndMonth={false}
              collectionData={collectionData}
              listData={[]}
              onSelectSchemeType={(schemeType) =>
                setCollectionData({ ...collectionData, schemeType })
              }
              handleStartChange={(date) => {
                setCollectionData({
                  ...collectionData,
                  startDate: date == null ? "" : date.format(),
                });
              }}
              handleEndChange={(date) =>
                setCollectionData({
                  ...collectionData,
                  endDate: date == null ? "" : date.format(),
                })
              }
              onSelectDealer={(dealerCode, currentSelected) => {}}
              onGetDealerDropdownData={() => {}}
              disableDateRestriction={true}
            /> */}
            <DatePicker
              mode="month"
              onChange={(date, dateString) => {
                setCollectionData({
                  ...collectionData,
                  startDate: date == null ? "" : date.format(),
                });
              }}
              className="start  h-100"
              placeholder="Start month"
              picker="month"
              disabledDate={disabledDate}
            // onPanelChange={(value)=>setCollectionData(({
            //   ...collectionData,
            //   startDate: value == null ? "" : value.format(),
            //   }))}
            // value ={startMonth}
            />
          </Col>
          <Col>
            <Button
              className="mx-1 h-full"
              disabled={dataSource?.length == 0}
              onClick={handleDownload}
            >
              Download
            </Button>
            {/* <Button
            disabled={dataSource?.length == 0}
            icon={<Download />}
            type="text"
            onClick={handleDownload}
          >
            Download
          </Button> */}
          </Col>
        </Row>
        <span>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </grid>

      <Form>
        <Table
          loading={
            (loadingState == "pending" || loadingCosmstateWise == "pending")
              ? true
              : false
          }

          // title={tableHeader}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowClassName={(record, index) =>
            (dataSource?.length==2&&index==1)||(dataSource?.length>2&&dataSource?.length-1===index) ? "pmis-active-row" : ""
          }
          className="pmisTable"
          summary={(pageData) => {
            const totalData = pageData?.filter((data) => !data?.stateName)?.[0] || pageData?.[0]||[]

            return (
              <Table.Summary.Row
                style={{ fontWeight: "500", fontSize: "18px" }}
              >
                <Table.Summary.Cell fixed index={0}>With Institutional Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{TextLocalString(totalData?.yTDTotalQuantity||"",true)}</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>{TextLocalString(totalData?.institutionalQty||"",true)}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{TextLocalString(totalData?.netInstitutionalQty||"",true)}</Table.Summary.Cell>
                <Table.Summary.Cell index={4}>{TextLocalString(totalData?.collIncentiveAmt||"",true)}</Table.Summary.Cell>
                <Table.Summary.Cell index={5}>{TextLocalString(totalData?.installationAmt||"",true)}</Table.Summary.Cell>
                <Table.Summary.Cell index={6}>{TextLocalString(totalData?.priceSupportAmt||"",true)}</Table.Summary.Cell>
                <Table.Summary.Cell index={7}>{TextLocalString(totalData?.bTLAmt||"",true)}</Table.Summary.Cell>
                <Table.Summary.Cell index={8}>{TextLocalString(totalData?.yTDTotalAmt||"",true)}</Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  {TextLocalString(Math.round((totalData?.collIncentiveAmt / totalData?.totalQuantity)||""),true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10}>
                  {TextLocalString(Math.round((totalData?.installationAmt / totalData?.totalQuantity) ||""),true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11}>
                  {TextLocalString(Math.round((totalData?.priceSupportAmt / totalData?.totalQuantity) ||""),true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={12}>
                  {TextLocalString(Math.round((totalData?.bTLAmt / totalData?.totalQuantity) ||""),true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={13}>
                  {TextLocalString(Math.round((totalData?.totalAmt / totalData?.totalQuantity) ||""),true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={14}>{TextLocalString(totalData?.yTDTotalQuantity,true)||""}</Table.Summary.Cell>
                <Table.Summary.Cell index={15}>{TextLocalString(totalData?.institutionalQty||"",true)}</Table.Summary.Cell>
                <Table.Summary.Cell index={16}>{TextLocalString(totalData?.netInstitutionalQty||"",true)}</Table.Summary.Cell>
                <Table.Summary.Cell index={17}>{TextLocalString(totalData?.yTDCollIncentiveAmt,true)||""}</Table.Summary.Cell>
                <Table.Summary.Cell index={18}>{TextLocalString(totalData?.yTDInstallationAmt,true)||""}</Table.Summary.Cell>
                <Table.Summary.Cell index={19}>{TextLocalString(totalData?.yTDPriceSupportAmt,true)||""}</Table.Summary.Cell>
                <Table.Summary.Cell index={20}>{TextLocalString(totalData?.yTDBTLAmt,true)||""}</Table.Summary.Cell>
                <Table.Summary.Cell index={21}>{TextLocalString(totalData?.yTDTotalAmt,true)||""}</Table.Summary.Cell>
                <Table.Summary.Cell index={22}>
                {TextLocalString(Math.round((totalData?.yTDCollIncentiveAmt / totalData?.yTDTotalQuantity) || ""),true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={23}>
                {TextLocalString(Math.round((totalData?.yTDInstallationAmt / totalData?.yTDTotalQuantity) || ""),true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={24}>
                {TextLocalString(Math.round((totalData?.yTDPriceSupportAmt / totalData?.totalQuantity) || ""),true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={25}>
                {TextLocalString(Math.round((totalData?.yTDBTLAmt / totalData?.totalQuantity) || ""),true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={26}>
              {TextLocalString(Math.round((totalData?.yTDTotalAmt / totalData?.totalQuantity) || ""),true)}
                </Table.Summary.Cell>
              </Table.Summary.Row>)
          }

          }
          bordered
          scroll={{
            x: 1000,
            y: 200,
          }}
        />
      </Form>

      <Pagination
        defaultCurrent={1}
        className="paginationDesign"
        // style={{ position: "absolute", right: "58px", marginTop: "-43px" }}
        current={collectionData.pageNumber}
        onChange={(pageNumber, pageSize) =>
          setCollectionData({ ...collectionData, pageNumber, pageSize })
        }
        total={dashboardCOSMStateWiseReport?.totalcount}
        showSizeChanger
        pageSize={collectionData.pageSize}
        pageSizeOptions={['150', '250', '350']}
      />
    </div>
  );
};
export default StateWiseScheme;
