import { Tag } from "antd";
import { STATE_WISE_DASHBOARD_LABEL } from "../../config";
import { formatNumberDecimal } from "../../utils/formatter";
import { TextLocalString, TextLocalStringCheck } from "../ToastifyShow";
import { render } from "@testing-library/react";

const useColumns = (dashboardType, userRole, page) => {
  let columnsList = [
    {
      title: (
        <div>
          Scheme <br /> No
        </div>
      ),
      key: "SchemeNo",
      dataIndex: "scheme",
      width: "140px",
      fixed: "left",
      defaultSortOrder: "ascend",
      sorter: true,
      render: (item) => {
        const dataItem = item?.holdStatus == 9 ? item?.holdStatus : item.status
        let color = " ";
        switch (dataItem) {
          case 0:
            color = "#FCE838"; //inProgress
            break;
          case 1:
            color = "#FCE838"; //inProgress
            break;
          case 2:
            color = "#51DE4E"; //Approved
            break;
          case 3:
            color = "#9999ff"; //Withdraw
            break;
          case 4:
            color = "#FF3232"; //Cancel
            break;
          case 5:
            color = "#FF3232"; //Reject
            break;
          case 7:
            color = "#bcb38d"; //Deviation
            break;
          case 8:
            color = "rgb(255, 0, 255)"; //Auto Reject
            break;
          case 9:
            color = "#9999ff"; //hold
            break;
          default:
            color = "#FCE838";
            break;
        }
        return (
          <Tag
            // item.status === 1 ? "#ffef66" : item.status === 2 ? "green":"red"
            style={{ color: "black", fontSize: "13px" }}
            color={color}
          >
            {item.schemeNo}
          </Tag>
        );
      },
    },
    {
      title: (
        <div>
          Dealer <br /> Code
        </div>
      ),
      key: "DealerCode",
      dataIndex: "code",
      width: "90px",
      fixed: "left",
      defaultSortOrder: "ascend",
      sorter: true,
    },

    {
      title: (
        <div>
          Dealer <br /> Location
        </div>
      ),

      dataIndex: "Location",
      fixed: "left",
      width: "90px",
      defaultSortOrder: "ascend",
      sorter: true,
      key: "DealerLocation",
      // onFilter: (value, record) => record.Location.indexOf(value) === 0,
    },
    {
      title: (
        <div>
          Dealer <br /> Name
        </div>
      ),
      key: "DealerName",
      dataIndex: "name",
      width: "90px",
      // fixed: "left",
      defaultSortOrder: "ascend",
      sorter: true,
    },
    {
      key: "StateName",
      title: "State",
      dataIndex: "state",
      width: "90px",
      defaultSortOrder: "ascend",
      sorter: true,
      //   fixed: "left",
    },
    {
      title: (
        <div>
          Territory <br /> Manager
        </div>
      ),
      key: "TerritoryManager",
      dataIndex: "Manager",
      width: "120px",
      defaultSortOrder: "ascend",
      sorter: true,
    }, {
      title: "TOP SLAB",
      width: "90px",
      render: (x, y, index) => y?.scheme?.topTarget?.toUpperCase(),
    },
    {
      title: (
        <div>
          Start <br /> Month
        </div>
      ),
      dataIndex: "StartMonth",
      width: "90px",
      defaultSortOrder: "descend",
      // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
    },
    {
      title: (
        <div>
          End <br /> Month
        </div>
      ),
      dataIndex: "EndMonth",
      width: "90px",
      defaultSortOrder: "descend",
      // sorter: (a, b) =>(a?.name&&b?.name)&&(a.name.length - b.name.length),
    },
    {
      title: "Industry",
      dataIndex: "industry",
      width: "80px",
      defaultSortOrder: "descend",
      // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
    },
    {
      title: "B",
      dataIndex: "oldBillingQty",
      width: "50px",
      defaultSortOrder: "descend",

      // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
    },
    {
      title: "I",
      dataIndex: "oldInstallation",
      width: "50px",
      defaultSortOrder: "descend",
      // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
    },
    {
      title: "C",
      dataIndex: "oldCollection",
      width: "50px",
      defaultSortOrder: "descend",
      // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
    },
    // {
    //     title: "Slab Achieved Last Year",
    //     dataIndex: "target",
    //     defaultSortOrder: "descend",
    //     sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
    // },
    {
      title: (
        <div>
          Opening <br /> Stock
        </div>
      ),
      dataIndex: "stock",
      width: "90px",
      defaultSortOrder: "descend",

      //   sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      children: [
        {
          title: "Nos",
          dataIndex: "oldOpeningStock",
          width: "60px",
          defaultSortOrder: "descend",

        },
        {
          title: "Days",
          dataIndex: "openingStockDays",
          width: "60px",
          defaultSortOrder: "descend",

        },
      ],
    },
    // {
    //     title: "Nov'21",
    //     dataIndex: "nov21",
    // },
    // {
    //     title: "dec'21",
    //     dataIndex: "dec21",
    // },
    {
      title: "Closing Os. (In Lacs)",
      dataIndex: "stock",
      defaultSortOrder: "descend",
      //   sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      children: [
        {
          title: (
            <span>
              Last <span className="ordinal-title">3<sup className="ordinal-suffix">rd</sup></span> Month
            </span>
          ),
          dataIndex: "lastThirdMonth",
          width: "120px",

          defaultSortOrder: "descend",
          render: (x, y) => formatNumberDecimal(x) || "0",

        },
        {
          title: (
            <span>
              Last <span className="ordinal-title">2<sup className="ordinal-suffix">nd</sup></span> Month
            </span>
          ),
          dataIndex: "lastTwoMonth",
          width: "120px",

          defaultSortOrder: "descend",
          render: (x, y) => formatNumberDecimal(x) || "0",

        },
        {
          title: (
            <span>
            Last <span className="ordinal-title">1<sup className="ordinal-suffix">st</sup></span> Month
          </span>
          ),
          dataIndex: "lastOneMonth",
          width: "120px",

          defaultSortOrder: "descend",
          render: (x, y) => formatNumberDecimal(x) || "0",

        },
      ],
    },
    {
      title: "Closing OS Days",
      dataIndex: "outStandingDays",
      children: [
        {
          title: "OS Last Month",
          dataIndex: "outStandingDays",
          width: "90px",
          render: (x, y) => x || 0,
        },
      ],
    },
    {
      title: "BG",
      dataIndex: "bg",
      defaultSortOrder: "descend",
      width: "60px",
      //   sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      children: [
        {
          title: "Lacs",
          dataIndex: "bgLacsCurrentYear",
          defaultSortOrder: "descend",
          width: "60px",
          render: (x) => TextLocalString(x)
        },
      ],
    },
    {
      title: (
        <div>
          Installation <br /> Plan
        </div>
      ),
      dataIndex: "IP",
      defaultSortOrder: "descend",
      width: "60px",
      // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      children: [
        {
          title: "Nos",
          dataIndex: "delayPlan",
          width: "60px",
          defaultSortOrder: "descend",
          render: (x) => TextLocalString(x)
        },
      ],
    },
    {
      title: (
        <div>
          Billing <br /> Details
        </div>
      ),
      dataIndex: "plan",
      defaultSortOrder: "descend",
      //   sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      children: [
        {
          title: "B",
          dataIndex: "b",
          width: "60px",
          defaultSortOrder: "descend",
          render: (x) => TextLocalString(x)
          
        },
        {
          title: "I",
          dataIndex: "i",
          defaultSortOrder: "descend",
          width: "60px",
          render: (x) => TextLocalString(x)
        },
        {
          title: "C",
          dataIndex: "c",
          defaultSortOrder: "descend",
          width: "60px",
          render: (x) => TextLocalString(x)
        },
      ],
    },
    {
      title: (
        <div>
          Closing <br /> Stock
        </div>
      ),
      render: (x) => TextLocalString(x),
      dataIndex: "closingStock",
      width: "60px",
    },
    {
      title: (
        <div>
          Closing <br /> OutStanding (In Lacs)
        </div>
      ),
      dataIndex: "closingOutStanding",
      width: "120px",
      render: (x) => TextLocalString(x)
    },
    {
      title: "Books Collection Targets (No. of Trs.)",
      dataIndex: "BooksCollectionTarget",
      defaultSortOrder: "descend",
      //   sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      children: [
        {
          title: "T1",
          dataIndex: "BooksCollectionTargetT1",
          defaultSortOrder: "descend",
           width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t1") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }

        },
        {
          title: "T2",
          dataIndex: "BooksCollectionTargetT2",
          defaultSortOrder: "descend", 
          width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }

        },
        {
          title: "T2-T1",
          dataIndex: "BooksCollectionTargetT2T1",
          defaultSortOrder: "descend",
           width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }

        },
        {
          title: "T3",
          dataIndex: "BooksCollectionTargetT3",
          defaultSortOrder: "descend", 
          width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }

        },
        {
          title: "T3-T2",
          dataIndex: "BooksCollectionTargetT3T2",
          defaultSortOrder: "descend",
           width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }

        },
        {
          title: "T4",
          dataIndex: "BooksCollectionTargetT4",
          defaultSortOrder: "descend", 
          width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t4") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }

        },
        {
          title: "T4-T3",
          dataIndex: "BooksCollectionTargetT4T3",
          defaultSortOrder: "descend",
           width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t4") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
        {
          title: "T5",
          dataIndex: "BooksCollectionTargetT5",
          defaultSortOrder: "descend",
           width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t5") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }

        },
        {
          title: "T5-T4",
          dataIndex: "BooksCollectionTargetT5T4",
          defaultSortOrder: "descend", width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t5") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
        {
          title: "T6",
          dataIndex: "BooksCollectionTargetT6",
          defaultSortOrder: "descend", width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t6") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }

        },
        {
          title: "T6-T5",
          dataIndex: "BooksCollectionTargetT6T5",
          defaultSortOrder: "descend", width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t6") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
      ],
    },
    {
      title: (
        <div>
          Payout <br /> Rate
        </div>
      ),
      dataIndex: "pr",
      defaultSortOrder: "descend",
      //   sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),

      children: [
        {
          title: "R1",
          dataIndex: "PayoutRatep1",
          defaultSortOrder: "descend",
          width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t1") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
        {
          title: "R2",
          dataIndex: "PayoutRatep2",
          defaultSortOrder: "descend",
          width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
        {
          title: "R3",
          dataIndex: "PayoutRatep3",
          defaultSortOrder: "descend",
          width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
        {
          title: "R4",
          dataIndex: "PayoutRatep4",
          defaultSortOrder: "descend",
          width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t4") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
        {
          title: "R5",
          dataIndex: "PayoutRatep5",
          defaultSortOrder: "descend",
          width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t5") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
        {
          title: "R6",
          dataIndex: "PayoutRatep6",
          defaultSortOrder: "descend",
          width: "60px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t6") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
      ],
    },
    {
      title: "Remarks",
      dataIndex: "reason",
      width: "100px",
      defaultSortOrder: "descend",
      // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
    },
    {
      title: "Hold Remarks",
      dataIndex: "holdReason",
      width: "100px",
    },
    {
      title: "Payout",
      dataIndex: "payout",
      defaultSortOrder: "descend",
      //   sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),

      children: [
        {
          title: "P1",
          dataIndex: "payoutR1",
          defaultSortOrder: "descend",
          width: "90px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t1") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }

        },
        {
          title: "P2",
          dataIndex: "payoutR2",
          defaultSortOrder: "descend",
          width: "90px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t2") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
        {
          title: "P3",
          dataIndex: "payoutR3",
          defaultSortOrder: "descend",
          width: "90px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t3") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
        {
          title: "P4",
          dataIndex: "payoutR4",
          defaultSortOrder: "descend",
          width: "90px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t4") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }

        },
        {
          title: "P5",
          dataIndex: "payoutR5",
          defaultSortOrder: "descend",
          width: "90px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t5") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
        {
          title: "P6",
          dataIndex: "payoutR6",
          defaultSortOrder: "descend",
          width: "90px",
          render: (x, y) => {
            return (
              !y?.scheme?.topTarget?.split(",")?.includes("t6") ?
                <div className="h-24 input-disabled"></div> : <div >{TextLocalString(x)}</div>
            )
          }
        },
      ],
    },
    {
      title: "Total Expected Payout",
      dataIndex: "TEP",
      defaultSortOrder: "descend",
      //   sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      children: [
        {
          title: "Expected Volume",
          dataIndex: "TEPvol",
          defaultSortOrder: "descend", width: "120px",
          render: (x) => TextLocalString(x)

        },
        {
          title: "Per Tr.",
          dataIndex: "TEPpertr",
          defaultSortOrder: "descend", width: "120px",
          render: (x) => TextLocalString(x)

        },
        {
          title: "Amount",
          dataIndex: "TEPamount",
          defaultSortOrder: "descend", width: "120px",
          render: (x) => TextLocalString(x)
          //         render: (x, y) => x.toLocaleString('en-IN')
        },
      ],
    },
    // {
    //     title: "",
    //     dataIndex: "",
    //     children: [
    //         {
    //             title: "Last 1 Month Slab Achieved",
    //             dataIndex: "LMSA",
    //         },
    //         {
    //             title: "Last 1 Month Col Qty",
    //             dataIndex: "LCQ",
    //         },
    //         {
    //             title: "Last 2 Month Slab Achieved",
    //             dataIndex: "LMSA1",
    //         },
    //         {
    //             title: "Last 2 Month Col Qty",
    //             dataIndex: "LCQ1",
    //         },
    //     ],
    // },
  ];
  // Actual Billing Amount
  // Actual Billing Quantity
  // Billing Factor
  // Actual Collection
  // Collection Quantity
  // Achieved Slab
  // Payout
  if (dashboardType === STATE_WISE_DASHBOARD_LABEL) {
    columnsList = [
      ...columnsList,
      {
        title: "Actual Billing Amount",
        dataIndex: "actualBillingAmount",
        // sorter: true,
        // fixed: "left",

        render: (x, y) => TextLocalString(Math?.round(x)) || "0", width: "90px",
        defaultSortOrder: "descend",
        // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      },
      {
        title: "Actual Billing Quantity",
        dataIndex: "actualBillingQuantity",
        width: "90px",
        // sorter: true,
        render: (x, y) => Math?.round(x) || "0",
        // fixed: "left",
        defaultSortOrder: "descend",
        // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      },
      {
        title: "Billing Factor",
        dataIndex: "billingFactor",
        width: "90px",
        // sorter: true,
        // fixed: "left",
        render: (x, y) => TextLocalString(x),
        defaultSortOrder: "descend",
        // render: (item, x) => {
        //   let calculateBillingFactor=Math.round(x?.actualBillingAmount / x?.actualBillingQuantity)
        //   return (
        //     item
        //   );
        // },
        // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      },
      {
        title: "Actual Collection",
        dataIndex: "actualCollection",
        width: "90px",
        // sorter: true,
        // fixed: "left",
        render: (x, y) => TextLocalStringCheck(x),
        defaultSortOrder: "descend",
        // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      },
      {
        title: "Collection Quantity",
        dataIndex: "collectionQuantity",
        width: "90px",
        render: (x) => <div>{TextLocalString(x)}</div>,
        // sorter: true,
        // fixed: "left",
        // render: (x, y) => {
        //   let numberDetails =
        //     Math.round(
        //       parseInt(y?.actualCollection) /
        //       parseInt(
        //         Math.round(
        //           y?.actualBillingAmount / y?.actualBillingQuantity
        //         ) || 0
        //       )
        //     ) || 0;
        //   return numberDetails == Infinity
        //     ? 0
        //     : numberDetails
        //       ? Math.round(numberDetails)
        //       : 0;
        // },
        defaultSortOrder: "descend",
        // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      },
      {
        title: "Achieved Slab",
        dataIndex: "achievedSlab", width: "90px",
        // sorter: true,
        // fixed: "left",
        // render: (x, y) => {
        //   const {t1,t2,t3,t4,t5,t6,topTarget}=y?.scheme
        // //   if(y?.code=="NOH205521"){
        // //  let actualAmount1=   parseInt(Math.round(
        // //     y?.actualBillingAmount / y?.actualBillingQuantity
        // //   ) || 0)

        // //   let collquantity1 =
        // //     Math.round(
        // //       parseInt(y?.actualCollection) /parseInt(actualAmount1)
        // //     ) || 0;
        // //  }

        //  let actualAmount=   parseInt(Math.round(
        //   y?.actualBillingAmount / y?.actualBillingQuantity
        // ) || 0)
        //   let collquantity =
        //     Math.round(
        //       parseInt(y?.actualCollection) /parseInt(actualAmount)
        //     ) || 0;


        //     let Achievedslab = "No Slab"
        //   let collquantity1=collquantity==Infinity?0:parseInt(collquantity)
        //   if(topTarget?.split(",")?.includes("t6")){
        //   if (collquantity1 < t1) {
        //     Achievedslab = "No Slab"
        //   } else if (collquantity1 >=  parseInt(t1) && collquantity1 < parseInt(t2)) {
        //     Achievedslab = "T1"
        //   } else if (collquantity1 >=  parseInt(t2) && collquantity1 <  parseInt(t3)) {
        //     Achievedslab = "T2"
        //   } else if (collquantity1 >=  parseInt(t3) && collquantity1 <  parseInt(t4)) {
        //     Achievedslab = "T3"
        //   } else if (collquantity1 >=  parseInt(t4) && collquantity1 <  parseInt(t5)) {
        //     Achievedslab = "T4"
        //   } else if (collquantity1 >=  parseInt(t5) && collquantity1 <  parseInt(t6)) {
        //     Achievedslab = "T5"
        //   } else if (collquantity1 >= t6) {
        //     Achievedslab = "T6"
        //   }
        // }else if(topTarget?.split(",")?.includes("t5")){
        //   if (collquantity1 < t1) {
        //     Achievedslab = "No Slab"
        //   } else if (collquantity1 >=  parseInt(t1) && collquantity1 < parseInt(t2)) {
        //     Achievedslab = "T1"
        //   } else if (collquantity1 >=  parseInt(t2) && collquantity1 <  parseInt(t3)) {
        //     Achievedslab = "T2"
        //   } else if (collquantity1 >=  parseInt(t3) && collquantity1 <  parseInt(t4)) {
        //     Achievedslab = "T3"
        //   } else if (collquantity1 >=  parseInt(t4) && collquantity1 <  parseInt(t5)) {
        //     Achievedslab = "T4"
        //   } else if (collquantity1 >=  parseInt(t5)) {
        //     Achievedslab = "T5"
        //   } 
        // }else if(topTarget?.split(",")?.includes("t4")){
        //   if (collquantity1 < t1) {
        //     Achievedslab = "No Slab"
        //   } else if (collquantity1 >=  parseInt(t1) && collquantity1 < parseInt(t2)) {
        //     Achievedslab = "T1"
        //   } else if (collquantity1 >=  parseInt(t2) && collquantity1 <  parseInt(t3)) {
        //     Achievedslab = "T2"
        //   } else if (collquantity1 >=  parseInt(t3) && collquantity1 <  parseInt(t4)) {
        //     Achievedslab = "T3"
        //   } else if (collquantity1 >=  parseInt(t4)) {
        //     Achievedslab = "T4"
        //   } 
        // }else if(topTarget?.split(",")?.includes("t3")){
        //   if (collquantity1 < t1) {
        //     Achievedslab = "No Slab"
        //   } else if (collquantity1 >=  parseInt(t1) && collquantity1 < parseInt(t2)) {
        //     Achievedslab = "T1"
        //   } else if (collquantity1 >=  parseInt(t2) && collquantity1 <  parseInt(t3)) {
        //     Achievedslab = "T2"
        //   } else if (collquantity1 >=  parseInt(t3)) {
        //     Achievedslab = "T3"
        //   }
        // }else if(topTarget?.split(",")?.includes("t2")){
        //   if (collquantity1 < t1) {
        //     Achievedslab = "No Slab"
        //   } else if (collquantity1 >=  parseInt(t1) && collquantity1 < parseInt(t2)) {
        //     Achievedslab = "T1"
        //   } else if (collquantity1 >=  parseInt(t2)) {
        //     Achievedslab = "T2"
        //   } 
        // }else if(topTarget?.split(",")?.includes("t5")){
        //   if (collquantity1 < t1) {
        //     Achievedslab = "No Slab"
        //   } else if (collquantity1 >=  parseInt(t1)) {
        //     Achievedslab = "T1"
        //   } 
        // }

        //   return x ||"No Slab"
        // },
        defaultSortOrder: "descend",
        // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      },
      {
        title: "Payout 1",
        dataIndex: "payout", width: "90px",
        render: (x) => <div>{TextLocalString(x)}</div>,
        // sorter: true,
        // fixed: "left",
        //collectionQuantity
        // render: (x, y) => {
        //   //  y?.collectionQuantityy
        //   let payout = 0;

        //   let collactionQty =
        //     Math.round(
        //       parseInt(y?.actualCollection) /
        //       parseInt(
        //         Math.round(
        //           y?.actualBillingAmount / y?.actualBillingQuantity
        //         ) || 0
        //       )
        //     ) || 0;
        //   let { collectionQuantity, t1, t2, t3, t4, t5, t6, r1, r2, r3, r4, r5, r6 } = y?.scheme;
        //   if (collactionQty < t1) {
        //     payout = 0;
        //   } else if (collactionQty >= t1 && collactionQty < t2) {
        //     payout = collactionQty * r1;
        //   } else if (collactionQty >= t2 && collactionQty < t3) {
        //     payout = collactionQty * r2;
        //   } else if (collactionQty >= t3 && collactionQty < t4) {
        //     payout = collactionQty * r3;
        //   } else if (collactionQty >= t4 && collactionQty < t5) {
        //     payout = collactionQty * r4;
        //   } else if (collactionQty >= t5 && collactionQty < t6) {
        //     payout = collactionQty * r5;
        //   } else if (collactionQty >= t6) {
        //     payout = collactionQty * r6;
        //   }

        //   return x;
        // },
        defaultSortOrder: "descend",
        // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      },
      {
        title: "Payout 2",
        dataIndex: "payout2",
        // sorter: true,
        // fixed: "left",
        defaultSortOrder: "descend", width: "90px",
        render: (x) => <div>{TextLocalString(x)}</div>
        // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      },
      {
        title: "Payout 3",
        dataIndex: "payout3",
        // sorter: true,
        // fixed: "left",
        defaultSortOrder: "descend", width: "90px",
        render: (x) => <div>{TextLocalString(x)}</div>
        // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      },
      {
        title: "Payout 4",
        dataIndex: "payout4",
        // sorter: true,
        // fixed: "left",
        defaultSortOrder: "descend", width: "90px",
        render: (x) => <div>{TextLocalString(x)}</div>
        // sorter: (a, b) => (a?.name&&b?.name)&&(a.name.length - b.name.length),
      },
    ];
  }

  return columnsList;
};

export default useColumns;
